import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, ConfiguracionService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

import { ActivatedRoute } from '@angular/router';
import { orderBy, process, State } from '@progress/kendo-data-query';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { fromEvent, Subscription } from 'rxjs';

declare var GanttChartDrag: any;
//declare var GanttChartClick: any;

import { take, tap } from 'rxjs/operators';
import { Renderer2, NgZone } from '@angular/core';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { MaquinaDetalleAlarmasControladasComponent } from '../maquinaDetalle/maquinaDetalleAlarmasControladas.component';

//import { MaquinaDetalleAlarmasControladasComponent } from '../maquinaDetalle/maquinaDetalleAlarmasControladas.component';

import { Router } from '@angular/router';
//import { Maquina } from '../_models';

import { GroupResult, groupBy } from '@progress/kendo-data-query';
//import * as moment from 'moment';

import { MyFunctions } from '@app/_helpers';

import { RowClassArgs } from '@progress/kendo-angular-grid';

// ALETR GUARDADO
import { AlertService } from '@app/_services';
import { NodeWithI18n } from '@angular/compiler';
import { isTypeOnlyImportOrExportDeclaration } from 'typescript';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Marca } from '@app/_models';
import { Messages$1 } from '@progress/kendo-angular-dateinputs';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-planificadorCorto',
  templateUrl: './planificadorCorto.component.html',
  styles: [`.k-grid tr.dragging {
                /*background-color: #00b8bd;*/
            };`]
})
export class PlanificadorCortoComponent {
  @ViewChild('popupReorganizar') popupReorganizar: NgbModalRef;
  @ViewChild('popupUsarVersion') popupUsarVersion: NgbModalRef;
  @ViewChild('popupBorrarVersion') popupBorrarVersion: NgbModalRef;
  @ViewChild('popupCopiarAVersion') popupCopiarAVersion: NgbModalRef;
  @ViewChild('popupVistaTemporal') popupVistaTemporal: NgbModalRef;
  @ViewChild('popupMandarALargo') popupMandarALargo: NgbModalRef;
  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  //FILTRO
  public aplicarTiempoEstimado: boolean = true;
  public aplicarIneficiencias: boolean = false;
  public multiplicadorTiempos: number = 0.0;
  public textoPlanificadorCortoTask_Inferior: number = 1;
  public Jplanificadores: any;
  public JplanificadoresSelected: any;
  public JplanificadoresSinOriginal: any;
  public JplanificadoresSelectedCopiar: any;
  //TABLAS PRINCIPALES
  private DatPlanificador: JSON;
  private DatPlanificador_grupos: JSON;
  private DatPlanificador_gruposOperaciones: JSON;
  private DatPlanificador_tiemposEjecutados: JSON;
  private DatMaquinas: any;
  private DatTurnos: JSON;
  private tasks: any;
  //GANTT
  private ganttChart: any;

  //AYUDAS FECHAS
  private today: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  //INFO
  public visibleInfo: boolean = false;
  public visibleInfoGrupo: boolean = false;
  public infoOF: string;
  public infoCliente: string;
  public infoProyecto: string;
  public infoPlano: string;
  public infoRefPieza: string;
  public infoPieza: string;
  public infoParte: string;

  public JsemanaSelected: any;
  public JsemanasSelected: any;
  public Jsemanas = [];

  public JmaquinaSelected: any;
  public Jmaquinas: any;
  public JmaquinasMostradas: any;
  public JmaquinaSelectedLargo: any;
  public JmaquinasSelectedLargo: any;
  public JmaquinasLargo: any;
  public JmaquinasLargoMostradas: any;
  public JmaquinasLargoMostradasCorto: any;
  public JmaquinasSelectedCorto: any;

  public Joperaciones: any;//GRID PROGRAMAS
  public operacionesSelected = [];//GRID PROGRAMAS SELECCIONADOS 

  public requiereMaquina: boolean = false;
  public requiereMaquinaLargo: boolean = false;
  public requiereSemana: boolean = false;

  public dtTiemposMaquinas: any;

  public Jgrupos: JSON;
  public DatOperaciones_grupos: JSON;

  public DatOperacionesSinCorto: JSON;
  public JOperacionesSinCortoTodo: any;
  public JOperacionesSinCortoView: any;
  public JOperacionesSinCorto: any;
  public JOperacionesSinCortoSelecteds = [];
  public JOperacionesSinCortoSelectedsAux = [];

  public JOperacionesCortoTodo: any;
  public JoperacionesCorto: any;
  public JOperacionesCortoSelecteds = [];

  public JOperacionesCortoSelectedsDrag: number[] = [];
  public JOperacionesCortoSelectedsAux = [];
  public stateDrag: State = {
    skip: 0,
    take: 100
  };
  public gridData: any = process([], this.stateDrag);

  public visibleUsarVersion: boolean = false;

  public loadingPanel: boolean = false;

  public pageSize = 40;
  public skip = 0;

  private dataFiltro: any;
  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any;
  public piezasSeleccionados: any;

  public JgruposMaquinas: any;
  public JgruposMaquinasSelected: any;
  public JgruposMaquinasMaquinas: any;

  popupReorganizandoVersion: boolean = false;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //OPERACUIBES HECHAS AHORA MISMO
  private operacionesActuales: any = [];

  //DATOS DE CONFIGURACION
  public dtConfiguracion: any = {};
  public dtMaquinas_temperaturas_rangos: any = {};
  public dtMaquinas_tiempoMinimoCambioTemperatura: any = {};
  public dtConfiguracionPlanificador: any = {};
  public prioridadCliente: number;
  public prioridadFacturacion: number;
  public prioridadOF: number;
  public prioridadMaquina: number;
  public prioridadFecha: number;
  public prioridadTemperatura: number;
  public dias_reserva: number;
  public percent_cap_maquina: number;
  public optimizarCuelloBotella: boolean;
  public metodologiasDePlanificacion: any;
  public metodologiasDePlanificacionSelected: any;
  public replanificarTiempoEstimado: boolean;
  public replanificarIneficiencias: boolean;
  public planificarSoloNoPlanificadas: boolean;
  public asap_maximoMesesParaIniciarAntesDeFechaEntrega: number;

  public ocultarParte: number = 0;
  public tiempoOperacionMinimoPlanificadorCorto: number = 600; // 10 MINUTOS POR DEFECTO: luego se carga desde configuracion_variables! NO DESDE PLANIFICADOR

  public requiereSemanaMandarALargo: boolean = false;
  public JsemanaSelectedMandarALargo: any;
  public replanificarMandarALargo: boolean = false;


  public idOperacionesGrupos: number;
  public nombreGrupo: string;
  public temperaturaGrupo: string;
  public tiempoEstimadoGrupo: string;
  public pesoGrupo: string;
  public unidadesGrupo: string;

  private cadenas_Maquinas = [];

  //D&D  
  private currentSubscription: Subscription;
  public targetCells: NodeListOf<HTMLTableDataCellElement>;


  public GANTT_multirrow = false;
  public GANTT_marcadores = false;


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  //"LOAD"
  constructor(private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
    private modalService: NgbModal, private planificadorService: PlanificadorService, public myFunctions: MyFunctions,
    public route: ActivatedRoute, private menuService: MenuService, private configuracionService: ConfiguracionService, public router: Router, private renderer: Renderer2,
    private zone: NgZone, private alertService: AlertService) {

    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
        });
    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
    }

    this.loadingPanel = true;

    this.menuService.titulo = this.translateService.instant('corto').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.planificadorService.getPlanificadoresActivos(this.translateService.instant('real')).subscribe(
      json => {
        this.Jplanificadores = json;
        this.JplanificadoresSelected = json[0];

        var copia: any = [];
        copia.push(json[0]);
        copia.push(json[1]);
        copia.push(json[2]);
        copia.push(json[3]);

        this.JplanificadoresSinOriginal = copia;
        this.JplanificadoresSelectedCopiar = copia[0];

        var r1, r2, r3, r4, r5, r6, r7: boolean = false;
        var version = 1;

        if (this.JplanificadoresSelected != undefined)
          version = this.JplanificadoresSelected.value;

        this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
          this.dtConfiguracionPlanificador = result[0];

          this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
          this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
          this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;
          this.textoPlanificadorCortoTask_Inferior = this.dtConfiguracionPlanificador.textoPlanificadorCortoTask_Inferior;

          this.planificadorService.get_maquinas_tiemposEstimados(this.JplanificadoresSelected.value).subscribe(
            json => {
              this.dtTiemposMaquinas = (json as any).dtTiempos;
              this.DatPlanificador_grupos = (json as any).dtOperaciones_grupos;
              this.DatPlanificador_gruposOperaciones = (json as any).dtOperaciones_grupos_operaciones;

              this.getDatosGrid();

              this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                json => {

                  this.DatPlanificador = (json as any).dtOperaciones;

                  (this.DatPlanificador as any).forEach(
                    operacionoGrupo => {
                      // Cogemos el tiempo estimado de esa operacion para esa maquina
                      var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                        ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                          (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                      // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                      if (tiempos.length == 0) {
                        tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                          (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                      }
                      if (tiempos.length > 0) {
                        // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                        // 1. tiempo estimado o predictivo
                        // 2. tiene preparacion si es principal = 1
                        var tiempo = tiempos[0];
                        if (this.aplicarTiempoEstimado) {
                          operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                          //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                          operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                        } else {
                          operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                          //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                          operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                        }
                        // 3. se aplica infeciciencia en caso de ser necesario
                        operacionoGrupo.tiempoIneficiencia = 0;
                        if (this.aplicarIneficiencias) {
                          operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                        }
                        operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                      }
                      // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                      // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                      if (operacionoGrupo.idOperacion > -1) {
                        operacionoGrupo.nombre = '';
                        if (operacionoGrupo.cliente != '')
                          operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                        if (operacionoGrupo.plano != '')
                          operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                        if (operacionoGrupo.pieza != '')
                          operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                        if (operacionoGrupo.refPieza != '')
                          operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                        // if (operacionoGrupo.plano != '')
                        //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                        if (operacionoGrupo.parte != '' && !this.ocultarParte)
                          operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                        if (operacionoGrupo.nombre != "")
                          operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                      } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                        operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                      }
                    });

                  r1 = true;
                  if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
                    this.PREcargarGantt();
                  //this.cargarGantt_operaciones_temporal();
                });
            });
        });


        var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
        if (maquinas_Y_subcontratado_planificador_model == false) {
          this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {

            this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);

            this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
            this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
            //FLTRO POR SECCIONES
            var idsSeccionesSelecteds: any = [];
            if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
              this.seccionesSeleccionadas.forEach(
                seccion => {
                  idsSeccionesSelecteds.push(seccion.id);
                });
            } else {
              this.secciones.forEach(
                seccion => {
                  idsSeccionesSelecteds.push(seccion.id);
                });
            }
            this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
            this.JmaquinasLargoMostradas = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
            this.JmaquinasLargoMostradasCorto = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
            this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
            r2 = true;
            if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
              this.PREcargarGantt();
            //this.cargarGantt_operaciones_temporal();
          })
        }
        else {
          this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
          this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
          //FLTRO POR SECCIONES
          var idsSeccionesSelecteds: any = [];
          if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
            this.seccionesSeleccionadas.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }
          else {
            this.secciones.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }
          this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
          this.JmaquinasLargoMostradas = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
          this.JmaquinasLargoMostradasCorto = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
          this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
          r2 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
            this.PREcargarGantt();
          //this.cargarGantt_operaciones_temporal();
        }

        this.planificadorService.Get_turnos(this.now).subscribe(
          json => {
            this.DatTurnos = json;
            r3 = true;
            if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
              this.PREcargarGantt();
            //this.cargarGantt_operaciones_temporal();
          });

        this.maquinasService.getGruposMaquinasPlanificadorTodos().subscribe(
          json => {
            this.JgruposMaquinas = json.data;

            r4 = true;
            if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
              this.PREcargarGantt();
          }
        );

        this.planificadorService.getGrupoMaquinas_MaquinasYSubcontratasPlanificadorById(0).subscribe(
          json => {
            this.JgruposMaquinasMaquinas = json;

            r5 = true;
            if (r1 && r2 && r3 && r4 && r5 && r6)
              this.PREcargarGantt();
          }
        );

        this.configuracionService.get_configuracion().subscribe(result => {
          this.dtConfiguracion = result[0];
          this.ocultarParte = this.dtConfiguracion.ocultarParte;
          this.tiempoOperacionMinimoPlanificadorCorto = this.dtConfiguracion.tiempoOperacionMinimoPlanificadorCorto;
          r6 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
            this.PREcargarGantt();
        });
        this.planificadorService.get_maquinas_temperaturas_rangos().subscribe(
          result => {
            this.dtMaquinas_temperaturas_rangos = result['maquinas_temperaturas_rangos'];
            this.dtMaquinas_tiempoMinimoCambioTemperatura = result['maquinas_tiempoMinimoCambioTemperatura'];
            r7 = true;
            if (r1 && r2 && r3 && r4 && r5 && r6 && r7)
              this.PREcargarGantt();
          });
      });

    this.planificadorService.GetFechaSemanasLimite().subscribe(
      (json) => {
        if (Object.keys(json).length > 0) {
          var a: any = json[0];

          var fecha = this.startOfWeek(this.dateCopy(this.now))
          var semana = this.getNumberOfWeek(fecha);
          var año = fecha.getFullYear();

          var fechaMax = a.fechaMax;
          var añoMax = a.año;
          var semanaMax = a.semana;

          var index = 1;
          while (año <= añoMax || (año == añoMax && semana <= semanaMax)) {
            //crear linea Json para añadir al "grid"
            var fecha2 = new Date(fecha);
            var js: any = {};
            js['value'] = index;
            js['fecha'] = fecha2;
            js['año'] = año;
            js['semana'] = semana;
            js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
            this.Jsemanas.push(js);

            //actualizar variables para proximo loop
            index++;
            fecha.setDate(fecha.getDate() + 7);
            semana = this.getNumberOfWeek(fecha);
            año = fecha.getFullYear();
          }
        } else {
          var fecha = this.startOfWeek(this.dateCopy(this.now));
          var semana = this.getNumberOfWeek(fecha);
          var año = fecha.getFullYear();


          var index = 1;
          //crear linea Json para añadir al "grid"
          var fecha2 = new Date(fecha);
          var js: any = {};
          js['value'] = index;
          js['fecha'] = fecha2;
          js['año'] = año;
          js['semana'] = semana;
          js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
          this.Jsemanas.push(js);
        }
      }
    );

    this.configuracionService.get_metodologiaDePlanificacion().subscribe(result => {
      var an: any = result;
      an.forEach(element => {
        element.nombre = this.translateService.instant(element.nombre);
      });
      this.metodologiasDePlanificacion = an;

      this.metodologiasDePlanificacionSelected = an[0];

    });

    this.cargarDatosFiltro();

    //PARA IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION 
    this.allData = this.allData.bind(this);
    this.allDataLargo = this.allDataLargo.bind(this);

  }
  ngOnInit() {

    this.planificadorService.get_maquinas_encadenadas().subscribe(
      (json) => {
        this.cadenas_Maquinas = this.myFunctions.conseguir_cadenasDeMaquinas(json);
      }
    );

  }

  /////////SELECT ALL GRID/////////
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public state: any;

  public onSelectAllChangeGrid(checkedState: SelectAllCheckboxState) {

    if (checkedState === 'checked') {

      this.JOperacionesSinCortoSelecteds = [];
      this.selectAllState = 'checked';

      var dataParaFiltrar;
      if (this.state == undefined) {
        dataParaFiltrar = this.JOperacionesSinCorto;
      } else {
        this.state.skip = 0;
        this.state.take = 10000000;
        dataParaFiltrar = process(this.JOperacionesSinCorto, this.state).data;
      }

      dataParaFiltrar.forEach(
        operacion => {
          this.JOperacionesSinCortoSelecteds.push(operacion.idPlanificador)
        });

    } else {

      this.JOperacionesSinCortoSelecteds = [];
      this.selectAllState = 'unchecked';

    }

    this.JOperacionesSinCortoSelectedsAux = this.JOperacionesSinCortoSelecteds;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.JOperacionesSinCortoSelecteds = this.JOperacionesSinCortoSelectedsAux;
    this.selectAllState = 'unchecked';
  }

  public pageChangeGrid(event: PageChangeEvent): void {
    this.JOperacionesSinCortoSelecteds = this.JOperacionesSinCortoSelectedsAux;
  }
  /////////FIN SELECT ALL GRID/////////

  /////////SELECT ALL GRID CORTO/////////
  public selectAllStateCorto: SelectAllCheckboxState = 'unchecked';
  public stateCorto: any;

  public dataStateChangeCorto(state: DataStateChangeEvent): void {
    this.stateCorto = state;
    this.JOperacionesCortoSelecteds = this.JOperacionesCortoSelectedsAux;
    this.selectAllState = 'unchecked';
  }

  public pageChangeCorto(event: PageChangeEvent): void {
    this.JOperacionesCortoSelecteds = this.JOperacionesCortoSelectedsAux;
  }

  loadItemsCorto() {
    this.JOperacionesCortoSelecteds = this.JOperacionesCortoSelectedsAux.slice(this.skip, this.skip + this.pageSize);
  }

  onSelectAllChangeGridCorto(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {

      this.JOperacionesCortoSelecteds = [];
      this.selectAllStateCorto = 'checked';

      var dataParaFiltrar;
      if (this.state == undefined) {
        dataParaFiltrar = this.JoperacionesCorto;
      } else {
        this.state.skip = 0;
        this.state.take = 10000000;
        dataParaFiltrar = process(this.JoperacionesCorto, this.state).data;
      }

      dataParaFiltrar.forEach(
        operacion => {
          this.JOperacionesCortoSelecteds.push(operacion.idPlanificador)
        });

    } else {

      this.JOperacionesCortoSelecteds = [];
      this.selectAllStateCorto = 'unchecked';

    }

    this.JOperacionesSinCortoSelectedsAux = this.JOperacionesCortoSelecteds;
  }
  /////////FIN SELECT ALL GRID CORTO/////////
  // EXPORTAR EXCEL CORTO
  imprimirXls(e, grid: GridComponent) {
    console.log("________________________________________________________________________________")
    console.log(grid)
    e.preventDefault();
    setTimeout(function () {
      grid.saveAsExcel();
    });
  }
  // FIN EXPORTAR EXCEL CORTO
  //FILTRO AREAS PRODUCTIVAS / SECCIONES
  seccionChanged() {
    this.ganttChart = undefined;

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
    this.JmaquinasLargoMostradas = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)
    this.JmaquinasLargoMostradasCorto = this.JmaquinasLargo.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)

  }

  primer_programa_maquina(maquina, planificador_temporal) {
    // OUTPUT: primer programa que no haya sido añadido al gantt y este planificado en la maquina seleccionada
    var programas: any = planificador_temporal.filter(f => f.idMaquina == maquina.id && f.cantidad > f.enGantt && f.cantidad - f.enGantt >= f.cantidadAgrupada)
    if (programas != undefined) {
      return programas[0];
    }
    return undefined;
  }
  operacionPrevia(programaParaAñadir, operacionesHechas) {
    // OUTPUT: operacion anterior a la que se quiere añadir.
    var operacionPrevia = operacionesHechas.find(f => f.orden = programaParaAñadir.ordenOperacion - 1 && f.idRuta == programaParaAñadir.idRuta)

    return operacionPrevia;
  }
  desbloquearMaquinas(maquinas) {
    maquinas.forEach(
      maquina => {
        maquina.bloqueada = false;
      }
    );
  }

  PREcargarGantt() {
    this.actualizarReorganizandoVersion();

    (this.DatPlanificador as any).forEach(
      operacionoGrupo => {
        // Cogemos el tiempo estimado de esa operacion para esa maquina
        var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
          ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
        // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
        if (tiempos.length == 0) {
          tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
        }
        if (tiempos.length > 0) {
          // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
          // 1. tiempo estimado o predictivo
          // 2. tiene preparacion si es principal = 1
          var tiempo = tiempos[0];
          if (this.aplicarTiempoEstimado) {
            operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
            //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
            operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
          } else {
            operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
            //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
            operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
          }
          // 3. se aplica infeciciencia en caso de ser necesario
          operacionoGrupo.tiempoIneficiencia = 0;
          if (this.aplicarIneficiencias) {
            operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
          }
          operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
        }
        // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
        // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

        // Esto estaba de antes y por ahora esta para mantener la misma estructura
        // operacionoGrupo.pretiempoPreparacion
        operacionoGrupo.tiempoPreparacion
        // operacionoGrupo.tiempoPreparacionIneficiencia
        // operacionoGrupo.pretiempoEstimado
        operacionoGrupo.tiempoEstimado
        // operacionoGrupo.tiempoEstimadoIneficiencia
        // operacionoGrupo.pretiempoPreparacionHH
        // operacionoGrupo.tiempoPreparacionHH
        // operacionoGrupo.tiempoPreparacionIneficienciaHH
        // operacionoGrupo.pretiempoEstimadoHH
        // operacionoGrupo.tiempoEstimadoHH
        // operacionoGrupo.tiempoEstimadoIneficiencia
      });

    var r1, r2: boolean = false;
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    //PARA CONTAR BIEN EL TIEMPO EJECUTADO, se actualiza cada vez que se recarga el grafico.
    if (this.DatPlanificador_tiemposEjecutados == undefined) {
      this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
        json => {
          this.DatPlanificador_tiemposEjecutados = json;

          r1 = true;
          if (r1 && r2)
            this.cargarGantt();
        });
    } else {
      r1 = true;
      if (r1 && r2)
        this.cargarGantt();

    }
    this.planificadorService.Get_cantidadesOperacionesActual(version).subscribe(
      json => {
        this.operacionesActuales = [];

        var an: any = json;
        an.forEach(
          row => {
            if (row.nuevasPosibles > 0 && row.ordenOperacion > 1) { //se miran si se pueden hacer nuevas operaciones de esta operacion

              for (var _i = 0; _i < row.nuevasPosibles; _i++) {//por cada oepracion que se puede hacer se crea una linea con la operacion ANTERIOR hecha a fecha ACTUAL
                var operaciones: any = [];
                operaciones['idRuta'] = row.idRuta;
                operaciones['orden'] = row.ordenOperacion - 1;
                operaciones['cantidad'] = 1;
                operaciones['cantidadColores'] = 1;
                //var now: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds());
                //operaciones['fecha'] = this.dateCopy(now);// cuando ya se empieza una la proxima se pone en amarillo.
                operaciones['fecha'] = row.fecha;// cuando ya se empieza una la proxima se pone en amarillo.
                this.operacionesActuales.push(operaciones)
              }
            }
          });

        r2 = true;
        if (r1 && r2)
          this.cargarGantt();
      });
  }


  //#region "CARGAR GANT"
  public machineToMaquina = [];
  cargarGantt() {
    // 1 TIEMPO EJECUTADO
    // 2 ESTRUCTURAS
    // 3 LOOP
    // 4 DAR COLOR     
    // 5 MONTAR GANTT
    // 6 OPERACIONES FILTRADAS
    // 7 GRID

    // 1 DESCONTAMOS EL TIEMPO YA EJECUTADO A LAS OPERACIONES ANTES DE EMPEZAR A PLANIFICARLAS
    this.descontarTiemposEjecutrados(this.DatPlanificador_tiemposEjecutados, this.DatPlanificador);

    // 2 PREPARAR ESTRUCTURAS
    //    LAS OPERACIONES TIENEN QUE VENIR EN ORDEN!
    var operacionesHechas: any = this.operacionesActuales; //SE COGEN LAS OPERACIONES HECHAS HASTA AHORA
    var maquinas_temperaturas: any = {};
    //    Preparamos las maquinas como las vamos a tener que necesitar.
    this.DatMaquinas.forEach(row => {
      row['name'] = row.nombre;
      row['fecha'] = this.dateCopy(this.now);
      row['fechaUltimaPreparacion'] = this.dateCopy(this.now); // Fecha en la que acaba la preparacion de la ultima operacion.
      row['tiempoUltimaPreparacion'] = 0; // El tiempo preparacion se usa para saber cuanto tarda una pieza desde entrar hasta salir de la maquina en estos casos.
      row['image'] = 'assets/idcontent/' + this.user.idDb + '/modelos/' + row.imagen;
      maquinas_temperaturas[row.id] = 0;
    });

    // 3 LOOP PARA CREAR LOS TASKS DEL GANTT
    this.tasks = this.crearTodosTasks(maquinas_temperaturas, operacionesHechas, this.DatPlanificador
      , this.DatMaquinas
      , this.dtMaquinas_temperaturas_rangos, this.dtMaquinas_tiempoMinimoCambioTemperatura
      , this.DatTurnos, this.DatPlanificador_grupos
      , this.ocultarParte, this.DatPlanificador_gruposOperaciones);

    // 4 DAR COLOR A LOS TASKS                                
    this.darColorTasks(this.tasks, operacionesHechas);

    // 5 QUITAR SOLAPES SI ES QUE LOS HAY Y CREAR NUEVAS LINEAS DE PLANIFICADOR
    var machines = this.crearMachines_filtradas(this.JgruposMaquinasSelected, this.JgruposMaquinas, this.seccionesSeleccionadas, this.secciones, this.JgruposMaquinasMaquinas);
    var dataConSolapes = this.crearMultiplesMaquinasParaSolapes(this.tasks, machines);
    machines = dataConSolapes.machines;
    this.tasks = dataConSolapes.tasks;

    // 6 MONTAR GANTT

    let data: any = {
      machines: [],
      tasks: []
    };
    var orden = 1;
    var nuevoOrden_minimo = 1;

    var todasMaquinas = [];
    this.machineToMaquina = [];

    machines.forEach(
      maquina => {
        data.machines.push({ id: orden, name: maquina.name, nombreCorto: maquina.nombreCorto, imagenBase64: maquina.imagenBase64 })
        todasMaquinas.push(orden);
        this.machineToMaquina[orden] = maquina.id;
        orden++;
        for (let i = 2; i <= maquina.multiRow; i++) {
          data.machines.push({ id: orden, name: '', nombreCorto: '', imagenBase64: '' })
          todasMaquinas.push(orden);
          this.machineToMaquina[orden] = maquina.id;
          orden++;
        }
        maquina.nuevoOrden_minimo = nuevoOrden_minimo + 0;
        nuevoOrden_minimo += maquina.multiRow;
      });

    this.tasks.forEach(
      task => {
        var machines_filtradas = machines.filter(f => f.id == task.machine);
        if (machines_filtradas.length > 0) {
          task.machine = machines_filtradas[0].nuevoOrden_minimo + task.multiRow - 1; // MULTIROW MINIMO SERA 1 Y ESTO HARA QUE SIEMPRE HAYA QUE RESTAR 1
          task.allowedMachines = todasMaquinas;
          data.tasks.push(task);
        }
      });

    //    FECHA MINIMA
    var startDate = this.today;
    //    FECHA MAXIMA
    var endDate = new Date(this.now);
    this.DatMaquinas.forEach(m => { if (endDate < m.fecha) endDate = this.dateCopy(m.fecha); });
    endDate.setDate(endDate.getDate() + 3);
    //    CREAR / ACTUALIZAR GANT
    // if (this.ganttChart) {
    //   // ACTUALIZAR GANTT
    //   this.ganttChart.updateTasks(this.tasks);
    //   this.ganttChart.setDateRange(startDate, endDate)
    // }
    // else {
    // CREAR GANT

    var a = 50 + 50 * data['machines'].length;
    this.ganttChart = new GanttChartDrag('gantt-chart', {
      width: 'fit'
      , height: 50 + 50 * data['machines'].length
      , startDate: startDate, endDate: endDate
      , data: data
      , marcadores: this.GANTT_marcadores
    }, this.translateService)
      .on('task-dropped', (id, newStartDate, newMachine) => {
        this.ganttDropped(id, newStartDate, newMachine);
      })
      .on('task-ctrl-clicked', (id) => {
        this.ganttClicked(id);
      });
    // }
    // 7 RESALTAR LAS OPERACIONES FILTRADAS
    this.resaltarOoperacionesFiltradas(this.ofsSeleccionados, this.clientesSeleccionados, this.piezasSeleccionados
      , this.Joperaciones, this.DatPlanificador, this.ganttChart, this.tasks);

    // 8 RECARGAR GRID
    //    ACTUALIZAMOS LOS VALORES DEL GRID PARA DAR COLOR Y TEXTO A PROGRAMA
    (this.DatPlanificador as any).forEach(
      row => {
        if (row.tienePrograma == 0) {
          row.tieneProgramaText = this.translateService.instant('no');
        }
        else {
          row.tieneProgramaText = this.translateService.instant('si');
        }
        var colorTask = this.tasks.filter(f => f.idPlanificador == row.idPlanificador);
        if (colorTask.length > 0)
          row.color = colorTask[0].backgroundColor;
      });
    //    RECARGAR GRID
    this.recargarGridCortoFiltrado();

    this.loadingPanel = false;
  }
  crearMultiplesMaquinasParaSolapes(tasks, machines) {
    //#region ORDENAMOS LAS TASKS ----------------------------------------------------------------------------------------------------
    // Segun como se agrupe, lo ordenaremos de una forma u otra
    tasks.sort((s1, s2) => {
      if (s1.machine > s2.machine) return 1
      else if (s1.machine < s2.machine) return -1
      else if (s1.fechaIni > s2.fechaIni) return 1
      else if (s1.fechaIni < s2.fechaIni) return -1
      else return 0
    });
    //#endregion ----------------------------------------------------------------------------------------------------

    tasks.forEach(
      task => {
        var tasksSimultaneas = tasks.filter(f => this.GANTT_multirrow && f.endDate > task.startDate && f.startDate < task.endDate && f.machine == task.machine && f.multiRow > 0);
        tasksSimultaneas.sort((s1, s2) => {
          if (s1.multiRow > s2.multiRow) return 1
          else if (s1.multiRow < s2.multiRow) return -1
          else return 0
        });

        var multirow_previa_machine = 0;
        var primera_multirow_simultanea_libre = 0

        var tasks_previas_machine = tasks.filter(f => f.endDate <= task.startDate && f.machine == task.machine);
        if (tasks_previas_machine.length > 0) {
          if (tasks_previas_machine[tasks_previas_machine.length - 1].multiRow == undefined)
            tasks_previas_machine[tasks_previas_machine.length - 1] = 1;
          multirow_previa_machine = tasks_previas_machine[tasks_previas_machine.length - 1].multiRow;
        }
        if (multirow_previa_machine == 0 || multirow_previa_machine == undefined) multirow_previa_machine = 1;

        var i = 0;
        var machines_ocupadas = [];
        for (i = 0; i < tasksSimultaneas.length; i++) {
          if (!machines_ocupadas.includes(tasksSimultaneas[i].multiRow))
            machines_ocupadas.push(tasksSimultaneas[i].multiRow);
        }
        if (!machines_ocupadas.includes(multirow_previa_machine)) {
          task.multiRow = multirow_previa_machine;
        } else {
          i = 0;
          for (i = 0; i < machines_ocupadas.length; i++) {
            if (!machines_ocupadas.includes(i + 1) && primera_multirow_simultanea_libre == 0) primera_multirow_simultanea_libre = i + 1;
          }
          if (primera_multirow_simultanea_libre == 0) primera_multirow_simultanea_libre = i + 1;
          task.multiRow = primera_multirow_simultanea_libre;
        }

        if (!task.rowResumen) // 0 es la linea de resumen de estado al tener lineas simultaneas
        {
          var machines_filtradas = machines.filter(f => f.id == task.machine);
          if (machines_filtradas.length > 0) {
            if (machines_filtradas[0].multiRow == undefined) machines_filtradas[0].multiRow = 0;
            machines_filtradas[0].multiRow = Math.max(machines_filtradas[0].multiRow, task.multiRow);
          }
        }
      });

    return {
      machines: machines,
      tasks: tasks
    };
  }
  crearTodosTasks(maquinas_temperaturas, operacionesHechas, datPlanificador
    , datMaquinas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura
    , datTurnos, datPlanificador_grupos, ocultarParte, datPlanificador_gruposOperaciones) {

    var tasks = [];
    //CREAR APPOINTMENTS START
    //   se genera un array con key segun el orden que queramos
    var operacionesOrdenadas = [];
    (datPlanificador as any).forEach(a => operacionesOrdenadas[a.idMaquina + '_' + this.myFunctions.addXzero(a.ordenPlanificado, 20)] = a);

    var keys = [];
    Object.keys(operacionesOrdenadas).sort().forEach(key => keys.push(key));

    var operacionesOrdenadasCompactas = [];
    var cantidadOperacionesPorMaquina = [];
    keys.forEach(
      key => {
        operacionesOrdenadas[key].ordenCompacto = cantidadOperacionesPorMaquina[operacionesOrdenadas[key].idMaquina] ? cantidadOperacionesPorMaquina[operacionesOrdenadas[key].idMaquina] + 1 : 1;
        operacionesOrdenadasCompactas[operacionesOrdenadas[key].idMaquina + '_' + operacionesOrdenadas[key].ordenCompacto] = operacionesOrdenadas[key];
        cantidadOperacionesPorMaquina[operacionesOrdenadas[key].idMaquina] = operacionesOrdenadas[key].ordenCompacto;
      });

    var operacionesAplanificar = Object.keys(operacionesOrdenadasCompactas).length;
    var variablescontrolLoop = // esto se hace asi para que las variables se actualizen dentro del loop
    {
      ultimoOrdenMaquina: [],
      operacionesTaskeadas: 0,
      controlLoop: 0,
      maquinasBloqueadas: [],
      index: 0,
      forzarCarga: false,
    }

    while (variablescontrolLoop.operacionesTaskeadas < operacionesAplanificar && (variablescontrolLoop.controlLoop <= datMaquinas.length)) {
      // COGEMOS LA PROXIMA OPERACION POR FECHA
      var idMaquina = this.get_maquina_mas_atrasada(datMaquinas, variablescontrolLoop.maquinasBloqueadas);
      var orden = variablescontrolLoop.ultimoOrdenMaquina[idMaquina] ? variablescontrolLoop.ultimoOrdenMaquina[idMaquina] + 1 : 1;
      var operacion = operacionesOrdenadasCompactas[idMaquina + '_' + orden];

      if (operacion) {
        // MIRAMOS SI ESA OPERACION TIENE OPERACIONES SIMULTANEAS
        if (variablescontrolLoop.forzarCarga) {
          this.añadirOperacionCompleta(operacion,
            datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
            datTurnos, operacionesHechas,
            datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
            tasks, variablescontrolLoop,
            false);
        } else if (operacion.idOperacionSimultanea > 0) {
          var operacionesSimultaneas = (this.DatPlanificador as any).filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
          if (operacionesSimultaneas.length > 0) {
            // MIRAMOS SI ALGUNA DE LAS MAQUINAS ESTA MENOS AVANZADA QUE LA ACTUAL y si es asi, bloqueamos la actual hasta que todas las operaciones simultaneas sean las primeras en cada maquina.
            var simultaneasInsertables = 0;
            operacionesSimultaneas.forEach(
              simultanea => {
                var maquina_orden = variablescontrolLoop.ultimoOrdenMaquina[simultanea.idMaquina] ? variablescontrolLoop.ultimoOrdenMaquina[simultanea.idMaquina] + 1 : 1;
                if (maquina_orden >= simultanea.ordenCompacto) simultaneasInsertables++; // aqui es ==, he puesto <= poirque tambien vale y para hacer pruebas es mas comodo.
              });
            if (simultaneasInsertables == operacionesSimultaneas.length) {
              // MIRAMOS A QUE HORA SE DEVERIA DE METER CADA OEPRACION Y ESTA LIMITARA EL RESTO.
              this.añadirOperacionesSimultaneas(operacionesSimultaneas,
                datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
                datTurnos, operacionesHechas,
                variablescontrolLoop,
                datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
                tasks);
            }
            else {
              // BLOQEAMOS LA MAQUINA ACTUAL PARA QUE INSERTE LAS DEMAS MAQUINAS Y SIGA CON LA PLANIFICACION
              variablescontrolLoop.maquinasBloqueadas.push(idMaquina);
            }
          }
        } else {
          this.añadirOperacionCompleta(operacion,
            datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
            datTurnos, operacionesHechas,
            datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
            tasks, variablescontrolLoop,
            false);
        }
      } else {
        variablescontrolLoop.maquinasBloqueadas.push(idMaquina);
      }

      variablescontrolLoop.controlLoop += 1;
      // Se intenta forzar una ultima carga
      if (variablescontrolLoop.controlLoop > datMaquinas.length && !variablescontrolLoop.forzarCarga) {
        variablescontrolLoop.forzarCarga = true;
        variablescontrolLoop.controlLoop = 0;
        variablescontrolLoop.maquinasBloqueadas = [];
      }
    }

    return tasks;
  }
  añadirOperacionesSimultaneas(operacionesSimultaneas,
    datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
    datTurnos, operacionesHechas,
    variablescontrolLoop,
    datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
    tasks) {

    // SE MIRA LA HORA DE INICIO DE CADA UNA DE LAS OPERACIONES SIMULTANEAS.
    var operacionesConCambioTemperatura = [];
    var operacionMasAtrasada;
    var fechaMasAtradados;
    operacionesSimultaneas.forEach(
      operacion => {
        var maquina_planificada = datMaquinas.filter(m => m.id == operacion.idMaquina);
        if (maquina_planificada.length > 0) {
          var maquina = maquina_planificada[0];
          if (maquinas_temperaturas[operacion.idMaquina] != operacion.temperatura)
            operacionesConCambioTemperatura.push(operacion)

          var fechaMaquina_antes = this.myFunctions.dateCopy(maquina.fecha);
          // AÑADIMOS EL TIEMPO DE ESPERA ENTRE OPERACIONES
          this.añadirTiempoEsperaEntreOperaciones(operacion, maquina, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura);

          var fechaMaquina_despues = this.myFunctions.dateCopy(maquina.fecha);

          if (fechaMasAtradados == undefined || fechaMasAtradados < fechaMaquina_despues) {
            fechaMasAtradados = fechaMaquina_despues;
            operacionMasAtrasada = operacion;
          }
        }
      });
    // SE PONEN TODAS LAS MAQUINAS CON EL TIEMPO MAXIMO AÑADIDO DE LOS TIEMPOS ESPERADOS
    operacionesSimultaneas.forEach(
      operacion => {
        var maquinas = datMaquinas.filter(m => m.id == operacion.idMaquina);
        if (maquinas.length > 0) {
          var maquina = maquinas[0];
          if (operacion.ordenOperacion < operacionMasAtrasada.ordenOperacion) {
            var fechaLimitada = this.myFunctions.dateAddSeconds(fechaMasAtradados, -operacion.tiempoPreparacion);
            if (maquina.fecha < fechaLimitada)
              maquina.fecha = fechaLimitada
          }
        }
      });

    // SE MIRA DE LAS OPERACIONES SIMULTANEAS CUAL ES LA MAS RESTRICTIVA (la que mas tiempo ha añadido teniendo en cuenta que tiene cambio de temperatura).
    // SEGUN LA MAS RESTRICTUVA, SE AÑADE EL RESTO.    
    var operacionesOrdenadas = [];
    (operacionesSimultaneas as any).forEach(a => operacionesOrdenadas[a['idMaquina'] + '_' + this.myFunctions.addXzero(a['ordenOperacion'], 20)] = a);
    operacionesOrdenadas.sort(function (a, b) { return b[1] - a[1] });
    //ORDENAMOS KEYS PARA INSERTAR LAS OPERACIONES EN ORDEN
    var keys = [];
    Object.keys(operacionesOrdenadas).sort().forEach(key => keys.push(key));
    keys.forEach(
      key => {
        //COGEMOS LA PROXIMA OPERACION POR ORDEN 
        var operacion_planificada = operacionesOrdenadas[key];
        this.añadirOperacionCompleta(operacion_planificada,
          datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
          datTurnos, operacionesHechas,
          datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
          tasks,
          variablescontrolLoop,
          true);

        var maquina_planificada = datMaquinas.filter(m => m.id == operacion_planificada.idMaquina);
        if (maquina_planificada.length > 0) {
          var maquina_planificada = maquina_planificada[0];
          operacionesSimultaneas.forEach(
            operacion => {
              var maquinas = datMaquinas.filter(m => m.id == operacion.idMaquina);
              if (maquinas.length > 0) {
                var maquina = maquinas[0];
                if (maquina.fecha < maquina_planificada.fechaUltimaPreparacion &&
                  operacion_planificada.ordenOperacion < operacion.ordenOperacion)
                  maquina.fecha = this.myFunctions.dateCopy(maquina_planificada.fechaUltimaPreparacion);
              }
            });
        }
      });
  }
  get_maquina_mas_atrasada(datMaquinas, maquinasBloqueadas) {
    var idMaquina = -1;
    var fechaMin;
    datMaquinas.forEach(
      maquina => {
        if (!maquinasBloqueadas.includes(maquina.id)) {
          if (!fechaMin) {
            fechaMin = maquina.fecha;
            idMaquina = maquina.id;
          } else if (fechaMin > maquina.fecha) {
            fechaMin = maquina.fecha;
            idMaquina = maquina.id;
          }
        }
      });

    return idMaquina;
  }
  añadirOperacionCompleta(operacion,
    datMaquinas, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura,
    datTurnos, operacionesHechas,
    datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte,
    tasks,
    variablescontrolLoop,
    desdeSimultaneas) {

    var ultimoOrdenMaquina = variablescontrolLoop.ultimoOrdenMaquina;
    var operacionesTaskeadas = variablescontrolLoop.operacionesTaskeadas;
    var controlLoop = variablescontrolLoop.controlLoop;
    var index = variablescontrolLoop.index;


    // TIEMPO EJECUTADO
    this.calcularTiempoRestante(operacion);

    //EL PROCESO ANTERIOR (CON EL auxi.length>0 de abajo)
    var maquina_planificada = datMaquinas.filter(m => m.id == operacion.idMaquina);
    if (maquina_planificada.length > 0) {
      var maquina = maquina_planificada[0];

      // AÑADIMOS EL TIEMPO DE ESPERA ENTRE OPERACIONES
      if (!desdeSimultaneas) // Si la operacion es simultanea, ya tiene este tiempo AÑADIDO! pues se añade a todas el tiempo maximo para añadir el bloque cuando toca.
        this.añadirTiempoEsperaEntreOperaciones(operacion, maquina, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura);

      //INICIAMOS EL TASK PRINCIPAL Y CREAMOS LA VARIABLE POR SI ESTE SE DIVIDE
      var tasks_operacion = []; // se guardan los task generados en este momento (por si se cortan en turnos) y se le actualiza el tooltip al final 
      var task: any = this.iniciarTask(index, operacion, maquina.id, datMaquinas);

      //TURNOS
      var turnos = datTurnos.filter(f => new Date(f.max) > maquina.fecha && f.idMaquina == maquina.id);
      if (operacion.fueraTurno || turnos.length == 0) {
        // SE PUEDE AÑADIR LA OPERACION COMPLETA
        task['startDate'] = this.myFunctions.dateCopy(maquina.fecha);
        // Se añade la operacion completa y se añaden las piezas creadas como disponibles.
        this.añadirOperacionCompleta_sinTurnos(operacion, maquina, operacionesHechas, task['startDate']);
      }
      else {// SE AÑADE LA OPERACION CORTADA EN TURNOS
        // se mira si empieza dentro de turno o se adelanta la fecha hasta el inicio del proximo 
        if (new Date(turnos[0].min) > maquina.fecha) maquina.fecha = new Date(turnos[0].min);
        task['startDate'] = this.myFunctions.dateCopy(maquina.fecha);
        // COMO EL TASK ACTUAL SE VA CORTANDO, SE TIENE QUE DEVOLVER CUAL HA SIDO EL ULTIMO CORTE
        task = this.añadirOperacionCompleta_conTurnos(operacion, maquina, turnos, operacionesHechas, task['startDate'], task, tasks_operacion, index, datMaquinas, datTurnos);
      }

      task['detail'] = (d) => ``; // tooltip
      task['endDate'] = this.myFunctions.dateCopy(maquina.fecha);
      task['linking'] = index + ' ';// id interno

      tasks_operacion.push(task)
      var taskN = 0;
      //se prepara el tooltip pero se cargara junto al color para poner cuantas piezas es posible hacer.
      tasks_operacion.forEach(
        t => {
          taskN++;

          this.crearTaskTooltip(t, operacion, taskN, maquina, tasks_operacion, datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte, tasks);
          this.datTexturaTask(t, operacion);

          tasks.push(t);
        });

      // CUANDO SE AÑADE UNA OPERACION, SE ACTUALIZAN LAS VARIABLES NECESARIAS
      ultimoOrdenMaquina[operacion.idMaquina] = operacion.ordenCompacto;
      operacionesTaskeadas += 1;
      controlLoop = 0;
      index++; // se incrementa el id interno


      variablescontrolLoop.ultimoOrdenMaquina = ultimoOrdenMaquina;
      variablescontrolLoop.operacionesTaskeadas = operacionesTaskeadas;
      variablescontrolLoop.controlLoop = controlLoop;
      variablescontrolLoop.index = index;
      variablescontrolLoop.maquinasBloqueadas = [];


      // TENER EN CUENTA EL TIEMPO DE PREPARACION DE LA ULTIMA OPERACION HECHA. 
      // El tiempo preparacion se usa para saber cuanto tarda una pieza desde entrar hasta salir de la maquina en estos casos.
      maquina.tiempoUltimaPreparacion = operacion.tiempoPreparacion;
    }
  }
  añadirOperacionCompleta_sinTurnos(operacion, maquina, operacionesHechas, fechaPlanificadoInicio) {
    // se le da a la operacion el inicio de su fecha de planificacion
    if (operacion.fechaPlanificadoInicio == undefined) operacion.fechaPlanificadoInicio = fechaPlanificadoInicio;
    //SE AÑADE EL TIEMPO DE PREPARACION SI ES NECESARIO *! teniendo en cuenta el tiempoEjecutado
    if (operacion.cantidadHechas == 0 && operacion.cantidad - operacion.cantidadHechas > 0) {
      if (operacion.tiempoEjecutado < operacion.tiempoPreparacion) {
        maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (operacion.tiempoPreparacion - operacion.tiempoEjecutado)); // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
        // SE GUARDA EL FINAL DE LA PREPARACION
        maquina.fechaUltimaPreparacion = this.myFunctions.dateCopy(maquina.fecha);
        operacion.tiempoEjecutado = 0;
      } else {
        operacion.tiempoEjecutado = operacion.tiempoEjecutado - operacion.tiempoPreparacion;
      }
    }
    var cantidadLotePlanificador = operacion.cantidadAgrupada + 0;
    //CHECK si eentra otra cantidad lote completa para el siguiente ciclo
    if (operacion.cantidadHechas + cantidadLotePlanificador > operacion.cantidad) cantidadLotePlanificador = operacion.cantidad - operacion.cantidadHechas;
    if (cantidadLotePlanificador <= 0) cantidadLotePlanificador = 1;

    //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
    for (var _i = operacion.cantidadHechas; _i < operacion.cantidad; _i += cantidadLotePlanificador) {
      //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
      if (operacion.tiempoEjecutado < cantidadLotePlanificador * operacion.tiempoEstimado) {
        maquina.fecha.setSeconds(maquina.fecha.getSeconds() + 1 * (cantidadLotePlanificador * operacion.tiempoEstimado - operacion.tiempoEjecutado));
        operacion.tiempoEjecutado = 0;
      } else {
        operacion.tiempoEjecutado = cantidadLotePlanificador * operacion.tiempoEjecutado - operacion.tiempoEstimado;
      }

      this.añadirOperacionesHechas(operacionesHechas, operacion, cantidadLotePlanificador, maquina);
      operacion.fechaPlanificadoFin = this.myFunctions.dateCopy(maquina.fecha);

      //CHECK si eentra otra cantidad lote completa para el siguiente ciclo
      if (_i + cantidadLotePlanificador > operacion.cantidad) {
        cantidadLotePlanificador = operacion.cantidad - _i
        if (cantidadLotePlanificador <= 0)
          cantidadLotePlanificador = 1
      }
    }
  }
  añadirOperacionCompleta_conTurnos(operacion, maquina, turnos, operacionesHechas, fechaPlanificadoInicio, task, tasks_operacion, index, datMaquinas, datTurnos) {
    //  ESTA FUNCION DEVUELVE EL TASK ACTUAL XQ SE ACTUALIZA CADA VEZ QUE SE PARTE PARA PONERLE DESPUES UNA FECHAFIN.

    if (operacion.fechaPlanificadoInicio == undefined) operacion.fechaPlanificadoInicio = fechaPlanificadoInicio

    if (operacion.cantidadHechas == 0 && operacion.cantidad - operacion.cantidadHechas > 0) {

      var fechaActual_preparacion = this.myFunctions.dateCopy(maquina.fecha);
      // fechaActual_preparacion.setSeconds(fechaActual_preparacion.getSeconds() + operacion.tiempoPreparacion);
      // maquina.fechaUltimaPreparacion = this.myFunctions.dateCopy(fechaActual_preparacion);

      var tiempoPreparacionRestante = operacion.tiempoPreparacion;
      if (new Date(turnos[0].max) < fechaActual_preparacion) {
        //partir appointment en 2 y AÑADIR LO QUE SE LLEVAVA HASTA AHORA! (coge el appointment 1, lo copia al 2, añade el appointment 2 y el 1 lo actualiza como si fuese el nuevo)
        var task2: any = this.iniciarTask(index, operacion, maquina.id, datMaquinas);

        task2['startDate'] = fechaPlanificadoInicio
        task2['endDate'] = this.myFunctions.dateCopy(new Date(turnos[0].max)); // fin
        task2['cantidadReal'] = task['cantidadReal'] + 0; // +0 para copiar la variable rapidamente.
        task2['operacionEmpezada'] = operacion.operacionEmpezada;
        task2['enEjecucion'] = operacion.enEjecucion;
        task2['linking'] = index + ' ';// id interno
        tasks_operacion.push(task2); // se añade el task aktual a todos

        task['startDate'] = this.myFunctions.dateCopy(maquina.fecha); // inicio

        task = task2;

        // se mira cuanto tiempo falta para acabar la preparacion y se intenta encajar en el planificador
        tiempoPreparacionRestante -= (new Date(turnos[0].max).getTime() - maquina.fecha.getTime()) / 1000;

        maquina.fecha = new Date(turnos[0].max);// se sale de turno!

        // añadir las proximas partes de la preparacion
        var indice_loop_preparacion = 0
        while (tiempoPreparacionRestante > 0 && indice_loop_preparacion < 10) {
          indice_loop_preparacion++;
          turnos = datTurnos.filter(f => new Date(f.max) > maquina.fecha && f.idMaquina == maquina.id);
          if (turnos.length > 0) {
            maquina.fecha = new Date(turnos[0].min);// esta fuera de turno!

            fechaActual_preparacion = this.myFunctions.dateCopy(maquina.fecha)
            fechaActual_preparacion.setSeconds(fechaActual_preparacion.getSeconds() + tiempoPreparacionRestante)

            if (new Date(turnos[0].max) < fechaActual_preparacion) {

              //partir appointment en 2 y AÑADIR LO QUE SE LLEVAVA HASTA AHORA! (coge el appointment 1, lo copia al 2, añade el appointment 2 y el 1 lo actualiza como si fuese el nuevo)
              var task2: any = this.iniciarTask(index, operacion, maquina.id, datMaquinas);

              task2['startDate'] = maquina.fecha
              task2['endDate'] = this.myFunctions.dateCopy(new Date(turnos[0].max)); // fin
              task2['cantidadReal'] = task['cantidadReal'] + 0; // +0 para copiar la variable rapidamente.
              task2['operacionEmpezada'] = operacion.operacionEmpezada;
              task2['enEjecucion'] = operacion.enEjecucion;
              task2['linking'] = index + ' ';// id interno
              tasks_operacion.push(task2); // se añade el task aktual a todos

              task = task2;

              this.añadirOperacionesHechas(operacionesHechas, operacion, cantidadLotePlanificador, maquina);
              operacion.fechaPlanificadoFin = this.myFunctions.dateCopy(maquina.fecha);

              // se mira cuanto tiempo falta para acabar la preparacion y se intenta encajar en el planificador
              tiempoPreparacionRestante -= (new Date(turnos[0].max).getTime() - maquina.fecha.getTime()) / 1000;

              maquina.fecha = new Date(turnos[0].max);// se sale de turno!
            }
            else {
              //partir appointment en 2 y AÑADIR LO QUE SE LLEVAVA HASTA AHORA! (coge el appointment 1, lo copia al 2, añade el appointment 2 y el 1 lo actualiza como si fuese el nuevo)
              var task2: any = this.iniciarTask(index, operacion, maquina.id, datMaquinas);

              task2['startDate'] = this.myFunctions.dateCopy(maquina.fecha);
              maquina.fecha.setSeconds(maquina.fecha.getSeconds() + tiempoPreparacionRestante) // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
              task2['endDate'] = maquina.fecha; // fin
              task2['cantidadReal'] = task['cantidadReal'] + 0; // +0 para copiar la variable rapidamente.
              task2['operacionEmpezada'] = operacion.operacionEmpezada;
              task2['enEjecucion'] = operacion.enEjecucion;
              task2['linking'] = index + ' ';// id interno
              tasks_operacion.push(task2); // se añade el task aktual a todos

              task = task2;

              //maquina.fecha.setSeconds(maquina.fecha.getSeconds() + tiempoPreparacionRestante) // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
              tiempoPreparacionRestante = 0;
            }
          }
          else {
            maquina.fecha.setSeconds(maquina.fecha.getSeconds() + tiempoPreparacionRestante) // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
            tiempoPreparacionRestante = 0;
          }
        }
      } else {
        maquina.fecha.setSeconds(maquina.fecha.getSeconds() + operacion.tiempoPreparacion) // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
      }
    }
    // SE GUARDA EL FINAL DE LA PREPARACION
    maquina.fechaUltimaPreparacion = this.myFunctions.dateCopy(maquina.fecha);

    var repetidasPorCortesDeTurnos = 0;

    var cantidadLotePlanificador = operacion.cantidadAgrupada + 0;
    //CHECK si eentra otra cantidad lote completa para el siguiente ciclo
    if (operacion.cantidadHechas + cantidadLotePlanificador > operacion.cantidad + repetidasPorCortesDeTurnos) {
      cantidadLotePlanificador = operacion.cantidad + repetidasPorCortesDeTurnos - operacion.cantidadHechas
    }
    if (cantidadLotePlanificador <= 0)
      cantidadLotePlanificador = 1
    for (var _i = operacion.cantidadHechas; _i < operacion.cantidad + repetidasPorCortesDeTurnos; _i += cantidadLotePlanificador) {
      var turnos = datTurnos.filter(f => new Date(f.max) > maquina.fecha && f.idMaquina == maquina.id);
      if (turnos.length > 0) {
        if (new Date(turnos[0].min) > maquina.fecha) { //AVANZA EL TIEMPO DE LA MAQUINA HASTA EL INICIO DEL TURNO

          this.añadirOperacionesHechas(operacionesHechas, operacion, cantidadLotePlanificador, maquina); // Se añaden las hechas hasta el final del turno

          var piezasPaletizadas = 0;
          if (maquina.posicionesSistemaDePaletizado > 1) {// PALETIZADO!
            /*
              Esta opcion solo afecta a planificaciones con turnos por ahora. Añadiendo un tiempo extra para el 
              paletizado igual al numero de piezas paletizxables - 1 * tiempo estimado al final del turno.          
            */
            piezasPaletizadas = (maquina.posicionesSistemaDePaletizado - 1);
            // Las piezas paletizadas no pueden ser mas que las restantes por hacer
            if (_i + piezasPaletizadas + cantidadLotePlanificador > operacion.cantidad + repetidasPorCortesDeTurnos) {
              piezasPaletizadas = operacion.cantidad + repetidasPorCortesDeTurnos - _i
              if (piezasPaletizadas <= 0)
                piezasPaletizadas = 0
            }
            _i += piezasPaletizadas; // En _i se lleva el control de las piezas hechas
            maquina.fecha = new Date(maquina.fecha.getTime() + (1000 * (piezasPaletizadas * operacion.tiempoEstimado)));
            this.añadirOperacionesHechas(operacionesHechas, operacion, piezasPaletizadas, maquina); // Se añaden las hechas en el paletizado fuera del turno
          }
          //partir appointment en 2 y AÑADIR LO QUE SE LLEVAVA HASTA AHORA! (coge el appointment 1, lo copia al 2, añade el appointment 2 y el 1 lo actualiza como si fuese el nuevo)
          var task2: any = this.iniciarTask(index, operacion, maquina.id, datMaquinas);

          task2['startDate'] = task['startDate']
          task2['endDate'] = this.myFunctions.dateCopy(maquina.fecha); // fin
          task2['cantidadReal'] = task['cantidadReal'] + 0; // +0 para copiar la variable rapidamente.
          task2['operacionEmpezada'] = operacion.operacionEmpezada;
          task2['enEjecucion'] = operacion.enEjecucion;
          task2['linking'] = index + ' ';// id interno
          tasks_operacion.push(task2); // se añade el task aktual a todos

          if (new Date(turnos[0].min) > maquina.fecha) maquina.fecha = new Date(turnos[0].min);// esta fuera de turno! PUEDE QUE POR EL PALETIZADO AHORA SE SOLAPEN EL FINAL DEL PALETIZADO Y EL INICIO DEL TURNO
          task['startDate'] = this.myFunctions.dateCopy(maquina.fecha); // inicio

          operacion.fechaPlanificadoFin = this.myFunctions.dateCopy(maquina.fecha);
        }
      }
      //task['cantidadReal']++;
      // cada vez que ya se ha tenido en cuenta la operacion (o no) este valos se vuelve a poner a false para la siguiente iteracion.
      var esTiempoSobranteOperacionAnterior = false
      //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
      //  SE TIENE EN CUENTA LA FECHA DE FINAL DE TURNO
      if (operacion.tiempoEjecutado < cantidadLotePlanificador * operacion.tiempoEstimado) {
        // si entró en un turno, aqui tenemos ese turno para saber cual es su fecha fin.
        if (turnos.length == 0) {
          maquina.fecha = new Date(maquina.fecha.getTime() + (1000 * (cantidadLotePlanificador * operacion.tiempoEstimado - operacion.tiempoEjecutado)));
          operacion.tiempoEjecutado = 0;
        } else {
          var fechaFinActual = this.myFunctions.dateCopy(maquina.fecha)
          fechaFinActual.setSeconds(maquina.fecha.getSeconds() + (cantidadLotePlanificador * operacion.tiempoEstimado - operacion.tiempoEjecutado));
          if (new Date(turnos[0].max) < fechaFinActual) {
            esTiempoSobranteOperacionAnterior = true;
            repetidasPorCortesDeTurnos = repetidasPorCortesDeTurnos + cantidadLotePlanificador;
            maquina.fecha = new Date(turnos[0].max);
            var tiempoSobranteS = (fechaFinActual.getTime() - new Date(turnos[0].max).getTime()) / 1000 //se divide en 1000 para conseguir los segundos
            operacion.tiempoEjecutado += ((cantidadLotePlanificador * operacion.tiempoEstimado - operacion.tiempoEjecutado) - tiempoSobranteS);
          } else {
            maquina.fecha = new Date(maquina.fecha.getTime() + (1000 * (cantidadLotePlanificador * operacion.tiempoEstimado - operacion.tiempoEjecutado)));
            operacion.tiempoEjecutado = 0;
          }
        }
      }
      else {
        operacion.tiempoEjecutado -= cantidadLotePlanificador * operacion.tiempoEstimado;
      }
      //AÑADIR OPERACION COMO HECHA
      if (!esTiempoSobranteOperacionAnterior) {
        this.añadirOperacionesHechas(operacionesHechas, operacion, cantidadLotePlanificador, maquina);
        operacion.fechaPlanificadoFin = this.myFunctions.dateCopy(maquina.fecha);
      }

      //CHECK si eentra otra cantidad lote completa para el siguiente ciclo
      if (_i + cantidadLotePlanificador > operacion.cantidad + repetidasPorCortesDeTurnos) {
        cantidadLotePlanificador = operacion.cantidad + repetidasPorCortesDeTurnos - _i
        if (cantidadLotePlanificador <= 0)
          cantidadLotePlanificador = 1
      }
    }

    return task;
  }
  calcularTiempoRestante(row) {
    // SI UNA OPERACION ESTA EMPEZADA, SE LE QUITA EL TIEMPO DE PREPARACION DIRECTAMENTE 
    if (row.operacionEmpezada) row.tiempoPreparacion = 0;
    var cantidadParaCorreccionTiempoEstimado = row.cantidad - row.cantidadHechas
    // SI UN  A OPERACION ES MENOR AL TIEMPO MINIMO, TENIENDO EN CUENTA EL TIEMPO CONSUMIDO, SE LE PONE QUE EL TIEMPO ESTIMADO ES = AL CONSUMIDO + ESE MINIMO PARA QUE CUANDO SE PRINTE SIEMPRE APAREZCA ESE MINIMO
    if (cantidadParaCorreccionTiempoEstimado < 1) // si ya se han hehco mas de las planificadas, almenos enseñar 1 para que salga en el planificador
      cantidadParaCorreccionTiempoEstimado = 1;
    if ((row.tiempoEstimado * (cantidadParaCorreccionTiempoEstimado)) - row.tiempoEjecutado < this.tiempoOperacionMinimoPlanificadorCorto) { // el tiempo estimado va por pieza mientras que el ejecutado es global
      row.tiempoEstimado = (row.tiempoEjecutado + this.tiempoOperacionMinimoPlanificadorCorto) / (cantidadParaCorreccionTiempoEstimado);
    }
    // calculamos cual sera el tiempo restante, teniendo en cuenta la preparacion
    if (row.tiempoRestante == null) {
      var tiempoEstimadoDelAppointment = row.tiempoEstimado * (cantidadParaCorreccionTiempoEstimado);
      if (row.cantidadHechas == 0 && row.cantidad - row.cantidadHechas > 0) {
        tiempoEstimadoDelAppointment += row.tiempoPreparacion;
      }
      row.tiempoRestante = tiempoEstimadoDelAppointment - row.tiempoEjecutado;
    }
    // datos para poder editar bien el resto
    if (row.tiempoEjecutadoInicial == null) row.tiempoEjecutadoInicial = row.tiempoEjecutado;
  }
  añadirTiempoEsperaEntreOperaciones(operacion, maquina, maquinas_temperaturas, dtMaquinas_temperaturas_rangos, dtMaquinas_tiempoMinimoCambioTemperatura) {
    // TIEMPOESPERA, TIEMPOENFRIADO, TIEMPOHORNOS, TIEMPO ESPERA, TIEMPO ENFRIADO
    var temperatura_maquina = maquinas_temperaturas[operacion.idMaquina];
    var temperatura_operacion = operacion.temperatura;
    var maquinaRangos = dtMaquinas_temperaturas_rangos.filter(f => f.idMaquina == operacion.idMaquina);
    var tiempoMinimoCambioTemperatura = 0; // 45 * 60;
    dtMaquinas_tiempoMinimoCambioTemperatura.forEach(
      maquina => {
        if (maquina.idMaquina == operacion.idMaquina)
          tiempoMinimoCambioTemperatura = maquina.tiempoMinimoCambioTemperatura;
      });
    var tiempoDescansoEntreOperaciones = this.calcularTiempoCambioTemperatura(temperatura_maquina, temperatura_operacion, maquinaRangos, tiempoMinimoCambioTemperatura);
    if (maquina.multiproceso && temperatura_maquina == temperatura_operacion) {
      // Este tiempo no tiene en cuenta los fuera de turno. PARA MEJORAR, se deverian de tener en cuenta para hacer un programa mejor.
      maquina.fecha.setSeconds(maquina.fecha.getSeconds() - maquina.tiempoUltimaPreparacion);
    }
    maquina.fecha.setSeconds(maquina.fecha.getSeconds() + tiempoDescansoEntreOperaciones);
    maquinas_temperaturas[operacion.idMaquina] = operacion.temperatura;
  }
  maquinasPosibles(operacion, maquinas) {
    // SE HA PEDIDO DESLIMITAR ESTO!
    operacion.maquinasPosibles = '';

    var maquinasPosiblesStrings: any = operacion.maquinasPosibles.split(',');
    var maquinasPosibles: number[] = [];
    if (maquinasPosiblesStrings[0] == '') {
      maquinas.forEach(m => maquinasPosibles.push(m.id))// si no hay maquinas, se añade a todas
    }
    else {
      maquinasPosiblesStrings.forEach(m => { // se añaden maquinas posibles
        var num: number = m;
        maquinasPosibles.push(num);
      });
    }
    return maquinasPosibles;
  }
  iniciarTask(indice, operacion, idMaquina, maquinas) {
    var parte = this.getCodigoParte(operacion.refOF, operacion.pieza, operacion.parte, operacion.idOperacionesGrupos);

    var task: any = {};
    task['id'] = indice;// id interno        
    task['of'] = parte;// of del task      
    task['pieza'] = operacion.pieza;// of del task  
    task['operacion'] = operacion.operacion;// of del task  
    task['refOf'] = operacion.refOF;// of del task  
    task['allowedMachines'] = this.maquinasPosibles(operacion, maquinas); // maquinas posibles
    task['peso'] = operacion.peso; // TTTbergara por ahora
    task['numeroEnvases'] = operacion.numeroEnvases; // TTTbergara por ahora
    task['idMaquina'] = idMaquina; // Para luego saber que maquina es
    task['tPreparacion'] = operacion.tiempoPreparacion; // Para luego descontar este tiempo al final de la operacion y sabes a que hora se ha dejado de meter la of anterior en la misma maquina
    //variables que hacen falta para despues NO GANTT
    task['idRuta'] = operacion.idRuta;
    task['orden'] = operacion.ordenOperacion;
    task['ordenPlanificado'] = operacion.ordenPlanificado;
    task['idPlanificador'] = operacion.idPlanificador;
    task['cantidad'] = operacion.cantidad - operacion.cantidadHechas;
    task['cantidadAgrupada'] = operacion.cantidadAgrupada;
    task['operacionEmpezada'] = operacion.operacionEmpezada;
    task['enEjecucion'] = operacion.enEjecucion;
    task['cantidadReal'] = operacion.cantidad - operacion.cantidadHechas;
    task['fechaLimite'] = (operacion.fechafin == null) ? operacion.fechafin : new Date(operacion.fechafin);
    task['machine'] = idMaquina; // maquina actual
    task['cantidadTotalOF'] = operacion.cantidadTotalOF; // maquina actual
    task['cantidadHechasOF'] = operacion.cantidadHechasOF; // maquina actual

    switch (this.textoPlanificadorCortoTask_Inferior) {
      case 1: task['textoInferior'] = operacion.pieza; break;
      case 2: task['textoInferior'] = operacion.plano; break;
      case 3: task['textoInferior'] = operacion.refPieza; break;
      case 4: task['textoInferior'] = operacion.parte; break;
    }

    return task;
  }
  añadirOperacionesHechas(operacionesHechas, operacion, cantidadLotePlanificador, maquina) {
    var operaciones: any = {
      idRuta: operacion.idRuta,
      orden: operacion.ordenOperacion,
      cantidad: cantidadLotePlanificador,
      cantidadColores: cantidadLotePlanificador,
      fecha: this.myFunctions.dateCopy(maquina.fecha)// cuando ya se empieza una la proxima se pone en amarillo
    };
    operacionesHechas.push(operaciones);
  }
  datTexturaTask(t, operacion) {
    // TEXTURA: ejecucion
    t.filter = {
      src: '',
      animated: false
    };
    if (t.enEjecucion) {
      t.filter = {
        src: 'assets/img/ejecucion.png',
        animated: true
      };
    }
    // FUERA PLAZO : color top
    t.fueraPlazo = false;
    if (!(new Date(operacion.fechafin) > new Date(t.endDate) || operacion.fechafin == null)) {
      t.fueraPlazo = true;
      // t.filter.src = 'assets/img/fueraPlazo.png';
      // t.filter.animated = false;

    }
  }
  crearTaskTooltip(t, operacion, taskN, maquina, tasks_operacion, datPlanificador_grupos, datPlanificador_gruposOperaciones, ocultarParte, tasks) {
    t['tooltip'] = ``;
    if (tasks_operacion.length > 1) {
      t['tooltip'] += `<p><span class="tooltip-title" > ` + taskN + ` </span> </p >`;
    }

    // ESTO SE DEBERIA DE HACER EN EL BACKEND
    if (operacion.cantidadHechas == undefined) {
      operacion.cantidadHechas = 0;
      operacion.cantidad = 0;
    }
    if (operacion.idOperacion > -1) {
      // OPERACIONES
      t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('of') + `:</span>  <span class="tooltip-valor"> ` + operacion.refOF + `</span>  </p>`;
      t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('cliente') + `: </span>  <span class="tooltip-valor"> ` + operacion.cliente + `</span>  </p>`;
      t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('pieza') + `:</span>   <span class="tooltip-valor"> ` + operacion.pieza + `</span>  </p>`;
      if (operacion.parte != operacion.pieza) t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('parte') + ':</span>   <span class="tooltip-valor">  ' + operacion.parte + '</span>  </p>'
      t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('operacion') + `:</span>   <span class="tooltip-valor"> ` + operacion.operacion + `</span>  </p>`;
      if (t.peso > 0)
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('peso') + `:</span>  <span class="tooltip-valor"> ` + this.myFunctions.rendondeo_mod(operacion.cantidadHechas * operacion.peso, 0.01).toString() + ` Kg/` + this.myFunctions.rendondeo_mod(operacion.cantidad * operacion.peso, 0.01).toString() + ` Kg</span>  </p>`;
      else
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('cantidad') + `:</span>  <span class="tooltip-valor"> ` + operacion.cantidadHechas.toString() + `/` + operacion.cantidad.toString() + `</span>  </p>`;
      if (operacion.cantidadTotalOF != null)
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('cantidadTerminada') + `:</span>  <span class="tooltip-valor"> ` + operacion.cantidadHechasOF.toString() + `/` + operacion.cantidadTotalOF.toString() + `</span>  </p>`;

      if (maquina.tipo_maquina == 12) t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('temperatura') + `:</span>  <span class="tooltip-valor"> ` + operacion.temperatura + `º</span>  </p>`;

    } else if (operacion.idOperacionesGrupos > -1) {
      //GRUPOS
      var infoGrupos = (datPlanificador_grupos as any).filter(f => f.idOperacionesGrupos == operacion.idOperacionesGrupos);
      if (infoGrupos.length > 0) {
        var grupo = infoGrupos[0]
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('grupo') + `:</span>   <span class="tooltip-valor"> ` + grupo.idOperacionesGrupos + `</span>  </p>`;
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('temperatura') + `:</span>   <span class="tooltip-valor"> ` + grupo.temperatura + ` º</span>  </p>`;
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('peso') + `:</span>   <span class="tooltip-valor"> ` + grupo.peso + ` kg</span>  </p>`;
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('unidades') + `:</span>   <span class="tooltip-valor"> ` + grupo.unidades + ` u.</span>  </p>`;
      }
      var infoOperaciones = (datPlanificador_gruposOperaciones as any).filter(f => f.idOperacionesGrupos == operacion.idOperacionesGrupos);
      if (infoOperaciones.length > 0) {
        t['tooltip'] += `<p><span class="tooltip-title">` + this.translateService.instant('operaciones') + `:</span></p>`;
        infoOperaciones.forEach(
          operacion => {
            if (ocultarParte) t['tooltip'] += `<p><span class="tooltip-valor">` + operacion.refOf + ` - ` + operacion.pieza + ` - ` + operacion.operacion + ` (` + operacion.cantidadProv + `)</span></p>`;
            else t['tooltip'] += `<p><span class="tooltip-valor">` + operacion.refOf + ` - ` + operacion.pieza + ` - ` + operacion.parte + ` - ` + operacion.operacion + ` (` + operacion.cantidadProv + `)</span></p>`;
          });
      }
    }

    t['tooltip2'] = `<p><span class="tooltip-title">` + this.translateService.instant('tiempoEstimado') + `:</span>   <span class="tooltip-valor"> ` + this.myFunctions.secondsToHms(operacion.tiempoEstimado * (operacion.cantidad - operacion.cantidadHechas)) + `</span>  </p>`;
    if (operacion.tiempoPreparacion > 0) t['tooltip2'] += `<p><span class="tooltip-title">` + this.translateService.instant('preparacion') + `:</span>  <span class="tooltip-valor"> ` + this.myFunctions.secondsToHms(operacion.tiempoPreparacion) + `</span>  </p>`;
    if (operacion.tiempoEjecutadoInicial > 0) t['tooltip2'] += `<p><span class="tooltip-title">` + this.translateService.instant('tiempoRestante') + `:</span>   <span class="tooltip-valor"> ` + this.myFunctions.secondsToHms(operacion.tiempoRestante) + `</span>  </p>`;
    t['tooltip2'] += `<p><span class="tooltip-title">` + this.translateService.instant('fechaentrega') + `:</span>  <span class="tooltip-valor"> ` + new Date(operacion.fechafin).toLocaleDateString() + `</span>  </p>`;

    if (tasks_operacion.length == 1 && t.peso > 0) {
      // Numero de envases
      t['tooltip2'] += `<p><span class="tooltip-title">` + this.translateService.instant('numeroEnvases') + `:</span>  <span class="tooltip-valor"> ` + operacion.numeroEnvases + `</span>  </p>`;

      // la unica empresa con peso por ahora es TTTbergara y son los que han pedido esto
      var fechaMaxAnterior = this.now;
      var tPreparacionAnterior = 0;
      tasks.forEach(
        task => {
          if (task.endDate > fechaMaxAnterior && task.idMaquina == operacion.idMaquina) {
            tPreparacionAnterior = task.tPreparacion;
            fechaMaxAnterior = task.endDate;
          }
        });
      if (fechaMaxAnterior > this.now) {
        var espera = ((operacion.fechaPlanificadoInicio.getTime() - fechaMaxAnterior.getTime()) / 1000);
        if (espera > 60) {
          espera += tPreparacionAnterior;
        } else if (espera < -60) {
          espera += tPreparacionAnterior;
          if (espera < 0)
            espera = -espera;
        } else {
          espera = tPreparacionAnterior;
        } t['tooltip2'] += `<p><span class="tooltip-title">` + this.translateService.instant('tiempoEspera') + `:</span>  <span class="tooltip-valor"> ` + this.myFunctions.secondsToHms(espera) + `</span>  </p>`;
      }
    }
  }
  darColorTasks(tasks, operacionesHechas) {


    //DAR COLOR AL GRAFICO START
    // se genera un array con key segun la fecha y la maquina y se ordena para poder editar si la operacion se puede hacer.

    var array = []
    tasks.forEach(a => array[this.myFunctions.dateToYYYYMMDDHHmmSS(a['startDate']) + this.myFunctions.addXzero(a['orden'], 20) + a['machine']] = a);
    array.sort(function (a, b) { return b[1] - a[1] });
    var keys = [];
    Object.keys(array).sort().forEach(key => keys.push(key));
    // hacemnos la iteracion en orden de las ejecuciones segun la fecha.
    var coloresID: any = {};
    keys.forEach(key => {
      var row = array[key];
      //SI LA OPERACION ESTA EMPEZADA, SE PONE EN COLOR VERDE PERO DESPUES DE DESCONTAR LAS OPERACIONES PREVIAS PARA NO CONTARLAS COMO POSIBLES AUN HABIENDO EMPEZADO LA PROXIMA OPERACION.
      var cantidadEmpezada = 0;
      if (row.operacionEmpezada) {
        cantidadEmpezada = 1; //SI UNA OPERACION ESTA EN EJECUCION, POR AHORA CONTAREMOS COMO QUE HAY 1 EMPEZADA DE ESE APPOINTMENT, POR LO CUAL NO TIENE QUE CONSUMIR SU CANTIDAD COMPLETA SINO ESA CANTIDAD - 1, PUES LA EMPEZADA YA SE HA PODIDO HACER Y PARA ESO YA SE SUPONE QUE HA "DESCONTADO UNA OPERACINO ANTERIOR"
      }
      if (coloresID[row.id]) {
        row.backgroundColor = coloresID[row.id];
      }
      else {
        if (row.orden > 1) {
          var anteriores = operacionesHechas.filter(hecha => new Date(hecha.fecha) <= new Date(row.startDate) && hecha.idRuta == row.idRuta && hecha.orden + 1 == row.orden);
          var cantidad: number = row.cantidadReal - cantidadEmpezada;//la cantidad es igual a la cantidad - las piezas en proceso         
          anteriores.forEach(anterior => {
            if (cantidad > anterior.cantidadColores) {
              cantidad = cantidad - anterior.cantidadColores;
              anterior.cantidadColores = 0;
            } else {
              anterior.cantidadColores = anterior.cantidadColores - cantidad;
              cantidad = 0;
            }
          });
          if (cantidad > 0) {
            if (cantidad < row.cantidadReal) {
              if ((row.cantidadReal - cantidadEmpezada) - cantidad >= row.cantidadAgrupada) { // hay mas operaciones que se han descontado que la cantidad agrupada minima
                row.backgroundColor = '#D8CB3F'; // amarillo // es orden > 1 y entra en la fecha, ademas se puede EMPEZAR
                row.tooltip = row.tooltip + `
<p><span class="tooltip-title">` + this.translateService.instant('cantidadPosibles') + `:</span>  <span class="tooltip-valor"> ` + cantidad + `</span>  </p>`
              }
              else {
                row.backgroundColor = '#EE9F51'; // naranja //  NO se puede EMPEZAR LA CANTIDAD AGRUPADA!
                row.tooltip = row.tooltip + `
<p><span class="tooltip-title">` + this.translateService.instant('cantidadPosibles') + `:</span>  <span class="tooltip-valor"> ` + cantidad + `</span>  </p>`
              }

            }
            else {
              row.backgroundColor = '#EE9F51'; // naranja // es orden > 1 y entra en la fecha, ademas NO se puede EMPEZAR
            }

            //SI LA OPERACION ESTA EMPEZADA, SE PONE EN COLOR VERDE PERO DESPUES DE DESCONTAR LAS OPERACIONES PREVIAS PARA NO CONTARLAS COMO POSIBLES AUN HABIENDO EMPEZADO LA PROXIMA OPERACION.
            if (row.operacionEmpezada) {
              var actuales = operacionesHechas.filter(
                hecha => new Date(hecha.fecha) >= new Date(row.startDate) &&
                  new Date(hecha.fecha) <= new Date(row.endDate) &&
                  hecha.idRuta == row.idRuta &&
                  hecha.orden == row.orden);
              actuales.reverse().forEach(
                operacionNoPosible => {
                  if (cantidad > 0) {
                    operacionNoPosible.cantidadColores = operacionNoPosible.cantidadColores - 1;
                    cantidad--;// = cantidad - 1;
                  }
                });
            }
          } else
            row.backgroundColor = '#509AB5'; // azul // es orden > 1 y entra en la fecha, ademas se puede HACER
        } else
          row.backgroundColor = '#509AB5'; // azul // es de orden 1 y entra en la fecha
        //una vez ya se consumen
        coloresID[row.id] = row.backgroundColor;
      }

      //SI LA OPERACION ESTA EMPEZADA, SE PONE EN COLOR VERDE PERO DESPUES DE DESCONTAR LAS OPERACIONES PREVIAS PARA NO CONTARLAS COMO POSIBLES AUN HABIENDO EMPEZADO LA PROXIMA OPERACION.
      if (row.operacionEmpezada) {
        row.backgroundColor = '#48D348'; //VERDE //ESTA EN EJECUCION
      }


      row.detail = (d) => row.tooltip + row.tooltip2 + `
<p><span class="tooltip-valor">${d.startDate.toLocaleDateString()} ${d.startDate.toLocaleTimeString()} - ${d.endDate.toLocaleDateString()} ${d.endDate.toLocaleTimeString()}</span> </p>`;
    });
    //DAR COLOR AL GRAFICO END


  }
  crearMachines_filtradas(jgruposMaquinasSelected, jgruposMaquinas, seccionesSeleccionadas, secciones, jgruposMaquinasMaquinas) {

    //FILTRO POR GRUPO DE MAQUINAS   
    var idsGruposSelecteds: any = [];
    if (jgruposMaquinasSelected && jgruposMaquinasSelected.length > 0) {
      jgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }
    else {
      jgruposMaquinas.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (seccionesSeleccionadas && seccionesSeleccionadas.length > 0) {
      seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //CON LOS DOS ANTERIORES FILTROS SE FILTRAN LAS ID DE LAS MAQUINAS
    var idsMaquinasSelecteds: any = [];
    jgruposMaquinasMaquinas.forEach(
      maquina => {
        if ((idsGruposSelecteds.includes(maquina.idGrupo) || jgruposMaquinas.length == 0) && (idsSeccionesSelecteds.includes(maquina.idSeccion) || secciones.length == 0))
          idsMaquinasSelecteds.push(maquina.tipo + '_' + maquina.id);
      });

    var maquinasSeleccionadas: any = [];

    this.DatMaquinas.forEach(
      maquina => {
        maquina.multiRow = 1;
        if (idsMaquinasSelecteds.includes(maquina.tipo + '_' + maquina.id))
          maquinasSeleccionadas.push(maquina);
      });

    return maquinasSeleccionadas;
  }
  resaltarOoperacionesFiltradas(ofsSeleccionados, clientesSeleccionados, piezasSeleccionados, joperaciones, datPlanificador, ganttChart, tasks) {
    /*  FILTRAR OFS, CLIENTES Y PIEZAS (ahora solo resalta el filtrado) */
    var idsOFs = [];
    if (ofsSeleccionados != undefined) ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));
    var idsClientes = [];
    if (clientesSeleccionados != undefined) clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));
    var idsPiezas = [];
    if (piezasSeleccionados != undefined) {
      piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.ids));
      idsPiezas = idsPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []);
    }

    var partes = [];
    tasks.forEach(
      task => {
        joperaciones = (datPlanificador as any).filter(f => f.idPlanificador == task.idPlanificador)
        var datPlan = joperaciones[0];
        if ((idsOFs.length == 0 || idsOFs.includes(datPlan.idOF)) &&
          (idsClientes.length == 0 || idsClientes.includes(datPlan.idCliente)) &&
          (idsPiezas.length == 0 || idsPiezas.includes(datPlan.idPieza))) {
          partes.push(this.getCodigoParte(datPlan.refOF, datPlan.pieza, datPlan.parte, -1));
        }
      });

    if ((idsOFs.length != 0) || (idsClientes.length != 0) || (idsPiezas.length != 0))
      ganttChart.resaltarOF(partes);
    else
      ganttChart.resaltarOF([]);


  }
  descontarTiemposEjecutrados(datPlanificador_tiemposEjecutados, datPlanificador) {
    //  Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
    var operacionesOrdenadas2 = {};
    var operacionesEnEjecucion = {};
    // Preparamos un array con las operaciones que se han ejecutado 
    // Tanto por maquina operacion como solo por operacion por si no se encuentra en esa maquina
    datPlanificador_tiemposEjecutados.forEach(
      a => {
        if (operacionesOrdenadas2[a['idOFs_Operacion']])
          operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
        else
          operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

        operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
        operacionesEnEjecucion[/*a['idMaquina'] + '_' +*/ a['idOFs_Operacion']] = a['enEjecucion'];
      });
    // Quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
    datPlanificador.forEach(
      row => {
        // añadir las 3 nuevas variables
        row['tiempoEjecutado'] = 0;
        row['operacionEmpezada'] = false;
        row['enEjecucion'] = false;

        //se ponen las que estan en ejecucion en este momento
        var enEjecucion = operacionesEnEjecucion[/*row['idMaquina'] + '_' +*/ row['idOperacion']];
        if (enEjecucion) {
          if (enEjecucion == 1) {
            row['enEjecucion'] = true;
          }
        }

        //se descuenta el tiempo por maquina y operacion
        var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
        if (tiempoEjecutado != undefined) {
          //se le pone que esta empezada              
          row['operacionEmpezada'] = true;
          if (tiempoEjecutado > 0) {
            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //se descuenta el tiempo de las 3 partes que afecta 
            if (tiempoEjecutado <= maximoTiempoAppointment) {
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
              operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
              operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            } else {
              operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
              operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            }
          }
        }

      });
    // Despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
    datPlanificador.forEach(
      row => {
        var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
        if (tiempoEjecutado != undefined) {
          //se le pone que esta empezada              
          row['operacionEmpezada'] = true;
          if (tiempoEjecutado > 0) {
            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //se descuenta el tiempo de las 3 partes que afecta 
            if (tiempoEjecutado <= maximoTiempoAppointment) {
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
              operacionesOrdenadas2[row['idOperacion']] = 0;
            } else {
              operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            }
          }
        }
      });
  }
  calcularTiempoCambioTemperatura(temperatura_maquina, temperatura_operacion, maquinaRangos, tiempoMinimoCambioTemperatura) {


    // if (temperatura_maquina == 0)
    //   return 0;
    var subida = temperatura_maquina < temperatura_operacion;
    var tiempo = 0;
    var minimo = temperatura_maquina;
    var maximo = temperatura_operacion;

    if (!subida) {
      minimo = temperatura_operacion;
      maximo = temperatura_maquina;
    }

    maquinaRangos.forEach(
      rango => {
        if (rango.subida == subida) {
          if (rango.temperaturaMin <= minimo && maximo <= rango.temperaturaMax) {
            tiempo += 60 * (maximo - minimo) / rango.gradosPorMinuto
          } else if (rango.temperaturaMin <= minimo && minimo <= rango.temperaturaMax) {
            tiempo += 60 * (rango.temperaturaMax - minimo) / rango.gradosPorMinuto
          } else if (rango.temperaturaMin <= maximo && maximo <= rango.temperaturaMax) {
            tiempo += 60 * (maximo - rango.temperaturaMin) / rango.gradosPorMinuto
          }
        }
      });

    if (tiempo < tiempoMinimoCambioTemperatura) {
      return tiempoMinimoCambioTemperatura
    }
    else {
      return tiempo
    }
  }
  //#endregion





  ganttDropped(id, newStartDate, newMachine) {
    this.loadingPanel = true;

    var idPlanificador = this.tasks.filter(f => f.id == id)[0].idPlanificador;
    var operacion = (this.DatPlanificador as any).filter(f => f.idPlanificador == idPlanificador)[0];

    if (operacion.idPlanificador > 0) {
      // LA OPERACION MOVIDA SE HA ENCONTRADO
      var newOrden = 1;
      var proximasDrop = this.tasks.filter(f => f.machine == newMachine && new Date(f.endDate) >= new Date(newStartDate));
      var noSeMueve = false;
      var aLaPrimera = true;

      if (proximasDrop.length > 0) { // se pone antes del proximo     
        newOrden = proximasDrop[0].ordenPlanificado;
        noSeMueve = proximasDrop[0].id == id
      } else {
        aLaPrimera = false;
        proximasDrop = this.tasks.filter(f => f.machine == newMachine);
        if (proximasDrop.length > 0)// se pone el ultimo
        {
          newOrden = proximasDrop[proximasDrop.length - 1].ordenPlanificado + 1;
          noSeMueve = proximasDrop[proximasDrop.length - 1].id == id
        }
      }

      // SE SABEN LOS NUEVOS ORDENES DE LAS OPERACIONES
      if (!noSeMueve) {
        var oldMaquina = operacion.idMaquina;
        var newMaquina = this.machineToMaquina[newMachine];
        var oldOrden = operacion.ordenPlanificado + 0;

        //SI ES LA MISMA MAQUINA, EL NUEVO ORDEN DEVERIA DE SER -1
        if (oldMaquina == newMaquina && oldOrden < newOrden) newOrden = newOrden - 1;

        var ultimaSimultaneaAnterior;

        (this.DatPlanificador as any).forEach(
          f => {
            // al hacer D&D se reestablecen las cantidades de cada appointment para no perder operaciones en el proceso
            f.cantidad = f.total;
            if (f.idPlanificador == operacion.idPlanificador) {
              f.idMaquina = newMaquina;
              // Se actualiza el nombre de la nueva maquina tambien START
              var maquina = this.DatMaquinas.filter(f => f.id == newMaquina)
              f.maquina = maquina[0].nombre;
              // Se actualiza el nombre de la nueva maquina tambien END
              f.ordenPlanificado = newOrden;
            } else {
              if (f.idMaquina == oldMaquina && f.ordenPlanificado >= oldOrden)
                f.ordenPlanificado--;
              if (f.idMaquina == newMaquina && f.ordenPlanificado >= newOrden)
                f.ordenPlanificado++;

              // SE MIRA A VER CUAL ES LA ULTIMA OPERACION SIMULTANEA ANTERIOR EN LA NUEVA MAQUINA
              if (f.idMaquina == newMaquina && f.ordenPlanificado < newOrden && f.idOperacionSimultanea > 0) {
                if (ultimaSimultaneaAnterior == undefined || f.ordenPlanificado > ultimaSimultaneaAnterior.ordenPlanificado) {
                  ultimaSimultaneaAnterior = f;
                }
              }
            }
          });

        if (operacion.idOperacionSimultanea > 0) {
          var operacionesSimultaneas = (this.DatPlanificador as any).filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
          if (operacionesSimultaneas.length > 0) {

            // Sacamos el orden de simultanea que tiene la operacion seleccionada
            var i = 0;
            var index = 0;
            operacionesSimultaneas.forEach(
              op => {
                if (op.idOperacion == operacion.idOperacion) index = i;
                i++;
              });

            // Sacamos la primera ruta que nos coincida con la maquina 
            var cadenaEncontrada = [];
            this.cadenas_Maquinas.forEach(
              cadena => {
                if (cadena[index] == newMaquina) {
                  cadenaEncontrada = cadena;
                }
              });

            //DESPUES DE MOVER LA OPERACION ORIGINAL, MIRAMOS SI TIENE MAS OPERACIONES SIMULTANEAS
            index = 0;
            operacionesSimultaneas.forEach(
              operacionSimultanea => {
                if (operacion.idPlanificador != operacionSimultanea.idPlanificador) {
                  //SE MUEVEN TODAS LAS OPERACINOES SIMULTANEAS
                  var operacionSimultaneaEquivalenteAnteriores = (this.DatPlanificador as any).filter(
                    f => f.idOperacionSimultanea == ultimaSimultaneaAnterior?.idOperacionSimultanea && f.idMaquina == operacionSimultanea.idMaquina
                  );

                  var newMaquinaSimultanea = 0;
                  var newOrdenSimultanea = 0;

                  if (operacionSimultaneaEquivalenteAnteriores.length > 0) {
                    var operacionSimultaneaEquivalenteAnterior = operacionSimultaneaEquivalenteAnteriores[0];
                    newMaquinaSimultanea = operacionSimultaneaEquivalenteAnterior.idMaquina;
                    newOrdenSimultanea = operacionSimultaneaEquivalenteAnteriores.ordenPlanificado + 1;
                  } else {
                    newMaquinaSimultanea = cadenaEncontrada[index];
                    if (newMaquinaSimultanea == undefined) {
                      newMaquinaSimultanea = 0;
                    } else {
                      (this.DatPlanificador as any).forEach(
                        operacion => {
                          if (operacion.orden >= newOrdenSimultanea && operacion.idMaquina == newMaquinaSimultanea) {
                            newOrdenSimultanea = operacion.orden + 1; // la colocamoas al final de la maquina enlazada si no tenemos una referencia clara.
                          }
                        });
                    }
                  }

                  var oldMaquinaSimultanea = operacionSimultanea.idMaquina;
                  var oldOrdenSimultanea = operacionSimultanea.ordenPlanificado + 0;

                  if (newMaquinaSimultanea == 0) newMaquinaSimultanea = newMaquina; //si no se encuentra ninguna maquina de ninguna ruta, se pone tras la operacion simultanea previa 
                  if (newOrdenSimultanea == 0) newOrdenSimultanea = newOrden; //si no se encuentra ninguna maquina de ninguna ruta, se pone tras la operacion simultanea previa 

                  (this.DatPlanificador as any).forEach(
                    f => {
                      if (f.idPlanificador == operacionSimultanea.idPlanificador) {
                        f.idMaquina = newMaquinaSimultanea;
                        // Se actualiza el nombre de la nueva maquina tambien START
                        var maquina = this.DatMaquinas.filter(f => f.id == newMaquinaSimultanea)
                        f.maquina = maquina[0].nombre;
                        // Se actualiza el nombre de la nueva maquina tambien END
                        f.ordenPlanificado = newOrdenSimultanea;
                      } else {
                        if (f.idMaquina == oldMaquinaSimultanea && f.ordenPlanificado >= oldOrdenSimultanea)
                          f.ordenPlanificado--;
                        if (f.idMaquina == newMaquinaSimultanea && f.ordenPlanificado >= newOrdenSimultanea)
                          f.ordenPlanificado++;

                        // SE MIRA A VER CUAL ES LA ULTIMA OPERACION SIMULTANEA ANTERIOR EN LA NUEVA MAQUINA
                        if (f.idMaquina == newMaquinaSimultanea && f.ordenPlanificado < newOrdenSimultanea && f.idOperacionSimultanea > 0) {
                          if (ultimaSimultaneaAnterior == undefined || f.ordenPlanificado > ultimaSimultaneaAnterior.idPlanificador) {
                            ultimaSimultaneaAnterior = f;
                          }
                        }
                      }
                    });
                }
                index++;
              });
          }
        }


      }
    }
    this.PREcargarGantt();
    //this.cargarGantt_operaciones_temporal();
  }
  private actual_Task_info = -1;
  ganttClicked(id) {
    if (this.actual_Task_info != id) {
      this.actual_Task_info = id;

      var task = this.tasks.filter(f => f.id == id)[0];
      var an: any = this.DatPlanificador;
      this.Joperaciones = an.filter(f => f.idPlanificador == task.idPlanificador)

      this.visibleInfo = true;
      if (this.Joperaciones[0].idOperacion > -1) {
        this.visibleInfoGrupo = false;

        this.idOperacionesGrupos = -1;

        var datPlan = this.Joperaciones[0];

        if (datPlan.cantidad - datPlan.cantidadHechas < datPlan.total)
          datPlan.cantidadProv = datPlan.cantidad - datPlan.cantidadHechas;
        else
          datPlan.cantidadProv = datPlan.total;

        this.infoOF = datPlan.refOF;
        this.infoCliente = datPlan.cliente;
        this.infoProyecto = datPlan.proyecto;
        this.infoPieza = datPlan.pieza;
        this.infoPlano = datPlan.plano;
        this.infoParte = datPlan.parte;
        this.infoRefPieza = datPlan.refPieza;

        this.JmaquinaSelected = undefined;
        this.JmaquinaSelectedLargo = undefined;
        this.Jmaquinas.forEach(f => {
          if (f.id == datPlan.idMaquina) {
            this.JmaquinaSelected = f;
            this.JmaquinaSelectedLargo = f;
          }
        });

        this.JsemanaSelected = this.Jsemanas[0];
      } else if (this.Joperaciones[0].idOperacionesGrupos > -1) {
        this.visibleInfoGrupo = true;

        this.idOperacionesGrupos = this.Joperaciones[0].idOperacionesGrupos;

        var datPlan = (this.DatPlanificador_grupos as any).filter(f => f.idOperacionesGrupos = this.Joperaciones[0].idOperacionesGrupos)[0];

        this.nombreGrupo = this.Joperaciones[0].nombre;
        this.temperaturaGrupo = datPlan.temperatura + " º";
        this.tiempoEstimadoGrupo = this.myFunctions.secondsTo_HH_MM(datPlan.tiempoEstimado);
        this.pesoGrupo = datPlan.peso + " kg";
        this.unidadesGrupo = datPlan.unidades + " u.";

        this.JmaquinaSelected = undefined;
        this.JmaquinaSelectedLargo = undefined;
        this.Jmaquinas.forEach(f => {
          if (f.id == this.Joperaciones[0].idMaquina) {
            this.JmaquinaSelected = f;
            this.JmaquinaSelectedLargo = f;
          }
        });

        this.JsemanaSelected = this.Jsemanas[0];

        // como es un grupo, obtendremos los datos de las operaciones internas
        this.Joperaciones = (this.DatPlanificador_gruposOperaciones as any).filter(f => f.idOperacionesGrupos == this.Joperaciones[0].idOperacionesGrupos);
      }
    } else {
      this.visibleInfo = false;
      this.visibleInfoGrupo = false;
      this.actual_Task_info = -1;
    }
  }

  //BOTONES "FILTRO"
  btnTiempoEstimado() {
    this.loadingPanel = true;

    this.aplicarTiempoEstimado = true;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.getPlanificadorCorto_CargarGANTT(version);
  }
  btnPredictivo() {
    this.loadingPanel = true;

    this.aplicarTiempoEstimado = false;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.getPlanificadorCorto_CargarGANTT(version);
  }
  btnIneficiencia() {
    this.loadingPanel = true;

    this.aplicarIneficiencias = !this.aplicarIneficiencias;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.getPlanificadorCorto_CargarGANTT(version);
  }
  //CAMBIO DE VERSION
  versionChanged(event) {
    this.loadingPanel = true;

    this.JplanificadoresSelected = event;

    this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
      this.dtConfiguracionPlanificador = result[0];

      this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
      this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
      this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;
      this.textoPlanificadorCortoTask_Inferior = this.dtConfiguracionPlanificador.textoPlanificadorCortoTask_Inferior;

      // var r1_1, r1_2: boolean = false;
      // this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
      //   json => {
      //     this.DatOperacionesSinCorto = json;

      //     this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

      //     r1_1 = true;
      //     if (r1_1 && r1_2)
      //       this.recargarGrid();
      //   }
      // );
      // this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
      //   json => {
      //     var grupos = (json as any).datOperaciones_grupos
      //     grupos.forEach(
      //       grupo => {
      //         grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

      //         grupo.maquina = '';
      //         var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
      //         if (maquinas.length > 0)
      //           grupo.maquina = maquinas[0].nombre;

      //       });

      //     this.Jgrupos = grupos;

      //     this.DatOperaciones_grupos = grupos;

      //     r1_2 = true;
      //     if (r1_1 && r1_2)
      //       this.recargarGrid();
      //   }
      // );
      var version = 1;
      if (this.JplanificadoresSelected != undefined)
        version = this.JplanificadoresSelected.value;
      this.getPlanificadorCorto_CargarGANTT(version);

      this.cargarDatosFiltro();

      if (version > 1)
        this.visibleUsarVersion = true;
      else
        this.visibleUsarVersion = false;

    });


  }

  //BOTONES
  btnTraerDesdeLargo() {
    this.visibleInfo = false;
    this.visibleInfoGrupo = false;
  }
  btnGuardar() {
    this.planificadorService.Update_corto(this.DatPlanificador).subscribe({
      next: () => {
        this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
      }
    });
  }
  btnReorganizar() {
    this.prioridadCliente = 0;
    this.prioridadFacturacion = 0;
    this.prioridadOF = 0;
    this.prioridadMaquina = 0;
    this.prioridadFecha = 0;
    this.prioridadTemperatura = 0;

    this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion[0];

    this.dias_reserva = 0;
    this.percent_cap_maquina = 0;
    this.optimizarCuelloBotella = true;
    this.replanificarTiempoEstimado = true;
    this.replanificarIneficiencias = false;
    this.planificarSoloNoPlanificadas = false;
    this.asap_maximoMesesParaIniciarAntesDeFechaEntrega = 6;

    this.prioridadCliente = this.dtConfiguracionPlanificador.prioridadCliente;
    this.prioridadFacturacion = this.dtConfiguracionPlanificador.prioridadFacturacion;
    this.prioridadOF = this.dtConfiguracionPlanificador.prioridadOF;
    this.prioridadMaquina = this.dtConfiguracionPlanificador.prioridadMaquina;
    this.prioridadFecha = this.dtConfiguracionPlanificador.prioridadFecha;
    this.prioridadTemperatura = this.dtConfiguracionPlanificador.prioridadTemperatura;

    this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion.find(f => f.id == this.dtConfiguracionPlanificador.metodologiaPlanificacion);

    this.dias_reserva = this.dtConfiguracionPlanificador.dias_reserva;
    this.percent_cap_maquina = this.dtConfiguracionPlanificador.percent_cap_maquina;
    this.optimizarCuelloBotella = this.dtConfiguracionPlanificador.optimizarCuelloBotella;
    this.replanificarTiempoEstimado = this.dtConfiguracionPlanificador.replanificarTiempoEstimado;
    this.replanificarIneficiencias = this.dtConfiguracionPlanificador.replanificarIneficiencias;
    this.planificarSoloNoPlanificadas = this.dtConfiguracionPlanificador.planificarSoloNoPlanificadas;
    this.asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.dtConfiguracionPlanificador.asap_maximoMesesParaIniciarAntesDeFechaEntrega;

    this.modalReference = this.modalService.open(this.popupReorganizar, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnReorganizarTiempoEstimado() {
    this.replanificarTiempoEstimado = true;
  }
  btnReorganizarTiempoPredictivo() {
    this.replanificarTiempoEstimado = false;
  }
  btnReorganizarIneficiencia() {
    this.replanificarIneficiencias = !this.replanificarIneficiencias;
  }
  btnReorganizarAceptar() {
    var prioridadCliente: number = 0;
    var prioridadFacturacion: number = 0;
    var prioridadOF: number = 0;
    var prioridadMaquina: number = 0;
    var prioridadFecha: number = 0;
    var prioridadTemperatura: number = 0;

    var metodologiaPlanificacion: number = 0;

    var dias_reserva: number = 0;
    var percent_cap_maquina: number = 0;
    var optimizarCuelloBotella: boolean = true;
    var replanificarTiempoEstimado = true;
    var replanificarIneficiencias = false;
    var planificarSoloNoPlanificadas = false;
    var asap_maximoMesesParaIniciarAntesDeFechaEntrega = 6;

    var total: number = 0;

    total = this.prioridadCliente + this.prioridadFacturacion + this.prioridadOF + this.prioridadMaquina + this.prioridadFecha + this.prioridadTemperatura;

    prioridadCliente = this.reglaDeTres(this.prioridadCliente, total, 100);
    prioridadFacturacion = this.reglaDeTres(this.prioridadFacturacion, total, 100);
    prioridadOF = this.reglaDeTres(this.prioridadOF, total, 100);
    prioridadMaquina = this.reglaDeTres(this.prioridadMaquina, total, 100);
    prioridadFecha = this.reglaDeTres(this.prioridadFecha, total, 100);
    prioridadTemperatura = this.reglaDeTres(this.prioridadTemperatura, total, 100);

    metodologiaPlanificacion = this.metodologiasDePlanificacionSelected.id;

    dias_reserva = this.dias_reserva;
    percent_cap_maquina = this.percent_cap_maquina;

    replanificarTiempoEstimado = this.replanificarTiempoEstimado;
    replanificarIneficiencias = this.replanificarIneficiencias;
    planificarSoloNoPlanificadas = this.planificarSoloNoPlanificadas;
    asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.asap_maximoMesesParaIniciarAntesDeFechaEntrega;

    optimizarCuelloBotella = this.optimizarCuelloBotella;

    var tiempo = 1;
    if (!this.aplicarTiempoEstimado)
      tiempo = 2;

    this.planificadorService.Reorganizar_Skootik(1, this.JplanificadoresSelected.value, tiempo,// la maquina no se usa por ahora
      prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, percent_cap_maquina, prioridadFecha, prioridadTemperatura,
      metodologiaPlanificacion, optimizarCuelloBotella, 0, !replanificarTiempoEstimado, replanificarIneficiencias, planificarSoloNoPlanificadas, asap_maximoMesesParaIniciarAntesDeFechaEntrega).subscribe(
        json => {
          var r1_1, r1_2: boolean = false;
          this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
            json => {
              this.DatOperacionesSinCorto = json;

              this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

              r1_1 = true;
              if (r1_1 && r1_2)
                this.recargarGrid();
            }
          );
          this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
            json => {
              var grupos = (json as any).datOperaciones_grupos
              grupos.forEach(
                grupo => {
                  grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                  grupo.maquina = '';
                  var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                  if (maquinas.length > 0)
                    grupo.maquina = maquinas[0].nombre;

                });

              this.Jgrupos = grupos;

              this.DatOperaciones_grupos = grupos;

              r1_2 = true;
              if (r1_1 && r1_2)
                this.recargarGrid();

            });

          var version = 1;
          if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

          this.getPlanificadorCorto_CargarGANTT(version);
        }
      );

    this.modalReference.close();
  }

  btnCopiarAVersion() {
    this.modalReference = this.modalService.open(this.popupCopiarAVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnCopiarAVersionAceptar() {
    this.planificadorService.copiarAVersion(this.JplanificadoresSelected.value, this.JplanificadoresSelectedCopiar.value, 1).subscribe(
      json => {
        this.JplanificadoresSelected = this.JplanificadoresSelectedCopiar;

        var r1_1, r1_2: boolean = false;
        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
          json => {
            this.DatOperacionesSinCorto = json;

            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

            r1_1 = true;
            if (r1_1 && r1_2)
              this.recargarGrid();
          }
        );
        this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
          json => {
            var grupos = (json as any).datOperaciones_grupos
            grupos.forEach(
              grupo => {
                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                grupo.maquina = '';
                var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                if (maquinas.length > 0)
                  grupo.maquina = maquinas[0].nombre;

              });

            this.Jgrupos = grupos;

            this.DatOperaciones_grupos = grupos;

            r1_2 = true;
            if (r1_1 && r1_2)
              this.recargarGrid();

          });
        this.getPlanificadorCorto_CargarGANTT(this.JplanificadoresSelected.value);

        if (this.JplanificadoresSelected.value > 1)
          this.visibleUsarVersion = true;
        else
          this.visibleUsarVersion = false;
      });

    this.modalReference.close();
  }

  btnBorrarVersion() {
    this.modalReference = this.modalService.open(this.popupBorrarVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnBorrarVersionAceptar() {
    this.planificadorService.borrarVersion(this.JplanificadoresSelected.value, 1).subscribe(
      json => {
        var r1_1, r1_2: boolean = false;
        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
          json => {
            this.DatOperacionesSinCorto = json;

            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

            r1_1 = true;
            if (r1_1 && r1_2)
              this.recargarGrid();
          }
        );
        this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
          json => {
            var grupos = (json as any).datOperaciones_grupos
            grupos.forEach(
              grupo => {
                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                grupo.maquina = '';
                var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                if (maquinas.length > 0)
                  grupo.maquina = maquinas[0].nombre;

              });

            this.Jgrupos = grupos;

            this.DatOperaciones_grupos = grupos;

            r1_2 = true;
            if (r1_1 && r1_2)
              this.recargarGrid();

          });
        this.getPlanificadorCorto_CargarGANTT(this.JplanificadoresSelected.value);
      });
    this.modalReference.close();
  }

  //BOTONES
  btnSacar() {
    this.loadingPanel = true;

    //if (this.idOperacionesGrupos > 0) {
    //   var rowsSelecteds: any = (this.DatPlanificador as any).filter(f => f.idOperacionesGrupos == this.idOperacionesGrupos);
    //   rowsSelecteds.forEach(
    //     row => {
    //       row.cantidad = row.cantidad - row.cantidadProv;
    //       row.cantidadProv = row.cantidad - row.cantidadHechas;
    //     }
    //   );
    // } else {
    //   var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    //   rowsSelecteds.forEach(
    //     row => {
    //       row.cantidad = row.cantidad - row.cantidadProv;
    //       row.cantidadProv = row.cantidad - row.cantidadHechas;
    //     }
    //   );
    // }

    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
    if (rowsSelecteds.length == 0) // grupos seleccionados grid
      rowsSelecteds = (this.Jgrupos as any).filter(f => this.operacionesSelected.includes(f.numFila));
    if (this.visibleInfoGrupo) // click en grupo y desde dentro
      rowsSelecteds = (this.Jgrupos as any).filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

    var idsOperaciones: any = [];
    var idMaquinaSeleccionada: number = this.JmaquinaSelected.id
    rowsSelecteds.forEach(f => {
      // if (f.idOperacionSimultanea > 0) {
      idsOperaciones.push(f.idOperacion);
      idMaquinaSeleccionada = f.idMaquina;
      // }
    });

    if (idsOperaciones.length > 0) {

      this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
        json => {
          var opsSimultaneas: any = json;

          rowsSelecteds.forEach(
            operacion => {
              // Se buscan sus simultanias si tiene
              if (operacion.idOperacionSimultanea > 0) {
                // SI TIENE OP SIMULTANEAS
                var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);

                // Las añadimos para el insert
                ops_simultaneas.forEach(
                  op => {
                    // La actualizamos si estaba planificada
                    if (op.idMaquina > 0) {
                      var rowsSelecteds: any = (this.DatPlanificador as any).filter(f => f.idOperacion == op.idOperacion && f.idMaquina == op.idMaquina); // operaciones
                      if (rowsSelecteds.length > 0) { // grupos seleccionados grid
                        rowsSelecteds[0].cantidad = operacion.cantidad - operacion.cantidadProv;
                        rowsSelecteds[0].cantidadProv = operacion.cantidad - operacion.cantidadHechas;
                      }
                    }
                  });
              }
            });

          this.visibleInfo = false;
          this.visibleInfoGrupo = false;
          this.PREcargarGantt();
          this.cargarDatosFiltro();
          //this.cargarGantt_operaciones_temporal();
        });
    }





  }
  btnMover() {
    this.loadingPanel = true;

    var salir = false;
    if (this.JmaquinaSelected == undefined) {
      this.requiereMaquina = true;
      salir = true;
    }
    if (!salir) {



      if (this.idOperacionesGrupos > 0) {
        var rowsSelecteds: any = (this.DatPlanificador as any).filter(f => f.idOperacionesGrupos == this.idOperacionesGrupos);
        rowsSelecteds.forEach(
          row => {
            var nuevaOperacion: any = {};
            Object.assign(nuevaOperacion, row);
            nuevaOperacion.idMaquina = this.JmaquinaSelected.id + 0;
            nuevaOperacion.maquina = this.JmaquinaSelected.nombre;
            nuevaOperacion.cantidad = row.cantidadProv + 0;
            nuevaOperacion.cantidadProv = row.cantidadProv + 0;
            nuevaOperacion.cantidadHechas = 0;
            nuevaOperacion.total = row.cantidadProv + 0;
            nuevaOperacion.idPlanificador = 0;
            var ann: any = this.DatPlanificador;
            ann.push(nuevaOperacion);

            row.cantidad = row.cantidad - row.cantidadProv;
            row.cantidadProv = row.cantidad - row.cantidadHechas;
          }
        );
      } else {
        var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
        rowsSelecteds.forEach(
          row => {
            var nuevaOperacion: any = {};
            Object.assign(nuevaOperacion, row);
            nuevaOperacion.idMaquina = this.JmaquinaSelected.id + 0;
            nuevaOperacion.maquina = this.JmaquinaSelected.nombre;
            nuevaOperacion.cantidad = row.cantidadProv + 0;
            nuevaOperacion.cantidadProv = row.cantidadProv + 0;
            nuevaOperacion.cantidadHechas = 0;
            nuevaOperacion.total = row.cantidadProv + 0;
            nuevaOperacion.idPlanificador = 0;
            var ann: any = this.DatPlanificador;
            ann.push(nuevaOperacion);

            row.cantidad = row.cantidad - row.cantidadProv;
            row.cantidadProv = row.cantidad - row.cantidadHechas;
          }
        );
      }

      this.visibleInfo = false;
      this.visibleInfoGrupo = false;
      this.PREcargarGantt();
      //this.cargarGantt_operaciones_temporal();
    }
  }
  btnMandarALargo() {
    this.loadingPanel = true;


    var th = this;
    setTimeout(function () {
      th.mandarALargo();
    }, 0);
  }

  mandarALargo() {

    /*  OLD  */
    // var salir = false;
    // if (this.JmaquinaSelectedLargo == undefined) {
    //   this.requiereMaquinaLargo = true;
    //   salir = true;
    // }
    // if (this.JsemanaSelected == undefined) {
    //   this.requiereSemana = true;
    //   salir = true;
    // }

    // if (!salir) {

    //   var reqInsertar: any = [];

    //   if (this.idOperacionesGrupos > 0) {
    //     var rowsSelecteds: any = (this.DatPlanificador as any).filter(f => f.idOperacionesGrupos == this.idOperacionesGrupos);
    //     rowsSelecteds.forEach(
    //       row => {
    //         var r2: any = {};
    //         r2.idMaquina = this.JmaquinaSelectedLargo.id;
    //         r2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año));
    //         r2.idOperacion = row.idOperacion;
    //         r2.idOperacionesGrupos = row.idOperacionesGrupos;
    //         r2.cantidad = row.cantidadProv;
    //         r2.version = this.JplanificadoresSelected.value;
    //         r2.orden = row.orden;
    //         reqInsertar.push(r2);

    //         row.cantidad = row.cantidad - row.cantidadProv;
    //         row.cantidadProv = row.cantidad - row.cantidadHechas;
    //       }
    //     );
    //   } else {
    //     var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    //     rowsSelecteds.forEach(
    //       row => {
    //         var r2: any = {};
    //         r2.idMaquina = this.JmaquinaSelectedLargo.id;
    //         r2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año));
    //         r2.idOperacion = row.idOperacion;
    //         r2.idOperacionesGrupos = row.idOperacionesGrupos;
    //         r2.cantidad = row.cantidadProv;
    //         r2.version = this.JplanificadoresSelected.value;
    //         r2.orden = row.orden;
    //         reqInsertar.push(r2);

    //         row.cantidad = row.cantidad - row.cantidadProv;
    //         row.cantidadProv = row.cantidad - row.cantidadHechas;
    //       }
    //     );
    //   }

    //   if (reqInsertar.length > 0) {
    //     this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
    //       r => {
    //         this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
    //           this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
    //           var r1, r2, r3: boolean = false;
    //           var version = 1;
    //           if (this.JplanificadoresSelected != undefined)
    //             version = this.JplanificadoresSelected.value;

    //           this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
    //             json => {

    //               this.DatPlanificador = (json as any).dtOperaciones;

    //               (this.DatPlanificador as any).forEach(
    //                 operacionoGrupo => {
    //                   // Cogemos el tiempo estimado de esa operacion para esa maquina
    //                   var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
    //                     ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
    //                       (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
    //                   // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
    //                   if (tiempos.length == 0) {
    //                     tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
    //                       (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
    //                   }
    //                   if (tiempos.length > 0) {
    //                     // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
    //                     // 1. tiempo estimado o predictivo
    //                     // 2. tiene preparacion si es principal = 1
    //                     var tiempo = tiempos[0];
    //                     if (this.aplicarTiempoEstimado) {
    //                       operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
    //                       //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
    //                       operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
    //                     } else {
    //                       operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
    //                       //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
    //                       operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
    //                     }
    //                     // 3. se aplica infeciciencia en caso de ser necesario
    //                     operacionoGrupo.tiempoIneficiencia = 0;
    //                     if (this.aplicarIneficiencias) {
    //                       operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
    //                     }
    //                     operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
    //                   }
    //                   // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
    //                   // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

    //                   if (operacionoGrupo.idOperacion > -1) {
    //                     operacionoGrupo.nombre = '';
    //                     if (operacionoGrupo.cliente != '')
    //                       operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
    //                     if (operacionoGrupo.plano != '')
    //                       operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
    //                     if (operacionoGrupo.pieza != '')
    //                       operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
    //                     if (operacionoGrupo.refPieza != '')
    //                       operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
    //                     // if (operacionoGrupo.plano != '')
    //                     //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
    //                     if (operacionoGrupo.parte != '' && !this.ocultarParte)
    //                       operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

    //                     if (operacionoGrupo.nombre != "")
    //                       operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
    //                   } else if (operacionoGrupo.idOperacionesGrupos > -1) {
    //                     operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
    //                   }
    //                 });

    //               r1 = true;
    //               if (r1 && r2 && r3) {
    //                 this.PREcargarGantt();
    //                 this.cargarDatosFiltro();
    //               }
    //             });
    //           var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
    //           if (maquinas_Y_subcontratado_planificador_model == false) {
    //             this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
    //               this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
    //               this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
    //               this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
    //               this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
    //               r2 = true;
    //               if (r1 && r2 && r3) {
    //                 this.PREcargarGantt();
    //                 this.cargarDatosFiltro();
    //               }
    //             })
    //           } else {
    //             this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
    //             this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
    //             this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
    //             r2 = true;
    //             if (r1 && r2 && r3) {
    //               this.PREcargarGantt();
    //               this.cargarDatosFiltro();
    //             }
    //           }

    //           this.planificadorService.Get_turnos(this.now).subscribe(
    //             json => {
    //               r3 = true;

    //               this.DatTurnos = json;
    //               if (r1 && r2 && r3) {
    //                 this.PREcargarGantt();
    //                 this.cargarDatosFiltro();
    //               }
    //               //this.cargarGantt_operaciones_temporal();
    //             });

    //           var r1_1, r1_2: boolean = false;
    //           this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
    //             json => {
    //               this.DatOperacionesSinCorto = json;

    //               this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

    //               r1_1 = true;
    //               if (r1_1 && r1_2)
    //                 this.recargarGrid();
    //             }
    //           );
    //           this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
    //             json => {
    //               var grupos = (json as any).datOperaciones_grupos
    //               grupos.forEach(
    //                 grupo => {
    //                   grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

    //                   grupo.maquina = '';
    //                   var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
    //                   if (maquinas.length > 0)
    //                     grupo.maquina = maquinas[0].nombre;

    //                 });

    //               this.Jgrupos = grupos;

    //               this.DatOperaciones_grupos = grupos;

    //               r1_2 = true;
    //               if (r1_1 && r1_2)
    //                 this.recargarGrid();

    //             });
    //           this.visibleInfo = false;
    //           this.visibleInfoGrupo = false;
    //         });
    //       }
    //     );
    //   }
    //   else {
    //     this.loadingPanel = false;
    //   }
    // }
    // else {
    //   this.loadingPanel = false;
    // }


    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
    if (rowsSelecteds.length == 0) // grupos seleccionados grid
      rowsSelecteds = (this.Jgrupos as any).filter(f => this.operacionesSelected.includes(f.numFila));
    if (this.visibleInfoGrupo) // click en grupo y desde dentro
      rowsSelecteds = (this.Jgrupos as any).filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

    var idsOperaciones: any = [];
    var idMaquinaSeleccionada: number = this.JmaquinaSelected.id
    rowsSelecteds.forEach(f => {
      // if (f.idOperacionSimultanea > 0) {
      idsOperaciones.push(f.idOperacion);
      idMaquinaSeleccionada = f.idMaquina;
      // }
    });

    if (idsOperaciones.length > 0) {

      this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
        json => {
          var opsSimultaneas: any = json;

          // var reqSacar: any = []; // aqui no se sacan! se ponen a 0 y se hace el update completo! 
          var reqInsertar: any = [];

          //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
          rowsSelecteds.forEach(
            operacion => {
              // Operaciones para sacar de plannig
              operacion.cantidad = operacion.cantidad - operacion.cantidadProv;
              operacion.cantidadProv = operacion.cantidad - operacion.cantidadHechas;
              // reqSacar.push({// aqui no se sacan! se ponen a 0 y se hace el update completo! 
              //   idMaquina: operacion.idMaquina,
              //   fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(operacion.semana, operacion.año)),
              //   idOperacion: operacion.idOperacion,
              //   idOperacionesGrupos: operacion.idOperacionesGrupos,
              //   nuevaCantidad: operacion.total - operacion.cantidad,
              //   version: this.JplanificadoresSelected.value,
              // });
              // Operaciones para añadir de plannig
              reqInsertar.push({
                idMaquina: operacion.idMaquina,
                fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                idOperacion: operacion.idOperacion,
                idOperacionesGrupos: operacion.idOperacionesGrupos,
                cantidad: operacion.cantidad,
                version: this.JplanificadoresSelected.value,
                orden: operacion.ordenPlanificador,
              });

              // Se buscan sus simultanias si tiene
              if (operacion.idOperacionSimultanea > 0) {
                // SI TIENE OP SIMULTANEAS
                var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                // Las ordenamos en el orden requerido.
                ops_simultaneas.sort((s1, s2) => {
                  if (s1.orden > s2.orden) return 1
                  else if (s1.orden < s2.orden) return -1
                  else return 0
                });

                // Sacamos el orden de simultanea que tiene la operacion seleccionada
                var i = 0;
                var index = 0;
                ops_simultaneas.forEach(
                  op => {
                    if (op.idOperacion == operacion.idOperacion) index = i;
                    i++;
                  });

                // Sacamos la primera ruta que nos coincida con la maquina 
                var cadenaEncontrada = [];
                this.cadenas_Maquinas.forEach(
                  cadena => {
                    if (cadena[index] == idMaquinaSeleccionada) {
                      cadenaEncontrada = cadena;
                    }
                  });

                // Las añadimos para el insert
                var ordenSimultanea = 0
                ops_simultaneas.forEach(
                  op => {
                    // Si no es una de las operaciones originales se añade a donde le toca
                    if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                      // La actualizamos si estaba planificada
                      if (op.idMaquina > 0) {
                        var rowsSelecteds: any = (this.DatPlanificador as any).filter(f => f.idOperacion == op.idOperacion && f.idMaquina == op.idMaquina); // operaciones
                        if (rowsSelecteds.length > 0) { // grupos seleccionados grid
                          rowsSelecteds[0].cantidad = operacion.cantidad - operacion.cantidadProv;
                          rowsSelecteds[0].cantidadProv = operacion.cantidad - operacion.cantidadHechas;
                        }
                        // Operaciones para sacar de plannig
                        // reqSacar.push({ // aqui no se sacan! se ponen a 0 y se hace el update completo! 
                        //   idMaquina: op.idMaquina,
                        //   fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                        //   idOperacion: op.idOperacion,
                        //   idOperacionesGrupos: operacion.idOperacionesGrupos,
                        //   nuevaCantidad: operacion.total - operacion.cantidad,
                        //   version: this.JplanificadoresSelected.value,
                        // });
                      }

                      // Operaciones para añadir de plannig
                      reqInsertar.push({
                        idMaquina: cadenaEncontrada[ordenSimultanea],
                        fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                        idOperacion: op.idOperacion,
                        idOperacionesGrupos: operacion.idOperacionesGrupos,
                        cantidad: operacion.cantidad,
                        version: this.JplanificadoresSelected.value,
                        orden: operacion.ordenPlanificador,
                      });
                    }
                    // Incrementar el indice 
                    ordenSimultanea++;
                  });
              }

              this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
                r => {
                  this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
                    this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
                    var r1, r2, r3: boolean = false;
                    var version = 1;
                    if (this.JplanificadoresSelected != undefined)
                      version = this.JplanificadoresSelected.value;

                    this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                      json => {

                        this.DatPlanificador = (json as any).dtOperaciones;

                        (this.DatPlanificador as any).forEach(
                          operacionoGrupo => {
                            // Cogemos el tiempo estimado de esa operacion para esa maquina
                            var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                              ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                            // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                            if (tiempos.length == 0) {
                              tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                            }
                            if (tiempos.length > 0) {
                              // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                              // 1. tiempo estimado o predictivo
                              // 2. tiene preparacion si es principal = 1
                              var tiempo = tiempos[0];
                              if (this.aplicarTiempoEstimado) {
                                operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                                //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                                operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                              } else {
                                operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                                //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                                operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                              }
                              // 3. se aplica infeciciencia en caso de ser necesario
                              operacionoGrupo.tiempoIneficiencia = 0;
                              if (this.aplicarIneficiencias) {
                                operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                              }
                              operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                            }
                            // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                            // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                            if (operacionoGrupo.idOperacion > -1) {
                              operacionoGrupo.nombre = '';
                              if (operacionoGrupo.cliente != '')
                                operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                              if (operacionoGrupo.plano != '')
                                operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                              if (operacionoGrupo.pieza != '')
                                operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                              if (operacionoGrupo.refPieza != '')
                                operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                              // if (operacionoGrupo.plano != '')
                              //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                              if (operacionoGrupo.parte != '' && !this.ocultarParte)
                                operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                              if (operacionoGrupo.nombre != "")
                                operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                            } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                              operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                            }
                          });

                        r1 = true;
                        if (r1 && r2 && r3) {
                          this.PREcargarGantt();
                          this.cargarDatosFiltro();
                        }
                      });
                    var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    if (maquinas_Y_subcontratado_planificador_model == false) {
                      this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                        this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                        this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                        this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                        this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                        r2 = true;
                        if (r1 && r2 && r3) {
                          this.PREcargarGantt();
                          this.cargarDatosFiltro();
                        }
                      })
                    } else {
                      this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                      this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                      this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                      r2 = true;
                      if (r1 && r2 && r3) {
                        this.PREcargarGantt();
                        this.cargarDatosFiltro();
                      }
                    }

                    this.planificadorService.Get_turnos(this.now).subscribe(
                      json => {
                        r3 = true;

                        this.DatTurnos = json;
                        if (r1 && r2 && r3) {
                          this.PREcargarGantt();
                          this.cargarDatosFiltro();
                        }
                        //this.cargarGantt_operaciones_temporal();
                      });

                    var r1_1, r1_2: boolean = false;
                    this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                      json => {
                        this.DatOperacionesSinCorto = json;

                        this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                        r1_1 = true;
                        if (r1_1 && r1_2)
                          this.recargarGrid();
                      }
                    );
                    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                      json => {
                        var grupos = (json as any).datOperaciones_grupos
                        grupos.forEach(
                          grupo => {
                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                            grupo.maquina = '';
                            var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                            if (maquinas.length > 0)
                              grupo.maquina = maquinas[0].nombre;

                          });

                        this.Jgrupos = grupos;

                        this.DatOperaciones_grupos = grupos;

                        r1_2 = true;
                        if (r1_1 && r1_2)
                          this.recargarGrid();

                      });
                    this.visibleInfo = false;
                    this.visibleInfoGrupo = false;
                  });
                });

            });
        });
    }



  }

  // antes de TTTbergara
  // btnMandarACorto() {
  //   this.loadingPanel = true;

  //   this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
  //     r => {
  //       var rowsSelecteds: any = this.JOperacionesSinCorto.filter(f => this.JOperacionesSinCortoSelecteds.includes(f.idPlanificador));
  //       var reqSacar: any = [];
  //       var reqInsertar: any = [];

  //       rowsSelecteds.reverse().forEach(f => { //Sin reverse, pasa en orden inverso las operaciones
  //         // Operaciones para sacar de plannig
  //         var r: any = {};
  //         r.idMaquina = f.idMaquina;
  //         r.fecha = this.dateToYYYYMMDDtHHmmSSz(new Date(f.fecha));
  //         r.idOperacion = f.idOperacion;
  //         r.nuevaCantidad = f.total - f.cantidad;
  //         r.version = this.JplanificadoresSelected.value;
  //         r.idOperacionesGrupos = f.idOperacionesGrupos;
  //         r.orden = f.orden;
  //         reqSacar.push(r);
  //         // Operaciones para añadir de plannig
  //         var r2: any = {};
  //         r2.idMaquina = f.idMaquina;
  //         r2.fecha = this.dateToYYYYMMDDtHHmmSSz(new Date(f.fecha));
  //         r2.idOperacion = f.idOperacion;
  //         r2.cantidad = f.cantidad;
  //         r2.version = this.JplanificadoresSelected.value;
  //         r2.idOperacionesGrupos = f.idOperacionesGrupos;
  //         r2.orden = f.orden;
  //         reqInsertar.push(r2);
  //       });

  //       var r1 = false;
  //       var r2 = false;
  //       if (reqSacar.length > 0) {
  //         this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
  //           this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
  //           r1 = true;
  //           //cerrar popup
  //           if (r1 && r2) {
  //             this.visibleInfo = false;
  //             this.visibleInfoGrupo = false;
  //             this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
  //               (json) => {
  //                 var r1, r2, r3: boolean = false;
  //                 var version = 1;

  //                 if (this.JplanificadoresSelected != undefined)
  //                   version = this.JplanificadoresSelected.value;

  //                 this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
  //                   json => {

  //                     this.DatPlanificador = (json as any).dtOperaciones;

  //                     (this.DatPlanificador as any).forEach(
  //                       operacionoGrupo => {
  //                         // Cogemos el tiempo estimado de esa operacion para esa maquina
  //                         var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
  //                           ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                             (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                         // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
  //                         if (tiempos.length == 0) {
  //                           tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                             (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                         }
  //                         if (tiempos.length > 0) {
  //                           // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
  //                           // 1. tiempo estimado o predictivo
  //                           // 2. tiene preparacion si es principal = 1
  //                           var tiempo = tiempos[0];
  //                           if (this.aplicarTiempoEstimado) {
  //                             operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
  //                             //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
  //                             operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
  //                           } else {
  //                             operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
  //                             //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
  //                             operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
  //                           }
  //                           // 3. se aplica infeciciencia en caso de ser necesario
  //                           operacionoGrupo.tiempoIneficiencia = 0;
  //                           if (this.aplicarIneficiencias) {
  //                             operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
  //                           }
  //                           operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
  //                         }
  //                         // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
  //                         // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

  //                         if (operacionoGrupo.idOperacion > -1) {
  //                           operacionoGrupo.nombre = '';
  //                           if (operacionoGrupo.cliente != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
  //                           if (operacionoGrupo.plano != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                           if (operacionoGrupo.pieza != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
  //                           if (operacionoGrupo.refPieza != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
  //                           // if (operacionoGrupo.plano != '')
  //                           //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                           if (operacionoGrupo.parte != '' && !this.ocultarParte)
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

  //                           if (operacionoGrupo.nombre != "")
  //                             operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
  //                         } else if (operacionoGrupo.idOperacionesGrupos > -1) {
  //                           operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
  //                         }
  //                       });

  //                     r1 = true;
  //                     if (r1 && r2 && r3)
  //                       this.PREcargarGantt();
  //                     //this.cargarGantt_operaciones_temporal();
  //                   });
  //                 //this.maquinasService.get().subscribe(
  //                 //  json => {
  //                 //    r2 = true;

  //                 //    this.Jmaquinas = json;
  //                 //    this.JmaquinasLargo = json;

  //                 //    this.DatMaquinas = json;

  //                 //    if (r1 && r2 && r3)
  //                 //      //this.cargarGantt();
  //                 //      this.cargarGantt_operaciones_temporal();
  //                 //  });
  //                 var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                 if (maquinas_Y_subcontratado_planificador_model == false) {
  //                   this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
  //                     this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
  //                     this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     r2 = true;
  //                     if (r1 && r2 && r3)
  //                       this.PREcargarGantt();
  //                     //this.cargarGantt_operaciones_temporal();
  //                   })
  //                 } else {
  //                   this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
  //                   this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
  //                   this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
  //                   r2 = true;
  //                   if (r1 && r2 && r3)
  //                     this.PREcargarGantt();
  //                   //this.cargarGantt_operaciones_temporal();
  //                 }


  //                 this.planificadorService.Get_turnos(this.now).subscribe(
  //                   json => {
  //                     r3 = true;

  //                     this.DatTurnos = json;
  //                     if (r1 && r2 && r3)
  //                       this.PREcargarGantt();
  //                     //this.cargarGantt_operaciones_temporal();
  //                   });

  //                 var r1_1, r1_2: boolean = false;
  //                 this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
  //                   json => {
  //                     this.DatOperacionesSinCorto = json;

  //                     this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

  //                     r1_1 = true;
  //                     if (r1_1 && r1_2)
  //                       this.recargarGrid();
  //                   }
  //                 );
  //                 this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
  //                   json => {
  //                     var grupos = (json as any).datOperaciones_grupos
  //                     grupos.forEach(
  //                       grupo => {
  //                         grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

  //                         grupo.maquina = '';
  //                         var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
  //                         if (maquinas.length > 0)
  //                           grupo.maquina = maquinas[0].nombre;

  //                       });

  //                     this.Jgrupos = grupos;

  //                     this.DatOperaciones_grupos = grupos;

  //                     r1_2 = true;
  //                     if (r1_1 && r1_2)
  //                       this.recargarGrid();

  //                   });
  //               }
  //             );
  //           }
  //         });
  //       }
  //       else {
  //         r1 = true;
  //         //cerrar popup
  //         if (r1 && r2) {
  //           this.visibleInfo = false;
  //           this.visibleInfoGrupo = false;
  //           this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
  //             (json) => {
  //               var r1, r2, r3: boolean = false;
  //               var version = 1;

  //               if (this.JplanificadoresSelected != undefined)
  //                 version = this.JplanificadoresSelected.value;

  //               this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
  //                 json => {

  //                   this.DatPlanificador = (json as any).dtOperaciones;

  //                   (this.DatPlanificador as any).forEach(
  //                     operacionoGrupo => {
  //                       // Cogemos el tiempo estimado de esa operacion para esa maquina
  //                       var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
  //                         ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                           (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                       // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
  //                       if (tiempos.length == 0) {
  //                         tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                           (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                       }
  //                       if (tiempos.length > 0) {
  //                         // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
  //                         // 1. tiempo estimado o predictivo
  //                         // 2. tiene preparacion si es principal = 1
  //                         var tiempo = tiempos[0];
  //                         if (this.aplicarTiempoEstimado) {
  //                           operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
  //                           //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
  //                           operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
  //                         } else {
  //                           operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
  //                           //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
  //                           operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
  //                         }
  //                         // 3. se aplica infeciciencia en caso de ser necesario
  //                         operacionoGrupo.tiempoIneficiencia = 0;
  //                         if (this.aplicarIneficiencias) {
  //                           operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
  //                         }
  //                         operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
  //                       }
  //                       // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
  //                       // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

  //                       if (operacionoGrupo.idOperacion > -1) {
  //                         operacionoGrupo.nombre = '';
  //                         if (operacionoGrupo.cliente != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
  //                         if (operacionoGrupo.plano != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                         if (operacionoGrupo.pieza != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
  //                         if (operacionoGrupo.refPieza != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
  //                         // if (operacionoGrupo.plano != '')
  //                         //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                         if (operacionoGrupo.parte != '' && !this.ocultarParte)
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

  //                         if (operacionoGrupo.nombre != "")
  //                           operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
  //                       } else if (operacionoGrupo.idOperacionesGrupos > -1) {
  //                         operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
  //                       }
  //                     });

  //                   r1 = true;
  //                   if (r1 && r2 && r3)
  //                     this.PREcargarGantt();
  //                   //this.cargarGantt_operaciones_temporal();
  //                 });
  //               //this.maquinasService.get().subscribe(
  //               //  json => {
  //               //    r2 = true;

  //               //    this.Jmaquinas = json;
  //               //    this.JmaquinasLargo = json;

  //               //    this.DatMaquinas = json;

  //               //    if (r1 && r2 && r3)
  //               //      //this.cargarGantt();
  //               //      this.cargarGantt_operaciones_temporal();
  //               //  });
  //               var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //               if (maquinas_Y_subcontratado_planificador_model == false) {
  //                 this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
  //                   this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
  //                   this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   r2 = true;
  //                   if (r1 && r2 && r3)
  //                     this.PREcargarGantt();
  //                   //this.cargarGantt_operaciones_temporal();
  //                 })
  //               } else {
  //                 this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
  //                 this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
  //                 this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
  //                 r2 = true;
  //                 if (r1 && r2 && r3)
  //                   this.PREcargarGantt();
  //                 //this.cargarGantt_operaciones_temporal();
  //               }


  //               this.planificadorService.Get_turnos(this.now).subscribe(
  //                 json => {
  //                   r3 = true;

  //                   this.DatTurnos = json;
  //                   if (r1 && r2 && r3)
  //                     this.PREcargarGantt();
  //                   //this.cargarGantt_operaciones_temporal();
  //                 });

  //               var r1_1, r1_2: boolean = false;
  //               this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
  //                 json => {
  //                   this.DatOperacionesSinCorto = json;

  //                   this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

  //                   r1_1 = true;
  //                   if (r1_1 && r1_2)
  //                     this.recargarGrid();
  //                 }
  //               );
  //               this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
  //                 json => {
  //                   var grupos = (json as any).datOperaciones_grupos
  //                   grupos.forEach(
  //                     grupo => {
  //                       grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

  //                       grupo.maquina = '';
  //                       var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
  //                       if (maquinas.length > 0)
  //                         grupo.maquina = maquinas[0].nombre;

  //                     });

  //                   this.Jgrupos = grupos;

  //                   this.DatOperaciones_grupos = grupos;

  //                   r1_2 = true;
  //                   if (r1_1 && r1_2)
  //                     this.recargarGrid();

  //                 });
  //             }
  //           );
  //         }
  //       }
  //       if (reqInsertar.length > 0) {
  //         this.planificadorService.InsertOperacionesPlanificadasACorto(reqInsertar).subscribe(r => {
  //           r2 = true;
  //           //cerrar popup
  //           if (r1 && r2) {
  //             this.visibleInfo = false;
  //             this.visibleInfoGrupo = false;
  //             this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
  //               (json) => {
  //                 var r1, r2, r3: boolean = false;
  //                 var version = 1;

  //                 if (this.JplanificadoresSelected != undefined)
  //                   version = this.JplanificadoresSelected.value;

  //                 this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
  //                   json => {

  //                     this.DatPlanificador = (json as any).dtOperaciones;

  //                     (this.DatPlanificador as any).forEach(
  //                       operacionoGrupo => {
  //                         // Cogemos el tiempo estimado de esa operacion para esa maquina
  //                         var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
  //                           ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                             (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                         // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
  //                         if (tiempos.length == 0) {
  //                           tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                             (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                         }
  //                         if (tiempos.length > 0) {
  //                           // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
  //                           // 1. tiempo estimado o predictivo
  //                           // 2. tiene preparacion si es principal = 1
  //                           var tiempo = tiempos[0];
  //                           if (this.aplicarTiempoEstimado) {
  //                             operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
  //                             //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
  //                             operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
  //                           } else {
  //                             operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
  //                             //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
  //                             operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
  //                           }
  //                           // 3. se aplica infeciciencia en caso de ser necesario
  //                           operacionoGrupo.tiempoIneficiencia = 0;
  //                           if (this.aplicarIneficiencias) {
  //                             operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
  //                           }
  //                           operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
  //                         }
  //                         // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
  //                         // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

  //                         if (operacionoGrupo.idOperacion > -1) {
  //                           operacionoGrupo.nombre = '';
  //                           if (operacionoGrupo.cliente != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
  //                           if (operacionoGrupo.plano != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                           if (operacionoGrupo.pieza != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
  //                           if (operacionoGrupo.refPieza != '')
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
  //                           // if (operacionoGrupo.plano != '')
  //                           //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                           if (operacionoGrupo.parte != '' && !this.ocultarParte)
  //                             operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

  //                           if (operacionoGrupo.nombre != "")
  //                             operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
  //                         } else if (operacionoGrupo.idOperacionesGrupos > -1) {
  //                           operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
  //                         }
  //                       });

  //                     r1 = true;
  //                     if (r1 && r2 && r3) {
  //                       this.PREcargarGantt();
  //                       this.cargarDatosFiltro();
  //                     }
  //                     //this.cargarGantt_operaciones_temporal();
  //                   });
  //                 //this.maquinasService.get().subscribe(
  //                 //  json => {
  //                 //    r2 = true;
  //                 //    this.Jmaquinas = json;
  //                 //    this.JmaquinasLargo = json;

  //                 //    this.DatMaquinas = json;

  //                 //    if (r1 && r2 && r3)
  //                 //      //this.cargarGantt();
  //                 //      this.cargarGantt_operaciones_temporal();
  //                 //  });
  //                 var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                 if (maquinas_Y_subcontratado_planificador_model == false) {
  //                   this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
  //                     this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
  //                     this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                     r2 = true;
  //                     if (r1 && r2 && r3) {
  //                       this.PREcargarGantt();
  //                       this.cargarDatosFiltro();
  //                     }
  //                     //this.cargarGantt_operaciones_temporal();
  //                   })
  //                 } else {
  //                   this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
  //                   this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
  //                   this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
  //                   r2 = true;
  //                   if (r1 && r2 && r3) {
  //                     this.PREcargarGantt();
  //                     this.cargarDatosFiltro();
  //                   }
  //                   //this.cargarGantt_operaciones_temporal();
  //                 }

  //                 this.planificadorService.Get_turnos(this.now).subscribe(
  //                   json => {
  //                     r3 = true;

  //                     this.DatTurnos = json;
  //                     if (r1 && r2 && r3) {
  //                       this.PREcargarGantt();
  //                       this.cargarDatosFiltro();
  //                     }
  //                     //this.cargarGantt_operaciones_temporal();
  //                   });

  //                 var r1_1, r1_2: boolean = false;
  //                 this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
  //                   json => {
  //                     this.DatOperacionesSinCorto = json;

  //                     this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

  //                     r1_1 = true;
  //                     if (r1_1 && r1_2)
  //                       this.recargarGrid();
  //                   }
  //                 );
  //                 this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
  //                   json => {
  //                     var grupos = (json as any).datOperaciones_grupos
  //                     grupos.forEach(
  //                       grupo => {
  //                         grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

  //                         grupo.maquina = '';
  //                         var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
  //                         if (maquinas.length > 0)
  //                           grupo.maquina = maquinas[0].nombre;

  //                       });

  //                     this.Jgrupos = grupos;

  //                     this.DatOperaciones_grupos = grupos;

  //                     r1_2 = true;
  //                     if (r1_1 && r1_2)
  //                       this.recargarGrid();

  //                   });
  //               }
  //             );
  //           }
  //         });
  //       }
  //       else {
  //         r2 = true;
  //         //cerrar popup
  //         if (r1 && r2) {
  //           this.visibleInfo = false;
  //           this.visibleInfoGrupo = false;
  //           this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
  //             (json) => {
  //               var r1, r2, r3: boolean = false;
  //               var version = 1;

  //               if (this.JplanificadoresSelected != undefined)
  //                 version = this.JplanificadoresSelected.value;

  //               this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
  //                 json => {

  //                   this.DatPlanificador = (json as any).dtOperaciones;

  //                   (this.DatPlanificador as any).forEach(
  //                     operacionoGrupo => {
  //                       // Cogemos el tiempo estimado de esa operacion para esa maquina
  //                       var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
  //                         ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                           (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                       // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
  //                       if (tiempos.length == 0) {
  //                         tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
  //                           (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
  //                       }
  //                       if (tiempos.length > 0) {
  //                         // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
  //                         // 1. tiempo estimado o predictivo
  //                         // 2. tiene preparacion si es principal = 1
  //                         var tiempo = tiempos[0];
  //                         if (this.aplicarTiempoEstimado) {
  //                           operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
  //                           //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
  //                           operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
  //                         } else {
  //                           operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
  //                           //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
  //                           operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
  //                         }
  //                         // 3. se aplica infeciciencia en caso de ser necesario
  //                         operacionoGrupo.tiempoIneficiencia = 0;
  //                         if (this.aplicarIneficiencias) {
  //                           operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
  //                         }
  //                         operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
  //                       }
  //                       // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
  //                       // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

  //                       if (operacionoGrupo.idOperacion > -1) {
  //                         operacionoGrupo.nombre = '';
  //                         if (operacionoGrupo.cliente != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
  //                         if (operacionoGrupo.plano != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                         if (operacionoGrupo.pieza != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
  //                         if (operacionoGrupo.refPieza != '')
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
  //                         // if (operacionoGrupo.plano != '')
  //                         //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
  //                         if (operacionoGrupo.parte != '' && !this.ocultarParte)
  //                           operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

  //                         if (operacionoGrupo.nombre != "")
  //                           operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
  //                       } else if (operacionoGrupo.idOperacionesGrupos > -1) {
  //                         operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
  //                       }
  //                     });

  //                   r1 = true;
  //                   if (r1 && r2 && r3) {
  //                     this.PREcargarGantt();
  //                     this.cargarDatosFiltro();
  //                   }
  //                   //this.cargarGantt_operaciones_temporal();
  //                 });
  //               //this.maquinasService.get().subscribe(
  //               //  json => {
  //               //    r2 = true;
  //               //    this.Jmaquinas = json;
  //               //    this.JmaquinasLargo = json;

  //               //    this.DatMaquinas = json;

  //               //    if (r1 && r2 && r3)
  //               //      //this.cargarGantt();
  //               //      this.cargarGantt_operaciones_temporal();
  //               //  });
  //               var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //               if (maquinas_Y_subcontratado_planificador_model == false) {
  //                 this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
  //                   this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
  //                   this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
  //                   r2 = true;
  //                   if (r1 && r2 && r3) {
  //                     this.PREcargarGantt();
  //                     this.cargarDatosFiltro();
  //                   }
  //                   //this.cargarGantt_operaciones_temporal();
  //                 })
  //               } else {
  //                 this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
  //                 this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
  //                 this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
  //                 r2 = true;
  //                 if (r1 && r2 && r3) {
  //                   this.PREcargarGantt();
  //                   this.cargarDatosFiltro();
  //                 }
  //                 //this.cargarGantt_operaciones_temporal();
  //               }

  //               this.planificadorService.Get_turnos(this.now).subscribe(
  //                 json => {
  //                   r3 = true;

  //                   this.DatTurnos = json;
  //                   if (r1 && r2 && r3) {
  //                     this.PREcargarGantt();
  //                     this.cargarDatosFiltro();
  //                   }
  //                   //this.cargarGantt_operaciones_temporal();
  //                 });

  //               var r1_1, r1_2: boolean = false;
  //               this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
  //                 json => {
  //                   this.DatOperacionesSinCorto = json;

  //                   this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

  //                   r1_1 = true;
  //                   if (r1_1 && r1_2)
  //                     this.recargarGrid();
  //                 }
  //               );
  //               this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
  //                 json => {
  //                   var grupos = (json as any).datOperaciones_grupos
  //                   grupos.forEach(
  //                     grupo => {
  //                       grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

  //                       grupo.maquina = '';
  //                       var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
  //                       if (maquinas.length > 0)
  //                         grupo.maquina = maquinas[0].nombre;

  //                     });

  //                   this.Jgrupos = grupos;

  //                   this.DatOperaciones_grupos = grupos;

  //                   r1_2 = true;
  //                   if (r1_1 && r1_2)
  //                     this.recargarGrid();

  //                 });
  //             }
  //           );
  //         }
  //       }
  //     }
  //   );
  // }

  btnMandarACorto() {
    this.loadingPanel = true;

    this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
      r => {
        var rowsSelecteds: any = this.JOperacionesSinCorto.filter(f => this.JOperacionesSinCortoSelecteds.includes(f.idPlanificador));

        var idsOperaciones: any = [];
        rowsSelecteds.forEach(f => {
          // if (f.idOperacionSimultanea > 0)
          idsOperaciones.push(f.idOperacion);
        });

        if (idsOperaciones.length > 0) {
          this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
            json => {
              var opsSimultaneas: any = json;

              var reqSacar: any = [];
              var reqInsertar: any = [];

              //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
              rowsSelecteds.forEach(
                operacion => {
                  // Operaciones para sacar de plannig
                  reqSacar.push({
                    idMaquina: operacion.idMaquina,
                    fecha: this.dateToYYYYMMDDtHHmmSSz(new Date(operacion.fecha)),
                    idOperacion: operacion.idOperacion,
                    idOperacionesGrupos: operacion.idOperacionesGrupos,
                    nuevaCantidad: operacion.total - operacion.cantidad,
                    version: this.JplanificadoresSelected.value,
                  });
                  // Operaciones para añadir de plannig
                  reqInsertar.push({
                    idMaquina: operacion.idMaquina,
                    // fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                    fecha: this.dateToYYYYMMDDtHHmmSSz(new Date(operacion.fecha)), //+ 1
                    idOperacion: operacion.idOperacion,
                    idOperacionesGrupos: operacion.idOperacionesGrupos,
                    cantidad: operacion.cantidad,
                    version: this.JplanificadoresSelected.value,
                    orden: operacion.orden,
                  });

                  // Se buscan sus simultanias si tiene
                  if (operacion.idOperacionSimultanea > 0) {
                    // SI TIENE OP SIMULTANEAS
                    var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                    // Las ordenamos en el orden requerido.
                    ops_simultaneas.sort((s1, s2) => {
                      if (s1.orden > s2.orden) return 1
                      else if (s1.orden < s2.orden) return -1
                      else return 0
                    });

                    // Sacamos el orden de simultanea que tiene la operacion seleccionada
                    var i = 0;
                    var index = 0;
                    ops_simultaneas.forEach(
                      op => {
                        if (op.idOperacion == operacion.idOperacion) index = i;
                        i++;
                      });

                    // Sacamos la primera ruta que nos coincida con la maquina 
                    var cadenaEncontrada = [];
                    this.cadenas_Maquinas.forEach(
                      cadena => {
                        if (cadena[index] == operacion.idMaquina) {
                          cadenaEncontrada = cadena;
                        }
                      });

                    // Las añadimos para el insert
                    var ordenSimultanea = 0
                    ops_simultaneas.forEach(
                      op => {
                        // Si no es una de las operaciones originales se añade a donde le toca
                        if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                          // La actualizamos si estaba planificada
                          if (op.idMaquina > 0) {
                            // Operaciones para sacar de plannig
                            reqSacar.push({
                              idMaquina: op.idMaquina,
                              fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                              idOperacion: op.idOperacion,
                              idOperacionesGrupos: operacion.idOperacionesGrupos,
                              nuevaCantidad: operacion.total - operacion.cantidad,
                              version: this.JplanificadoresSelected.value,
                            });
                          }

                          // Operaciones para añadir de plannig
                          reqInsertar.push({
                            idMaquina: cadenaEncontrada[ordenSimultanea],
                            fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)), //+ 1
                            idOperacion: op.idOperacion,
                            idOperacionesGrupos: operacion.idOperacionesGrupos,
                            cantidad: operacion.cantidad,
                            version: this.JplanificadoresSelected.value,
                            orden: operacion.orden,
                          });
                        }
                        // Incrementar el indice 
                        ordenSimultanea++;
                      });
                  }
                });

              /*************************************************************************/
              /*****   POR LAS SIMULTANEAS SE TIENE QUE AÑADIR ANTES DE ELIMINAR   *****/
              /*************************************************************************/
              //#region "LLAMADAS PARALELAS"
              var r1 = false;
              var r2 = false;
              if (reqSacar.length > 0) {
                this.loadingPanel = true;
                this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
                  this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
                  r1 = true;
                  //cerrar popup
                  if (r1 && r2) {
                    this.visibleInfo = false;
                    this.visibleInfoGrupo = false;
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                      (json) => {
                        var r1, r2, r3: boolean = false;
                        var version = 1;

                        if (this.JplanificadoresSelected != undefined)
                          version = this.JplanificadoresSelected.value;

                        this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                          json => {

                            this.DatPlanificador = (json as any).dtOperaciones;

                            (this.DatPlanificador as any).forEach(
                              operacionoGrupo => {
                                // Cogemos el tiempo estimado de esa operacion para esa maquina
                                var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                                  ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                                // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                                if (tiempos.length == 0) {
                                  tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                                }
                                if (tiempos.length > 0) {
                                  // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                                  // 1. tiempo estimado o predictivo
                                  // 2. tiene preparacion si es principal = 1
                                  var tiempo = tiempos[0];
                                  if (this.aplicarTiempoEstimado) {
                                    operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                                  } else {
                                    operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                                  }
                                  // 3. se aplica infeciciencia en caso de ser necesario
                                  operacionoGrupo.tiempoIneficiencia = 0;
                                  if (this.aplicarIneficiencias) {
                                    operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                                  }
                                  operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                                }
                                // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                                // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                                if (operacionoGrupo.idOperacion > -1) {
                                  operacionoGrupo.nombre = '';
                                  if (operacionoGrupo.cliente != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                                  if (operacionoGrupo.plano != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                                  if (operacionoGrupo.pieza != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                                  if (operacionoGrupo.refPieza != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                                  // if (operacionoGrupo.plano != '')
                                  //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                                  if (operacionoGrupo.parte != '' && !this.ocultarParte)
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                                  if (operacionoGrupo.nombre != "")
                                    operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                                } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                                  operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                                }
                              });

                            r1 = true;
                            if (r1 && r2 && r3)
                              this.PREcargarGantt();
                            //this.cargarGantt_operaciones_temporal();
                          });
                        //this.maquinasService.get().subscribe(
                        //  json => {
                        //    r2 = true;

                        //    this.Jmaquinas = json;
                        //    this.JmaquinasLargo = json;

                        //    this.DatMaquinas = json;

                        //    if (r1 && r2 && r3)
                        //      //this.cargarGantt();
                        //      this.cargarGantt_operaciones_temporal();
                        //  });
                        var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                        if (maquinas_Y_subcontratado_planificador_model == false) {
                          this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                            this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                            this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            r2 = true;
                            if (r1 && r2 && r3)
                              this.PREcargarGantt();
                            //this.cargarGantt_operaciones_temporal();
                          })
                        } else {
                          this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                          this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                          this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                          r2 = true;
                          if (r1 && r2 && r3)
                            this.PREcargarGantt();
                          //this.cargarGantt_operaciones_temporal();
                        }


                        this.planificadorService.Get_turnos(this.now).subscribe(
                          json => {
                            r3 = true;

                            this.DatTurnos = json;
                            if (r1 && r2 && r3)
                              this.PREcargarGantt();
                            //this.cargarGantt_operaciones_temporal();
                          });

                        var r1_1, r1_2: boolean = false;
                        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                          json => {
                            this.DatOperacionesSinCorto = json;

                            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                            r1_1 = true;
                            if (r1_1 && r1_2)
                              this.recargarGrid();
                          }
                        );
                        this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                          json => {
                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                              grupo => {
                                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                                grupo.maquina = '';
                                var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                                if (maquinas.length > 0)
                                  grupo.maquina = maquinas[0].nombre;

                              });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r1_2 = true;
                            if (r1_1 && r1_2)
                              this.recargarGrid();

                          });
                      }
                    );
                  }
                });
              } else {
                r1 = true
              }
              if (reqInsertar.length > 0) {
                this.loadingPanel = true;
                this.planificadorService.InsertOperacionesPlanificadasACorto(reqInsertar).subscribe(r => {
                  r2 = true;
                  //cerrar popup
                  if (r1 && r2) {
                    this.visibleInfo = false;
                    this.visibleInfoGrupo = false;
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                      (json) => {
                        var r1, r2, r3: boolean = false;
                        var version = 1;

                        if (this.JplanificadoresSelected != undefined)
                          version = this.JplanificadoresSelected.value;

                        this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                          json => {

                            this.DatPlanificador = (json as any).dtOperaciones;

                            (this.DatPlanificador as any).forEach(
                              operacionoGrupo => {
                                // Cogemos el tiempo estimado de esa operacion para esa maquina
                                var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                                  ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                                // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                                if (tiempos.length == 0) {
                                  tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                                }
                                if (tiempos.length > 0) {
                                  // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                                  // 1. tiempo estimado o predictivo
                                  // 2. tiene preparacion si es principal = 1
                                  var tiempo = tiempos[0];
                                  if (this.aplicarTiempoEstimado) {
                                    operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                                  } else {
                                    operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                                  }
                                  // 3. se aplica infeciciencia en caso de ser necesario
                                  operacionoGrupo.tiempoIneficiencia = 0;
                                  if (this.aplicarIneficiencias) {
                                    operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                                  }
                                  operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                                }
                                // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                                // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                                if (operacionoGrupo.idOperacion > -1) {
                                  operacionoGrupo.nombre = '';
                                  if (operacionoGrupo.cliente != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                                  if (operacionoGrupo.plano != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                                  if (operacionoGrupo.pieza != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                                  if (operacionoGrupo.refPieza != '')
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                                  // if (operacionoGrupo.plano != '')
                                  //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                                  if (operacionoGrupo.parte != '' && !this.ocultarParte)
                                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                                  if (operacionoGrupo.nombre != "")
                                    operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                                } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                                  operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                                }
                              });

                            r1 = true;
                            if (r1 && r2 && r3) {
                              this.PREcargarGantt();
                              this.cargarDatosFiltro();
                            }
                            //this.cargarGantt_operaciones_temporal();
                          });
                        //this.maquinasService.get().subscribe(
                        //  json => {
                        //    r2 = true;
                        //    this.Jmaquinas = json;
                        //    this.JmaquinasLargo = json;

                        //    this.DatMaquinas = json;

                        //    if (r1 && r2 && r3)
                        //      //this.cargarGantt();
                        //      this.cargarGantt_operaciones_temporal();
                        //  });
                        var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                        if (maquinas_Y_subcontratado_planificador_model == false) {
                          this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                            this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                            this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                            r2 = true;
                            if (r1 && r2 && r3) {
                              this.PREcargarGantt();
                              this.cargarDatosFiltro();
                            }
                            //this.cargarGantt_operaciones_temporal();
                          })
                        } else {
                          this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                          this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                          this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                          r2 = true;
                          if (r1 && r2 && r3) {
                            this.PREcargarGantt();
                            this.cargarDatosFiltro();
                          }
                          //this.cargarGantt_operaciones_temporal();
                        }

                        this.planificadorService.Get_turnos(this.now).subscribe(
                          json => {
                            r3 = true;

                            this.DatTurnos = json;
                            if (r1 && r2 && r3) {
                              this.PREcargarGantt();
                              this.cargarDatosFiltro();
                            }
                            //this.cargarGantt_operaciones_temporal();
                          });

                        var r1_1, r1_2: boolean = false;
                        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                          json => {
                            this.DatOperacionesSinCorto = json;

                            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                            r1_1 = true;
                            if (r1_1 && r1_2)
                              this.recargarGrid();
                          }
                        );
                        this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                          json => {
                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                              grupo => {
                                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                                grupo.maquina = '';
                                var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                                if (maquinas.length > 0)
                                  grupo.maquina = maquinas[0].nombre;

                              });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r1_2 = true;
                            if (r1_1 && r1_2)
                              this.recargarGrid();

                          });
                      }
                    );
                  }
                });
              } else {
                r2 = true
              }
              //#endregion

            });
        } else {
          this.loadingPanel = false;
        }
      });
  }

  //TOOLTIP
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  getDatosGrid() {

    var r1, r2: boolean = false
    this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
      json => {
        this.DatOperacionesSinCorto = json;

        this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

        r1 = true;
        if (r1 && r2)
          this.recargarGrid();

        //this.loadItems()
      });

    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
      json => {
        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

            grupo.maquina = '';
            var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
            if (maquinas.length > 0)
              grupo.maquina = maquinas[0].nombre;

          });

        this.Jgrupos = grupos;

        this.DatOperaciones_grupos = grupos;

        r2 = true;
        if (r1 && r2)
          this.recargarGrid();

      });
    // LOS TIEMPOS AHORA SE CARGAN AL PRINCIPIO PARA QUE EL CORTO TAMBIEN TENGA LOS TIEMPOS Y NO SE TENGAN QUE CARGAR 2 VECES EN LA MISMA PAGINA
    // this.planificadorService.get_maquinas_tiemposEstimados(this.JplanificadoresSelected.value).subscribe(
    //   json => {
    //     this.dtTiemposMaquinas = json;

    //     r3 = true;
    //     if (r1 && r2 && r3)
    //       this.recargarGrid();
    //   });

  }
  recargarGrid() {

    this.JOperacionesSinCortoTodo = []
    var Joperaciones: any = this.DatOperacionesSinCorto;
    var Jgrupos: any = this.DatOperaciones_grupos;


    Joperaciones.forEach(
      operacion => {
        operacion.numFila = this.JOperacionesSinCortoTodo.length;
        operacion.tiempoEstimadoPreparacions = 0
        operacion.tiempoEstimados = 1
        // Cogemos el tiempo estimado de esa operacion para esa maquina
        var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacion.idMaquina &&
          ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
            (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
        // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
        if (tiempos.length == 0) {
          tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
            (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
        }

        if (tiempos.length > 0) {
          // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
          // 1. tiempo estimado o predictivo
          // 2. tiene preparacion si es principal = 1
          var tiempo = tiempos[0];
          if (this.aplicarTiempoEstimado) {
            operacion.tiempoEstimados = tiempo.tiempoEstimadoEjecucion
            operacion.tiempoEstimadoPreparacions = tiempo.tiempoEstimadoPreparacion
          } else {
            operacion.tiempoEstimados = tiempo.tiempoPredictivoEjecucion
            operacion.tiempoEstimadoPreparacions = tiempo.tiempoPredictivoPreparacion
          }
          // 3. se aplica infeciciencia en caso de ser necesario
          operacion.tiempoIneficiencia = 0;
          if (this.aplicarIneficiencias) {
            operacion.tiempoIneficiencia = (operacion.tiempoEstimados * tiempo.ineficiencia / 100);
          }
        }

        var operacionYgrupo = {
          idPlanificador: operacion.idPlanificador,
          año: operacion.año,
          cantidad: operacion.cantidad,
          cantidadAgrupada: operacion.cantidadAgrupada,
          dividida: operacion.dividida,
          fecha: operacion.fecha,
          fechaIni: operacion.fecha,
          fechaLimite: operacion.fechafin,
          grupoPrincipal: operacion.grupoPrincipal,
          numFila: operacion.numFila,
          secuencia: operacion.secuencia,
          nombre: '',
          idMaquina: operacion.idMaquina,
          idOF: operacion.idOF,
          idPieza: operacion.idPieza,
          idParte: operacion.idParte,
          idRuta: operacion.idRuta,
          idOperacion: operacion.idOperacion,
          idOperacionSimultanea: operacion.idOperacionSimultanea,
          idOperacionesGrupos: -1,
          peso: null,
          temperatura: null,
          unidades: null,
          principal: operacion.principal,
          orden: operacion.ordenPlanificado,
          semana: operacion.numSemana,
          total: operacion.total,
          tiempoEstimadoPreparacions: operacion.tiempoEstimadoPreparacions,
          tiempoEstimados: operacion.tiempoEstimados,
          tipo: operacion.tipo,
          maquina: operacion.maquina,
          refOF: operacion.refOF,
          operacion: operacion.operacion
        }

        if (operacion.cliente != '')
          operacionYgrupo.nombre += ' - ' + operacion.cliente
        if (operacion.plano != '')
          operacionYgrupo.nombre += ' - ' + operacion.plano
        if (operacion.pieza != '')
          operacionYgrupo.nombre += ' - ' + operacion.pieza
        if (operacion.refPieza != '')
          operacionYgrupo.nombre += ' - ' + operacion.refPieza
        // if (operacion.plano != '')
        //   operacionYgrupo.nombre += ' - ' + operacion.plano
        if (operacion.parte != '' && !this.ocultarParte)
          operacionYgrupo.nombre += ' - ' + operacion.parte

        if (operacionYgrupo.nombre != "")
          operacionYgrupo.nombre = operacionYgrupo.nombre.substring(2, operacionYgrupo.nombre.length)

        this.JOperacionesSinCortoTodo.push(operacionYgrupo);
      });
    Jgrupos.forEach(
      grupo => {
        //los grupos  no se tratan igual que las operaciones, pues las operaciones se usan para cargar el grafico y ademas mostrar cuando son clicadas. en el caso de grpos tambien estan las operaciones internas para mostrar
        // pero los grupos para cargar los tiempos. por esta razon, las operaciones encima vienen filtradas por maquina pero estos grupos NO! y se muestran en todas las maquinas todos los grupos. 
        // Antes de añadir un grupo al grid, lo filtraremos en este momento. 
        // if (grupo.idMaquina == this.JmaquinaSelected.id) {
        grupo.numFila = this.JOperacionesSinCortoTodo.length;


        // Cogemos el tiempo estimado de esa operacion para esa maquina
        var tiempos = this.dtTiemposMaquinas.filter(f => (f.idMaquina == grupo.idMaquina &&
          (f.idOperacion == grupo.idOperacion && grupo.idOperacion > 0) ||
          (f.idOperacionesGrupos == grupo.idOperacionesGrupos && grupo.idOperacionesGrupos > 0)))
        // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
        if (tiempos.length == 0) {
          tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == grupo.idOperacion && grupo.idOperacion > 0) ||
            (f.idOperacionesGrupos == grupo.idOperacionesGrupos && grupo.idOperacionesGrupos > 0)))
        }

        if (tiempos.length > 0) {
          // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
          // 1. tiempo estimado o predictivo
          // 2. tiene preparacion si es principal = 1
          var tiempo = tiempos[0];
          if (this.aplicarTiempoEstimado) {
            grupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion
          } else {
            grupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion
          }
          // 3. se aplica infeciciencia en caso de ser necesario
          grupo.tiempoIneficiencia = 0;
          if (this.aplicarIneficiencias) {
            grupo.tiempoIneficiencia = (grupo.tiempoEstimado * tiempo.ineficiencia / 100);
          }
        }

        var operacionYgrupo = {
          idPlanificador: grupo.idPlanificador,
          año: grupo.año,
          cantidad: grupo.cantidad,
          cantidadAgrupada: grupo.cantidad,
          dividida: grupo.dividida,
          fecha: grupo.fechaIni,
          fechaIni: grupo.fechaIni,
          fechaLimite: grupo.fechaLimite,
          grupoPrincipal: grupo.grupoPrincipal,
          numFila: grupo.numFila,
          secuencia: '',
          nombre: grupo.nombre,
          idMaquina: grupo.idMaquina,
          idOF: -1,
          idPieza: -1,
          idParte: -1,
          idRuta: -1,
          idOperacion: -1,
          idOperacionSimultanea: -1,
          idOperacionesGrupos: grupo.idOperacionesGrupos,
          peso: grupo.peso,
          temperatura: grupo.temperatura,
          unidades: grupo.unidades,
          principal: grupo.principal,
          orden: null,
          semana: grupo.semana,
          total: grupo.total,
          tiempoEstimadoPreparacions: 0,
          tiempoEstimados: grupo.tiempoEstimado,
          tipo: -1,
          maquina: grupo.maquina,
          refOF: '',
          operacion: ''
        }

        this.JOperacionesSinCortoTodo.push(operacionYgrupo);
        // } else {
        //   grupo.numFila = this.JOperacionesSinCortoTodo.length;
        // }
      });

    this.JOperacionesSinCortoTodo.sort((s1, s2) => {
      if (s1.machine > s2.machine) return 1
      else if (s1.machine < s2.machine) return -1
      else if (s1.año > s2.año) return 1
      else if (s1.año < s2.año) return -1
      else if (s1.semana > s2.semana) return 1
      else if (s1.semana < s2.semana) return -1
      else return 0
    });

    // this.JOperacionesSinCortoTodo.forEach(
    //   operacion => {
    //     // Cogemos el tiempo estimado de esa operacion para esa maquina
    //     var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacion.idMaquina &&
    //       ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
    //         (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
    //     // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
    //     if (tiempos.length == 0) {
    //       tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
    //         (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
    //     }
    //     if (tiempos.length > 0) {
    //       // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
    //       // 1. tiempo estimado o predictivo
    //       // 2. tiene preparacion si es principal = 1
    //       var tiempo = tiempos[0];
    //       if (this.aplicarTiempoEstimado) {
    //         operacion.tiempoEstimado = tiempo.tiempoEstimadoEjecucion * operacion.cantidad
    //         if (operacion.principal)
    //           operacion.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
    //       } else {
    //         operacion.tiempoEstimado = tiempo.tiempoPredictivoEjecucion * operacion.cantidad
    //         if (operacion.principal)
    //           operacion.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
    //       }
    //       // 3. se aplica infeciciencia en caso de ser necesario
    //       operacion.tiempoIneficiencia = 0;
    //       if (this.aplicarIneficiencias) {
    //         operacion.tiempoIneficiencia = (operacion.tiempoEstimado * tiempo.ineficiencia / 100);
    //       }
    //       operacion.tiempo = operacion.tiempoEstimado - operacion.tiempoIneficiencia;

    //       operacion.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion;
    //     }

    //   });

    var idsMaquinas = [];
    if (this.JmaquinasSelectedLargo != undefined)
      this.JmaquinasSelectedLargo.forEach(row => idsMaquinas.push(row.id))
    var idsSemanas = [];
    if (this.JsemanasSelected != undefined)
      this.JsemanasSelected.forEach(row => idsSemanas.push(row.semana))

    if (idsMaquinas.length == 0)
      if (idsSemanas.length == 0)
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo; // ninguna
      else
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsSemanas.includes(f.semana)); //solo semnaa      
    else
      if (idsSemanas.length == 0)
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsMaquinas.includes(f.idMaquina)); // solo maquina
      else
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsSemanas.includes(f.semana) && idsMaquinas.includes(f.idMaquina)); // las dos

    //this.loadItems()
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    //this.loadItems();
  }
  btnVistaTemporal() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    // COGER OPERACIONES PLANIFICADAS

    const url = this.router.serializeUrl(this.router.parseUrl('#/planificadorCortoTemporal/' + version + '/' + this.aplicarTiempoEstimado + '/' + this.aplicarIneficiencias));
    window.open(url, '_blank');

    //this.router.navigateByUrl('planificadorCortoTemporal/' + version + '/' + this.aplicarTiempoEstimado + '/' + this.aplicarIneficiencias);

    //this.cargarGantt_operaciones_temporal();

    //this.modalReference = this.modalService.open(this.popupVistaTemporal, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });

  }
  btnLista() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    const url = this.router.serializeUrl(this.router.parseUrl('#/planificadorLista/' + version + '/' + 2 + '/' + 0));
    window.open(url, '_blank');
  }

  cargarDatosFiltro() {
    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 1, 0).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];

      //GROUP BY POR OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

      //GROUP BY POR PIEZA
      var kontagailua = 1;
      var listaPiezasSinRepetidos = [];
      data.forEach(function (a) {
        if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza).length == 0 && a.idPieza != -1) {
          listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: a.nombrePieza, ids: [a.idPieza] });
          kontagailua++;
        }
        else {
          listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza)[0].ids.push(a.idPieza);
        }
      }, Object.create(null));

      this.listaPiezas = listaPiezasSinRepetidos.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));
    });

  }
  CambioFiltro() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 1, 0).subscribe((data: any) => {

      this.dataFiltro = data;

    });
    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idsPiezas = [];
    if (this.piezasSeleccionados != undefined) {
      //this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.idPieza));
      this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.ids));
      idsPiezas = idsPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []); //flatten array of arrays
    }

    var groupByCliente = [];
    var groupByPiezas = [];
    var groupByOf = [];

    //GROUP BY POR OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY POR CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY POR PIEZA
    lag = {};
    var kontagailua = 1;
    var listaPiezasSinRepetidos = [];
    data.forEach(
      row => {
        if ((idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
        ) {
          if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza).length == 0) {
            listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: row.nombrePieza, ids: [row.idPieza] });
            kontagailua++;
          }
          else listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza)[0].ids.push(row.idPieza);

        }
      });

    this.listaPiezas = listaPiezasSinRepetidos;
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

  }
  btnFiltrar() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.getPlanificadorCorto_CargarGANTT(version);

    if (version > 1)
      this.visibleUsarVersion = true;
    else
      this.visibleUsarVersion = false;
  }

  gruposMaquinasChanged() {
    this.ganttChart = undefined;
  }
  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.JOperacionesSinCortoSelecteds = [];
      this.selectAllState = 'checked';
      this.JOperacionesSinCorto.forEach(
        operacion => {
          this.JOperacionesSinCortoSelecteds.push(operacion.idPlanificador)
        });
    } else {
      this.JOperacionesSinCortoSelecteds = [];
      this.selectAllState = 'unchecked';
    }
  }
  btnActualizarReorganizandoVersion() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.actualizarReorganizandoVersion();
  }
  actualizarReorganizandoVersion() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_ultima_reorganizacion(1, version).subscribe(
      json => {
        var an: any = json;
        if (an.length == 0) {

          var version = 1;
          if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;
          if (this.popupReorganizandoVersion)
            this.getPlanificadorCorto_CargarGANTT(version);

          if (version > 1)
            this.visibleUsarVersion = true;
          else
            this.visibleUsarVersion = false;

          this.popupReorganizandoVersion = false;
        } else {
          this.popupReorganizandoVersion = true;
          if (this.router.url.includes('/planificadorCorto')) {
            setTimeout((d) => this.actualizarReorganizandoVersion(), 1000);
          }
        }
      });


  }
  getPlanificadorCorto_CargarGANTT(version) {

    this.planificadorService.get_maquinas_tiemposEstimados(version).subscribe(
      json => {
        this.dtTiemposMaquinas = (json as any).dtTiempos;
        this.DatPlanificador_grupos = (json as any).dtOperaciones_grupos;
        this.DatPlanificador_gruposOperaciones = (json as any).dtOperaciones_grupos_operaciones;

        this.getDatosGrid();

        this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          json => {

            this.DatPlanificador = (json as any).dtOperaciones;

            (this.DatPlanificador as any).forEach(
              operacionoGrupo => {
                // Cogemos el tiempo estimado de esa operacion para esa maquina
                var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                  ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                if (tiempos.length == 0) {
                  tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                    (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                }
                if (tiempos.length > 0) {
                  // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                  // 1. tiempo estimado o predictivo
                  // 2. tiene preparacion si es principal = 1
                  var tiempo = tiempos[0];
                  if (this.aplicarTiempoEstimado) {
                    operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                  } else {
                    operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                    //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                    operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                  }
                  // 3. se aplica infeciciencia en caso de ser necesario
                  operacionoGrupo.tiempoIneficiencia = 0;
                  if (this.aplicarIneficiencias) {
                    operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                  }
                  operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                } else {
                }
                // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                if (operacionoGrupo.idOperacion > -1) {
                  operacionoGrupo.nombre = '';
                  if (operacionoGrupo.cliente != '')
                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                  if (operacionoGrupo.plano != '')
                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                  if (operacionoGrupo.pieza != '')
                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                  if (operacionoGrupo.refPieza != '')
                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                  // if (operacionoGrupo.plano != '')
                  //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                  if (operacionoGrupo.parte != '' && !this.ocultarParte)
                    operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                  if (operacionoGrupo.nombre != "")
                    operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                  operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                }
              });

            this.PREcargarGantt();
          });
      });


  }

  // MANDAR A LARGO START
  btnMandarALargoDesdeTodos() {
    if (this.JOperacionesCortoSelecteds.length > 0) {
      this.JsemanaSelectedMandarALargo = this.Jsemanas[0];
      this.modalReference = this.modalService.open(this.popupMandarALargo, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
    }
  }
  btnpopupMandarALargoAceptar() {
    this.loadingPanel = true;

    this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
      r => {
        var rowsSelecteds: any = this.JoperacionesCorto.filter(f => this.JOperacionesCortoSelecteds.includes(f.idPlanificador) && f.idPlanificador >= 0);
        var reqSacar: any = [];
        var reqInsertar: any = [];
        rowsSelecteds.reverse().forEach(f => { //el reverse ese esta sin confirmar! el insert lo hace al reves
          // Operaciones para sacar de plannig
          var r: any = {};
          r.id = f.idPlanificador;
          r.version = this.JplanificadoresSelected.value;
          reqSacar.push(r);
          // Operaciones para añadir de plannig
          var r2: any = {};
          r2.idMaquina = f.idMaquina;
          r2.fecha = this.dateToYYYYMMDDtHHmmSSz(new Date(this.JsemanaSelectedMandarALargo.fecha));
          r2.idOperacion = f.idOperacion;
          r2.idOperacionesGrupos = f.idOperacionesGrupos;
          r2.cantidad = f.cantidad;
          r2.version = this.JplanificadoresSelected.value;
          r2.orden = f.orden;
          reqInsertar.push(r2);
        });

        var r1 = false;
        var r2 = false;
        if (reqSacar.length > 0) {
          this.planificadorService.delete_corto(reqSacar).subscribe(r => {
            r1 = true;
            //cerrar popup
            if (r1 && r2) {
              this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
              this.visibleInfo = false;
              this.visibleInfoGrupo = false;
              this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                (json) => {
                  var r1, r2, r3: boolean = false;
                  var version = 1;
                  if (this.JplanificadoresSelected != undefined)
                    version = this.JplanificadoresSelected.value;

                  //R1
                  this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {

                      this.DatPlanificador = (json as any).dtOperaciones;

                      (this.DatPlanificador as any).forEach(
                        operacionoGrupo => {
                          // Cogemos el tiempo estimado de esa operacion para esa maquina
                          var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                            ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                              (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                          // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                          if (tiempos.length == 0) {
                            tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                              (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                          }
                          if (tiempos.length > 0) {
                            // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                            // 1. tiempo estimado o predictivo
                            // 2. tiene preparacion si es principal = 1
                            var tiempo = tiempos[0];
                            if (this.aplicarTiempoEstimado) {
                              operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                              //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                              operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                            } else {
                              operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                              //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                              operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                            }
                            // 3. se aplica infeciciencia en caso de ser necesario
                            operacionoGrupo.tiempoIneficiencia = 0;
                            if (this.aplicarIneficiencias) {
                              operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                            }
                            operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                          }
                          // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                          // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                          if (operacionoGrupo.idOperacion > -1) {
                            operacionoGrupo.nombre = '';
                            if (operacionoGrupo.cliente != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                            if (operacionoGrupo.plano != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                            if (operacionoGrupo.pieza != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                            if (operacionoGrupo.refPieza != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                            // if (operacionoGrupo.plano != '')
                            //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                            if (operacionoGrupo.parte != '' && !this.ocultarParte)
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                            if (operacionoGrupo.nombre != "")
                              operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                          } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                            operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                          }
                        });

                      r1 = true;
                      if (r1 && r2 && r3)
                        this.PREcargarGantt();
                      //this.cargarGantt_operaciones_temporal();
                    });
                  //R2
                  var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                  if (maquinas_Y_subcontratado_planificador_model == false) {
                    this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                      this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                      this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      r2 = true;
                      if (r1 && r2 && r3)
                        this.PREcargarGantt();
                      //this.cargarGantt_operaciones_temporal();
                    })
                  }
                  else {
                    this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                    this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                    this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                    r2 = true;
                    if (r1 && r2 && r3)
                      this.PREcargarGantt();
                    //this.cargarGantt_operaciones_temporal();
                  }
                  //R3
                  this.planificadorService.Get_turnos(this.now).subscribe(
                    json => {
                      r3 = true;

                      this.DatTurnos = json;
                      if (r1 && r2 && r3)
                        this.PREcargarGantt();
                      //this.cargarGantt_operaciones_temporal();
                    });

                  var r1_1, r1_2: boolean = false;
                  this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                    json => {
                      this.DatOperacionesSinCorto = json;

                      this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                      r1_1 = true;
                      if (r1_1 && r1_2)
                        this.recargarGrid();

                    }
                  );
                  this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                    json => {
                      var grupos = (json as any).datOperaciones_grupos
                      grupos.forEach(
                        grupo => {
                          grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                          grupo.maquina = '';
                          var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                          if (maquinas.length > 0)
                            grupo.maquina = maquinas[0].nombre;

                        });

                      this.Jgrupos = grupos;

                      this.DatOperaciones_grupos = grupos;

                      r1_2 = true;
                      if (r1_1 && r1_2)
                        this.recargarGrid();

                    });
                  this.modalReference.close();
                  if (this.replanificarMandarALargo) {
                    this.replanificarPlanificadorLargo();
                  }
                }
              );
            }
          });
        }
        else {
          r1 = true;
          //cerrar popup
          if (r1 && r2) {
            this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
            this.visibleInfo = false;
            this.visibleInfoGrupo = false;
            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
              (json) => {
                var r1, r2, r3: boolean = false;
                var version = 1;
                if (this.JplanificadoresSelected != undefined)
                  version = this.JplanificadoresSelected.value;

                //R1
                this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                  json => {

                    this.DatPlanificador = (json as any).dtOperaciones;

                    (this.DatPlanificador as any).forEach(
                      operacionoGrupo => {
                        // Cogemos el tiempo estimado de esa operacion para esa maquina
                        var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                          ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                        // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                        if (tiempos.length == 0) {
                          tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                        }
                        if (tiempos.length > 0) {
                          // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                          // 1. tiempo estimado o predictivo
                          // 2. tiene preparacion si es principal = 1
                          var tiempo = tiempos[0];
                          if (this.aplicarTiempoEstimado) {
                            operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                            //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                            operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                          } else {
                            operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                            //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                            operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                          }
                          // 3. se aplica infeciciencia en caso de ser necesario
                          operacionoGrupo.tiempoIneficiencia = 0;
                          if (this.aplicarIneficiencias) {
                            operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                          }
                          operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                        }
                        // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                        // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                        if (operacionoGrupo.idOperacion > -1) {
                          operacionoGrupo.nombre = '';
                          if (operacionoGrupo.cliente != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                          if (operacionoGrupo.plano != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                          if (operacionoGrupo.pieza != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                          if (operacionoGrupo.refPieza != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                          // if (operacionoGrupo.plano != '')
                          //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                          if (operacionoGrupo.parte != '' && !this.ocultarParte)
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                          if (operacionoGrupo.nombre != "")
                            operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                        } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                          operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                        }
                      });

                    r1 = true;
                    if (r1 && r2 && r3)
                      this.PREcargarGantt();
                    //this.cargarGantt_operaciones_temporal();
                  });
                //R2
                var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                if (maquinas_Y_subcontratado_planificador_model == false) {
                  this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                    this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                    this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    r2 = true;
                    if (r1 && r2 && r3)
                      this.PREcargarGantt();
                    //this.cargarGantt_operaciones_temporal();
                  })
                }
                else {
                  this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                  this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                  this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                  r2 = true;
                  if (r1 && r2 && r3)
                    this.PREcargarGantt();
                  //this.cargarGantt_operaciones_temporal();
                }
                //R3
                this.planificadorService.Get_turnos(this.now).subscribe(
                  json => {
                    r3 = true;

                    this.DatTurnos = json;
                    if (r1 && r2 && r3)
                      this.PREcargarGantt();
                    //this.cargarGantt_operaciones_temporal();
                  });

                var r1_1, r1_2: boolean = false;
                this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                  json => {
                    this.DatOperacionesSinCorto = json;

                    this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                    r1_1 = true;
                    if (r1_1 && r1_2)
                      this.recargarGrid();
                  }
                );
                this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                  json => {
                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                      grupo => {
                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                        grupo.maquina = '';
                        var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                        if (maquinas.length > 0)
                          grupo.maquina = maquinas[0].nombre;

                      });

                    this.Jgrupos = grupos;

                    this.DatOperaciones_grupos = grupos;

                    r1_2 = true;
                    if (r1_1 && r1_2)
                      this.recargarGrid();

                  });
                this.modalReference.close();
                if (this.replanificarMandarALargo) {
                  this.replanificarPlanificadorLargo();
                }
              }
            );
          }
        }
        if (reqInsertar.length > 0) {
          this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
            r2 = true;
            //cerrar popup
            if (r1 && r2) {
              this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
              this.visibleInfo = false;
              this.visibleInfoGrupo = false;
              this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                (json) => {
                  var r1, r2, r3: boolean = false;
                  var version = 1;
                  if (this.JplanificadoresSelected != undefined)
                    version = this.JplanificadoresSelected.value;

                  //R1
                  this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {

                      this.DatPlanificador = (json as any).dtOperaciones;

                      (this.DatPlanificador as any).forEach(
                        operacionoGrupo => {
                          // Cogemos el tiempo estimado de esa operacion para esa maquina
                          var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                            ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                              (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                          // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                          if (tiempos.length == 0) {
                            tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                              (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                          }
                          if (tiempos.length > 0) {
                            // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                            // 1. tiempo estimado o predictivo
                            // 2. tiene preparacion si es principal = 1
                            var tiempo = tiempos[0];
                            if (this.aplicarTiempoEstimado) {
                              operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                              //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                              operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                            } else {
                              operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                              //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                              operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                            }
                            // 3. se aplica infeciciencia en caso de ser necesario
                            operacionoGrupo.tiempoIneficiencia = 0;
                            if (this.aplicarIneficiencias) {
                              operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                            }
                            operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                          }
                          // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                          // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                          if (operacionoGrupo.idOperacion > -1) {
                            operacionoGrupo.nombre = '';
                            if (operacionoGrupo.cliente != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                            if (operacionoGrupo.plano != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                            if (operacionoGrupo.pieza != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                            if (operacionoGrupo.refPieza != '')
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                            // if (operacionoGrupo.plano != '')
                            //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                            if (operacionoGrupo.parte != '' && !this.ocultarParte)
                              operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                            if (operacionoGrupo.nombre != "")
                              operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                          } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                            operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                          }
                        });

                      r1 = true;
                      if (r1 && r2 && r3) {
                        this.PREcargarGantt();
                        this.cargarDatosFiltro();
                      }
                      //this.cargarGantt_operaciones_temporal();
                    });
                  //R2
                  var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                  if (maquinas_Y_subcontratado_planificador_model == false) {
                    this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                      this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                      this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                      r2 = true;
                      if (r1 && r2 && r3) {
                        this.PREcargarGantt();
                        this.cargarDatosFiltro();
                      }
                      //this.cargarGantt_operaciones_temporal();
                    })
                  }
                  else {
                    this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                    this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                    this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                    r2 = true;
                    if (r1 && r2 && r3) {
                      this.PREcargarGantt();
                      this.cargarDatosFiltro();
                    }
                    //this.cargarGantt_operaciones_temporal();
                  }
                  //R3
                  this.planificadorService.Get_turnos(this.now).subscribe(
                    json => {
                      r3 = true;

                      this.DatTurnos = json;
                      if (r1 && r2 && r3) {
                        this.PREcargarGantt();
                        this.cargarDatosFiltro();
                      }
                      //this.cargarGantt_operaciones_temporal();
                    });

                  var r1_1, r1_2: boolean = false;
                  this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                    json => {
                      this.DatOperacionesSinCorto = json;

                      this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                      r1_1 = true;
                      if (r1_1 && r1_2)
                        this.recargarGrid();
                    }
                  );
                  this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                    json => {
                      var grupos = (json as any).datOperaciones_grupos
                      grupos.forEach(
                        grupo => {
                          grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                          grupo.maquina = '';
                          var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                          if (maquinas.length > 0)
                            grupo.maquina = maquinas[0].nombre;

                        });

                      this.Jgrupos = grupos;

                      this.DatOperaciones_grupos = grupos;

                      r1_2 = true;
                      if (r1_1 && r1_2)
                        this.recargarGrid();

                    });
                  this.modalReference.close();
                  if (this.replanificarMandarALargo) {
                    this.replanificarPlanificadorLargo();
                  }
                }
              );
            }
          });
        }
        else {
          r2 = true;
          //cerrar popup
          if (r1 && r2) {
            this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
            this.visibleInfo = false;
            this.visibleInfoGrupo = false;
            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
              (json) => {
                var r1, r2, r3: boolean = false;
                var version = 1;
                if (this.JplanificadoresSelected != undefined)
                  version = this.JplanificadoresSelected.value;

                //R1
                this.planificadorService.get_cortoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                  json => {

                    this.DatPlanificador = (json as any).dtOperaciones;

                    (this.DatPlanificador as any).forEach(
                      operacionoGrupo => {
                        // Cogemos el tiempo estimado de esa operacion para esa maquina
                        var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacionoGrupo.idMaquina &&
                          ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                        // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                        if (tiempos.length == 0) {
                          tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacionoGrupo.idOperacion && operacionoGrupo.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacionoGrupo.idOperacionesGrupos && operacionoGrupo.idOperacionesGrupos > 0)))
                        }
                        if (tiempos.length > 0) {
                          // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                          // 1. tiempo estimado o predictivo
                          // 2. tiene preparacion si es principal = 1
                          var tiempo = tiempos[0];
                          if (this.aplicarTiempoEstimado) {
                            operacionoGrupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion //* operacionoGrupo.cantidad
                            //operacionoGrupo.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                            operacionoGrupo.tiempoPreparacion = tiempo.tiempoEstimadoPreparacion
                          } else {
                            operacionoGrupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion //* operacionoGrupo.cantidad
                            //operacionoGrupo.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                            operacionoGrupo.tiempoPreparacion = tiempo.tiempoPredictivoPreparacion
                          }
                          // 3. se aplica infeciciencia en caso de ser necesario
                          operacionoGrupo.tiempoIneficiencia = 0;
                          if (this.aplicarIneficiencias) {
                            operacionoGrupo.tiempoIneficiencia = (operacionoGrupo.tiempoEstimado * tiempo.ineficiencia / 100);
                          }
                          operacionoGrupo.tiempoEstimado = operacionoGrupo.tiempoEstimado + operacionoGrupo.tiempoIneficiencia;
                        }
                        // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                        // if ((this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacionoGrupo.idOperacion > 0 ){

                        if (operacionoGrupo.idOperacion > -1) {
                          operacionoGrupo.nombre = '';
                          if (operacionoGrupo.cliente != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.cliente
                          if (operacionoGrupo.plano != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                          if (operacionoGrupo.pieza != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.pieza
                          if (operacionoGrupo.refPieza != '')
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.refPieza
                          // if (operacionoGrupo.plano != '')
                          //   operacionoGrupo.nombre += ' - ' + operacionoGrupo.plano
                          if (operacionoGrupo.parte != '' && !this.ocultarParte)
                            operacionoGrupo.nombre += ' - ' + operacionoGrupo.parte

                          if (operacionoGrupo.nombre != "")
                            operacionoGrupo.nombre = operacionoGrupo.nombre.substring(2, operacionoGrupo.nombre.length)
                        } else if (operacionoGrupo.idOperacionesGrupos > -1) {
                          operacionoGrupo.nombre = this.translateService.instant("grupo") + ' ' + operacionoGrupo.idOperacionesGrupos
                        }
                      });

                    r1 = true;
                    if (r1 && r2 && r3) {
                      this.PREcargarGantt();
                      this.cargarDatosFiltro();
                    }
                    //this.cargarGantt_operaciones_temporal();
                  });
                //R2
                var maquinas_Y_subcontratado_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                if (maquinas_Y_subcontratado_planificador_model == false) {
                  this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                    this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                    this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    this.JmaquinasLargo = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    this.DatMaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    r2 = true;
                    if (r1 && r2 && r3) {
                      this.PREcargarGantt();
                      this.cargarDatosFiltro();
                    }
                    //this.cargarGantt_operaciones_temporal();
                  })
                }
                else {
                  this.Jmaquinas = maquinas_Y_subcontratado_planificador_model;
                  this.JmaquinasLargo = maquinas_Y_subcontratado_planificador_model;
                  this.DatMaquinas = maquinas_Y_subcontratado_planificador_model;
                  r2 = true;
                  if (r1 && r2 && r3) {
                    this.PREcargarGantt();
                    this.cargarDatosFiltro();
                  }
                  //this.cargarGantt_operaciones_temporal();
                }
                //R3
                this.planificadorService.Get_turnos(this.now).subscribe(
                  json => {
                    r3 = true;

                    this.DatTurnos = json;
                    if (r1 && r2 && r3) {
                      this.PREcargarGantt();
                      this.cargarDatosFiltro();
                    }
                    //this.cargarGantt_operaciones_temporal();
                  });

                var r1_1, r1_2: boolean = false;
                this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                  json => {
                    this.DatOperacionesSinCorto = json;

                    this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;

                    r1_1 = true;
                    if (r1_1 && r1_2)
                      this.recargarGrid();
                  }
                );
                this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                  json => {
                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                      grupo => {
                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;

                        grupo.maquina = '';
                        var maquinas = this.DatMaquinas.filter(f => f.id == grupo.idMaquina)
                        if (maquinas.length > 0)
                          grupo.maquina = maquinas[0].nombre;

                      });

                    this.Jgrupos = grupos;

                    this.DatOperaciones_grupos = grupos;

                    r1_2 = true;
                    if (r1_1 && r1_2)
                      this.recargarGrid();

                  });
                this.modalReference.close();
                if (this.replanificarMandarALargo) {
                  this.replanificarPlanificadorLargo();
                }
              }
            );
          }
        }
      }
    );
  }
  replanificarPlanificadorLargo() {

    var prioridadCliente = this.dtConfiguracionPlanificador.prioridadCliente;
    var prioridadFacturacion = this.dtConfiguracionPlanificador.prioridadFacturacion;
    var prioridadOF = this.dtConfiguracionPlanificador.prioridadOF;
    var prioridadMaquina = this.dtConfiguracionPlanificador.prioridadMaquina;
    var prioridadFecha = this.dtConfiguracionPlanificador.prioridadFecha;
    var prioridadTemperatura = this.dtConfiguracionPlanificador.prioridadTemperatura;

    var metodologiaPlanificacion = this.metodologiasDePlanificacionSelected.value;

    var dias_reserva = this.dtConfiguracionPlanificador.dias_reserva;
    var percent_cap_maquina = this.dtConfiguracionPlanificador.percent_cap_maquina;
    var optimizarCuelloBotella = this.dtConfiguracionPlanificador.optimizarCuelloBotella;
    var replanificarTiempoEstimado = this.dtConfiguracionPlanificador.replanificarTiempoEstimado;
    var replanificarIneficiencias = this.dtConfiguracion.replanificarIneficiencias;
    var planificarSoloNoPlanificadas = this.dtConfiguracion.planificarSoloNoPlanificadas;
    var asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.dtConfiguracion.asap_maximoMesesParaIniciarAntesDeFechaEntrega;
    var tiempo = 1;
    if (!this.aplicarTiempoEstimado)
      tiempo = 2;

    this.planificadorService.Reorganizar_Skootik(2, this.JplanificadoresSelected.value, tiempo,// la maquina no se usa por ahora
      prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, percent_cap_maquina, prioridadFecha, prioridadTemperatura,
      metodologiaPlanificacion, optimizarCuelloBotella, 0, !replanificarTiempoEstimado, replanificarIneficiencias, planificarSoloNoPlanificadas, asap_maximoMesesParaIniciarAntesDeFechaEntrega).subscribe();
  }
  recargarGridCortoFiltrado() {
    this.JOperacionesCortoSelecteds = [];

    var an: any = (this.DatPlanificador as any).filter(f => f.cantidad > 0);
    var idsMaquinas = [];
    if (this.JmaquinasSelectedCorto != undefined) {
      this.JmaquinasSelectedCorto.forEach(row => idsMaquinas.push(row.id))
    }

    if (idsMaquinas.length == 0)
      an = an; // ninguna
    else
      an = an.filter(f => idsMaquinas.includes(f.idMaquina)); // solo maquina


    // MUY IMPORTANTE!!! SI NO SE LE PASA ORDENADA LA LISTA, NO FUNCIONARA EL DRAG AND DROP DEL GRID!!!!
    an = an.sort(function (elem1, elem2) {
      if (elem1.ordenPlanificado > elem2.ordenPlanificado) return 1;
      if (elem1.ordenPlanificado <= elem2.ordenPlanificado) return -1;
      return 0;
    });

    this.JoperacionesCorto = an;
    this.gridData = process(this.JoperacionesCorto, this.stateDrag);
    if (this.currentSubscription != undefined)
      this.currentSubscription.unsubscribe();

    var soloUnaMaquina = true;
    var idMaquina = -1;
    this.gridData.data.forEach(
      row => {
        if (idMaquina > 0 && idMaquina != row.idMaquina) soloUnaMaquina = false;
        idMaquina = row.idMaquina;
      });
    // SOLO SE ACTIVARA EL D&D SI HAY UNA SOLA MAQUINA EN EL GRID
    if (soloUnaMaquina)
      this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }
  // MANDAR A LARGO END
  // D&D START
  public ngAfterViewInit(): void {
    var soloUnaMaquina = true;
    var idMaquina = -1;
    this.gridData.data.forEach(
      row => {
        if (idMaquina > 0 && idMaquina != row.idMaquina) soloUnaMaquina = false;
        idMaquina = row.idMaquina;
      });
    // SOLO SE ACTIVARA EL D&D SI HAY UNA SOLA MAQUINA EN EL GRID
    if (soloUnaMaquina)
      this.currentSubscription = this.handleDragAndDrop();
  }
  public ngOnDestroy(): void {
    if (this.currentSubscription != undefined)
      this.currentSubscription.unsubscribe();
  }
  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.grid-plan-corto tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        // 0. prevent default to allow drop
        e.preventDefault();
        // 1. cogemos el row que estamos moviendo (dragged)
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        // 2. creamos un nuevo contenedor con TODOS los elemenos, incluido el dragged
        var an: any = [dataItem];
        this.gridData.data.forEach(element => an.push(element));
        // 3. ordenamos el nuevo contenedor
        an = an.sort(function (elem1, elem2) {
          if (elem1.ordenPlanificado > elem2.ordenPlanificado) return 1;
          if (elem1.ordenPlanificado <= elem2.ordenPlanificado) return -1;
          return 0;
        });
        // 4. actualizamos el indice que estamos desplazando
        draggedItemIndex = an.indexOf(dataItem);
        // 5. cogemos el row sobre el que estamos
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = an[dropIndex];
        // 6. si no acabamos de pasar por encima (si hemos cambiado de row)
        if (dataItem.OLDordenPlanificado != dropItem.ordenPlanificado) {
          // 6.1. asiganamos el nuevo orden al dragged y al droped
          var orden = dataItem.ordenPlanificado + 0;
          dataItem.ordenPlanificado = dropItem.ordenPlanificado + 0;
          dataItem.OLDordenPlanificado = orden;
          dropItem.ordenPlanificado = orden;
        }
        // 7. quitamos de nuevo la linea que hemos añadido arriba  
        an.splice(draggedItemIndex, 1);
        // 8. actualizamos el grid
        this.gridData.data = an;
        // 9. estaba asi y sin esto no hace bien el D&D... mantenedlo asi
        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );

        // se les da color a las celdas actuales
        const targetEl = e.target as HTMLElement;
        if (targetEl.tagName === "TD") {
          // se quita el color a las celdas anteriores
          if (this.targetCells) {
            this.removeLineIndicators();
          }
          this.targetCells = targetEl.parentElement.querySelectorAll("td, th");
          this.targetCells.forEach((td: HTMLElement) => {
            const gridData: any[] = this.gridData.data as any[];
            if (td.tagName === "TH" && gridData.length !== 0) {
              this.renderer.addClass(td, "linea-draging");
            } else if (td.tagName === "TD") {
              this.renderer.addClass(td, "linea-draging");
            }
          });
        }

      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
        dataItem.OLDordenPlanificado = undefined;

        this.gridData.data = this.gridData.data.sort(function (elem1, elem2) {
          // Get data
          var keyA = elem1.ordenPlanificado, keyB = elem2.ordenPlanificado;
          // Compare the 2 elems
          if (keyA > keyB) return 1;
          if (keyA <= keyB) return -1;
          return 0;
        });

        this.JoperacionesCorto = this.gridData.data;

        // se quita el color a las celdas anteriores
        if (this.targetCells) {
          this.removeLineIndicators();
        }

        this.PREcargarGantt();
      }));
    });

    return sub;
  }
  clickGridCorto(e) {
    this.ofsSeleccionados = [];
    this.clientesSeleccionados = [];
    this.piezasSeleccionados = [];

    if (this.JOperacionesCortoSelecteds.length > 0) {
      var partes = [];
      this.JOperacionesCortoSelecteds.forEach(
        idOperacionSeleccionada => {
          var operacion = this.JoperacionesCorto.find(x => x.idPlanificador === idOperacionSeleccionada);
          var parte = this.getCodigoParte(operacion.refOF, operacion.pieza, operacion.parte, -1)
          partes.push(parte)
        })
      this.ganttChart.resaltarOF(partes);
    }
    else {
      this.ganttChart.resaltarOF();
    }
  }
  rowCallback(context: RowClassArgs) {

    // context.dataItem.orden = context.index + 1;

    return {
      dragging: context.dataItem.dragging
    };
  }
  // quita las marcas rojas de la linea de drop
  removeLineIndicators() {
    this.targetCells.forEach((td: HTMLElement) => {
      this.renderer.removeAttribute(td, "class");
    });
  }
  // D&D END



  btnIrAOperacion(dataItem) {
    const url = this.router.serializeUrl(this.router.parseUrl('#/operaciones/editar/' + dataItem.idOF + "/" + dataItem.idPieza + "/" + dataItem.idParte + "/" + dataItem.idRuta + "/" + dataItem.idOperacion + "/1"));
    window.open(url, '_blank');
  }




  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds());
  }
  dateToYYYYMMDDHHmmSS(fecha: Date) { // es para ordenar en ascendente el string
    //20201025230000
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + this.addZero(dia) + this.addZero(hora) + this.addZero(minutos) + this.addZero(segundos);
  }
  dateToYYYYMMDD(fecha: Date) {
    //2020-10-25
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return año + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
  msecondsToHms(mseconds: number) {
    var min = parseInt((mseconds / (60 * 1000)).toString())
    mseconds = mseconds - (min * (60 * 100))
    var s = parseInt((mseconds / 1000).toString())
    mseconds = mseconds - (s * 1000)
    return this.addZero(min) + ':' + this.addZero(s) + ':' + mseconds
  }
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }
  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  reglaDeTres(valor: number, total: number, sobre: number) {
    if (total == 0)
      return 0;
    else
      return sobre * valor / total;
  }
  public addXzero(n: number, x: number) {
    var zeros = 20;
    if (zeros > x)
      zeros = x;
    var r = '00000000000000000000' + n.toString();
    return r.substring(r.length - zeros, r.length);
  }

  getCodigoParte(refOF, pieza, parte, idOperacionesGrupos) {
    var result = refOF + ' - ' + parte
    if (result != pieza)
      result = refOF + ' - ' + pieza + ' - ' + parte

    if (idOperacionesGrupos > -1)
      parte = this.translateService.instant("grupo") + ' ' + idOperacionesGrupos
    return result;
  }


  //IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.gridData.data,
    };
    return result;
  }

  public allDataLargo(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.JOperacionesSinCorto,
    };
    return result;
  }
}
