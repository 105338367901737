import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuService, UsuariosService, ConfiguracionService, AlertService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuracionPlanificador',
  templateUrl: './configuracionPlanificador.component.html'
})

export class ConfiguracionPlanificadorComponent {

  user = this.userService.userValue;
  form: FormGroup;
  submitted = false;

  public gridData: any = [];
  public gridSelection: number[] = [];

  public metodologiasDePlanificacion: any = [];
  public criteriosParaAnnadirOperacion: any = [];

  public versionDataMap = new Map();
  verPorOperacionMap = new Map();
  replanificarTiempoEstimadoMap = new Map();
  cont: any = 0;
  //for tabs
  real: String = 'Real';
  v1: String = 'V1';
  v2: String = 'V2';
  v3: String = 'V3';

  constructor(
    private formBuilder: FormBuilder,
    private configuracionService: ConfiguracionService,
    private userService: UsuariosService,
    public router: Router,
    private menuService: MenuService,
    public translate: TranslateService,
    public alertService: AlertService) {
  }

  ngOnInit() {

    this.menuService.titulo = this.translate.instant('configuracionPlanificador').toUpperCase();

    this.form = this.formBuilder.group({

      idDb: this.user.idDb,

      prioridadCliente: [0,],
      prioridadFacturacion: [0,],
      prioridadOF: [0,],
      prioridadMaquina: [0,],
      prioridadFecha: [0,],
      prioridadTemperatura: [0,],
      dias_reserva: [0, ],
      asap_maximoMesesParaIniciarAntesDeFechaEntrega: [0, ],
      replanificarIneficiencias: [0,],
      optimizarCuelloBotella: [0,],
      agruparOperacionesPorColorPlanificadorLargoMaquinas: [0,],
      percent_cap_maquina: [0,],

      tiempoparada: [0, ],
      ocultarParte: [false,],
      cambioOperacion: [false,],
      operario_puede_cambiar_operacion_automatica: [false,],
      limitarMovimientosMaquinasCompatibles: [false,],

      version: [-1,]

    }, {});

    for (let index = 0; index < 4; index++) {
      this.versionDataMap.set(index, this.form.value);
      this.verPorOperacionMap.set(index, true);
      this.replanificarTiempoEstimadoMap.set(index, true);
      this.criterioAnnadirOperacionMap.set(index, 0);
      this.metodologiaPlanificacionMap.set(index, 0);
    }

    var r1, r2: boolean = false;

    this.configuracionService.get_metodologiaDePlanificacion().subscribe((result: any) => {
      result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
      this.metodologiasDePlanificacion = result;
      r1 = true;
      if (r1 && r2) this.cargarDatos();
    });

    this.configuracionService.get_criterioParaAnnadirOperacion().subscribe((result: any) => {
      result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
      this.criteriosParaAnnadirOperacion = result;
      r2 = true;
      if (r1 && r2) this.cargarDatos();
    });

    //this.verPorOperacion = true;
    //this.replanificarTiempoEstimado = true;

    

  }

  criterioAnnadirOperacionMap = new Map();
  metodologiaPlanificacionMap = new Map();

  cargarDatos() {

    this.configuracionService.get_configuracion().subscribe(result => {
      this.cont++;
      var configuraciones: any = result;

      configuraciones.forEach((configuracion) => {
        this.form = this.formBuilder.group({

          idDb: this.user.idDb,

          prioridadCliente: [configuracion.prioridadCliente,],
          prioridadFacturacion: [configuracion.prioridadFacturacion,],
          prioridadOF: [configuracion.prioridadOF,],
          prioridadMaquina: [configuracion.prioridadMaquina,],
          prioridadFecha: [configuracion.prioridadFecha,],
          prioridadTemperatura: [configuracion.prioridadTemperatura,],
          dias_reserva: [configuracion.dias_reserva, ],
          asap_maximoMesesParaIniciarAntesDeFechaEntrega: [configuracion.asap_maximoMesesParaIniciarAntesDeFechaEntrega, ],
          replanificarIneficiencias: [configuracion.replanificarIneficiencias,],
          optimizarCuelloBotella: [configuracion.optimizarCuelloBotella,],
          agruparOperacionesPorColorPlanificadorLargoMaquinas: [configuracion.agruparOperacionesPorColorPlanificadorLargoMaquinas,],
          percent_cap_maquina: [configuracion.percent_cap_maquina,],

          tiempoparada: [configuracion.tiempoparada, ],
          ocultarParte: [configuracion.ocultarParte,],
          cambioOperacion: [configuracion.cambioOperacion,],
          operario_puede_cambiar_operacion_automatica: [configuracion.operario_puede_cambiar_operacion_automatica,],
          limitarMovimientosMaquinasCompatibles: [configuracion.limitarMovimientosMaquinasCompatibles,],
          version: [configuracion.version]

        }, {});

        //this.verPorOperacion = configuracion.ver_por_operacion;
        //this.replanificarTiempoEstimado = configuracion.replanificarTiempoEstimado;
        this.verPorOperacionMap.set(configuracion.version, configuracion.ver_por_operacion);
        this.replanificarTiempoEstimadoMap.set(configuracion.version, configuracion.replanificarTiempoEstimado);

        
        //this.criterioAnnadirOperacion = this.criteriosParaAnnadirOperacion.find(x => x.id == configuracion.criterioAnnadirOperacion);
        //this.metodologiaPlanificacion = this.metodologiasDePlanificacion.find(x => x.id == configuracion.metodologiaPlanificacion);
        this.criterioAnnadirOperacionMap.set(configuracion.version, configuracion.criterioAnnadirOperacion);
        this.metodologiaPlanificacionMap.set(configuracion.version, configuracion.metodologiaPlanificacion);
        
        this.versionDataMap.set(configuracion.version, this.form.value);
        //console.log(this.versionDataMap.get(configuracion.version));
        this.cont++;
      });
      console.log(this.versionDataMap);
      console.log(this.cont);
      //comprobar si existen las 4 versiones, y si no creamos la que falta
      var falta = false;
      // for (let index = 0; index < 4; index++) {
      //   if(this.versionDataMap.get(index).version == -1){
      //     console.log("FALTAN VERSIONES!!!");
      //     this.crearVersionVacia(index);
      //     falta = true;
      //   }
      // }
    });

    this.configuracionService.get_cuelloDeBotella().subscribe(result => {

      this.gridData = result;
      this.gridSelection = this.gridData.filter(x => x.seleccionado == true).map(x => x.idMaquina);

    });
    
  }

  public selectedChangeEstimadoPredictivo(i, e) {
    //this.replanificarTiempoEstimado = e;
    this.replanificarTiempoEstimadoMap.set(i, e);
  }

  public selectedChange(i, e) {
    //this.verPorOperacion = e;
    this.verPorOperacionMap.set(i, e)
  }

  public criteriosParaAnnadirOperacion_selectionChange(i, value: any): void {
    //this.criterioAnnadirOperacion = value;
    this.criterioAnnadirOperacionMap.set(i, value.id);
    
  }

  public metodologiasDePlanificacion_selectionChange(i, value: any): void {
    //this.metodologiaPlanificacion = value;
    this.metodologiaPlanificacionMap.set(i, value.id);
    // for (let index = 0; index < 4; index++) {
    //   console.log(this.versionDataMap.get(index));
    // }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.submitted = true;
    for (let index = 1; index < 5; index++) {
      if (this.form.invalid || this.criterioAnnadirOperacionMap.get(index) == undefined || this.metodologiaPlanificacionMap.get(index) == undefined) return;
    }
    

    var r1, r2: any = false;

    //GUARDAR CONFIGURACION
    /* this.configuracionService.update_configuracion_planificador(this.form, this.verPorOperacionMap, this.replanificarTiempoEstimadoMap, this.criterioAnnadirOperacionMap, this.metodologiaPlanificacionMap).subscribe(result => {
      var r: any = result;
      if (r >= 1) r1 = true;
      if (r1 && r2) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        this.cargarDatos();
      }
    }); */
    for (let index = 0; index < 4; index++) {
      console.log(this.versionDataMap.get(index+1))
      this.configuracionService.update_configuracion_planificador(this.versionDataMap.get(index+1), this.verPorOperacionMap.get(index+1), this.replanificarTiempoEstimadoMap.get(index+1), this.criterioAnnadirOperacionMap.get(index+1), this.metodologiaPlanificacionMap.get(index+1)).subscribe(result => {
        var r: any = result;
        if (r >= 1) r1 = true;
        if (r1 && r2) {
          this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        }
      });
      this.cargarDatos();
    }
    

    //GUARDAR CUELLO DE BOTELLA
    this.configuracionService.Update_CuelloDeBotella(this.gridSelection.toString()).subscribe(result => {
      r2 = result;
      if (r1 && r2) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        this.cargarDatos();
      }
    });

  }

  crear(){
    
  }

  crearVersionVacia(version){
    console.log(" VERSION: "+ version+ " NO EXISTE, CREANDO VACIO...");
    var r1, r2: any = false;
    this.form = this.formBuilder.group({

      idDb: this.user.idDb,

      codigo: [0,],

      prioridadCliente: [0,],
      prioridadFacturacion: [0,],
      prioridadOF: [0,],
      prioridadMaquina: [0,],
      prioridadFecha: [0,],
      prioridadTemperatura: [0,],
      dias_reserva: [0, ],
      asap_maximoMesesParaIniciarAntesDeFechaEntrega: [0, ],
      replanificarIneficiencias: [0,],
      optimizarCuelloBotella: [0,],
      agruparOperacionesPorColorPlanificadorLargoMaquinas: [0,],
      percent_cap_maquina: [0,],

      tiempoparada: [0, ],
      ocultarParte: [false,],
      cambioOperacion: [false,],
      operario_puede_cambiar_operacion_automatica: [false,],
      limitarMovimientosMaquinasCompatibles: [false,],

      version: [version,]

    }, {});
    this.verPorOperacionMap.set(version, true);
    this.replanificarTiempoEstimadoMap.set(version, true);
    this.criterioAnnadirOperacionMap.set(version, 0);
    this.metodologiaPlanificacionMap.set(version, 0);
    // Datu basean bertsioa sartu
    this.configuracionService.create_configuracion_planificador(this.form.value, this.verPorOperacionMap.get(version), this.replanificarTiempoEstimadoMap.get(version), this.criterioAnnadirOperacionMap.get(version), this.metodologiaPlanificacionMap.get(version)).subscribe(result => {
      var r: any = result;
      if (r >= 1) r1 = true;
      if (r1 && r2) {
        this.alertService.success(this.translate.instant('creadocorrectamente'), { keepAfterRouteChange: true });
      }
    });

  }

}
