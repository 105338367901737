import { Component } from '@angular/core';
import { Router } from "@angular/router"

import { HistoricoMantenimientosService } from '@app/_services/historicoMantenimientos.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { UsuariosService, MaquinasService, MantenimientosPredefinidosService } from '@app/_services';
import { TipoMantenimiento_DAT } from '@app/_models';
import { IntlService } from '@progress/kendo-angular-intl';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';

@Component({
  selector: 'app-historicoMantenimientos',
  templateUrl: 'historicoMantenimientos.component.html'
})

export class HistoricoMantenimientosComponent {
  
  user = this.userService.userValue;
  public selectedTipo: TipoMantenimiento_DAT;
  public selectedMaquinaC: TipoMantenimiento_DAT;
  public selectedGrupo: TipoMantenimiento_DAT;
  public selectedMantenimiento: TipoMantenimiento_DAT =  new TipoMantenimiento_DAT("0", '');
  public loading: any = false;
  private selectedMaquina: number = 0;
  public fechaIniValue: Date;
  public fechaFinValue: Date;

  public Jmaquinas: any;
  public JmaquinasAux: any;
  public gruposMaquinas: any;
  public idGruposMaquinas: number = 0;
  public estadosMantenimientos_DAT: TipoMantenimiento_DAT[];
  public Mantenimientos_DAT: TipoMantenimiento_DAT[];

  public fechaIni: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth() - 1, this.myFunctions.getDateNow().getDate());
  public fechaFin: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
  public mantenimientos: any;
  public idEstado: number = 0;
  public idMantenimiento: number = 0;
  public nombreMantenimiento: string;
  public idFechaIniSeleccionada: string;
  public idFechaFinSeleccionada: string;
  public seleccionados: number[] = [];
  private translate: TranslateService;
  public historicoMantenimientos: JSON;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  public idTipoMantenimiento_DAT: number;
  public estadoMantenimiento: TipoMantenimiento_DAT;
  public Mantenimiento: TipoMantenimiento_DAT;
  public Jestados: any;
  public JestadosSelected: any;

  /* POPUP */
  public rowSelected: any = []; // CON ESTO SE PUEDE ACCEDER A SUS PARTES SIN NECESITAR DEFINIRLO TODO EN VARIABLES SUELTAS!


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild('popupEditar') popupEditar: NgbModalRef;
  @ViewChild('popupEliminar') popupEliminar: NgbModalRef;

  constructor(
    private maquinasService: MaquinasService,
    private intl: IntlService,
    private historicoMantenimientosService: HistoricoMantenimientosService,
    translate: TranslateService,
    private menuService: MenuService,
    private userService: UsuariosService,
    public router: Router,
    public mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    private translateService: TranslateService, public route: ActivatedRoute,
    private modalService: NgbModal,
    public myFunctions: MyFunctions) {

    this.loading = true;
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicomantenimientos').toUpperCase();

    this.cargarFiltro();
    this.CargarMantenimientos();
  }
  ngOnInit() {
    if (this.Jestados == undefined) {
      this.Jestados = [];
      var an: any = {};
      an["id"] = 1;
      an["nombre"] = this.translate.instant("realizado");
      this.Jestados.push(an);
      var an2: any = {};
      an2["id"] = 2;
      an2["nombre"] = this.translate.instant("sinRealizar");
      this.Jestados.push(an2);
    }
  }

  CargarMantenimientos() {
    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.fechaFin.getTime() + (1000 * 60 * 60 * 24)));
    var idMaquinas = this.selectedMaquina.toString();
    var idEstados = this.idEstado.toString();
    var idMantenimientos = this.idMantenimiento.toString();

    this.historicoMantenimientosService.Get(idMaquinas, fechaInicio, fechaFin, idEstados, idMantenimientos, "0").subscribe(
      (json) => {
        this.historicoMantenimientos = json;
        this.loading = false;
      });
  }

  clickEditar() {
    if (this.seleccionados.length > 0) {
      var an: any = this.historicoMantenimientos;
      var rowSelecteds = an.filter(row => row.idHMantenimiento == this.seleccionados[0])
      if (rowSelecteds.length > 0) {
        this.rowSelected = rowSelecteds[0];
        this.rowSelected["duracionHHMM"] = this.myFunctions.secondsToHms(this.rowSelected["duracion"]);
        this.rowSelected["fecha"] = new Date(this.rowSelected["fecha"]);
        this.rowSelected["fechaIni"] = new Date(this.rowSelected["fechaIni"]);
        this.rowSelected["fechaFin"] = new Date(this.rowSelected["fechaFin"]);
        this.rowSelected["tipo"] = this.translate.instant(this.rowSelected["tipo"]);
        this.rowSelected["estado"] = this.translate.instant(this.rowSelected["estado"]);
        this.rowSelected["perdida"] = this.translate.instant(this.rowSelected["perdida"]);

        this.Jestados.forEach(
          row => {
            if (row.id == 1 && this.rowSelected["idEstado"] == 3)
              this.JestadosSelected = row;
            if (row.id == 2 && this.rowSelected["idEstado"] != 3)
              this.JestadosSelected = row;
          });
      }
    }
    this.modalReference = this.modalService.open(this.popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  clickEliminar() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.popupEliminar, { backdrop: 'static', size: 'xs', keyboard: false, centered: true });
  }
  eliminarRegistro() {
    this.loading = true;
    this.modalReference.close();
    this.historicoMantenimientosService.delete(this.rowSelected["idMaquina"], this.rowSelected["idMantenimiento"], this.rowSelected["idHMantenimiento"], this.rowSelected["idTipo"]).subscribe(
      (json) => {
        this.CargarMantenimientos();
      });
  }
  actualizaqrMantenimiento() {
    this.loading = true;
    this.modalReference.close();
    this.historicoMantenimientosService.update_fachas(this.rowSelected["idHMantenimiento"],
      this.rowSelected["fecha"],
      this.rowSelected["fechaIni"],
      this.rowSelected["fechaFin"],
      this.rowSelected["editable"],
      this.rowSelected["idMaquina"],
      this.rowSelected["idMantenimiento"]).subscribe(
        json => {
          this.modalReference.close();
          this.historicoMantenimientosService.update_estado(this.rowSelected["idHMantenimiento"], this.JestadosSelected.id).subscribe(
            json => {
              this.CargarMantenimientos();
            });
        });
  }

  /* DESDE ESTE PUNTO HASTA EL FINAL DEL DOCUMENTO ESTABA HECHO DE ANTES, solo lo he ORDENADO y borrado MUCHAS cosas que sobraban (Aratz) */
  /* FILTRO */
  cargarFiltro() {
    this.fechaIni = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth() - 1, this.myFunctions.getDateNow().getDate());
    this.fechaFin = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
    this.fechaIniValue = this.fechaIni;
    this.fechaFinValue = this.fechaFin;
    this.maquinasService.getGruposMaquinasTodos().subscribe((result) => {
      this.gruposMaquinas = result.data;
      this.idGruposMaquinas = null;
      this.gruposMaquinas.forEach((element) => {
        element['nombre'] = this.translate.instant(element['nombre']);
      });
      this.selectedGrupo = { 'id': "-1", 'nombre': this.translate.instant('todos') };
    });
    this.mantenimientosPredefinidosService.GetAll_EstadosMantenimientos_DAT().subscribe((result) => {
      this.estadosMantenimientos_DAT = result.data;
      this.estadosMantenimientos_DAT.forEach((element) => {
        element['nombre'] = this.translate.instant(element['nombre']);
      });
      this.estadosMantenimientos_DAT.unshift({ 'id': "-1", 'nombre': this.translate.instant('todos') });
      this.selectedTipo = { 'id': "-1", 'nombre': this.translate.instant('todos') };
    });
    var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
    if (maquinas_planificador_model == false) {
      this.maquinasService.getMaquinasPlanificadorTodas().subscribe(json => {
        this.maquinasService.set_maquinas_planificador_model(json);
        this.Jmaquinas = this.maquinasService.get_maquinas_planificador_model();
        this.Jmaquinas.forEach((element) => {
          element['nombre'] = this.translate.instant(element['nombre']);
          this.selectedMaquinaC = { 'id': "-1", 'nombre': this.translate.instant('todos') };
        });
        this.JmaquinasAux = this.Jmaquinas;
      })
    } else {
      this.Jmaquinas = maquinas_planificador_model;
      this.JmaquinasAux = this.Jmaquinas;
    }
    this.maquinasService.GetAll_Mantenimiento(-1).subscribe((result) => {
      const groupedByName = result.data.reduce((acc, curr) => {
        if (!acc[curr.nombre]) {
          // Si no existe el nombre en el acumulador, creamos una nueva entrada
          acc[curr.nombre] = { ...curr };
        } else {
          // Si ya existe, concatenamos los IDs
          acc[curr.nombre].id = `${acc[curr.nombre].id},${curr.id}`;
        }
        return acc;
      }, {});

      // this.Mantenimientos_DAT = result.data;
      this.Mantenimientos_DAT = Object.values(groupedByName);

    });
  }
  selectionGrupoMaquinaChange(e) {
    try {
      if (e.id == -1) {
        this.idGruposMaquinas = null;
      } else {
        this.idGruposMaquinas = e.id;
      }
    } catch{
      this.idGruposMaquinas = null;
    }
    this.selectedMaquinaC = new TipoMantenimiento_DAT("0", '');
    this.selectedMantenimiento = new TipoMantenimiento_DAT("0", '');



    this.Jmaquinas = [];
    for (let maquina of this.JmaquinasAux) {
      if (this.idGruposMaquinas == null || this.idGruposMaquinas == maquina.grupo || -1 == maquina.grupo) {
        this.Jmaquinas.push(maquina);
      }
    }
  }
  selectionMaquinaChange(e) {
    var idMaquina = 0;
    try {
      idMaquina = e.id;
    } catch{
      idMaquina = 0;
    }

    this.Mantenimientos_DAT = [];
    this.selectedMaquina = idMaquina;
    this.maquinasService.GetAll_Mantenimiento(this.selectedMaquina).subscribe((result) => {
      const groupedByName = result.data.reduce((acc, curr) => {
        if (!acc[curr.nombre]) {
          // Si no existe el nombre en el acumulador, creamos una nueva entrada
          acc[curr.nombre] = { ...curr };
        } else {
          // Si ya existe, concatenamos los IDs
          acc[curr.nombre].id = `${acc[curr.nombre].id},${curr.id}`;
        }
        return acc;
      }, {});

      // this.Mantenimientos_DAT = result.data;
      this.Mantenimientos_DAT = Object.values(groupedByName);
    });
  }
  onFechaIniChange(e) {
    this.fechaIni = e;
  }
  onFechaFinChange(e) {
    this.fechaFin = e;
  }
  selectionTipoChange(e) {
    try {
      this.idEstado = e.id;
    } catch{
      this.idEstado = null;
    }

  }
  selectionMantenimientoChange(e) {
    try {
      this.idMantenimiento = e.id;
      this.nombreMantenimiento = e.nombre;
    } catch{
      this.idMantenimiento = 0;
      this.nombreMantenimiento = null;
    }
  }
  onFilterClck() {
    this.loading = true;
    this.CargarMantenimientos()
  }

  /*TOOLTIP*/
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
