<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)"
      *ngIf="(router.url === '/OF/crear' || router.url.startsWith('/OF/editar')) ">
  <div class="clearfix">

    <div id="divDatosPieza" class="card">
      <div class="card-header">
        <h3><label>OF</label></h3>
        <div class="plegarpanel"></div>
      </div>

      <div class="card-body">
        <!--OFCard-->

        <div class="form-row">

          <!--OF-->
          <div class="form-group mr-2" [style.width]="'5%'">
            <label ID="lNombrePlan" class="control-label">OF</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="of" [class.is-invalid]="submitted && f.of.errors">
              </kendo-textbox>
              <div *ngIf="submitted && f.of.errors" class="invalid-feedback">
                <div *ngIf="f.of.errors.required">{{ 'ofrequerido' | translate}}</div>
              </div>
            </div>
          </div>

          <!--VERSION-->
          <div class="form-group mr-2" [style.width]="'5%'">
            <label class="control-label">{{ 'version' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="textoVersion">
              </kendo-textbox>
            </div>
          </div>

          <!--PROYECTO-->
          <div class="form-group mr-2" [style.width]="'20%'">
            <label ID="lFinicio" class="control-label ">{{ 'proyecto' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="proyecto"></kendo-textbox>
            </div>
          </div>

          <!--CLIENTE-->
          <div class="form-group mr-2" [style.width]="'20%'">
            <label ID="lFinicio" class="control-label">{{ 'cliente' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="cliente"></kendo-textbox>
            </div>
          </div>

          <!--SUBCLIENTE-->
          <div class="form-group mr-2" [style.width]="'20%'">
            <label ID="LsubCliente" class="control-label">{{ 'subcliente' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="subcliente"></kendo-textbox>
            </div>
          </div>

        </div>

        <div class="form-row">

          <!--Seccion-->
          <div class="form-group mr-2" [style.width]="'20%'">
            <div class="form-group">
              <kendo-label text="{{ 'seccion' | translate }}">
                <kendo-dropdownlist [(data)]="groupedSeccion" [textField]="'nombre'" class="form-control"
                                    [valueField]="'id'" formControlName="idSeccion"
                                    [ngClass]="{ 'invalid-dropdown': submitted && f.idSeccion.errors }"></kendo-dropdownlist>
                <div *ngIf="submitted && f.idSeccion.errors" class="invalid-feedback-dropdown">
                  <div *ngIf="f.idSeccion.errors.required">{{ 'seccionrequerida' | translate}}</div>
                </div>
              </kendo-label>
            </div>
          </div>
          <!--IDERP-->
          <div class="form-group mr-2" [style.width]="'8%'">
            <label ID="LidERP" class="control-label">{{ 'iderp' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="idERP"></kendo-textbox>
            </div>
          </div>

          <!--REFERENCIA-->
          <div class="form-group mr-2" [style.width]="'5%'">
            <label ID="Lreferencia" class="control-label">{{ 'referencia' | translate }}</label>
            <div class="caja">
              <kendo-textbox maxlength="50" formControlName="referencia" [style.width]="'70%'"></kendo-textbox>
            </div>
          </div>

          <!--TERMINADO-->
          <div class="form-group form-check mr-2">
            <kendo-label for="activo" class="form-check-label" text="{{ 'terminado' | translate }}">
            </kendo-label>
            <div class="caja">
              <kendo-switch id="activo" formControlName="terminado" [onLabel]="' '" [offLabel]="' '"> </kendo-switch>
            </div>
          </div>

          <!--PROTOTIPO-->
          <div class="form-group form-check mr-2">
            <kendo-label for="activo" class="form-check-label" text="{{ 'prototipo' | translate }}">
            </kendo-label>
            <div class="caja">
              <kendo-switch id="activo" formControlName="prototipo" [onLabel]="' '" [offLabel]="' '"> </kendo-switch>
            </div>
          </div>

          <!--DIV LIMITES-->
          <div id="DIVLimites">
            <div class="clearfix">
              <!-- FECHA DE INICIO -->
              <div class="float-left mr-2">
                <div class="form-group">
                  <label ID="Lfechaini" class="control-label">{{ 'fechainicio' | translate}}</label>
                  <div class="caja">
                    <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." formControlName="fechainicio"
                                      id="rntfechainicio" class="form-control">
                    </kendo-datepicker>
                  </div>
                </div>
              </div>
              <!-- FECHA FINAL -->
              <div class="float-left mr-2">
                <div class="form-group">
                  <label ID="Lfechafin" class="control-label">{{ 'fechaentrega' | translate}}</label>
                  <div class="caja">
                    <kendo-datepicker placeholder="{{ 'fechaentregap' | translate}}..." formControlName="fechafin"
                                      id="rntfechafin" class="form-control">
                    </kendo-datepicker>
                  </div>
                </div>
              </div>
              <!-- PRIORIDAD -->
              <div class="float-left mr-2">
                <div class="form-group">
                  <label ID="Lprioridad" class="control-label">{{ 'prioridad' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox [(value)]="prioridad" formControlName="prioridad" [format]="'n'"
                                          [decimals]="decimals" [value]="value" [min]="0" [max]="100" [autoCorrect]="autoCorrect">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label ID="Label1" class="title-conf"></label>
            </div>
          </div>

        </div>
        <div class="form-row">
          <!-- ATRIBUTOS -->
          <div class="form-group mr-2" [style.width]="'20%'">
            <div class="form-group">
              <kendo-label text="{{ 'atributos' | translate }}">
                
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedAtributo"
                                     [textField]="'nombre'"
                                     [valueField]="'id'"
                                     [autoClose]="false"
                                     formControlName="atributos"
                                     [(ngModel)]="atributosSeleccionados"
                                     placeholder="{{ 'atributos' | translate }}"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'atributosSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  
              </kendo-label>
            </div>
          </div>
        
        </div>
      </div>

    </div>

    <div class="clearfix  mb-3" *ngIf="isAddMode==false" style="height: 550px">
      <div class="card">
        <div class="card-header">
          <h3><label>{{ 'piezas' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
               (mouseover)="showTooltip($event)">
            <!-- HIERARKIKO -->
            <kendo-treelist [kendoTreeListFlatBinding]="piezas"
                            kendoTreeListExpandable
                            idField="id"
                            [initiallyExpanded]="true"
                            parentIdField="idPadre"
                            [sortable]="true"
                            [navigable]="true"
                            filterable="menu"
                            [height]="490"
                            kendoTreeListSelectable
                            [(selectedItems)]="partesseleccionados"
                            (cellClick)="cellClick($event)"
                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, multiple: true}">
              <ng-template kendoTreeListToolbarTemplate position="top">
                <button ID="bNuevo" (click)="onClickNuevaPieza($event)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</button>
                <button [disabled]="!botonesActivados" ID="bEditar" (click)="onClickEditarPieza($event)" class="btncancelar btn btn-success btn-sm mr-1">{{ 'editar' | translate }}</button>
                <button ID="bEditar" (click)="onClickNuevaPiezaPred($event)" class="btncancelar btn btn-primary btn-sm mr-1">{{ 'anadirpred' | translate }}</button>
                <button [disabled]="!botonesActivados || this.user.proyectos < 2" ID="bEliminar" (click)="onClickEliminarPieza(content, $event)" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate }}</button>
              </ng-template>
              <kendo-treelist-checkbox-column width="10%" showSelectAll="true"></kendo-treelist-checkbox-column>
              <kendo-treelist-column [expandable]="true" field="refGrid" title="{{ 'referencia' | translate }}" width="50%"></kendo-treelist-column>
              <kendo-treelist-column field="nombreGrid" title="{{ 'nombre' | translate }}" width="100%"></kendo-treelist-column>
              <kendo-treelist-column field="finicio" title="{{ 'fechainicio' | translate }}" width="50%"></kendo-treelist-column>
              <kendo-treelist-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}" width="50%"></kendo-treelist-column>
              <kendo-treelist-column field="cantidad" title="{{ 'cantidad' | translate }}" [headerStyle]="{'text-align': 'right'}" [style]="{'text-align': 'right'}" width="30%"></kendo-treelist-column>
              <kendo-treelist-column field="hechos" title="{{ 'hechos' | translate }}" [headerStyle]="{'text-align': 'right'}" [style]="{'text-align': 'right'}" width="40%"></kendo-treelist-column>
              <kendo-treelist-column field="terminado" title="{{ 'terminado' | translate }}" width="37%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.terminado" disabled />
                </ng-template>
              </kendo-treelist-column>
              <kendo-treelist-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                       filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                       filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                       filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                       filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                       filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                       filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                       filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                       filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                       filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                       filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                       filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                       filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                       filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                       filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                       filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                       filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                       filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                       filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                       filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                       groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                                       noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
              </kendo-treelist-messages>
            </kendo-treelist>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.proyectos < 2"> {{ 'guardar' | translate}} </button>
      <a class="btn btn-danger btn-sm mr-1" (click)="Atras()"> {{ 'cancelar' | translate}} </a>
      <button class="btn btn-copiar btn-sm mr-1" (click)="onClickDuplicarOF(content, $event)" [disabled]="isAddMode || this.user.proyectos < 2"> {{ 'duplicar' | translate}} </button>
    </div>

  </div>
</form>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{'preguntaeliminarpopup' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="Eliminar(contentloading)" [disabled]="this.user.proyectos < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<ng-template #anadirPred let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid [kendoGridBinding]="piezasPred" [sortable]="true" [navigable]="true" filterable="menu"
                [resizable]="true" kendoGridSelectBy="id" [selectedKeys]="piezasPredSelected" scrollable="virtual"
                [height]="500" [rowHeight]="36" [pageSize]="50">
      <!--checkbox-->
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <!--id-->
      <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
      <!--nombre-->
      <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
      <!--referencia-->
      <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="referencia" title="{{ 'referencia' | translate}}"></kendo-grid-column>
      <!--numplano-->
      <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="numeroPlano" title="{{ 'plano' | translate}}"></kendo-grid-column>
      <!--pieza-->
      <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nombreParte" title="{{ 'parte' | translate}}"></kendo-grid-column>
      <!--descripcion-->
      <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>
      <!--logo-->
      <kendo-grid-column width="5%" field="tieneLogo" [style]="{'text-align': 'left'}" title="{{ 'logo' | translate}}" filter="boolean">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.tieneLogo" disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click');onClickCancelar()">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarAnadirPred()" [disabled]="this.user.proyectos < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>


<ng-template #terminarOFPiezaOperacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntarCerrarPiezasOperacion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="operacionesCantidadHechas()" [disabled]="this.user.operaciones<2">{{
      'aceptar' | translate }}</button>
  </div>
</ng-template>

<ng-template #catidadHechasOperacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntarCantidadHechasOperacion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeElements(0)">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="closeElements(1)" [disabled]="this.user.operaciones<2">{{
      'si' | translate }}</button>
  </div>
</ng-template>