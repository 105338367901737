import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MyFunctions, MyCharts } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
// import { isConstructSignatureDeclaration, updateFunctionDeclaration } from 'typescript';
// import { iif } from 'rxjs';
// import { NoteHoverEvent } from '@progress/kendo-angular-charts';
declare var GanttChart_estados: any;
const baseUrl = `${environment.apiUrl}/HistoricoMaquinasDatos`;

@Injectable()

export class HistoricoMaquinasDatosService {

  //#region VARIABLES DE CLASE
  // fecha de el ultimo update
  private segundosUtiles: number = 5 * 60;
  private fechaUpdate: Date;

  private fechaIni: Date;
  private fechaFin: Date;

  // historicos
  private historico_completo: JSON;
  private historico_maquinas_resumenes: JSON;
  private historico_maquinas_operaciones_agrupadas_resumen: JSON;
  private historico_contador: JSON;
  private historico_contador_previo: JSON;
  private historico_maquinas_operaciones_achatarradas: JSON; // CHATARRAS EN LOTES

  // informacion
  private info_alarmas: JSON;
  private info_perdidas: JSON;
  private info_mantenimientos: JSON;
  private info_maquinas: JSON;
  private info_operaciones: JSON;
  private info_ofs: JSON;
  private info_subestados: JSON;
  private info_configuracion = {
    ejecucionSinOperacionRepercuteA: 0,
    porcentajeEjecucionSinOperacionRepercuteOEE: 100,
    tiempoEstimado_porcentajeMinimoTiempoAtenerEnCuenta: 0,
    usaLotes: false,
    verNSerie: false,
    verColada: false,
    verLote: false
  };
  private info_turnos: JSON;
  private info_operarios: JSON;
  private info_nserie: JSON;
  //#endregion

  constructor(private http: HttpClient, private myFunctions: MyFunctions, private myCharts: MyCharts, private translateService: TranslateService) { }

  //#region LLAMADAS A LA API PARA CARGAS Y RECARGAS DE VARIABLES -----------------------------------------------------------------------------------------------------------
  public cargar_historico_completo(fechaIni, fechaFin) {
    console.log("CONSULTA HISTORICO COMPLETA: [" + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(fechaIni) + ' -> ' + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(fechaFin) + ')');
    return this.http.post<string>(`${baseUrl}/cargar_historico_completo`, { fechaIni, fechaFin }, { withCredentials: true });
  }
  //#endregion ---------------------------------------------------------------------------------------------------------------------------------------------------------------
  //#region SET DE VARIABLES PARA ACTUALIZAR LAS VARIABLES GENERALES CON LOS DATOS DELIDOS DESDE LA API
  public set_historico_datos(json, fechaIni, fechaFin) {
    console.log("SET HISTORICO COMPLETA")
    // console.log(json)
    // var json = inflate(new Uint8Array(compress_json), { to: 'string' });

    // Con la respuesta de cargar_historico_completo se tiene que crear esta funcion.
    // 00: HISTORICO MAQUINAS RESUMENES
    var historico_maquinas_resumenes: any = json.historico_maquinas_resumenes;
    historico_maquinas_resumenes.forEach(
      element => {
        element.fechaTurno = new Date(element.fechaTurno);
        element.fechaIni = new Date(element.fechaIni);
        element.fechaFin = new Date(element.fechaFin);
      });
    this.historico_maquinas_resumenes = historico_maquinas_resumenes;
    // 01: ALARMAS
    // console.log('01: ALARMAS')
    var info_alarmas = json.info_alarmas;
    info_alarmas.forEach(
      element => {
        element.fechaIni = new Date(element.fechaIni);
        element.fechaFin = new Date(element.fechaFin);
      });
    this.info_alarmas = info_alarmas;
    // 02: PERDIDAS
    // console.log('02: PERDIDAS')
    this.info_perdidas = json.info_perdidas;
    // 03: MANTENIMIENTOS
    // console.log('03: MANTENIMIENTOS')
    this.info_mantenimientos = json.info_mantenimientos;
    // 04: MAQUINAS
    // console.log('04: MAQUINAS')
    this.info_maquinas = json.info_maquinas;
    // 05: OPERACIONES
    // console.log('05: OPERACIONES')
    this.info_operaciones = json.info_operaciones;
    // 06: OFS, PIEZAS, PARTES, RUTAS
    // console.log('06: OFS, PIEZAS, PARTES, RUTAS')
    this.info_ofs = json.info_ofs;
    // 07: CANTIDADES/TIEMPOS MAQUINAS OPERACIONES AGRUPADAS
    // console.log('07: CANTIDADES/TIEMPOS MAQUINAS OPERACIONES AGRUPADAS')
    this.historico_maquinas_operaciones_agrupadas_resumen = json.historico_maquinas_operaciones_agrupadas_resumen;
    // 08: SUBESTADOS DE MAQUINA
    // console.log('08: SUBESTADOS DE MAQUINA')
    var info_subestados: any = json.info_subestados;
    info_subestados.forEach(
      element => {
        if (element.nombre != '')
          element.nombre = this.translateService.instant(element.nombre);
        else
          element.nombre = this.translateService.instant("sinEstado");
      });
    this.info_subestados = info_subestados;
    // 09: CONFIGURACION 
    // console.log('09: CONFIGURACION')
    this.info_configuracion.ejecucionSinOperacionRepercuteA = this.myFunctions.copy(json.info_configuracion[0].ejecucionSinOperacionRepercuteA);
    this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE = this.myFunctions.copy(json.info_configuracion[0].porcentajeEjecucionSinOperacionRepercuteOEE);
    this.info_configuracion.tiempoEstimado_porcentajeMinimoTiempoAtenerEnCuenta = this.myFunctions.copy(json.info_configuracion[0].tiempoEstimado_porcentajeMinimoTiempoAtenerEnCuenta);
    // this.info_configuracion.usaLotes = SE CARGA AL MONTAR EL HISTORICO EN LA PROPIA WEB;
    this.info_configuracion.verNSerie = (this.myFunctions.copy(json.info_configuracion[0].verNSerie) as boolean);
    this.info_configuracion.verColada = (this.myFunctions.copy(json.info_configuracion[0].verColada) as boolean);
    this.info_configuracion.verLote = (this.myFunctions.copy(json.info_configuracion[0].verLote) as boolean);

    // 10: TURNOS
    // console.log('10: TURNOS')
    var turnos = json.info_turnos;
    turnos.forEach(
      element => {
        element.fechaTurno = new Date(element.fechaTurno);
        element.fechaIni = new Date(element.fechaIni);
        element.fechaFin = new Date(element.fechaFin);
      });
    this.info_turnos = turnos;
    // 11: OPERARIOS
    // console.log('11: OPERARIOS')
    this.info_operarios = json.info_operarios;

    // 12: NSERIE
    // console.log('12: NSERIE')

    json.info_nserie.forEach(
      pins => {
        pins.perdida_apartada = '';
        pins.perdida_achatarra = '';
        if (pins.idPerdida_apartada > 0) {
          var perdida_encontrada = this.myFunctions.copy(this.info_perdidas as any).filter(f => f.id == pins.idPerdida_apartada)[0];
          if (perdida_encontrada != undefined) {
            pins.perdida_apartada = perdida_encontrada.perdida;
          }
        }
        if (pins.idPerdida_achatarrada > 0) {
          var perdida_encontrada = this.myFunctions.copy(this.info_perdidas as any).filter(f => f.id == pins.idPerdida_achatarrada)[0];
          if (perdida_encontrada == undefined) {
            pins.perdida_achatarra = perdida_encontrada.perdida;
          }
        }
      });

    this.info_nserie = json.info_nserie;

    // 13: CONTADOR
    // console.log('13: CONTADOR')
    this.historico_contador = json.historico_contador;
    // console.log(json)

    // 14: CONTADOR: Hata inicio de historico_maquinas_operaciones_asignadas_resumen
    // console.log('14: CONTADOR')
    this.historico_contador_previo = json.historico_contador_previo;
    // console.log(json)

    // 15: MOTIVOS CHATARRA
    // console.log('15: MOTIVOS CHATARRA')
    this.historico_maquinas_operaciones_achatarradas = json.historico_maquinas_operaciones_achatarradas;
    // console.log(json)

    // Actualizamos las fechas de actualizado y plazo de los datos recibidos
    this.fechaUpdate = new Date();
    this.fechaIni = fechaIni;
    this.fechaFin = fechaFin;

    // Despues de tratar todos los datos, dejamos preparado un historico completo
    this.historico_completo = (this.montar_historico_completo() as any);

    // Despues de tratar todos los datos, dejamos para el final el historico de contador para asignarle la OF y el turno
    //    que le corresponda para los nuevos criterios de agrupacion
    var an: any = this.montar_historico_contador(1, 1);
    this.historico_contador = an;
  }
  private montar_historico_completo() {
    // TABLA COMPLETA
    var result = [];

    // PONEMOS A FALSE EL USALOTES
    this.info_configuracion.usaLotes = false;

    // SE INTENTAN MAPEAR LOS DATOS ANTES DE EMPEZAR A FILTRAR ROW POR ROW PARA ACELARAR LA EJECUCION DEL PROGRAMa
    var perdidas_array = []; this.myFunctions.copy(this.info_perdidas as any).forEach(a => perdidas_array[a.id] = a);
    var mantenimientos_array = []; this.myFunctions.copy(this.info_mantenimientos as any).forEach(a => mantenimientos_array[a.idMaquinas_mantenimientos] = a);
    var maquinas_array = []; this.myFunctions.copy(this.info_maquinas as any).forEach(a => maquinas_array[a.idMaquina] = a);
    var hmoar_array = []; this.myFunctions.copy(this.historico_maquinas_operaciones_agrupadas_resumen as any).forEach(a => hmoar_array[a.idHistorico_maquinas_operaciones] = a);
    var operaciones_array = []; this.myFunctions.copy(this.info_operaciones as any).forEach(a => operaciones_array[a.idOperacion] = a);
    var ofs_array = []; this.myFunctions.copy(this.info_ofs as any).forEach(a => ofs_array[a.idRuta] = a);
    var operarios_array = []; this.myFunctions.copy(this.info_operarios as any).forEach(a => operarios_array[a.id] = a);
    var nseries_array = []; this.myFunctions.copy(this.info_nserie as any).forEach(a => nseries_array[a.idHistorico_maquinas_operaciones] = a);

    // PREFILTRADO ALARMAS
    var historicoPorFecha_alarmas;
    var fechaIniFiltrado_alarmas = '2000/01/01';
    var fechaFinFiltrado_alarmas = '2000/01/01';

    // LOOP
    (this.historico_maquinas_resumenes as any).forEach(
      element => {
        //PARA REDUCIOR EL TRAFICO DE DATOS, SE OVIAN ALGUNOS Y SE ASIGNAN LOS NULL A SU VALOR POR DEFECTO EN LA WEB
        if (element.idHistorico_operaciones == null) element.idHistorico_operaciones = -1;
        if (element.idHistorico_maquinas_operaciones_relacionadas == null) element.idHistorico_maquinas_operaciones_relacionadas = -1;
        if (element.idPerdidaHistoricoBase == null) element.idPerdidaHistoricoBase = -1;
        if (element.observacionHistoricoPerdida == null) element.observacionHistoricoPerdida = '';
        if (element.idOperario == null) element.idOperario = -1;
        if (element.idMaquinas_mantenimientos == null) element.idMaquinas_mantenimientos = -1;
        if (element.observacionMantenimiento == null) element.observacionMantenimiento = '';
        if (element.lineasHistorico_maquinas_resumenesSimultaneas == null) element.lineasHistorico_maquinas_resumenesSimultaneas = 1;
        if (element.operaciones_simultaneas == null) element.operaciones_simultaneas = 1;
        if (element.operarios_simultaneos == null) element.operarios_simultaneos = 1;
        if (element.descripcion == null) element.descripcion = '';
        if (element.nombrePrograma == null) element.nombrePrograma = '';
        if (element.cantidadContador == null) element.cantidadContador = 0;
        if (element.cantidadAsignadaOperario == null) element.cantidadAsignadaOperario = 0;
        if (element.cantidadValidada == null) element.cantidadValidada = 0;

        // se hacia mas abajo y no se muy bien por que... pero se mantiene aqui
        element.tNegativoCalidad = 0;

        // COMO SE TRAE EL IDHISTORICOOPERACION PORSEACASO AUNQUE NO SEA OPERACION, SE TRATA Y SE GUARDA EL ORIGINAL 
        if (element.operacion == 0) {
          element.idHistorico_operaciones_sinContar_es_operacion = element.idHistorico_operaciones1 + 0;
          element.idHistorico_operaciones = -1;
          element.idHistorico_maquinas_operaciones_relacionadas_sinContar_es_operacion = element.idHistorico_maquinas_operaciones_relacionadas + 0;
          element.idHistorico_maquinas_operaciones_relacionadas = -1;
        }

        // MAQUINA: ARRAY-ABLE
        var maquina_encontrada = maquinas_array[element.idMaquina];
        if (maquina_encontrada == undefined) {
          element.maquina = '';
          element.idMaquinaGrupo = -1;
          element.maquinaGrupo = '';
          element.idSeccion = -1;
          element.seccion = '';
          element.idAreaProductiva = -1;
          element.areaProductiva = '';
        } else {
          element.maquina = maquina_encontrada.maquina;
          element.idMaquinaGrupo = maquina_encontrada.idMaquinaGrupo;
          element.maquinaGrupo = maquina_encontrada.maquinaGrupo;
          element.idSeccion = maquina_encontrada.idSeccion;
          element.seccion = maquina_encontrada.seccion;
          element.idAreaProductiva = maquina_encontrada.idAreaProductiva;
          element.areaProductiva = maquina_encontrada.areaProductiva;
        }

        // SUBESTADOS: NO ARRAY-ABLE * es mas complejo porque usa varios ids. pero se podria hacer si realentiza mucho la carga (nunca deveria de ser muy grande)
        var subestado_encontrado = (this.info_subestados as any).filter(f => f.idSubestado == element.idSubEstado && f.idMaquina == element.idMaquina)[0];
        if (subestado_encontrado == undefined) {
          element.subestado = '';
          element.color_subestado = 'rgba(85, 222, 165, 1)';
          element.machine_subestado = 0;
        } else {
          element.subestado = subestado_encontrado.nombre;
          element.color_subestado = subestado_encontrado.color;
          element.machine_subestado = subestado_encontrado.machine;
        }
        var subestado_encontrado = (this.info_subestados as any).filter(f => f.idEstado == element.idEstado && f.idMaquina == element.idMaquina && f.idEstado < 100)[0];
        if (subestado_encontrado == undefined) {
          element.estado = '';
          element.color_estado = 'rgba(85, 222, 165, 1)';
          element.machine_estado = 0;
        } else {
          element.estado = subestado_encontrado.nombre;
          element.color_estado = subestado_encontrado.color;
          element.machine_estado = subestado_encontrado.machine;
        }

        // OPERARIOS: ARRAY-ABLE
        var operario_encontrado = undefined; if (element.idOperario > 0) operario_encontrado = operarios_array[element.idOperario];
        if (operario_encontrado == undefined) {
          element.operario = this.translateService.instant('sinOperario');
          element.operario_reducido = '??';
          element.operario_color = 'null';
        } else {
          element.operario = operario_encontrado.nombre + ' ' + operario_encontrado.apellido1 + ' ' + operario_encontrado.apellido2;
          element.operario_reducido = operario_encontrado.nombre.trim().substring(0, 1).toUpperCase() + operario_encontrado.apellido1.trim().substring(0, 1).toUpperCase();
          element.operario_color = operario_encontrado.color;
        }

        // HOMAR (CANTIDADES / TIEMPOS): ARRAY-ABLE
        var hmoar_encontrado = undefined; if (element.idHistorico_maquinas_operaciones_relacionadas > 0) hmoar_encontrado = hmoar_array[element.idHistorico_maquinas_operaciones_relacionadas];
        if (hmoar_encontrado == undefined) {
          element.hmoar_tiempoPreparacion = 0;
          element.hmoar_tiempoOperacion = 0;
          element.hmoar_tiempoTotalOperacion = 0;
          element.hmoar_tiempoNoComputable = 0;
          element.hmoar_tiempoDisponibilidad = 0;
          element.hmoar_tiempoRendimiento = 0;
          element.hmoar_tiempoCalidad = 0;
          element.hmoar_tiempoEstimadoPreparacion = 0;
          element.hmoar_tiempoEstimadoOperacion = 0;
          element.hmoar_tiempoPredictivoPreparacion = 0;
          element.hmoar_tiempoPredictivoOperacion = 0;
          element.hmoar_cantidadTerminada = 0;
          element.hmoar_cantidadApartada = 0;
          element.hmoar_cantidadAchatarrada = 0;
          element.hmoar_cantidad = 0;
          element.hmoar_cantidadContador = 0;
          element.hmoar_cantidadAsignadaOperario = 0;
          element.hmoar_cantidadValidada = 0;
          element.idOperacion = -1;
          // las de OPERACION se añaden aqui tambien
          element.nombreOperacion = '';
          element.tiempoEstimadoEjecucion = -1;
          element.tiempoEstimadoPreparacion = 0;
          element.tiempoPredictivoEjecucion = 0;
          element.tiempoPredictivoPreparacion = 0;
          element.operacionTerminada = -1;
          element.idRuta = -1;
          // las de OF se añaden aqui tambien
          element.idOF = -1;
          element.numeroOF = '';
          element.idCliente = -1;
          element.cliente = '';
          element.nombreSubCliente = '';
          element.referencia = '';
          element.idPieza = -1;
          element.nombrePieza = '';
          element.referenciaPieza = '';
          element.numeroPlano = '';
          element.idParte = -1;
          element.nombreParte = '';
          element.referenciaParte = '';
          element.idRuta = -1;
          element.nombreRuta = '';
          element.usaLotes = 0;
          element.cantidadLote = 0;
          element.cantidad = 0;
          // las de PINS se añaden aqui tambien 
          element.idHistorico_piezas = -1;
          element.nserie = '';
          element.colada = '';
          element.lote = '';
          element.idEstado_pieza = 1;
        } else {
          element.hmoar_tiempoPreparacion = hmoar_encontrado.tiempoPreparacion;
          element.hmoar_tiempoOperacion = hmoar_encontrado.tiempoOperacion;
          element.hmoar_tiempoTotalOperacion = hmoar_encontrado.tiempoTotalOperacion;
          element.hmoar_tiempoNoComputable = hmoar_encontrado.tiempoNoComputable;
          element.hmoar_tiempoDisponibilidad = hmoar_encontrado.tiempoDisponibilidad;
          element.hmoar_tiempoRendimiento = hmoar_encontrado.tiempoRendimiento;
          element.hmoar_tiempoCalidad = hmoar_encontrado.tiempoCalidad;
          element.hmoar_tiempoEstimadoPreparacion = hmoar_encontrado.tiempoEstimadoPreparacion;
          element.hmoar_tiempoEstimadoOperacion = hmoar_encontrado.tiempoEstimadoOperacion;
          element.hmoar_tiempoPredictivoPreparacion = hmoar_encontrado.tiempoPredictivoPreparacion;
          element.hmoar_tiempoPredictivoOperacion = hmoar_encontrado.tiempoPredictivoOperacion;
          element.hmoar_cantidadTerminada = hmoar_encontrado.cantidadTerminada;
          element.hmoar_cantidadApartada = hmoar_encontrado.cantidadApartada;
          element.hmoar_cantidadAchatarrada = hmoar_encontrado.cantidadAchatarrada;
          element.hmoar_cantidad = hmoar_encontrado.cantidadTerminada + hmoar_encontrado.cantidadApartada + hmoar_encontrado.cantidadAchatarrada;
          element.idOperacion = hmoar_encontrado.idOperacion;
          element.hmoar_cantidadContador = hmoar_encontrado.cantidadContador;
          element.hmoar_cantidadAsignadaOperario = hmoar_encontrado.cantidadAsignadaOperario;
          element.hmoar_cantidadValidada = hmoar_encontrado.cantidadValidada;
          // OPERACION: ARRAY-ABLE
          var operacion_encontrada = undefined; if (element.idOperacion > 0) operacion_encontrada = operaciones_array[element.idOperacion];
          if (operacion_encontrada == undefined) {
            element.nombreOperacion = '';
            element.tiempoEstimadoEjecucion = -1;
            element.tiempoEstimadoPreparacion = 0;
            element.tiempoPredictivoEjecucion = 0;
            element.tiempoPredictivoPreparacion = 0;
            element.operacionTerminada = -1;
            element.idRuta = -1;
            // las de OF se añaden aqui tambien
            element.idOF = -1;
            element.numeroOF = '';
            element.idCliente = -1;
            element.cliente = '';
            element.nombreSubCliente = '';
            element.referencia = '';
            element.idPieza = -1;
            element.nombrePieza = '';
            element.referenciaPieza = '';
            element.numeroPlano = '';
            element.idParte = -1;
            element.nombreParte = '';
            element.referenciaParte = '';
            element.idRuta = -1;
            element.nombreRuta = '';
            element.usaLotes = 0;
            element.cantidadLote = 0;
            element.cantidad = 0;
            // las de PINS se añaden aqui tambien 
            element.idHistorico_piezas = -1;
            element.nserie = '';
            element.colada = '';
            element.lote = '';
            element.idEstado_pieza = 1;
          } else {
            element.nombreOperacion = operacion_encontrada.nombreOperacion;
            element.tiempoEstimadoEjecucion = operacion_encontrada.tiempoEstimadoEjecucion;
            element.tiempoEstimadoPreparacion = operacion_encontrada.tiempoEstimadoPreparacion;
            element.tiempoPredictivoEjecucion = operacion_encontrada.tiempoPredictivoEjecucion;
            element.tiempoPredictivoPreparacion = operacion_encontrada.tiempoPredictivoPreparacion;
            element.operacionTerminada = operacion_encontrada.operacionTerminada;
            element.idRuta = operacion_encontrada.idRuta;
            // OF: ARRAY-ABLE
            var of_encontrado = undefined; if (element.idRuta > 0) of_encontrado = ofs_array[element.idRuta];
            if (of_encontrado == undefined) {
              element.idOF = -1;
              element.numeroOF = '';
              element.idCliente = -1;
              element.cliente = '';
              element.nombreSubCliente = '';
              element.referencia = '';
              element.idPieza = -1;
              element.nombrePieza = '';
              element.referenciaPieza = '';
              element.numeroPlano = '';
              element.idParte = -1;
              element.nombreParte = '';
              element.referenciaParte = '';
              element.idRuta = -1;
              element.nombreRuta = '';
              element.usaLotes = 0;
              element.cantidadLote = 0;
              element.cantidad = 0;
              // las de PINS se añaden aqui tambien 
              element.idHistorico_piezas = -1;
              element.nserie = '';
              element.colada = '';
              element.lote = '';
              element.idEstado_pieza = 1;
            } else {
              element.idOF = of_encontrado.idOF;
              element.numeroOF = of_encontrado.numeroOF;
              element.idCliente = of_encontrado.idCliente;
              element.cliente = of_encontrado.cliente;
              element.nombreSubCliente = of_encontrado.nombreSubCliente;
              element.referencia = of_encontrado.referencia;
              element.idPieza = of_encontrado.idPieza;
              element.nombrePieza = of_encontrado.nombrePieza;
              element.referenciaPieza = of_encontrado.referenciaPieza;
              element.numeroPlano = of_encontrado.numeroPlano;
              element.idParte = of_encontrado.idParte;
              element.nombreParte = of_encontrado.nombreParte;
              element.referenciaParte = of_encontrado.referenciaParte;
              element.idRuta = of_encontrado.idRuta;
              element.nombreRuta = of_encontrado.nombreRuta;
              element.usaLotes = of_encontrado.usaLotes;
              element.cantidadLote = of_encontrado.cantidadLote;
              element.cantidad = of_encontrado.cantidad;

              // NSERIE: ARRAY-ABLE 
              var nSerie_encontrados = undefined; if (element.idHistorico_maquinas_operaciones_relacionadas > 0) nSerie_encontrados = nseries_array[element.idHistorico_maquinas_operaciones_relacionadas];
              if (nSerie_encontrados == undefined) {
                element.idHistorico_piezas = -1;
                element.nserie = '';
                element.colada = '';
                element.lote = '';
                element.idEstado_pieza = 1;
              } else {
                element.idHistorico_piezas = nSerie_encontrados.idHistorico_piezas;//.map(objeto => objeto.nSerie).join(',');
                element.nserie = nSerie_encontrados.nSerie;//.map(objeto => objeto.nSerie).join(',');
                element.colada = nSerie_encontrados.colada;//.map(objeto => objeto.colada).join(',');
                element.lote = nSerie_encontrados.lote;//.map(objeto => objeto.lote).join(',');
                element.idEstado_pieza = nSerie_encontrados.idEstado;//.map(objeto => objeto.lote).join(',');
              }
            }
          }

        }

        if (element.usaLotes) this.info_configuracion.usaLotes = true; // SI ALGUNA OF USA LOTES, SE MOSTRARAN LOTES EN LAS VENTANAS
        // if (element.usaLotes && element.hmoar_cantidad > 0 && element.hmoar_cantidad < element.cantidadLote) element.hmoar_cantidad++; // si se han hecho 3, ahora estas haciendo la 4... y sino hay ninguna. se trata abajo despues de la cantidad de OF por ahora (como pide tximi) si no se usan lotes, la cantidad de pieza sera seriada y siempre deveria de ser solo 1.( como CAF)
        if (!element.usaLotes && element.hmoar_cantidad < 1) element.hmoar_cantidad = 1; // si no usa lotes y no hay ninguna... minumo una deveria de haber, la primera.
        if (element.cantidadLote == 0) element.cantidadLote = element.cantidad;
        if (element.hmoar_cantidad == 0) element.hmoar_cantidad = element.cantidadLote; // si no hay datos de ninguna pieza, se contara como que se esta haciendo el OF completo!

        // PERDIDA: ARRAY-ABLE
        var perdida_encontrada = undefined; if (element.idPerdidaHistoricoBase > 0) perdida_encontrada = perdidas_array[element.idPerdidaHistoricoBase];
        if (perdida_encontrada == undefined) {
          element.idPerdida = -1;
          element.perdida = '';
          element.idSubPerdida = -1;
          element.subPerdida = '';
          element.idGrupoPerdida = -1;
          element.grupoPerdida = '';
          element.idTipoOEE_perdida = -1;
        } else {
          element.idPerdida = perdida_encontrada.idPerdida;
          element.perdida = perdida_encontrada.perdida;
          element.idSubPerdida = perdida_encontrada.idSubPerdida;
          element.subPerdida = perdida_encontrada.subPerdida;
          element.idGrupoPerdida = perdida_encontrada.idGrupoPerdida;
          element.grupoPerdida = perdida_encontrada.grupoPerdida;
          element.idTipoOEE_perdida = perdida_encontrada.idTipoOEE_perdida;
        };

        // MANTENIMIENTO: ARRAY-ABLE
        var mantenimiento_encontrado = undefined; if (element.idMaquinas_mantenimientos > 0) mantenimiento_encontrado = mantenimientos_array[element.idMaquinas_mantenimientos];
        if (mantenimiento_encontrado == undefined) {
          element.mantenimiento = '';
          element.observacionMantenimiento = '';
        } else {
          element.mantenimiento = mantenimiento_encontrado.mantenimiento;
          element.observacionMantenimiento = mantenimiento_encontrado.observacionMantenimiento;
        }

        // ALARMAS : NO ARRAY-ABLE (POR FECHAS) 
        if (element.idEstado != 6) {
          element.idAlarma = -1;
          element.alarma = "";
        } else {
          if (fechaIniFiltrado_alarmas > this.myFunctions.dateToYYYYMMDD(element.fechaIni) || fechaFinFiltrado_alarmas < this.myFunctions.dateToYYYYMMDD(element.fechaFin)) {
            historicoPorFecha_alarmas = (this.info_alarmas as any).filter(
              f =>
                f.fechaIni <= this.myFunctions.dateToYYYYMMDD(element.fechaFin) &&
                f.fechaFin > this.myFunctions.dateToYYYYMMDD(element.fechaIni)
            );
            fechaIniFiltrado_alarmas = this.myFunctions.dateToYYYYMMDD(element.fechaIni);
            fechaFinFiltrado_alarmas = this.myFunctions.dateToYYYYMMDD(element.fechaFin);
          }

          var alarma_encontrada = historicoPorFecha_alarmas.filter(f => f.fechaFin > element.fechaIni && f.fechaIni <= element.fechaIni)[0];
          if (alarma_encontrada == undefined) {
            element.idAlarma = -1;
            if (element.descripcion != '') {
              element.alarma = element.descripcion; // SE TRATA COMO DESCRIPCION POR AHORA LA ALARMA
            } else {
              element.alarma = this.translateService.instant("alarmaNoEncontrada");
            }
          } else {
            element.idAlarma = alarma_encontrada.idMaquinas_Alarmas;
            element.alarma = "";
          };
        }

        result.push(element);
      });

    return result;
  }
  public montar_historico_contador_test_esto_sera_directo_de_configuracion(mostrarContadorAccion, mostrarContadorRepartir) {
    this.historico_contador = this.montar_historico_contador(mostrarContadorAccion, mostrarContadorRepartir);
  }
  private montar_historico_contador(mostrarContadorAccion, mostrarContadorRepartir) {
    var historico_contador: any = this.myFunctions.copy(this.historico_contador);
    var historicoPorFecha;
    var fechaFiltrado = '2000/01/01';
    // SE ESTRUCTURA LA TABLA COMO NOS HACE FALTA PARA TRABAJAR MAS COMODOS
    //                     -->
    //                       -->
    //                         -->
    //                           -->
    //                         -->
    //                       -->
    //                     -->
    historico_contador = historico_contador.sort((s1, s2) => {
      if (s1.idMaquina > s2.idMaquina) return 1
      else if (s1.idMaquina < s2.idMaquina) return -1
      else if (new Date(s1.fecha).getTime() > new Date(s2.fecha).getTime()) return 1
      else return -1
    });
    var historico_completo_filtrado;
    var idMaquinaFiltrada = -1;
    (historico_contador as any).forEach(
      element => {
        
        element.fecha = new Date(element.fecha);

        if (element.idMaquina != idMaquinaFiltrada) {
          historico_completo_filtrado = (this.historico_completo as any).filter(f => f.idMaquina == element.idMaquina);
          idMaquinaFiltrada = element.idMaquina;
          // SI CAMBIA DE MAQUINA, ACTUALIZAMOS LAS FECHAS DIRECTAMENTE.
          historicoPorFecha = historico_completo_filtrado.filter(f => (this.myFunctions.dateAddDays(f.fechaIni, -1) <= element.fecha && this.myFunctions.dateAddDays(f.fechaFin, 1) > element.fecha));
          fechaFiltrado = this.myFunctions.dateToYYYYMMDD(element.fecha);
        }

        if (fechaFiltrado != this.myFunctions.dateToYYYYMMDD(element.fecha)) {
          historicoPorFecha = historico_completo_filtrado.filter(f => (this.myFunctions.dateAddDays(f.fechaIni, -1) <= element.fecha && this.myFunctions.dateAddDays(f.fechaFin, 1) > element.fecha));
          fechaFiltrado = this.myFunctions.dateToYYYYMMDD(element.fecha);
        }

        var historico_encontrado = historicoPorFecha.filter(f => (f.fechaIni <= element.fecha && f.fechaFin > element.fecha && f.idMaquina == element.idMaquina))[0];
        if (historico_encontrado == undefined) {
          element.fechaTurno = this.myFunctions.datetimeToDate(element.fecha);
          element.idTipoTurno = 0;
          element.idOperacion = -1;
          element.hmoar_tiempoEstimadoOperacion = 0;
          element.hmoar_tiempoOperacion = 0;
          element.hmoar_cantidad = 0;
          element.idEstado_pieza = 1;
          element.cantidadApartadas = 0;
          element.cantidadAchatarradas = 0;
          element.numeroOF = "";
        } else {
          element.fechaTurno = historico_encontrado.fechaTurno;
          element.idTipoTurno = historico_encontrado.idTipoTurno;
          if (historico_encontrado.operacion) {
            element.idOperacion = historico_encontrado.idOperacion + 0;
            element.hmoar_tiempoEstimadoOperacion = historico_encontrado.hmoar_tiempoEstimadoOperacion + 0;
            element.hmoar_tiempoOperacion = historico_encontrado.hmoar_tiempoOperacion + 0;
            element.hmoar_cantidad = historico_encontrado.hmoar_cantidad + 0;
            element.idEstado_pieza = historico_encontrado.idEstado_pieza;
            element.cantidadApartadas = historico_encontrado.cantidadApartadas;
            element.cantidadAchatarradas = historico_encontrado.cantidadAchatarradas;
            element.numeroOF = historico_encontrado.numeroOF;
          } else {
            // Si no se considera operacion, directamente le quitamos la referencia a la operacion
            element.idOperacion = -1;
            element.hmoar_tiempoEstimadoOperacion = 0;
            element.hmoar_tiempoOperacion = 0;
            element.hmoar_cantidad = 0;
            element.idEstado_pieza = 1;
            element.cantidadApartadas = 0;
            element.cantidadAchatarradas = 0;
            element.numeroOF = "";
          }
        }
      });


    //#region AUTOCORRECTIVO ----------------------------------------------------------------------------------------------------
    var fechaFin_dia = new Date(this.fechaFin.getTime() + (1000 * 60 * 60 * 24));
    var fechaIni = this.fechaIni;
    // Historico filtrado
    var historico_contador_filtrado: any = (historico_contador as any).filter(
      f => (((f.fecha >= fechaIni && f.fecha <= fechaFin_dia) &&
        (f.cantidadContador > 0 || f.cantidadAsignadaOperario > 0 || f.cantidadValidada > 0 || f.malas > 0)) ||
        f.fecha < fechaIni)
    );
    if (historico_contador_filtrado.length > 0) {
      // Se ordena 1 > 2 para poner el contador agrupado
      //                     -->
      //                       -->
      //                         -->
      //                           -->
      //                         -->
      //                       -->
      //                     -->
      historico_contador_filtrado = historico_contador_filtrado.sort((s1, s2) => {
        if (s1.fecha.getTime() > s2.fecha.getTime()) return 1
        else return -1
      });
      // CRITERIO DE AGRUPADO DE CONTADOR         
      var columnasAgrupado = ['idMaquina', 'fechaTurno', 'idTipoTurno', 'idOperacion']
      var columnasAgrupado_operacion = ['idMaquina', 'idOperacion'] // Se guardaran en el mismo array por no crear mucho codigo, pero deveria de funcionar independiente al otro
      var contador_agrupado = []; // 1 -> 2
      var contador_agrupado_confirmado = []; // 1 -> 2 
      historico_contador_filtrado.forEach(
        row => {
          row.piezas_confirmadas = 0;

          // STRING AGRUPADO
          var stringAgrupado = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
          columnasAgrupado.forEach(
            columna => {
              try {
                stringAgrupado += row[columna] + '_'
              } catch { }
            });

          // SE CREA EL ARRAY[AGRUPADO] SI NO EXISTE
          if (contador_agrupado[stringAgrupado] == undefined) {
            // mientras siga siendo por turno, no hara falta coger todos los previos!
            contador_agrupado[stringAgrupado] = 0;
            contador_agrupado_confirmado[stringAgrupado] = 0;
          }

          // STRING AGRUPADO
          var stringAgrupado_operacion = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
          columnasAgrupado_operacion.forEach(
            columna => {
              try {
                stringAgrupado_operacion += row[columna] + '_'
              } catch { }
            });
          // SE CREA EL ARRAY[AGRUPADO_operacion] SI NO EXISTE
          if (contador_agrupado[stringAgrupado_operacion] == undefined) {
            contador_agrupado[stringAgrupado_operacion] = 0;
            contador_agrupado_confirmado[stringAgrupado_operacion] = 0;
            // Como este no filtra turno, es necesario que en esta primera carga, se cojan todos los leidos previamente como base en vez de 0
            var cantidadesPrevias = this.myFunctions.copy(this.historico_contador_previo as any).filter(f => f.idOperacion == row.idOperacion && f.idMaquina == row.idMaquina)[0];
            if (cantidadesPrevias != undefined) {
              contador_agrupado[stringAgrupado_operacion] = cantidadesPrevias.cantidadContador;
              contador_agrupado_confirmado[stringAgrupado_operacion] = cantidadesPrevias.cantidadAsiganda;
            }
          }

          // SE ASIGNAN LOS VALORES CORRECTIVOS
          if (row.cantidadValidada > 0) {
            row.backgroundColor = '#83e37a';
            contador_agrupado_confirmado[stringAgrupado] += row.cantidadValidada;
            contador_agrupado[stringAgrupado] = contador_agrupado_confirmado[stringAgrupado];
            contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadValidada;
            contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
          } else if (row.cantidadAsignadaOperario > 0) {
            row.backgroundColor = '#9198f5';
            contador_agrupado_confirmado[stringAgrupado] += row.cantidadAsignadaOperario;
            contador_agrupado[stringAgrupado] = contador_agrupado_confirmado[stringAgrupado];
            contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadAsignadaOperario;
            contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
          } else if (row.cantidadContador > 0) {
            row.backgroundColor = '#343434';
            contador_agrupado[stringAgrupado] += row.cantidadContador;
            contador_agrupado[stringAgrupado_operacion] += row.cantidadContador;
          }
          row.piezas_confirmadas = contador_agrupado_confirmado[stringAgrupado];
          row.piezas_previas = contador_agrupado[stringAgrupado];
          row.piezas_previasOperacion = contador_agrupado_confirmado[stringAgrupado_operacion]; // Cantidad anterior de la operacion
          row.piezasOperacion = contador_agrupado[stringAgrupado_operacion];

        });
      // Se ordena 1 < 2 para poner el contador agrupado proximo
      //                     <--
      //                   <--
      //                 <--
      //               <--
      //                 <--
      //                   <--
      //                     <--
      var contador_agrupado_confirmado_proximo = []; // 1 <- 2 
      var contador_agrupado_proxima = []; // 1 <- 2 
      historico_contador_filtrado = historico_contador_filtrado.sort((s1, s2) => {
        if (s1.fecha.getTime() < s2.fecha.getTime()) return 1
        else return -1
      });
      // valores posteriores
      historico_contador_filtrado.forEach(
        row => {
          // STRING AGRUPADO     
          var stringAgrupado_operacion = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
          columnasAgrupado_operacion.forEach(
            columna => {
              try {
                stringAgrupado_operacion += row[columna] + '_'
              } catch { }
            });
          // Si no existe se crea la primera linea y se coge piezas por si aun no se ha asignado nada despues a mano.
          if (contador_agrupado_confirmado_proximo[stringAgrupado_operacion] == undefined) {
            contador_agrupado_confirmado_proximo[stringAgrupado_operacion] = row.piezas;
          }

          // Si son iguales es que se acaba de actualizar a las manuales o validadas
          if (row.piezas_previas == row.piezas_confirmadas) {
            contador_agrupado_confirmado_proximo[stringAgrupado_operacion] = row.piezas_confirmadas;
            contador_agrupado_proxima[stringAgrupado_operacion] = 0; // como se suma antes de la asignacion, contamos que la manual es el -1
          }

          row.proximaCantidadConfirmada = contador_agrupado_confirmado_proximo[stringAgrupado_operacion];
          row.piezasProximo = contador_agrupado_proxima[stringAgrupado_operacion];
          if (row.piezasProximo < 0) row.piezasProximo = 0; // aunque se ponga -1, se cuenta como 0...

          if (row.piezas_previas != row.piezas_confirmadas) contador_agrupado_proxima[stringAgrupado_operacion] += row.cantidadContador;

        });

      // RETOQUES SEGUN CONFIGURACION
      //                     -->
      //                       -->
      //                         -->
      //                           -->
      //                         -->
      //                       -->
      //                     -->      
      contador_agrupado = []; // 1 -> 2
      contador_agrupado_confirmado = []; // 1 -> 2 
      historico_contador_filtrado = historico_contador_filtrado.sort((s1, s2) => {
        if (s1.fecha.getTime() > s2.fecha.getTime()) return 1
        else return -1
      });
      historico_contador_filtrado.forEach(
        row => {
          row.pieza = 0;
          if (mostrarContadorAccion == 1) row.pieza = row.piezas_previas;
          if (mostrarContadorAccion == 2) row.pieza = row.proximaCantidadConfirmada - row.piezasProximo;

          // COLOR DEL GANTT
          row.backgroundColor = '#343434'; // NEGRO
          if (row.idOperacion < 0) row.backgroundColor = '#999090'; //GRIS
          else if (row.cantidadValidada > 0) row.backgroundColor = '#83e37a'; // VERDE
          else if (row.cantidadAsignadaOperario > 0) row.backgroundColor = '#9198f5'; // AZUL
          else if (row.cantidadContador > 0) {
            if (mostrarContadorAccion == 1 &&
              ((row.proximaCantidadConfirmada < row.pieza && row.proximaCantidadConfirmada > row.piezas_confirmadas) ||
                row.piezasOperacion > row.proximaCantidadConfirmada + row.piezas_previasOperacion
              )) {
              row.backgroundColor = '#FA9C1B'; // NARANJA
              row.pieza = 0;
            }
            else if (mostrarContadorAccion == 2 &&
              (row.piezasProximo >= row.proximaCantidadConfirmada || row.proximaCantidadConfirmada >= row.piezas_confirmadas) &&
              row.piezasProximo + row.cantidadContador > row.proximaCantidadConfirmada - row.piezas_confirmadas
            ) {
              row.backgroundColor = '#FA9C1B'; // NARANJA
              row.pieza = 0;
            }
          }

          (row.piezas_previas - row.piezas_confirmadas + row.piezasProximo > row.proximaCantidadConfirmada)

          // STRING AGRUPADO     
          var stringAgrupado_operacion = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
          columnasAgrupado_operacion.forEach(
            columna => {
              try {
                stringAgrupado_operacion += row[columna] + '_'
              } catch { }
            });

          var piezasHastaAhora = contador_agrupado[stringAgrupado_operacion];
          if (piezasHastaAhora == undefined) piezasHastaAhora = 0;
          // SE CREA EL ARRAY[AGRUPADO_operacion] SI NO EXISTE
          if (contador_agrupado[stringAgrupado_operacion] == undefined) {
            contador_agrupado[stringAgrupado_operacion] = 0;
            contador_agrupado_confirmado[stringAgrupado_operacion] = 0;
          }
          // SE ASIGNAN LOS VALORES CORRECTIVOS
          if (row.cantidadValidada > 0) {
            contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadValidada;
            contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
          } else if (row.cantidadAsignadaOperario > 0) {
            contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadAsignadaOperario;
            contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
          } else if (row.cantidadContador > 0 && row.pieza > 0) {
            contador_agrupado[stringAgrupado_operacion] += row.cantidadContador;
          } else if (row.piezas_previas > row.proximaCantidadConfirmada && row.piezas_previas - row.cantidadContador < row.proximaCantidadConfirmada) { // se han contado algunas bien y otras ya pasadas las proximas confirmadas
            contador_agrupado[stringAgrupado_operacion] += row.cantidadContador - (row.piezas_previas - row.proximaCantidadConfirmada)
          }

          if (mostrarContadorAccion == 2) { // La cantidad de la operacion ya viene cargada de antes en el orden correcto. Pero al alinear las piezas al rever, esta cantidad puede cambiar
            row.piezasOperacion = contador_agrupado[stringAgrupado_operacion];
          }

          // Numero a mostrar en el GANTT
          row.piezasAmostrarGANTT = '';
          row.piezasContadasCorrectas = 0;
          if (mostrarContadorAccion == 1) {
            if (row.piezas_previas > row.proximaCantidadConfirmada) { // Si se ha pasado de la cantidad, se muestra la cantidad previa (la que se tenia antes de la accion
              if (row.piezas_previas - row.cantidadContador < row.proximaCantidadConfirmada) {
                row.piezasAmostrarGANTT = row.proximaCantidadConfirmada;
              }
              else if (!((row.proximaCantidadConfirmada >= row.pieza && row.proximaCantidadConfirmada >= row.piezas_confirmadas)) ||
                (row.piezasOperacion > row.proximaCantidadConfirmada + row.piezas_previasOperacion && row.proximaCantidadConfirmada <= row.piezas_confirmadas)
              ) {
                row.piezasAmostrarGANTT = row.piezas_previas;
              }
            } else {
              if (row.piezasOperacion <= row.proximaCantidadConfirmada + row.piezas_previasOperacion || row.proximaCantidadConfirmada == undefined)
                row.piezasAmostrarGANTT = row.piezas_previas;
            }
          }
          if (mostrarContadorAccion == 2) {
            if (row.proximaCantidadConfirmada == undefined) {
              row.piezasAmostrarGANTT = row.piezas_previas;
            } else if (row.piezasProximo < row.proximaCantidadConfirmada && row.proximaCantidadConfirmada <= row.piezas_confirmadas) {
              row.piezasAmostrarGANTT = row.piezas_confirmadas + row.piezasOperacion - row.piezas_previasOperacion;
            } else {
              if (row.piezas_confirmadas + row.cantidadContador > row.proximaCantidadConfirmada - row.piezasProximo) {
                if (row.piezas_confirmadas < row.proximaCantidadConfirmada - row.piezasProximo) {
                  row.piezasAmostrarGANTT = row.proximaCantidadConfirmada - row.piezasProximo;
                }
              } else {
                row.piezasAmostrarGANTT = row.proximaCantidadConfirmada - row.piezasProximo;
              }
            }
          }

          // Para facilitar el calculo del grafico por dias, vamos a poner la cantidad de piezas buenas y malas en el contador directamente
          // If manual
          if (row.backgroundColor == '#999090') { //GRIS
            row.piezasBuenasAutocorregido = 0;
            row.piezasMalasAutocorregido = row.malas;
            row.piezasAmostrarGANTT = '';
          } else if (row.backgroundColor == '#FA9C1B') {// NARANJA
            if (row.piezasAmostrarGANTT != '') {
              if (mostrarContadorAccion == 1) row.piezasBuenasAutocorregido = row.cantidadContador - (row.piezas_previas - row.proximaCantidadConfirmada); // piezas contadas automaticas - piezas que se han pasado del proximo contador manual
              if (mostrarContadorAccion == 2) row.piezasBuenasAutocorregido = row.cantidadContador - (row.piezas_previas - row.proximaCantidadConfirmada) - row.piezasProximo; // piezas contadas automaticas - piezas que se han pasado del proximo contador manual - PIEZAS CONTADAS HASTA EL PROXIMO CONTADOR
            } else {
              row.piezasBuenasAutocorregido = 0; // piezas contadas automaticas - piezas que se han pasado del proximo contador manual
            }
            row.piezasMalasAutocorregido = row.malas;
          } else {// CORRECT
            if (row.cantidadValidada > 0 || row.cantidadAsignadaOperario > 0) {
              row.piezasBuenasAutocorregido = contador_agrupado[stringAgrupado_operacion] - piezasHastaAhora; //row.cantidadValidada; Si esta autocorregido, no se cuentan todas, solo si son mas de las que se han contado automaticas
            } else if (row.cantidadContador > 0) {
              row.piezasBuenasAutocorregido = row.cantidadContador;
            }
            row.piezasMalasAutocorregido = row.malas;
          }

          if (row.piezasBuenasAutocorregido < 0 || row.piezasBuenasAutocorregido == null) row.piezasBuenasAutocorregido = 0;// Autocorregimos si el contador baja de 0 

          if(row.malas > 0) 
            var a = 1;
        });
    }
    //#endregion ----------------------------------------------------------------------------------------------------

    return historico_contador;
  }
  //#endregion
  //#region GET DE VARIABLES GENERALES CRUDAS PARA SU USO EN DIFERENTES INFORMES ----------------------------------------------------------------------------------------------------
  public util(fechaIni, fechaFin) {
    // esta funcion nos dira si los datos estan cargados o si los datos cargados aun son utiles.
    this.check_fecha_util(fechaIni, fechaFin);
    return this.historico_maquinas_resumenes != undefined;
  }
  private check_fecha_util(fechaIni, fechaFin) {
    if (this.fechaUpdate != undefined && (new Date().getTime() - this.fechaUpdate?.getTime()) / 1000 > this.segundosUtiles
      || fechaIni.getTime() < this.fechaIni?.getTime()
      || fechaFin.getTime() > this.fechaFin?.getTime()) {
      console.log("Historico no valido -> ")
      if (this.fechaUpdate == undefined)
        console.log('Fecha undeffined: ' + this.fechaUpdate)
      if ((new Date().getTime() - this.fechaUpdate?.getTime()) / 1000 > this.segundosUtiles)
        console.log('Datos obsoletos: ' + (new Date().getTime() - this.fechaUpdate?.getTime()) / 1000 + ' > ' + this.segundosUtiles)
      if (fechaIni.getTime() < this.fechaIni?.getTime())
        console.log('Fecha inicio menor: ' + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(this.myFunctions.dateCopy(fechaIni)) + ' < ' + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(this.myFunctions.dateCopy(this.fechaIni)))
      if (fechaFin.getTime() > this.fechaFin?.getTime())
        console.log('Fecha fin mayor: ' + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(this.myFunctions.dateCopy(fechaFin)) + ' > ' + this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(this.myFunctions.dateCopy(this.fechaFin)))
      this.historico_maquinas_resumenes = undefined;
      this.historico_maquinas_operaciones_agrupadas_resumen = undefined;
      this.historico_contador = undefined;
      this.info_alarmas = undefined;
      this.info_perdidas = undefined;
      this.info_mantenimientos = undefined;
      this.info_maquinas = undefined;
      this.info_operaciones = undefined;
      this.info_ofs = undefined;
      this.info_subestados = undefined;
      this.info_configuracion = {
        ejecucionSinOperacionRepercuteA: 0,
        porcentajeEjecucionSinOperacionRepercuteOEE: 100,
        tiempoEstimado_porcentajeMinimoTiempoAtenerEnCuenta: 0,
        usaLotes: false,
        verNSerie: false,
        verColada: false,
        verLote: false
      };
      this.info_turnos = undefined;
    }
  }
  private get_historico_completo() {
    var an: any = this.historico_completo
    return an;
  }
  public get_info_subestados(idMaquinas) {
    // los subestados no se limitan a fchas  // this.check_fecha_util();
    return this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado >= 100));
  }
  public get_info_estados(idMaquinas) {
    // los subestados no se limitan a fchas  // this.check_fecha_util();
    return this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado < 100));
  }
  public get_usaLotes(idMaquinas) {
    return this.info_configuracion.usaLotes;
  }
  //#endregion ----------------------------------------------------------------------------------------------------
  //#region GET DATOS HECHOS ----------------------------------------------------------------------------------------------------

  //#region COMPLETO FILTRADO
  public get_historicoMauqinas_filtrado(filtro) {// ALGUNA PAGINA VA MUY LENTA POQUE SE FILTRA 7 VECES EL HISTORICO COMPLETO, DE ESTA FORMA SE EVITA TENER QUE FILTRARLO CADA VEZ
    var a = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return a;
  }
  public filtrar_historico_completo_filtrado_OEE(historico_completo_filtrado, idTipoOEE) {// ALGUNA PAGINA VA MUY LENTA POQUE SE FILTRA 7 VECES EL HISTORICO COMPLETO, DE ESTA FORMA SE EVITA TENER QUE FILTRARLO CADA VEZ
    var a: any = this.myFunctions.copy((historico_completo_filtrado as any)).filter(f => (f.idTipoOEE == idTipoOEE || //Las perdidas que buscamos
      (idTipoOEE == 3 && //Si se buscan perdidas de calidad
        ((f.hmoar_cantidadAchatarrada + f.hmoar_cantidadApartada) > 0 || // Lotes con alguna pieza apartada o achatarrada 
          f.idEstado_pieza == 4 || // Pieza apartada 
          f.idEstado_pieza == 5 // Pieza achatarrada
        )
      )));
    return a;
  }

  public filtrar_historico_completo_filtrado_solo(historico_completo_filtrado, idMayor0) {// ALGUNA PAGINA VA MUY LENTA POQUE SE FILTRA 7 VECES EL HISTORICO COMPLETO, DE ESTA FORMA SE EVITA TENER QUE FILTRARLO CADA VEZ
    var a: any = this.myFunctions.copy((historico_completo_filtrado as any)).filter(f => (f[idMayor0] > 0));
    return a;
  }
  //CONTADOR

  public get_historico_contador_filtrado(filtro) {// ALGUNA PAGINA VA MUY LENTA POQUE SE FILTRA 7 VECES EL HISTORICO COMPLETO, DE ESTA FORMA SE EVITA TENER QUE FILTRARLO CADA VEZ
    var a = filterBy(this.myFunctions.copy((this.historico_contador as any)), filtro);
    return a;
  }
  //#endregion
  //#region GRIDS  
  public get_grid_operaciones(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.get_grid_operaciones_prefiltrado(historico_completo_filtrado);
  }
  public get_grid_operaciones_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["idOF", "idPieza", "idParte", "idRuta", "idOperacion"], 1, 0, false);
  }
  public get_grid_nSerie_prefiltrado(historico_completo_filtrado, tipoPerdida) {
    return this.crear_agrupado(historico_completo_filtrado, ["idOF", "idPieza", "idParte", "idRuta", "idOperacion", "nSerie", "colada", "lote"], 1, tipoPerdida, false);
  }

  public get_grid_maquinas(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.get_grid_maquinas_prefiltrado(historico_completo_filtrado);
  }
  public get_grid_maquinas_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["idMaquina"], 1, 0, false);
  }

  public get_grid_Operarios(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.get_grid_Operarios_prefiltrado(historico_completo_filtrado);
  }
  public get_grid_Operarios_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["idOperario"], 1, 0, false);
  }

  public get_grid_piezas(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.get_grid_piezas_prefiltrado(historico_completo_filtrado);
  }
  public get_grid_piezas_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["idPieza"], 1, 0, false);
  }
  public get_grid_HPiezas_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["idHistorico_piezas"], 1, 0, false);
  }

  public get_grid_clientes(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.get_grid_clientes_prefiltrado(historico_completo_filtrado);
  }
  public get_grid_clientes_prefiltrado(historico_completo_filtrado) {
    return this.crear_agrupado(historico_completo_filtrado, ["cliente", "idCliente"], 1, 0, false);
  }

  public get_grid_compacto_perdidas_prefiltrado(historico_completo_filtrado, idTipoOEE) {
    /* COMPACTA LAS FECHAINI Y FECHAFIN segun columnas */
    return this.crear_compacto(historico_completo_filtrado, ["perdida", "subPerdida"], idTipoOEE);
  }

  //#endregion
  //#region INFO EN GENERAL
  public get_info(filtro) {// POR ahora solo analitica avanzada, pero cualquier info general se deveria de meter aqui
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.calcular_resumen_info(historico_completo_filtrado);
  }
  public get_info_prefiltrado(historico_completo_filtrado) {// POR ahora solo analitica avanzada, pero cualquier info general se deveria de meter aqui
    return this.calcular_resumen_info(historico_completo_filtrado);
  }
  //#endregion
  //#region GANTT
  public load_historico_completo_GANTT(idHTML, idMaquina, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    /*AQUI SE SIGUE PASANDO MAQUINA Y FECHAS PARA HACER EL FILTRO DE LOS TURNOS! El historico ya se pasa filtrado */
    return new GanttChart_estados(idHTML
      , this.get_historico_completo_GANTT(idMaquina, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado)
      , this.translateService)
      .on('task_clicked', (id, data) => {
        //this.ganttClicked(id);
      });
  }
  public load_historico_completo_GANTT_vinculados(idHTML, idMaquina, fechaIni, fechaFin, historico_completo_filtrado, Ganttvinculados) {
    /*AQUI SE SIGUE PASANDO MAQUINA Y FECHAS PARA HACER EL FILTRO DE LOS TURNOS! El historico ya se pasa filtrado */
    var gafico = this.get_historico_completo_GANTT(idMaquina, fechaIni, fechaFin, [], historico_completo_filtrado)
    return new GanttChart_estados(idHTML
      , gafico
      , this.translateService
      , 50 + ((64 - Math.min((4 * Object.keys(gafico.data.machines).length), 29)) * Object.keys(gafico.data.machines).length)
      , Ganttvinculados);
  }
  private get_historico_completo_GANTT(idMaquina, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    //#region FRANJAS DE TURNOS ----------------------------------------------------------------------------------------------------
    var turnosArray = [];
    var fechaMin = this.myFunctions.copy(fechaIni);
    var fechaMax = this.myFunctions.copy(fechaFin);
    fechaMax.setDate(fechaMax.getDate() + 1);
    var fechaMin_filtro = this.myFunctions.copy(fechaIni);
    fechaMin_filtro.setDate(fechaMin_filtro.getDate() - 1);
    var fechaMax_filtro = this.myFunctions.copy(fechaFin);
    fechaMax_filtro.setDate(fechaMax_filtro.getDate() + 1);
    // AQUI SE COGE UN DIA ANTES PARA PODER PONER EL LIMITE INFERIOR DEL GANT CORRECTAMENTE A 0:00 SI NO HABIA TURNO ANTERIOR O A LA HORA NECESARIA SI HABIA TURNO.
    var an: any = this.myFunctions.copy((this.info_turnos as any)).filter(f => (f.fechaTurno >= fechaMin_filtro && f.fechaTurno <= fechaMax_filtro) && (idMaquina == f.idMaquina));
    an.forEach(f => {
      // if (f.fechaTurno.getTime() >= fechaIni.getTime() && f.fechaTurno.getTime() <= fechaFin.getTime()) {
      if (f.fechaTurno >= fechaIni && f.fechaTurno <= fechaFin) {
        turnosArray.push({ min: f.fechaIni, max: f.fechaFin, clase: f.clase });
        if (f.fechaIni < fechaMin)
          fechaMin = f.fechaIni;
        if (f.fechaFin > fechaMax)
          fechaMax = f.fechaFin;
      }
      else if (f.fechaTurno < fechaIni && f.fechaFin > fechaMin)/* si es un turno de el dia anterior mayor a las 12 se sube la fecha min, sino, se mantinee las 12*/
        fechaMin = f.fechaFin;
      else if (f.fechaTurno > fechaFin && f.fechaIni < fechaMax)
        fechaMax = f.fechaIni;
    });
    //#endregion  ----------------------------------------------------------------------------------------------------

    var conSubestado = false; // como si tiene lineas simultaneas tambien se duplican lineas, esto no se usa pero se ha dejado por si hace falta.
    //#region "MAQUINAS" (rows de estados y subestados principales en este caso) ----------------------------------------------------------------------------------------------------

    var machines = [];
    var orden = 1; // para que el gantt se mantenga en orden despues de agrupar los subestados
    (this.info_subestados as any).forEach(
      (subestado) => {
        if (subestado.idMaquina == idMaquina) {
          if (subestado.idMaquinas_subEstados_padre < 0) {
            // id: subestado.idProcesos_Tipo_subEstados,     
            var machine =
            {
              id: orden,
              name: subestado.nombre,
              image: '',
              multiRow: 1
            }
            orden += 1;
            machines.push(machine);
          }
          if (subestado.id >= 100) conSubestado = true;
        }
      });
    //#endregion ----------------------------------------------------------------------------------------------------

    //#region CREAR TODAS LAS TAREAS QUE HAY QUE DUPLICAR (SUB ESTADOS o NO) ----------------------------------------------------------------------------------------------------
    var tasks = [];
    historico_completo_filtrado.forEach(
      row => {

        // SE GENERA EL APPOINTMENT NECESARIO 
        var row_GANTT: any = {}
        row_GANTT.machine = row.machine_subestado;
        row_GANTT.backgroundColor = row.color_subestado;
        row_GANTT.of = row.numeroOF;
        row_GANTT.idOperacion = row.idOperacion; // ahroa se agrupa segun idOperacion

        // PARA MONTAR EL GANTT
        row_GANTT.multiRow = 0;
        row_GANTT.rowResumen = false;
        row_GANTT.fechaTurno = row.fechaTurno;
        row_GANTT.idTipoTurno = row.idTipoTurno;
        row_GANTT.subestado = row.subestado;
        row_GANTT.fechaIni = row.fechaIni;
        row_GANTT.fechaFin = row.fechaFin;
        row_GANTT.nombrePrograma = row.nombrePrograma;
        row_GANTT.descripcion = row.descripcion;
        row_GANTT.alarma = row.alarma;
        row_GANTT.idEstado = row.idEstado;
        row_GANTT.idSubEstado = row.idSubEstado;
        row_GANTT.cliente = row.cliente;
        row_GANTT.referenciaPieza = row.referenciaPieza;
        row_GANTT.nombrePieza = row.nombrePieza;
        row_GANTT.cantidad = row.cantidad;

        row_GANTT.cantidadContador = row.cantidadContador;
        row_GANTT.cantidadAsignadaOperario = row.cantidadAsignadaOperario;
        row_GANTT.cantidadValidada = row.cantidadValidada;

        row_GANTT.hmoar_cantidadContador = row.hmoar_cantidadContador;
        row_GANTT.hmoar_cantidadAsignadaOperario = row.hmoar_cantidadAsignadaOperario;
        row_GANTT.hmoar_cantidadValidada = row.hmoar_cantidadValidada;

        row_GANTT.lote = row.lote;
        row_GANTT.colada = row.colada;
        row_GANTT.nserie = row.nserie;
        row_GANTT.mantenimiento = row.mantenimiento;
        row_GANTT.observacionMantenimiento = row.observacionMantenimiento;
        row_GANTT.perdida = row.perdida;
        row_GANTT.duracion = row.duracion;
        row_GANTT.nombreOperacion = [row.nombreOperacion];
        row_GANTT.operario = [row.operario];
        row_GANTT.backgroundColor_estado = row.color_estado; // Para agrupar los estados en una sola linea en caso de ser con simultaneas
        row_GANTT.estado = row.estado; // Para agrupar los estados en una sola linea en caso de ser con simultaneas
        row_GANTT.observacionHistoricoPerdida = row.observacionHistoricoPerdida; // Para agrupar los estados en una sola linea en caso de ser con simultaneas

        tasks.push(row_GANTT);
        // SE GENERA EL APPOINTMENT NECESARIO SI ESTE TIENE UN ESTADO Y NO ESTA AGRUPADO EN LA MISMA LINEA
        if (row.machine_subestado != row.machine_estado) {
          var row_GANTT_estado: any = this.myFunctions.copy(row_GANTT);
          row_GANTT_estado.machine = row.machine_estado;
          row_GANTT_estado.backgroundColor = row.color_estado;
          tasks.push(row_GANTT_estado);
        }
      });

    //#endregion ----------------------------------------------------------------------------------------------------

    // true, true => OK
    // false, false => OK
    // true, false => OK
    // false, true => ... falla
    var groupByOperario = true;
    var groupByOperacion = false;
    var con_simultaneas = (historico_completo_filtrado as any).filter(f => (f.lineasHistorico_maquinas_resumenesSimultaneas > 1)).length > 0;
    //#region AGRUPAMOS LAS TASKS IGUALES! ----------------------------------------------------------------------------------------------------
    var groupedTasks = []
    if (con_simultaneas) {
      //#region ORDENAMOS LAS TASKS ----------------------------------------------------------------------------------------------------
      // Segun como se agrupe, lo ordenaremos de una forma u otra
      tasks.sort((s1, s2) => {
        if (s1.machine > s2.machine) return 1
        else if (s1.machine < s2.machine) return -1
        else if (s1.fechaIni > s2.fechaIni) return 1
        else if (s1.fechaIni < s2.fechaIni) return -1
        else if (s1.nombreOperacion > s2.nombreOperacion) return 1
        else if (s1.nombreOperacion < s2.nombreOperacion) return -1
        else if (s1.operario > s2.operario) return 1
        else if (s1.operario < s2.operario) return -1
        else return 0
      });
      //#endregion ----------------------------------------------------------------------------------------------------

      var lastTask_group;
      tasks.forEach(
        task => {
          if (task.perdida != "")
            var a = 1
          if (lastTask_group == undefined) {
            lastTask_group = this.myFunctions.copy(task);
          } else {
            if (lastTask_group.machine != task.machine ||
              lastTask_group.idSubEstado != task.idSubEstado ||
              lastTask_group.perdida != task.perdida ||
              this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(lastTask_group.fechaIni) != this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(task.fechaIni) ||
              this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(lastTask_group.fechaFin) != this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(task.fechaFin) ||
              (!groupByOperario && lastTask_group.operario[0] != task.operario[0]) ||
              (!groupByOperacion && lastTask_group.nombreOperacion[0] != task.nombreOperacion[0])) {
              // si no se cumple algunas de las condiciones anteriores se crea una nueva linea.
              groupedTasks.push(this.myFunctions.copy(lastTask_group));
              lastTask_group = this.myFunctions.copy(task);
            } else {

              lastTask_group.fechaFin = task.fechaFin;

              if (!lastTask_group.operario.includes(task.operario[0])) lastTask_group.operario.push(task.operario[0]) // si el operario no se a añadido ya, se añade
              if (!lastTask_group.nombreOperacion.includes(task.nombreOperacion[0])) lastTask_group.nombreOperacion.push(task.nombreOperacion[0]) // si la operacion no se a añadido ya, se añade
            }
          }
        });
      if (lastTask_group != undefined) {
        groupedTasks.push(this.myFunctions.copy(lastTask_group));
      }
    } else {
      groupedTasks = tasks;
    }
    //#endregion ----------------------------------------------------------------------------------------------------

    //#region FUSIONAMOS LAS TASKS IGUALES SEGUIDAS! ----------------------------------------------------------------------------------------------------
    //#region ORDENAMOS LAS TASKS ----------------------------------------------------------------------------------------------------
    // Segun como se agrupe, lo ordenaremos de una forma u otra
    groupedTasks.sort((s1, s2) => {
      if (s1.idSubEstado > s2.idSubEstado) return 1
      else if (s1.idSubEstado < s2.idSubEstado) return -1
      else if (s1.machine > s2.machine) return 1
      else if (s1.machine < s2.machine) return -1
      else if (groupByOperacion && groupByOperario && s1.fechaIni > s2.fechaIni) return 1
      else if (groupByOperacion && groupByOperario && s1.fechaIni < s2.fechaIni) return -1
      else if (groupByOperacion && s1.operario > s2.operario) return 1
      else if (groupByOperacion && s1.operario < s2.operario) return -1
      else if (groupByOperario && s1.nombreOperacion > s2.nombreOperacion) return 1
      else if (groupByOperario && s1.nombreOperacion < s2.nombreOperacion) return -1
      else if (s1.fechaIni > s2.fechaIni) return 1
      else if (s1.fechaIni < s2.fechaIni) return -1
      else return 0
    });
    //#endregion ----------------------------------------------------------------------------------------------------
    var mergedTasks = []
    var lastTask_merge;
    groupedTasks.forEach(
      task => {
        if (lastTask_merge == undefined) {
          lastTask_merge = this.myFunctions.copy(task);
        } else {
          if (lastTask_merge.machine != task.machine ||
            lastTask_merge.color != task.color || //lastTask_merge.idSubEstado != task.idSubEstado ||
            lastTask_merge.perdida != task.perdida ||
            this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(lastTask_merge.fechaFin) != this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(task.fechaIni) ||
            lastTask_merge.idTipoTurno != task.idTipoTurno ||
            this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(lastTask_merge.fechaTurno) != this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(task.fechaTurno) ||
            lastTask_merge.operario.toString() != task.operario.toString() ||
            lastTask_merge.nombreOperacion.toString() != task.nombreOperacion.toString() ||
            lastTask_merge.colada != task.colada ||
            lastTask_merge.lote != task.lote ||
            lastTask_merge.nserie != task.nserie ||
            lastTask_merge.nombrePrograma != task.nombrePrograma) {
            // si no se cumple algunas de las condiciones anteriores se crea una nueva linea.
            mergedTasks.push(this.myFunctions.copy(lastTask_merge));
            lastTask_merge = this.myFunctions.copy(task);
          } else {

            lastTask_merge.fechaFin = task.fechaFin;

            lastTask_merge.cantidadContador += task.cantidadContador;
            lastTask_merge.cantidadAsignadaOperario += task.cantidadAsignadaOperario;
            lastTask_merge.cantidadValidada += task.cantidadValidada;

            if (!lastTask_merge.operario.includes(task.operario[0])) lastTask_merge.operario.push(task.operario[0]) // si el operario no se a añadido ya, se añade
            if (!lastTask_merge.nombreOperacion.includes(task.nombreOperacion[0])) lastTask_merge.nombreOperacion.push(task.nombreOperacion[0]) // si la operacion no se a añadido ya, se añade
          }
        }
      });
    if (lastTask_merge != undefined) {
      mergedTasks.push(this.myFunctions.copy(lastTask_merge));
    }
    //#endregion ----------------------------------------------------------------------------------------------------

    if (con_simultaneas) {
      //#region CALCULAMOS LINEA DE ESTADOS PRINCIPAL ----------------------------------------------------------------------------------------------------
      //#region ORDENAMOS LAS TASKS ----------------------------------------------------------------------------------------------------
      // Segun como se agrupe, lo ordenaremos de una forma u otra
      mergedTasks.sort((s1, s2) => {
        if (s1.fechaIni > s2.fechaIni) return 1
        else if (s1.fechaIni < s2.fechaIni) return -1
        else if (s1.idSubEstado < s2.idSubEstado) return 1
        else if (s1.idSubEstado > s2.idSubEstado) return -1
        else return 0
      });
      //#endregion ----------------------------------------------------------------------------------------------------

      var task_estado_principal;
      var mergedTasks_estados_principal = [];
      mergedTasks.forEach(
        task => {
          var a = task;
          if (task_estado_principal == undefined) {
            task_estado_principal = this.myFunctions.copy(task);
            task_estado_principal.machine = 0;
            task_estado_principal.rowResumen = true;
          } else {
            if (task_estado_principal.idEstado != task.idEstado) {
              mergedTasks_estados_principal.push(this.myFunctions.copy(task_estado_principal));
              task_estado_principal = this.myFunctions.copy(task);
              task_estado_principal.machine = 0;
              task_estado_principal.rowResumen = true;
            }
          }
          task_estado_principal.fechaFin = task.fechaFin;
          mergedTasks_estados_principal.push(task); // añadimos todas las lineas tal cual
        });
      if (task_estado_principal != undefined) {
        mergedTasks_estados_principal.push(this.myFunctions.copy(task_estado_principal));
      }
      mergedTasks = mergedTasks_estados_principal;
      //#endregion ----------------------------------------------------------------------------------------------------
    }

    //#region CALCULAMOS LOS SOLAPES ----------------------------------------------------------------------------------------------------
    if (con_simultaneas) {
      //#region ORDENAMOS LAS TASKS ----------------------------------------------------------------------------------------------------
      // Segun como se agrupe, lo ordenaremos de una forma u otra
      mergedTasks.sort((s1, s2) => {
        if (s1.machine > s2.machine) return 1
        else if (s1.machine < s2.machine) return -1
        else if (groupByOperacion && groupByOperario && s1.fechaIni > s2.fechaIni) return 1
        else if (groupByOperacion && groupByOperario && s1.fechaIni < s2.fechaIni) return -1
        else if (groupByOperacion && s1.operario.toString() > s2.operario.toString()) return 1
        else if (groupByOperacion && s1.operario.toString() < s2.operario.toString()) return -1
        else if (groupByOperario && s1.nombreOperacion.toString() > s2.nombreOperacion.toString()) return 1
        else if (groupByOperario && s1.nombreOperacion.toString() < s2.nombreOperacion.toString()) return -1
        else if (s1.fechaIni > s2.fechaIni) return 1
        else if (s1.fechaIni < s2.fechaIni) return -1
        else return 0
      });
      //#endregion ----------------------------------------------------------------------------------------------------

      mergedTasks.forEach(
        task => {
          var tasksSimultaneas = mergedTasks.filter(f => f.fechaFin > task.fechaIni && f.fechaIni < task.fechaFin && f.machine == task.machine && f.multiRow > 0);
          tasksSimultaneas.sort((s1, s2) => {
            if (s1.multiRow > s2.multiRow) return 1
            else if (s1.multiRow < s2.multiRow) return -1
            else return 0
          });

          var multirow_previa_machine = 0;
          var primera_multirow_simultanea_libre = 0

          var tasks_previas_machine = mergedTasks.filter(f => f.fechaFin <= task.fechaIni && f.machine == task.machine &&
            (groupByOperario || f.operario.toString() == task.operario.toString()) &&
            (groupByOperacion || f.nombreOperacion.toString() == task.nombreOperacion.toString()));
          if (tasks_previas_machine.length > 0) multirow_previa_machine = tasks_previas_machine[tasks_previas_machine.length - 1].multiRow;
          if (multirow_previa_machine == 0) multirow_previa_machine = 1;

          var i = 0;
          var machines_ocupadas = [];
          for (i = 0; i < tasksSimultaneas.length; i++) {
            if (!machines_ocupadas.includes(tasksSimultaneas[i].multiRow))
              machines_ocupadas.push(tasksSimultaneas[i].multiRow);
          }
          if (!machines_ocupadas.includes(multirow_previa_machine)) {
            task.multiRow = multirow_previa_machine;
          } else {
            i = 0;
            for (i = 0; i < machines_ocupadas.length; i++) {
              if (!machines_ocupadas.includes(i + 1) && primera_multirow_simultanea_libre == 0) primera_multirow_simultanea_libre = i + 1;
            }
            if (primera_multirow_simultanea_libre == 0) primera_multirow_simultanea_libre = i + 1;
            task.multiRow = primera_multirow_simultanea_libre;
          }

          if (!task.rowResumen) // 0 es la linea de resumen de estado al tener lineas simultaneas
            machines[task.machine - 1].multiRow = Math.max(machines[task.machine - 1].multiRow, task.multiRow);
        });
    }
    //#endregion ----------------------------------------------------------------------------------------------------

    // SE GENERA LA ESTRUCTURA BASE
    let data = {
      machines: [],
      tasks: []
    };
    //#region SE CONVIERTEN LOS DATOS QUE TENEMOS EN MULTIROWS Y DAMOS FORMA DE TASKS ----------------------------------------------------------------------------------------------------
    var orden = 1;
    var nuevoOrden_minimo = 1;
    if (con_simultaneas) {
      data.machines.push({ id: 1, name: this.translateService.instant("resumen"), image: '' })
      orden++;
    }

    machines.forEach(
      estado => {
        data.machines.push({ id: orden, name: estado.name, image: '' })
        orden++;
        for (let i = 2; i <= estado.multiRow; i++) {
          data.machines.push({ id: orden, name: '', image: '' })
          orden++;
        }
        estado.nuevoOrden_minimo = nuevoOrden_minimo + 0;
        nuevoOrden_minimo += estado.multiRow;
      });

    mergedTasks.forEach(
      task => {
        if (task.idEstado == 6) {
          var a = 1
        }
        if (task.rowResumen)
          task.machine = 1;
        else
          task.machine = machines[task.machine - 1].nuevoOrden_minimo + task.multiRow; // MULTIROW MINIMO SERA 1 Y ESTO HARA QUE SIEMPRE HAYA QUE RESTAR 1
        data.tasks.push(this.preparar_task(task));
      });
    //#endregion ----------------------------------------------------------------------------------------------------

    //#region CONTADOR ----------------------------------------------------------------------------------------------------
    var fechaFin_dia = new Date(fechaFin.getTime() + (1000 * 60 * 60 * 24))
    var historico_contador_filtrado: any = this.myFunctions.copy((this.historico_contador as any).filter(
      f => (idMaquina == f.idMaquina) &&
        (((f.fecha >= fechaIni && f.fecha <= fechaFin_dia) &&
          (f.cantidadContador > 0 || f.cantidadAsignadaOperario > 0 || f.cantidadValidada > 0 || f.malas > 0) &&
          (tipoTurnos.includes(f.idTipoTurno) || tipoTurnos.length == 0)) ||
          f.fecha < fechaIni) //    Como se traen los tiempos tratador por maquina y hmoar previos al actual, es necesario coger todos los datos previos 
      //                      al filtro por si se ha filtrado antes un mes y ahroa la ultima semana.  
      //&& (f.idTipoTurno > 0)
    ));
    if (historico_contador_filtrado.length > 0) {
      // SI TENEMOS CONTADOR, AÑADIMOS EL "MACHINE"
      data.machines.push({
        id: 'contador',
        image: '',
        name: this.translateService.instant("contador")
      });
      // Se ordena 1 > 2 para poner el contador agrupado
      historico_contador_filtrado = historico_contador_filtrado.sort((s1, s2) => {
        if (s1.fecha.getTime() > s2.fecha.getTime()) return 1
        else return -1
      });
      // CRITERIO DE AGRUPADO DE CONTADOR         
      // var columnasAgrupado = ['idMaquina', 'fechaTurno', 'idTipoTurno', 'idOperacion']
      // var columnasAgrupado_operacion = ['idMaquina', 'idOperacion'] // Se guardaran en el mismo array por no crear mucho codigo, pero deveria de funcionar independiente al otro
      // var contador_agrupado = []; // 1 -> 2
      // var contador_agrupado_confirmado = []; // 1 -> 2      
      // var contador_agrupado_confirmado_proximo = []; // 1 <- 2 
      // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // //  AHORA SE CALCULA ANTES POR Y PARA TENER LOS GRAFICOS DE PIEZAS Y GANTT SEPARADOS
      // if (false)
      //   historico_contador_filtrado.forEach(
      //     row => {
      //       // VALORES POR DEFECTO
      //       row.backgroundColor = '#343434';
      //       row.piezas = 0;
      //       row.piezas_confirmadas = 0;

      //       // STRING AGRUPADO
      //       var stringAgrupado = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
      //       columnasAgrupado.forEach(
      //         columna => {
      //           try {
      //             stringAgrupado += row[columna] + '_'
      //           } catch { }
      //         });

      //       // SE CREA EL ARRAY[AGRUPADO] SI NO EXISTE
      //       if (contador_agrupado[stringAgrupado] == undefined) {
      //         // mientras siga siendo por turno, no hara falta coger todos los previos!
      //         contador_agrupado[stringAgrupado] = 0;
      //         contador_agrupado_confirmado[stringAgrupado] = 0;
      //       }

      //       // STRING AGRUPADO
      //       var stringAgrupado_operacion = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
      //       columnasAgrupado_operacion.forEach(
      //         columna => {
      //           try {
      //             stringAgrupado_operacion += row[columna] + '_'
      //           } catch { }
      //         });
      //       // SE CREA EL ARRAY[AGRUPADO_operacion] SI NO EXISTE
      //       if (contador_agrupado[stringAgrupado_operacion] == undefined) {
      //         contador_agrupado[stringAgrupado_operacion] = 0;
      //         contador_agrupado_confirmado[stringAgrupado_operacion] = 0;
      //         // Como este no filtra turno, es necesario que en esta primera carga, se cojan todos los leidos previamente como base en vez de 0
      //         var cantidadesPrevias = this.myFunctions.copy(this.historico_contador_previo as any).filter(f => f.idOperacion == row.idOperacion && f.idMaquina == row.idMaquina)[0];
      //         if (cantidadesPrevias != undefined) {
      //           contador_agrupado[stringAgrupado_operacion] = cantidadesPrevias.cantidadContador;
      //           contador_agrupado_confirmado[stringAgrupado_operacion] = cantidadesPrevias.cantidadAsiganda;
      //         }
      //       }

      //       // SE ASIGNAN LOS VALORES CORRECTIVOS
      //       if (row.cantidadValidada > 0) {
      //         row.backgroundColor = '#83e37a';
      //         contador_agrupado_confirmado[stringAgrupado] += row.cantidadValidada;
      //         contador_agrupado[stringAgrupado] = contador_agrupado_confirmado[stringAgrupado];
      //         contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadValidada;
      //         contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
      //       } else if (row.cantidadAsignadaOperario > 0) {
      //         row.backgroundColor = '#9198f5';
      //         contador_agrupado_confirmado[stringAgrupado] += row.cantidadAsignadaOperario;
      //         contador_agrupado[stringAgrupado] = contador_agrupado_confirmado[stringAgrupado];
      //         contador_agrupado_confirmado[stringAgrupado_operacion] += row.cantidadAsignadaOperario;
      //         contador_agrupado[stringAgrupado_operacion] = contador_agrupado_confirmado[stringAgrupado_operacion];
      //       } else if (row.cantidadContador > 0) {
      //         row.backgroundColor = '#343434';
      //         contador_agrupado[stringAgrupado] += row.cantidadContador;
      //         contador_agrupado[stringAgrupado_operacion] += row.cantidadContador;
      //       }
      //       row.piezas_confirmadas = contador_agrupado_confirmado[stringAgrupado];
      //       row.piezas = contador_agrupado[stringAgrupado];
      //       row.piezasOperacion = contador_agrupado[stringAgrupado_operacion];
      //     });
      // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // AÑADIMOS LOS TASK QUE HARAN FALTA DEL CONTADOR
      var fechaanterior = this.myFunctions.copy(fechaMax);
      // se ordena 2 > 1 para crear los task en orden inverso (el circulito sale en el start del task y no al final...)
      historico_contador_filtrado = historico_contador_filtrado.sort((s1, s2) => {
        if (s1.fecha.getTime() < s2.fecha.getTime()) return 1
        else return -1
      });
      // contador_agrupado_confirmado_proximo = [];
      historico_contador_filtrado.forEach(
        row => {
          // VALORES POR DEFECTO
          row.machine = 'contador';
          row.fechaIni = row.fecha;
          row.fechaFin = fechaanterior;

          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // //  AHORA SE CALCULA ANTES POR Y PARA TENER LOS GRAFICOS DE PIEZAS Y GANTT SEPARADOS
          // if (false) {
          //   // STRING AGRUPADO     
          //   var stringAgrupado = '';// row.idMaquina + '_' + this.myFunctions.dateToDD_MM_YYYY_forwardslash(row.fechaTurno) + '_' + row.idTipoTurno + '_' + row.idOperacion;
          //   columnasAgrupado.forEach(
          //     columna => {
          //       try {
          //         stringAgrupado += row[columna] + '_'
          //       } catch { }
          //     });
          //   // Si no existe se crea la primera linea y se coge piezas por si aun no se ha asignado nada despues a mano.
          //   if (contador_agrupado_confirmado_proximo[stringAgrupado] == undefined) {
          //     contador_agrupado_confirmado_proximo[stringAgrupado] = row.piezas;
          //   }

          //   // Si son iguales es que se acaba de actualizar a las manuales o validadas
          //   if (row.piezas == row.piezas_confirmadas)
          //     contador_agrupado_confirmado_proximo[stringAgrupado] = row.piezas_confirmadas;
          //   row.proximaCantidadConfirmada = contador_agrupado_confirmado_proximo[stringAgrupado];

          //   if (row.idOperacion < 0) {
          //     row.backgroundColor = '#999090';
          //   } else if (row.proximaCantidadConfirmada < row.piezas) {
          //     // Si es mayor es que se habia pasado de lo que despues se ha confirmado
          //     row.backgroundColor = '#FA9C1B';
          //   }
          // }
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          // AÑADIR A TASKS
          data.tasks.push(this.preparar_task_contador(row));
          // SE PREPARA LA PROXIMA VUELTA
          fechaanterior = this.myFunctions.copy(row.fecha);
        });
    }
    //#endregion ----------------------------------------------------------------------------------------------------


    var ret = {
      width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: 50 + ((64 - Math.min((4 * Object.keys(data.machines).length), 29)) * Object.keys(data.machines).length),//MAXIMO:  1 linea = 60 altura //MINIMO:  10 lineas = 35 altur // RESTO: dinamico
      startDate: fechaMin,
      endDate: fechaMax,
      shifts: turnosArray,
      data: data,
    }
    return ret;
  }
  private preparar_task(task) {

    // SE GENERA EL APPOINTMENT NECESARIO 
    var row_GANTT: any = {}
    row_GANTT.allowedMachines = '[]';
    row_GANTT.machine = task.machine;
    row_GANTT.backgroundColor = task.backgroundColor;
    row_GANTT.startDate = this.myFunctions.dateToYYYYMMDDtHHmmSS(task.fechaIni);
    row_GANTT.endDate = this.myFunctions.dateToYYYYMMDDtHHmmSS(task.fechaFin);
    row_GANTT.of = task.of;
    row_GANTT.idOperacion = task.idOperacion;

    // cuando una parada esta fuera de turnos, la carca con lineas diagonales, se ha dicho que esto ya no hace falta pero se deja comentado por si lo piden de nuevo
    // if (idDb == 33 && task.idProcesos_Tipo == 2 && task.fueraDeTiempo) {
    //     f['filter'] = {src: 'assets/img/diagonal-paradas.png',size: [95, 50],animated: false};
    //     task.backgroundColor = "#096844";
    // }        

    var tooltip1 = `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;
    var tooltip2 = `</strong></label></p>`;
    var tooltip3 = ``;
    if (task.nombrePrograma != "") tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${task.nombrePrograma}<br></span></p>`;
    if (task.alarma != "") tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("alarma").toUpperCase()}: </span><span class="tooltip-valor">${task.alarma}<br></span></p>`;
    if (task.descripcion != "" && task.alarma != task.descripcion) tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${task.descripcion}<br></span></p>`;
    tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${task.operario}</span></p>`;
    tooltip3 = tooltip3 + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
    if (task.of != "" && task.idEstado != 4) {
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${task.of}<br></span></p>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${task.cliente}<br></span></p>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${task.referenciaPieza}<br></span></p>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${task.nombrePieza}<br></span></p>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${task.nombreOperacion}<br></span></p>`;
      if (this.info_configuracion.verLote)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("lote").toUpperCase()}: </span><span class="tooltip-valor">${task.lote}<br></span></p>`;
      if (this.info_configuracion.verColada)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("colada").toUpperCase()}: </span><span class="tooltip-valor">${task.colada}<br></span></p>`;
      if (this.info_configuracion.verNSerie)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${task.nserie}<br></span></p>`;
      // if(!this.info_configuracion.verLote)
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("cantidad2").toUpperCase()}: </span><span class="tooltip-valor">${task.cantidad}<br></span></p>`;
      if (task.cantidadContador > 0)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("contador").toUpperCase()}: </span><span class="tooltip-valor">${task.cantidadContador}<br></span></p>`;
      if (task.cantidadAsignadaOperario > 0)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("asignadas").toUpperCase()}: </span><span class="tooltip-valor">${task.cantidadAsignadaOperario}<br></span></p>`;
      if (task.cantidadValidada > 0)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("validadas").toUpperCase()}: </span><span class="tooltip-valor">${task.cantidadValidada}<br></span></p>`;
    } else if (task.idEstado != 4) {
      tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
    } else {
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${task.mantenimiento}<br></span></p>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("observacion").toUpperCase()}: </span><span class="tooltip-valor">${task.observacionMantenimiento}<br></span></p>`;
    }
    if (task.perdida != "") {
      tooltip3 = tooltip3 + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
      tooltip3 = tooltip3 + `<p><span class="tooltip-title"> ${this.translateService.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${task.perdida}<br></span></p>`;
      if (task.observacionHistoricoPerdida != "" && task.perdida != task.observacionHistoricoPerdida)
        tooltip3 = tooltip3 + `<p><span class="tooltip-title">${this.translateService.instant("observacion").toUpperCase()}: </span><span class="tooltip-valor">${task.observacionHistoricoPerdida}<br></span></p>`;
    }
    row_GANTT.detail2 = tooltip1 + `${this.translateService.instant(task.subestado).toUpperCase()}` + tooltip2 + tooltip3;

    if (task.rowResumen) {
      row_GANTT.backgroundColor = task.backgroundColor_estado;
      row_GANTT.detail2 = tooltip1 + `${this.translateService.instant(task.estado).toUpperCase()}` + tooltip2;

      row_GANTT.of = task.estado;
    }

    row_GANTT.detail2 = row_GANTT.detail2 + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
    var dif = this.myFunctions.secondsTo_HH_MM_SS((new Date(task.fechaFin).getTime() - new Date(task.fechaIni).getTime()) / 1000);
    row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
    row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(new Date(task.fechaIni))}<br></span></p>`;
    row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(new Date(task.fechaFin))}<br></span></p>`;
    row_GANTT.detail2 = row_GANTT.detail2 + `</div>`;

    row_GANTT.detail = (d) => row_GANTT.detail2;

    return row_GANTT;
  }
  private preparar_task_contador(row_contador) {

    // SE GENERA EL APPOINTMENT NECESARIO 
    var row_GANTT: any = {}
    row_GANTT.allowedMachines = '[]';
    row_GANTT.machine = row_contador.machine;
    row_GANTT.backgroundColor = row_contador.backgroundColor;
    row_GANTT.startDate = this.myFunctions.dateToYYYYMMDDtHHmmSS(row_contador.fechaIni);
    row_GANTT.endDate = this.myFunctions.dateToYYYYMMDDtHHmmSS(row_contador.fechaFin);
    row_GANTT.of = row_contador.piezasAmostrarGANTT;
    row_GANTT.idOperacion = row_contador.idOperacion;
    row_GANTT.piezasAmostrarGANTT = row_contador.piezasAmostrarGANTT?.toString();
    var alerta = ""
    if (row_GANTT.backgroundColor == '#999090') {
      // row_GANTT.of = "";
      alerta = ': ' + this.translateService.instant("sinOperacion");
    } else if (row_GANTT.backgroundColor == '#FA9C1B') {
      // row_GANTT.of = "";
      alerta = ': ' + this.translateService.instant("noContabilizada");
    } 

    // TOOLTIP: Titulo
    if (row_contador.cantidadValidada > 0) {
      row_GANTT.detail2 = `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> ${this.translateService.instant("validado").toUpperCase()}</strong>` + alerta + `</label></p><div>`;
    } else if (row_contador.cantidadAsignadaOperario > 0) {
      row_GANTT.detail2 = `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> ${this.translateService.instant("manual").toUpperCase()}</strong>` + alerta + `</label></p><div>`;
    } else if (row_contador.cantidadContador > 0) {
      row_GANTT.detail2 = `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> ${this.translateService.instant("contadorAutomatico").toUpperCase()}</strong>` + alerta + `</label></p><div>`;
    } else if (row_contador.malas > 0) {
      row_GANTT.detail2 = `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> ${this.translateService.instant("malas").toUpperCase()}</strong>` + alerta + `</label></p><div>`;
    }



    //AYUDA PARA DEBUGUEAR
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezas_previas             </strong>` + row_contador.piezas_previas + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezas_previasOperacion    </strong>` + row_contador.piezas_previasOperacion + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> cantidadContador           </strong>` + row_contador.cantidadContador + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezas_confirmadas         </strong>` + row_contador.piezas_confirmadas + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> proximaCantidadConfirmada  </strong>` + row_contador.proximaCantidadConfirmada + `</label></p><div>`;
    // // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> proximaCantidadConfirmada - row.piezasProximo</strong>` + (row_contador.proximaCantidadConfirmada - row_contador.piezasProximo) + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezasProximo              </strong>` + row_contador.piezasProximo + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezasOperacion            </strong>` + row_contador.piezasOperacion + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezasBuenasAutocorregido            </strong>` + row_contador.piezasBuenasAutocorregido + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> piezasMalasAutocorregido            </strong>` + row_contador.piezasMalasAutocorregido + `</label></p><div>`;
    // row_GANTT.detail2 = row_GANTT.detail2 + `<div class="tooltipa"><p><strong><span class="tooltip-negrita"> malas            </strong>` + row_contador.malas + `</label></p><div>`;




    // TOOLTIP: Fecha
    row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("fecha").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(new Date(row_contador.fechaIni))}<br></span></p>`;
    row_GANTT.detail2 = row_GANTT.detail2 + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
    // TOOLTIP: Contadas este tooltip
    if (row_contador.cantidadValidada > 0) {
      row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("piezasTerminadas").toUpperCase()}: </span><span class="tooltip-valor">+ ${row_contador.cantidadValidada}<br></span></p>`;
    } else if (row_contador.cantidadAsignadaOperario > 0) {
      row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("piezasTerminadas").toUpperCase()}: </span><span class="tooltip-valor">+ ${row_contador.cantidadAsignadaOperario}<br></span></p>`;
    } else if (row_contador.cantidadContador > 0) {
      row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("piezasTerminadas").toUpperCase()}: </span><span class="tooltip-valor">+ ${row_contador.cantidadContador}<br></span></p>`;
    }
    // TOOLTIP: Malas
    if (row_contador.malas > 0) {
      row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("malas").toUpperCase()}: </span><span class="tooltip-valor">+ ${row_contador.malas}<br></span></p>`;

    }
    if (row_GANTT.backgroundColor != '#999090') {
      row_GANTT.detail2 = row_GANTT.detail2 + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
      // TOOLTIP: Contadas acumuladas
      // if (row_contador.piezas_confirmadas > 0) {
      //   row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("confirmadas").toUpperCase()}: </span><span class="tooltip-valor">${row_contador.piezas_confirmadas}<br></span></p>`;
      // }
      if (row_contador.piezas > 0) {
        row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("totalesTurno").toUpperCase()}: </span><span class="tooltip-valor">${row_contador.piezas}<br></span></p>`;
      }
      if (row_contador.piezasOperacion > 0) {
        row_GANTT.detail2 = row_GANTT.detail2 + `<p><span class="tooltip-title"> ${this.translateService.instant("total").toUpperCase()}: </span><span class="tooltip-valor">${row_contador.piezasOperacion}<br></span></p>`;
      }
    }


    row_GANTT.detail2 = row_GANTT.detail2 + `</div>`;

    row_GANTT.detail = (d) => row_GANTT.detail2;
    
    if(row_contador.malas > 0 ){
      row_GANTT.backgroundColor = '#ff0000';
    }
    return row_GANTT;
  }
  //#endregion
  //#region ESTADOS
  public load_resumen_estados_data_Donut_prefiltrado(chartDonutResumenSemana_Estados, idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    // this.load_Donut_data_grid('perdida', 'tTotalParcial', chartDonut, data_grid)
    var data_grid: any = this.get_resumen_estados_data_Donut_prefiltrado(idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado);

    this.myCharts.load(chartDonutResumenSemana_Estados, data_grid);

    var sinDatos = true;
    data_grid.columns.forEach(
      estado => {
        if (estado[1] > 0) sinDatos = false;
      });
    return sinDatos; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  public load_resumen_estados_data_Donut(chartDonutResumenSemana_Estados, idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    // this.load_Donut_data_grid('perdida', 'tTotalParcial', chartDonut, data_grid)
    var data_grid: any = this.get_resumen_estados_data_Donut(idMaquinas, fechaIni, fechaFin, tipoTurnos);

    this.myCharts.load(chartDonutResumenSemana_Estados, data_grid);

    var sinDatos = true;
    data_grid.columns.forEach(
      estado => {
        if (estado[1] > 0) sinDatos = false;
      });
    return sinDatos; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  public load_resumen_estados_data_Lineal(chartAreaHorasDia_Estados, idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana   
      chartAreaHorasDia_Estados.axis.max(86400 * 7)
      chartAreaHorasDia_Estados.internal.config.axis_y_tick_values = ([0 * 7, 10800 * 7, 21600 * 7, 32400 * 7, 43200 * 7, 54000 * 7, 64800 * 7, 75600 * 7, 86400 * 7]);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      chartAreaHorasDia_Estados.axis.max(86400 * 31)
      chartAreaHorasDia_Estados.internal.config.axis_y_tick_values = ([0 * 31, 10800 * 31, 21600 * 31, 32400 * 31, 43200 * 31, 54000 * 31, 64800 * 31, 75600 * 31, 86400 * 31]);
    } else { // no hay que agrupar
      chartAreaHorasDia_Estados.axis.max(86400);
      chartAreaHorasDia_Estados.internal.config.axis_y_tick_values = ([0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400]);
    }

    this.myCharts.load(chartAreaHorasDia_Estados, this.get_resumen_estados_data_Lineal(idMaquinas, fechaIni, fechaFin, tipoTurnos));

  }
  public load_resumen_subestados_data_Donut_prefiltrado(chartDonutResumenSemana_subEstados, idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    var data_grid: any = this.get_resumen_subestados_data_Donut_prefiltrado(idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado);
    chartDonutResumenSemana_subEstados.load(data_grid);

    this.myCharts.load(chartDonutResumenSemana_subEstados, data_grid);

    var sinDatos = true;
    data_grid.columns.forEach(
      subestado => {
        if (subestado[1] > 0) sinDatos = false;
      });
    return sinDatos; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  public load_resumen_subestados_data_Donut(chartDonutResumenSemana_subEstados, idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var data_grid: any = this.get_resumen_subestados_data_Donut(idMaquinas, fechaIni, fechaFin, tipoTurnos);

    this.myCharts.load(chartDonutResumenSemana_subEstados, data_grid);

    var sinDatos = true;
    data_grid.columns.forEach(
      subestado => {
        if (subestado[1] > 0) sinDatos = false;
      });
    return sinDatos; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  public load_resumen_subestados_data_Lineal(chartAreaHorasDia_subEstados, idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana   
      chartAreaHorasDia_subEstados.axis.max(86400 * 7)
      chartAreaHorasDia_subEstados.internal.config.axis_y_tick_values = ([0 * 7, 10800 * 7, 21600 * 7, 32400 * 7, 43200 * 7, 54000 * 7, 64800 * 7, 75600 * 7, 86400 * 7]);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      chartAreaHorasDia_subEstados.axis.max(86400 * 31)
      chartAreaHorasDia_subEstados.internal.config.axis_y_tick_values = ([0 * 31, 10800 * 31, 21600 * 31, 32400 * 31, 43200 * 31, 54000 * 31, 64800 * 31, 75600 * 31, 86400 * 31]);
    } else { // no hay que agrupar
      chartAreaHorasDia_subEstados.axis.max(86400);
      chartAreaHorasDia_subEstados.internal.config.axis_y_tick_values = ([0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400]);
    }

    this.myCharts.load(chartAreaHorasDia_subEstados, this.get_resumen_subestados_data_Lineal(idMaquinas, fechaIni, fechaFin, tipoTurnos));

  }

  private get_resumen_estados_data_Donut_prefiltrado(idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    var resumenSemana = (this.get_resumen_estados_prefiltrado(idMaquinas, historico_completo_filtrado));

    var columnas_Donut = [];
    var nombres_Donut = {};
    resumenSemana.forEach(
      estado => {
        columnas_Donut.push([estado.nombre, estado.tiempo]);
        nombres_Donut[estado.nombre] = estado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(estado.tiempo) + ")";
      });
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    return data_Donut;
  }
  private get_resumen_estados_data_Donut(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var resumenSemana = (this.get_resumen_estados(idMaquinas, fechaIni, fechaFin, tipoTurnos));

    var columnas_Donut = [];
    var nombres_Donut = {};
    resumenSemana.forEach(
      estado => {
        columnas_Donut.push([estado.nombre, estado.tiempo]);
        nombres_Donut[estado.nombre] = estado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(estado.tiempo) + ")";
      });
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    return data_Donut;
  }
  private get_resumen_estados_data_Lineal(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var infEstados_maquinas: any = this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado < 100));

    var indexColumna = [];
    var columnas_Lineal = [['x']];  //[[x          , '2023/01/01', '2023/01/02',...], ['Ejecucion',         100 ,         200 ,...]]
    indexColumna.push('x'); //para mantener el mismo orden en el index y en este array[][] añadimos la x 
    var nombres_Lineal = {}; // {Ejecucion: Ejecucion, Parada: Parada ,...}

    infEstados_maquinas.forEach(
      estado => {
        nombres_Lineal[estado.nombre] = estado.nombre;
        columnas_Lineal.push([estado.nombre]);
        indexColumna.push(estado.nombre);
      });

    var fechaGrafico: Date = new Date(fechaIni);
    var tipoAgrupado = 0; // 0 => dia, 1=> semana 2 => mes
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana     
      tipoAgrupado = 1;
      fechaGrafico = this.myFunctions.startOfWeek(fechaGrafico);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      tipoAgrupado = 2;
      fechaGrafico = this.myFunctions.startOfMonth(fechaGrafico);
    } else { // no hay que agrupar
      tipoAgrupado = 0;
    }

    var fechaFin_calculo = new Date(fechaFin);
    fechaFin_calculo.setDate(fechaFin_calculo.getDate() + 1);

    while (fechaGrafico <= fechaFin) {
      var proxima_fechaGrafico: Date = new Date(fechaGrafico);
      switch (tipoAgrupado) {
        case 1: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 7); break;
        case 2: proxima_fechaGrafico.setMonth(proxima_fechaGrafico.getMonth() + 1); break;
        default: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 1); break;
      }
      var fecha_Filtro_Ini: Date = this.myFunctions.Max_date(new Date(fechaGrafico), new Date(fechaIni)) // por si se filtra desde una fecha concreta que no corresponda a principio de mes o semana
      var fecha_Filtro_Fin: Date = this.myFunctions.Min_date(new Date(proxima_fechaGrafico), new Date(fechaFin_calculo)) // por si se filtra hasta una fecha concreta que no corresponda a principio de mes o semana
      fecha_Filtro_Fin.setDate(fecha_Filtro_Fin.getDate() - 1);
      var resumenSemana = this.get_resumen_estados(idMaquinas, fecha_Filtro_Ini, fecha_Filtro_Fin, tipoTurnos);
      columnas_Lineal[indexColumna.indexOf('x')].push(this.myFunctions.dateToDD_MM_YYYY_forwardslash(fechaGrafico));
      resumenSemana.forEach(
        estado => {
          columnas_Lineal[indexColumna.indexOf(estado.nombre)].push(estado.tiempo);
        });

      if (fechaGrafico >= proxima_fechaGrafico) break; // seguridad para no entrar en bucle
      fechaGrafico = proxima_fechaGrafico;
    }

    var data_Lineal = {
      columns: columnas_Lineal,
      names: nombres_Lineal
    }

    return data_Lineal;
  }
  private get_resumen_subestados_data_Donut_prefiltrado(idMaquinas, fechaIni, fechaFin, tipoTurnos, historico_completo_filtrado) {
    var resumenSemana = (this.get_resumen_subestados_prefiltrado(idMaquinas, historico_completo_filtrado));

    var columnas_Donut = [];
    var nombres_Donut = {};
    resumenSemana.forEach(
      estado => {
        columnas_Donut.push([estado.nombre, estado.tiempo]);
        nombres_Donut[estado.nombre] = estado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(estado.tiempo) + ")";
      });
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    return data_Donut;
  }
  private get_resumen_subestados_data_Donut(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var resumenSemana = (this.get_resumen_subestados(idMaquinas, fechaIni, fechaFin, tipoTurnos));

    var columnas_Donut = [];
    var nombres_Donut = {};
    resumenSemana.forEach(
      estado => {
        columnas_Donut.push([estado.nombre, estado.tiempo]);
        nombres_Donut[estado.nombre] = estado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(estado.tiempo) + ")";
      });
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    return data_Donut;
  }
  private get_resumen_subestados_data_Lineal(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var infEstados_maquinas: any = this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado >= 100));

    var indexColumna = [];
    var columnas_Lineal = [['x']];  //[[x          , '2023/01/01', '2023/01/02',...], ['Ejecucion',         100 ,         200 ,...]]
    indexColumna.push('x'); //para mantener el mismo orden en el index y en este array[][] añadimos la x 
    var nombres_Lineal = {}; // {Ejecucion: Ejecucion, Parada: Parada ,...}

    infEstados_maquinas.forEach(
      estado => {
        nombres_Lineal[estado.nombre] = estado.nombre;
        columnas_Lineal.push([estado.nombre]);
        indexColumna.push(estado.nombre);
      });

    var fechaGrafico: Date = new Date(fechaIni);
    var tipoAgrupado = 0; // 0 => dia, 1=> semana 2 => mes
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana     
      tipoAgrupado = 1;
      fechaGrafico = this.myFunctions.startOfWeek(fechaGrafico);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      tipoAgrupado = 2;
      fechaGrafico = this.myFunctions.startOfMonth(fechaGrafico);
    } else { // no hay que agrupar
      tipoAgrupado = 0;
    }

    var fechaFin_calculo = new Date(fechaFin);
    fechaFin_calculo.setDate(fechaFin_calculo.getDate() + 1);

    while (fechaGrafico <= fechaFin) {
      var proxima_fechaGrafico: Date = new Date(fechaGrafico);
      switch (tipoAgrupado) {
        case 1: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 7); break;
        case 2: proxima_fechaGrafico.setMonth(proxima_fechaGrafico.getMonth() + 1); break;
        default: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 1); break;
      }
      var fecha_Filtro_Ini: Date = this.myFunctions.Max_date(new Date(fechaGrafico), new Date(fechaIni)) // por si se filtra desde una fecha concreta que no corresponda a principio de mes o semana
      var fecha_Filtro_Fin: Date = this.myFunctions.Min_date(new Date(proxima_fechaGrafico), new Date(fechaFin_calculo)) // por si se filtra hasta una fecha concreta que no corresponda a principio de mes o semana
      fecha_Filtro_Fin.setDate(fecha_Filtro_Fin.getDate() - 1);
      var resumenSemana = this.get_resumen_subestados(idMaquinas, fecha_Filtro_Ini, fecha_Filtro_Fin, tipoTurnos);
      columnas_Lineal[indexColumna.indexOf('x')].push(this.myFunctions.dateToDD_MM_YYYY_forwardslash(fechaGrafico));
      resumenSemana.forEach(
        estado => {
          columnas_Lineal[indexColumna.indexOf(estado.nombre)].push(estado.tiempo);
        });

      if (fechaGrafico >= proxima_fechaGrafico) break; // seguridad para no entrar en bucle
      fechaGrafico = proxima_fechaGrafico;
    }

    var data_Lineal = {
      columns: columnas_Lineal,
      names: nombres_Lineal
    }

    return data_Lineal;
  }

  public get_resumen_estados_porcentaje(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var resumenSemana = (this.get_resumen_estados(idMaquinas, fechaIni, fechaFin, tipoTurnos));
    resumenSemana = resumenSemana.sort((a, b) => (a.tiempo > b.tiempo) ? 1 : ((b.tiempo > a.tiempo) ? -1 : 0));

    var tiempoTotal = resumenSemana.map(x => x.tiempo).reduce(function (a, b) { return a + b; }, 0);
    var porcentaje = 0;

    var columnas_Donut = [];
    var nombres_Donut = {};
    var porcentajeAcumulado = 0;
    var index = 0;
    resumenSemana.forEach(
      estado => {
        if (tiempoTotal != 0)
          porcentaje = Math.floor(estado.tiempo / tiempoTotal * 100);
        //#region Comprobar que el porcentaje llegue al 100%
        porcentajeAcumulado += porcentaje;
        if (tiempoTotal != 0 && index == resumenSemana.length - 1)
          if (porcentajeAcumulado != 100)
            porcentaje += (100 - porcentajeAcumulado);
        //#endregion
        columnas_Donut.push([estado.nombre, porcentaje, estado.tiempo]);
        nombres_Donut[estado.nombre] = estado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(porcentaje) + ")";
        index++;
      });
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    return data_Donut;
  }
  //#endregion
  //#region TURNOS
  public load_resumen_piezas_turnos_data_bar(chartBar_turnos, historico_contador_filtrado, fechaIni, fechaFin, tipoTurnos, mostrarHoras, tipoContador, turnoNochePrimero) {
    var data_max = this.get_resumen_piezas_turnos_data_bar(historico_contador_filtrado, fechaIni, fechaFin, tipoTurnos, mostrarHoras, tipoContador, turnoNochePrimero)
    var data = data_max.data;
    var max = data_max.max;
    var maxTotal = data_max.maxTotal;
    var totales = data_max.totales;
    chartBar_turnos.axis.max({
      y: Math.ceil(max / 10) * 10,  // se redondea al proximo multiplo de 10 
      y2: Math.ceil(maxTotal / 10) * 10  // se redondea al proximo multiplo de 10 
    });

    this.myCharts.load(chartBar_turnos, data);

    return { data, totales };
  }
  private get_resumen_piezas_turnos_data_bar(historico_contador_filtrado, fechaIni, fechaFin, tipoTurnos, mostrarEnHoras, tipoContador, turnoNochePrimero) {
    var totales: any = {
      mañana: { buenas: 0.0, malas: 0.0 },
      tarde: { buenas: 0.0, malas: 0.0 },
      noche: { buenas: 0.0, malas: 0.0 }
    };

    var indexColumna = [];
    var columnas_Lineal;
    if (turnoNochePrimero) {
      columnas_Lineal = [
        ['x'],
        ['n_ok'], ['n_nok'],
        ['m_ok'], ['m_nok'],
        ['t_ok'], ['t_nok'],
        ['total']
      ];
      indexColumna.push('x'); //para mantener el mismo orden en el index y en este array[][] añadimos la x
      indexColumna.push('n_ok');
      indexColumna.push('n_nok');
      indexColumna.push('m_ok');
      indexColumna.push('m_nok');
      indexColumna.push('t_ok');
      indexColumna.push('t_nok');
      indexColumna.push('total');
    }
    else {
      columnas_Lineal = [
        ['x'],
        ['m_ok'], ['m_nok'],
        ['t_ok'], ['t_nok'],
        ['n_ok'], ['n_nok'],
        ['total']
      ];      
      indexColumna.push('x'); //para mantener el mismo orden en el index y en este array[][] añadimos la x
      indexColumna.push('m_ok');
      indexColumna.push('m_nok');
      indexColumna.push('t_ok');
      indexColumna.push('t_nok');
      indexColumna.push('n_ok');
      indexColumna.push('n_nok');
      indexColumna.push('total');
    }

    var fechaGrafico: Date = new Date(fechaIni);
    var tipoAgrupado = 0; // 0 => dia, 1=> semana 2 => mes
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana     
      tipoAgrupado = 1;
      fechaGrafico = this.myFunctions.startOfWeek(fechaGrafico);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      tipoAgrupado = 2;
      fechaGrafico = this.myFunctions.startOfMonth(fechaGrafico);
    } else { // no hay que agrupar
      tipoAgrupado = 0;
    }

    var fechaFin_calculo = new Date(fechaFin);
    fechaFin_calculo.setDate(fechaFin_calculo.getDate() + 1);

    var max = 10;
    var maxTotal = 10;

    while (fechaGrafico <= fechaFin) {
      var proxima_fechaGrafico: Date = new Date(fechaGrafico);
      switch (tipoAgrupado) {
        case 1: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 7); break;
        case 2: proxima_fechaGrafico.setMonth(proxima_fechaGrafico.getMonth() + 1); break;
        default: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 1); break;
      }
      var fecha_Filtro_Ini: Date = this.myFunctions.Max_date(new Date(fechaGrafico), new Date(fechaIni)) // por si se filtra desde una fecha concreta que no corresponda a principio de mes o semana
      var fecha_Filtro_Fin: Date = this.myFunctions.Min_date(new Date(proxima_fechaGrafico), new Date(fechaFin_calculo)) // por si se filtra hasta una fecha concreta que no corresponda a principio de mes o semana
      fecha_Filtro_Fin.setDate(fecha_Filtro_Fin.getDate() - 1);
      var piezas = this.get_resumen_piezas_turno(historico_contador_filtrado, fecha_Filtro_Ini, fecha_Filtro_Fin, tipoTurnos, mostrarEnHoras, tipoContador);
      columnas_Lineal[indexColumna.indexOf('x')].push(this.myFunctions.dateToDD_MM_YYYY_forwardslash(fechaGrafico));
      var total = piezas.mañana.buenas + piezas.mañana.malas + piezas.tarde.buenas + piezas.tarde.malas + piezas.noche.buenas + piezas.noche.malas;
      if (mostrarEnHoras) {
        columnas_Lineal[indexColumna.indexOf('m_ok')].push((piezas.mañana.buenas / 3600).toFixed(1));
        columnas_Lineal[indexColumna.indexOf('m_nok')].push((piezas.mañana.malas / 3600).toFixed(1));
        columnas_Lineal[indexColumna.indexOf('t_ok')].push((piezas.tarde.buenas / 3600).toFixed(1));
        columnas_Lineal[indexColumna.indexOf('t_nok')].push((piezas.tarde.malas / 3600).toFixed(1));
        columnas_Lineal[indexColumna.indexOf('n_ok')].push((piezas.noche.buenas / 3600).toFixed(1));
        columnas_Lineal[indexColumna.indexOf('n_nok')].push((piezas.noche.malas / 3600).toFixed(1));
        total = (total / 3600).toFixed(1);
        columnas_Lineal[indexColumna.indexOf('total')].push(total.toString());
      }
      else {
        columnas_Lineal[indexColumna.indexOf('m_ok')].push(piezas.mañana.buenas);
        columnas_Lineal[indexColumna.indexOf('m_nok')].push(piezas.mañana.malas);
        columnas_Lineal[indexColumna.indexOf('t_ok')].push(piezas.tarde.buenas);
        columnas_Lineal[indexColumna.indexOf('t_nok')].push(piezas.tarde.malas);
        columnas_Lineal[indexColumna.indexOf('n_ok')].push(piezas.noche.buenas);
        columnas_Lineal[indexColumna.indexOf('n_nok')].push(piezas.noche.malas);
        columnas_Lineal[indexColumna.indexOf('total')].push(total);
      }

      // Calculamos totales      
      totales.mañana.buenas += piezas.mañana.buenas;
      totales.mañana.malas += piezas.mañana.malas;
      totales.tarde.buenas += piezas.tarde.buenas;
      totales.tarde.malas += piezas.tarde.malas;
      totales.noche.buenas += piezas.noche.buenas;
      totales.noche.malas += piezas.noche.malas;

      if (max < piezas.mañana.buenas + piezas.mañana.malas) max = piezas.mañana.buenas + piezas.mañana.malas;
      if (max < piezas.tarde.buenas + piezas.tarde.malas) max = piezas.tarde.buenas + piezas.tarde.malas;
      if (max < piezas.noche.buenas + piezas.noche.malas) max = piezas.noche.buenas + piezas.noche.malas;

      if (maxTotal < total) maxTotal = total;

      if (fechaGrafico >= proxima_fechaGrafico) break; // seguridad para no entrar en bucle
      fechaGrafico = proxima_fechaGrafico;
    }

    var data = {
      columns: columnas_Lineal
    }
    if (mostrarEnHoras) {
      max = Math.round(max / 360) / 10;
      totales.mañana.buenas = Math.round(totales.mañana.buenas / 360) / 10;
      totales.mañana.malas = Math.round(totales.mañana.malas / 360) / 10;
      totales.tarde.buenas = Math.round(totales.tarde.buenas / 360) / 10;
      totales.tarde.malas = Math.round(totales.tarde.malas / 360) / 10;
      totales.noche.buenas = Math.round(totales.noche.buenas / 360) / 10;
      totales.noche.malas = Math.round(totales.noche.malas / 360) / 10;
    }

    return { data, max, maxTotal, totales };
  }
  //#endregion
  //#region OEE
  public get_OEE_data_Donut_SIN_filtro(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    // esta funcion tiene esta estructura para ser similar a la de estados.
    var resumenOEE = (this.get_resumen_OEE_SIN_filtro(idMaquinas, fechaIni, fechaFin, tipoTurnos));

    // para hacer estos donnut, se pasa el label y el data del grafico por separado.
    var data_Donut = {
      OEE_porcentaje: resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000,
      OEE: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000]] },
      disponibilidad_porcentaje: resumenOEE.infoDisponibilidad.porcentaje,
      disponibilidad: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje]] },
      rendimiento_porcentaje: resumenOEE.infoRendimiento.porcentaje,
      rendimiento: { columns: [['completo', resumenOEE.infoRendimiento.porcentaje], ['nocompleto', 100 - resumenOEE.infoRendimiento.porcentaje]] },
      calidad_porcentaje: resumenOEE.infoCalidad.porcentaje,
      calidad: { columns: [['completo', resumenOEE.infoCalidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoCalidad.porcentaje]] }
    }

    return data_Donut;
  }
  public get_OEE_data_Donut(filtro) {
    // esta funcion tiene esta estructura para ser similar a la de estados.
    var resumenOEE = (this.get_resumen_OEE(filtro));

    // para hacer estos donnut, se pasa el label y el data del grafico por separado.
    var data_Donut = {
      OEE_porcentaje: resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000,
      OEE: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000]] },
      disponibilidad_porcentaje: resumenOEE.infoDisponibilidad.porcentaje,
      disponibilidad: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje]] },
      rendimiento_porcentaje: resumenOEE.infoRendimiento.porcentaje,
      rendimiento: { columns: [['completo', resumenOEE.infoRendimiento.porcentaje], ['nocompleto', 100 - resumenOEE.infoRendimiento.porcentaje]] },
      calidad_porcentaje: resumenOEE.infoCalidad.porcentaje,
      calidad: { columns: [['completo', resumenOEE.infoCalidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoCalidad.porcentaje]] }
    }

    return data_Donut;
  }
  public get_OEE_data_Donut_prefiltrado(historico_completo_filtrado) {
    // esta funcion tiene esta estructura para ser similar a la de estados.
    var resumenOEE = (this.get_resumen_OEE_prefiltrado(historico_completo_filtrado));

    // para hacer estos donnut, se pasa el label y el data del grafico por separado.
    var data_Donut = {
      OEE_porcentaje: resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000,
      OEE: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje * resumenOEE.infoRendimiento.porcentaje * resumenOEE.infoCalidad.porcentaje / 10000]] },
      disponibilidad_porcentaje: resumenOEE.infoDisponibilidad.porcentaje,
      disponibilidad: { columns: [['completo', resumenOEE.infoDisponibilidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoDisponibilidad.porcentaje]] },
      rendimiento_porcentaje: resumenOEE.infoRendimiento.porcentaje,
      rendimiento: { columns: [['completo', resumenOEE.infoRendimiento.porcentaje], ['nocompleto', 100 - resumenOEE.infoRendimiento.porcentaje]] },
      calidad_porcentaje: resumenOEE.infoCalidad.porcentaje,
      calidad: { columns: [['completo', resumenOEE.infoCalidad.porcentaje], ['nocompleto', 100 - resumenOEE.infoCalidad.porcentaje]] }
    }

    return data_Donut;
  }

  public get_OEE_data_Barras_SIN_filtro(idMaquinas, fechaIni, fechaFin, tipoTurnos) {
    var resumenOEE = (this.get_resumen_OEE_SIN_filtro(idMaquinas, fechaIni, fechaFin, tipoTurnos));

    // se prepara el columns data del grafico directamente
    var dataGraficoHoras = [[this.translateService.instant('total'), resumenOEE.tTotal, resumenOEE.infoDisponibilidad.tiempoPositivo, resumenOEE.infoRendimiento.tiempoPositivo, resumenOEE.infoCalidad.tiempoPositivo],
    [this.translateService.instant('rendimiento'), 0, 0, resumenOEE.tTotalPerdidaRendimiento, 0],
    [this.translateService.instant('microparadas'), 0, resumenOEE.infoDisponibilidad.tiempoMicroParada, resumenOEE.infoRendimiento.tiempoMicroParada, resumenOEE.infoCalidad.tiempoMicroParada],
    [this.translateService.instant('paradas'), 0, resumenOEE.infoDisponibilidad.tiempoParada, resumenOEE.infoRendimiento.tiempoParada, resumenOEE.infoCalidad.tiempoParada],
    [this.translateService.instant('mantenimientos'), 0, resumenOEE.infoDisponibilidad.tiempoMantenimiento, resumenOEE.infoRendimiento.tiempoMantenimiento, resumenOEE.infoCalidad.tiempoMantenimiento],
    [this.translateService.instant('alarmas'), 0, resumenOEE.infoDisponibilidad.tiempoAlarma, resumenOEE.infoRendimiento.tiempoAlarma, resumenOEE.infoCalidad.tiempoAlarma],
    [this.translateService.instant('apagadas'), 0, resumenOEE.infoDisponibilidad.tiempoApagada, resumenOEE.infoRendimiento.tiempoApagada, resumenOEE.infoCalidad.tiempoApagada],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, resumenOEE.tTotalNegativoCalidad]];


    return { columns: dataGraficoHoras };
  }
  public get_OEE_data_Barras(filtro) {
    var resumenOEE = (this.get_resumen_OEE(filtro));

    // se prepara el columns data del grafico directamente
    var dataGraficoHoras = [[this.translateService.instant('total'), resumenOEE.tTotal, resumenOEE.infoDisponibilidad.tiempoPositivo, resumenOEE.infoRendimiento.tiempoPositivo, resumenOEE.infoCalidad.tiempoPositivo],
    [this.translateService.instant('rendimiento'), 0, 0, resumenOEE.tTotalPerdidaRendimiento, 0],
    [this.translateService.instant('microparadas'), 0, resumenOEE.infoDisponibilidad.tiempoMicroParada, resumenOEE.infoRendimiento.tiempoMicroParada, resumenOEE.infoCalidad.tiempoMicroParada],
    [this.translateService.instant('paradas'), 0, resumenOEE.infoDisponibilidad.tiempoParada, resumenOEE.infoRendimiento.tiempoParada, resumenOEE.infoCalidad.tiempoParada],
    [this.translateService.instant('mantenimientos'), 0, resumenOEE.infoDisponibilidad.tiempoMantenimiento, resumenOEE.infoRendimiento.tiempoMantenimiento, resumenOEE.infoCalidad.tiempoMantenimiento],
    [this.translateService.instant('alarmas'), 0, resumenOEE.infoDisponibilidad.tiempoAlarma, resumenOEE.infoRendimiento.tiempoAlarma, resumenOEE.infoCalidad.tiempoAlarma],
    [this.translateService.instant('apagadas'), 0, resumenOEE.infoDisponibilidad.tiempoApagada, resumenOEE.infoRendimiento.tiempoApagada, resumenOEE.infoCalidad.tiempoApagada],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, resumenOEE.tTotalNegativoCalidad]];


    return { columns: dataGraficoHoras };
  }
  public get_OEE_data_Barras_prefiltrado(historico_completo_filtrado) {
    var resumenOEE = (this.get_resumen_OEE_prefiltrado(historico_completo_filtrado));

    // se prepara el columns data del grafico directamente
    var dataGraficoHoras = [[this.translateService.instant('total'), resumenOEE.tTotal, resumenOEE.infoDisponibilidad.tiempoPositivo, resumenOEE.infoRendimiento.tiempoPositivo, resumenOEE.infoCalidad.tiempoPositivo],
    [this.translateService.instant('rendimiento'), 0, 0, resumenOEE.tTotalPerdidaRendimiento, 0],
    [this.translateService.instant('microparadas'), 0, resumenOEE.infoDisponibilidad.tiempoMicroParada, resumenOEE.infoRendimiento.tiempoMicroParada, resumenOEE.infoCalidad.tiempoMicroParada],
    [this.translateService.instant('paradas'), 0, resumenOEE.infoDisponibilidad.tiempoParada, resumenOEE.infoRendimiento.tiempoParada, resumenOEE.infoCalidad.tiempoParada],
    [this.translateService.instant('mantenimientos'), 0, resumenOEE.infoDisponibilidad.tiempoMantenimiento, resumenOEE.infoRendimiento.tiempoMantenimiento, resumenOEE.infoCalidad.tiempoMantenimiento],
    [this.translateService.instant('alarmas'), 0, resumenOEE.infoDisponibilidad.tiempoAlarma, resumenOEE.infoRendimiento.tiempoAlarma, resumenOEE.infoCalidad.tiempoAlarma],
    [this.translateService.instant('apagadas'), 0, resumenOEE.infoDisponibilidad.tiempoApagada, resumenOEE.infoRendimiento.tiempoApagada, resumenOEE.infoCalidad.tiempoApagada],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, resumenOEE.tTotalNegativoCalidad]];


    return { columns: dataGraficoHoras };
  }

  public load_OEE_Lineal_prefiltrado(graficoLineal, fechaIni, fechaFin, historico_completo_filtrado, objetivoOee) {

    this.myCharts.load(graficoLineal, this.get_OEE_data_Lineal_prefiltrado(historico_completo_filtrado, fechaIni, fechaFin, objetivoOee));

  }
  private get_OEE_data_Lineal_prefiltrado(historico_completo_filtrado, fechaIni, fechaFin, objetivoOee) {

    var indexColumna = ['x', 'disponibilidad', 'rendimiento', 'calidad', 'oee', 'objetivo'];
    var columnas_Lineal = [['x'], ['disponibilidad'], ['rendimiento'], ['calidad'], ['oee'], ['objetivo']];  //[[x          , '2023/01/01', '2023/01/02',...], ['Ejecucion',         100 ,         200 ,...]]
    var nombres_Lineal = {
      disponibilidad: this.translateService.instant('disponibilidad')
      , rendimiento: this.translateService.instant('rendimiento')
      , calidad: this.translateService.instant('calidad')
      , oee: this.translateService.instant('oee')
      , objetivo: this.translateService.instant('objetivo')
    }; // {Ejecucion: Ejecucion, Parada: Parada ,...}

    var fechaGrafico: Date = new Date(fechaIni);
    var tipoAgrupado = 0; // 0 => dia, 1=> semana 2 => mes
    var difDays = (fechaFin.getTime() - fechaIni.getTime()) / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana     
      tipoAgrupado = 1;
      fechaGrafico = this.myFunctions.startOfWeek(fechaGrafico);
    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      tipoAgrupado = 2;
      fechaGrafico = this.myFunctions.startOfMonth(fechaGrafico);
    } else { // no hay que agrupar
      tipoAgrupado = 0;
    }

    var fechaFin_calculo = new Date(fechaFin);
    fechaFin_calculo.setDate(fechaFin_calculo.getDate() + 1);

    while (fechaGrafico < fechaFin) {
      var proxima_fechaGrafico: Date = new Date(fechaGrafico);
      switch (tipoAgrupado) {
        case 1: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 7); break;
        case 2: proxima_fechaGrafico.setMonth(proxima_fechaGrafico.getMonth() + 1); break;
        default: proxima_fechaGrafico.setDate(proxima_fechaGrafico.getDate() + 1); break;
      }
      var fecha_Filtro_Ini: Date = this.myFunctions.Max_date(new Date(fechaGrafico), new Date(fechaIni)) // por si se filtra desde una fecha concreta que no corresponda a principio de mes o semana
      var fecha_Filtro_Fin: Date = this.myFunctions.Min_date(new Date(proxima_fechaGrafico), new Date(fechaFin_calculo)) // por si se filtra hasta una fecha concreta que no corresponda a principio de mes o semana
      fecha_Filtro_Fin.setDate(fecha_Filtro_Fin.getDate() - 1);
      var historico_completo_filtrado_filtrado: any = this.myFunctions.copy((historico_completo_filtrado as any).filter(
        f => (f.fechaTurno >= fecha_Filtro_Ini && f.fechaTurno <= fecha_Filtro_Fin)
      ));
      var resumenSemana = this.get_resumen_OEE_prefiltrado(historico_completo_filtrado_filtrado);
      columnas_Lineal[indexColumna.indexOf('x')].push(this.myFunctions.dateToDD_MM_YYYY_forwardslash(fechaGrafico));
      columnas_Lineal[indexColumna.indexOf('disponibilidad')].push('' + resumenSemana.infoDisponibilidad.porcentaje);
      columnas_Lineal[indexColumna.indexOf('rendimiento')].push('' + resumenSemana.infoRendimiento.porcentaje);
      columnas_Lineal[indexColumna.indexOf('calidad')].push('' + resumenSemana.infoCalidad.porcentaje);
      columnas_Lineal[indexColumna.indexOf('oee')].push('' + resumenSemana.infoDisponibilidad.porcentaje * resumenSemana.infoRendimiento.porcentaje * resumenSemana.infoCalidad.porcentaje / 10000);
      columnas_Lineal[indexColumna.indexOf('objetivo')].push('' + objetivoOee);

      if (fechaGrafico >= proxima_fechaGrafico) break; // seguridad para no entrar en bucle
      fechaGrafico = proxima_fechaGrafico;
    }

    var data_Lineal = {
      columns: columnas_Lineal,
      names: nombres_Lineal
    }

    return data_Lineal;
  }

  //#endregion
  //#region PERDIDAS  
  public load_Donut_perdidas_prefiltrado(chartDonut, historico_completo_filtrado, idTipoOEE) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0 || f.operacion == 1 || f.idTipoOEE > 0)
    var data_grid: any = this.crear_agrupado(historico_completo_filtrado, ['perdida'], 2, idTipoOEE, false);
    // load_Donut_data_grid tiene el sleep dentro
    this.load_Donut_data_grid('perdida', 'tTotalParcial', chartDonut, data_grid)
    return data_grid.length > 0; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  //#endregion
  //#region ALARMAS
  public load_Donut_alarmas_prefiltrado(chartDonut, historico_completo_filtrado, idTipoOEE) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0 || f.operacion == 1 || f.idTipoOEE > 0)
    var data_grid: any = this.crear_agrupado(historico_completo_filtrado, ['alarma'], 4, idTipoOEE, false);
    // load_Donut_data_grid tiene el sleep dentro
    this.load_Donut_data_grid('alarma', 'tTotalParcial', chartDonut, data_grid)
    return data_grid.length > 0; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  //#endregion
  //#region MANTENIMIENTOS
  public load_Donut_mantenimientos_prefiltrado(chartDonut, historico_completo_filtrado, idTipoOEE) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0 || f.operacion == 1 || f.idTipoOEE > 0)
    var data_grid: any = this.crear_agrupado(historico_completo_filtrado, ['mantenimiento'], 3, idTipoOEE, false);
    // load_Donut_data_grid tiene el sleep dentro
    this.load_Donut_data_grid('mantenimiento', 'tTotalParcial', chartDonut, data_grid)
    return data_grid.length > 0; // SI NO TIENE DATOS DEVUELVE FALSE PARA OCULTAR EL GRAFICO
  }
  //#endregion

  //#endregion
  //#region CALCULOS ---------------------------------------------------------------------------------------------------- 
  private get_resumen_estados_prefiltrado(idMaquinas, historico_completo_filtrado) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0 || f.operacion == 1 || f.idTipoOEE > 0)
    return this.calcular_resumen_estados(idMaquinas, historico_completo_filtrado);
  }
  private get_resumen_estados(idMaquinas, fechaIni, fechaFin, tipoTurnos) {

    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE ESTADOS
    var historico_completo_filtrado: any = this.myFunctions.copy((this.historico_completo as any).filter(
      f => (idMaquinas.includes(f.idMaquina) || idMaquinas.length == 0) &&
        (f.fechaTurno >= fechaIni && f.fechaTurno <= fechaFin) &&
        (tipoTurnos.includes(f.idTipoTurno) || tipoTurnos.length == 0) &&
        (f.idTipoTurno > 0)
    ));
    return this.calcular_resumen_estados(idMaquinas, historico_completo_filtrado);
  }
  private calcular_resumen_estados(idMaquinas, historico_completo_filtrado) {
    // SE COGEN TODOS LOS ESTADOS DE LAS MAQUINAS
    var infEstados_maquinas: any = this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado < 100));

    // SE IDENTIFICAN LOS ESTADOS DIFERENTES (por si se filtran varias maquinas) Y SE CREA LA ESTRUCTURA QUE SE VA A DEVOLVER
    var infEstados: any = [];
    var idsEstadosLeidos = [];
    infEstados_maquinas.forEach(
      estado => {
        if (!idsEstadosLeidos.includes(estado.idSubestado)) {
          var jEstado = {};
          jEstado["id"] = estado.idSubestado;
          jEstado["idEstado"] = estado.idEstado;
          jEstado["idSubEstado"] = estado.idSubestado;
          jEstado["nombre"] = estado.nombre;
          jEstado["color"] = estado.color;
          jEstado["tiempo"] = 0;

          infEstados.push(jEstado);
          idsEstadosLeidos.push(estado.idSubestado);
        }
      });

    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS ESTADOS NECESARIOS
    infEstados.forEach(
      estado => {
        historico_completo_filtrado.forEach(
          row => {
            if (row.idEstado == 2) {
              if (row.micro == 1 && estado.idSubEstado == 12) {
                // esto se hace para identificar las microParadas Y  no se cuenten las paradas en microParadas
                estado.tiempo += row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;
              } else if (row.micro == 0 && estado.idSubEstado == 2) {
                estado.tiempo += row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;
              }
            } else if (row.idEstado == estado.idEstado) {
              estado.tiempo += row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;
            }
          });
      });

    return infEstados;
  }

  private get_resumen_subestados_prefiltrado(idMaquinas, historico_completo_filtrado) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0)
    return this.calcular_resumen_subestados(idMaquinas, historico_completo_filtrado);
  }
  private get_resumen_subestados(idMaquinas, fechaIni, fechaFin, tipoTurnos) {

    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = this.myFunctions.copy((this.historico_completo as any).filter(
      f => (idMaquinas.includes(f.idMaquina) || idMaquinas.length == 0) &&
        (f.fechaTurno >= fechaIni && f.fechaTurno <= fechaFin) &&
        (tipoTurnos.includes(f.idTipoTurno) || tipoTurnos.length == 0) &&
        (f.idTipoTurno > 0)
    ));
    return this.calcular_resumen_subestados(idMaquinas, historico_completo_filtrado);
  }
  private calcular_resumen_subestados(idMaquinas, historico_completo_filtrado) {
    /* COMO AUN NO HACIA FALTA, LOS SUBESTADOS NO SE CALCULAN PREFILTRADOS! SI HACE FALTA EN ALGUN FUTURO, SE HARA IGUAL QUE LOS ESTADOS! (miralos antes de hacer nada) */

    // SE COGEN TODOS LOS SUBESTADOS DE LAS MAQUINAS
    var infSubEstados_maquinas: any = this.myFunctions.copy((this.info_subestados as any).filter(f => idMaquinas.includes(f.idMaquina) && f.idSubestado >= 100));

    // SE IDENTIFICAN LOS SUBESTADOS DIFERENTES (por si se filtran varias maquinas) Y SE CREA LA ESTRUCTURA QUE SE VA A DEVOLVER
    var infSubEstados: any = [];
    var idsSubEstadosLeidos = [];
    infSubEstados_maquinas.forEach(
      subestado => {
        if (!idsSubEstadosLeidos.includes(subestado.idSubestado)) {
          var jSubEstado = {};
          jSubEstado["id"] = subestado.idSubestado;
          jSubEstado["idEstado"] = subestado.idEstado;
          jSubEstado["idSubEstado"] = subestado.idSubestado;
          jSubEstado["nombre"] = subestado.nombre;
          jSubEstado["color"] = subestado.color;
          jSubEstado["tiempo"] = 0;

          infSubEstados.push(jSubEstado);
          idsSubEstadosLeidos.push(subestado.idSubestado);
        }
      });

    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS SUBESTADOS NECESARIOS
    infSubEstados.forEach(
      subestado => {
        historico_completo_filtrado.forEach(
          row => {
            if (row.idSubEstado == subestado.idSubEstado) {
              subestado.tiempo += row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;
            }
          });
      });

    return infSubEstados;
  }

  private get_resumen_OEE_SIN_filtro(idMaquinas, fechaIni, fechaFin, tipoTurnos) { // esta funcion deveria desaparecer y pasar todo al filtro kendo
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = this.myFunctions.copy((this.historico_completo as any).filter(
      f => (idMaquinas.includes(f.idMaquina) || idMaquinas.length == 0) &&
        (f.fechaTurno >= fechaIni && f.fechaTurno <= fechaFin) &&
        (tipoTurnos.includes(f.idTipoTurno) || tipoTurnos.length == 0) &&
        (f.idTipoTurno > 0)
    ));

    return this.calcular_resumen_OEE(historico_completo_filtrado);
  }
  private get_resumen_OEE_prefiltrado(historico_completo_filtrado) {
    /* COMO A VECES SE LE LLAMA DESDE SITIOS DONDE SE MUESTRA DENTRO Y FUERA DE TURNOS, ASEGURAMOS QUE PARA EL CALCULO SE QUITAN LAS DE FUERA DE TURNO */
    historico_completo_filtrado = historico_completo_filtrado.filter(f => f.idTipoTurno > 0 || f.operacion == 1 || f.idTipoOEE > 0)
    return this.calcular_resumen_OEE(historico_completo_filtrado);
  }
  private get_resumen_OEE(filtro) {
    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE SUBESTADOS
    var historico_completo_filtrado: any = filterBy(this.myFunctions.copy((this.historico_completo as any)), filtro);
    return this.calcular_resumen_OEE(historico_completo_filtrado);
  }
  private calcular_resumen_OEE(historico_completo_filtrado) {
    // COMO HAY MAS DE UN FILTRO SE FILTRA LA TABLA Y DESPUES AQUI SE CALCULA EL OEE DE ESA TABLA FILTRADA.

    // SE IDENTIFICAN LOS SUBESTADOS DIFERENTES (por si se filtran varias maquinas) Y SE CREA LA ESTRUCTURA QUE SE VA A DEVOLVER
    var infoDisponibilidad: any = { tipoOEE: 1, nombre: "Disponibilidad", tiempoPositivo: 0, tiempoMicroParada: 0, tiempoParada: 0, tiempoAlarma: 0, tiempoApagada: 0, tiempoMantenimiento: 0, porcentaje: 0 };
    var infoRendimiento: any = { tipoOEE: 2, nombre: "Rendimiento", tiempoPositivo: 0, tiempoMicroParada: 0, tiempoParada: 0, tiempoAlarma: 0, tiempoApagada: 0, tiempoMantenimiento: 0, porcentaje: 0 };
    var infoCalidad: any = { tipoOEE: 3, nombre: "Calidad", tiempoPositivo: 0, tiempoMicroParada: 0, tiempoParada: 0, tiempoAlarma: 0, tiempoApagada: 0, tiempoMantenimiento: 0, porcentaje: 0 };

    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS SUBESTADOS NECESARIOS
    var tTotal = 0;
    var tTotalPerdidaRendimiento = 0;
    var tTotalNegativoCalidad = 0;

    historico_completo_filtrado.forEach(
      row => {
        if (row.idTipoOEE > 0) {
          var tOperacion = 0;
          // calculamos el tiempo que afecta a OEE por cada linea, teniendo en cuenta las lineas simultaneas.
          var tiempoOEE = row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas

          switch (row.idTipoOEE) {
            case 1:
              switch (row.idEstado) {
                case 1: case 3: infoDisponibilidad.tiempoParada += tiempoOEE; break; // esto no deberia pasar nunca, por eso se le asigna a parada (hay empresas que lo han pedido)
                case 2: if (row.micro) infoDisponibilidad.tiempoMicroParada += tiempoOEE; else infoDisponibilidad.tiempoParada += tiempoOEE; break;
                case 4: infoDisponibilidad.tiempoMantenimiento += tiempoOEE; break;
                case 6: infoDisponibilidad.tiempoAlarma += tiempoOEE; break;
                case 8: infoDisponibilidad.tiempoApagada += tiempoOEE; break;
              }
              break;
            case 2:
              if (row.operacion == 0) {// si no es operacion, se cuenta como tiempo de rendimiento (como si fuese default)
                switch (row.idEstado) {
                  case 1: case 3: tOperacion = tiempoOEE; break;
                  case 2: if (row.micro) infoRendimiento.tiempoMicroParada += tiempoOEE; else infoRendimiento.tiempoParada += tiempoOEE; break;
                  case 4: infoRendimiento.tiempoMantenimiento += tiempoOEE; break;
                  case 6: infoRendimiento.tiempoAlarma += tiempoOEE; break;
                  case 8: infoRendimiento.tiempoApagada += tiempoOEE; break;
                }
              } else {
                tOperacion = tiempoOEE;
              }
              break;
            // TEMPORALMENTE COMENTADO CON ALKORTA PARA TRATAR MAS ADELANTE, CON ESTO EL CALCULO DEL OEE NO TIENE SENTIDO (NO COINCIDEN LAS HORAS)
            // case 3:
            //   if (row.operacion == 0) {// si no es operacion, se cuenta como tiempo de rendimiento (como si fuese default)
            //     switch (row.idEstado) {
            //       case 1: case 3: tTotalNegativoCalidad += tiempoOEE; break;
            //       case 2: if (row.micro) infoCalidad.tiempoMicroParada += tiempoOEE; else infoCalidad.tiempoParada += tiempoOEE; break;
            //       case 4: infoCalidad.tiempoMantenimiento += tiempoOEE; break;
            //       case 6: infoCalidad.tiempoAlarma += tiempoOEE; break;
            //       case 8: infoCalidad.tiempoApagada += tiempoOEE; break;
            //     }
            //   } else {
            //     // tTotalNegativoCalidad += tiempoOEE;
            //     tOperacion = tiempoOEE;
            //   }
            //   break;
            // default:
            //   tOperacion = tiempoOEE;
            //   break;
          }

          if (tOperacion > 0) {
            // Aqui solo entrara si tiempo es de rendimiento y tiene operacion, o si no tiene operacion pero es de ejecucion o preparacion, en cullo caso se tendra
            //  en cuenta el porcentaje de tiempo que repercute a...
            var cantidad_row = row.hmoar_cantidad

            if (cantidad_row <= 0) cantidad_row = 1;

            var tEstimadoOperacion = row.hmoar_tiempoEstimadoOperacion * cantidad_row;

            var tEstimadoTotal = 0;
            if (row.hmoar_tiempoOperacion > 0) { // tiene operacion que no es de preparacion
              tEstimadoTotal += tEstimadoOperacion;
            }
            if (row.hmoar_tiempoPreparacion > 0) { // tiene preparacion
              tEstimadoTotal += row.hmoar_tiempoEstimadoPreparacion;
            }

            var tTotalOperacion = row.hmoar_tiempoTotalOperacion;
            if (tEstimadoOperacion <= 0) { // no tiene operacion o no tiene tiempo estimado en la operacion   
              tTotalOperacion = 0;
            }

            var tEstLag = 0;
            if (tTotalOperacion <= 0)
              // if (row.idOperacion <= 0)
              tEstLag = tOperacion * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
            else
              tEstLag = (tEstimadoTotal * row.duracion / tTotalOperacion) / row.lineasHistorico_maquinas_resumenesSimultaneas;

            // tiempo estimado parcial: despues de tener el tiempo estimado total, y el tiempo total operacion, limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
            var sumaAtiempoRendimiento = tiempoOEE - Math.min(tiempoOEE, tEstLag);
            tTotalPerdidaRendimiento += sumaAtiempoRendimiento;
            if (row.idTipoOEE == 3) {
              // No se podra asignar una perdida directamente a caldiad
              // NO BORRAR: esto asigna la perdida despues de aplicar el desvio de rendimiento!
              // NO BORRAR tTotalNegativoCalidad += Math.min(tiempoOEE, tEstLag); 
            } else {
              if (row.idEstado_pieza == 4 || row.idEstado_pieza == 5) {
                // POR AHORA SI ES UNA PIEZA NSERIE SE CUENTA ENTERA MAL! esto sera configurable 
                tTotalNegativoCalidad += tiempoOEE;
                tTotalPerdidaRendimiento -= sumaAtiempoRendimiento;
              }
              else if (row.hmoar_cantidadAchatarrada + row.hmoar_cantidadApartada > 0) {
                // SI ES UN LOTE CON CANTIDAD ACHATARRADA O APARTA TENDREMOS QUE QUITAR SU PARTE CORRESPONDIENTE DE CALIDAD A LA PERDIDA DE RENDIMIENTO Y AÑADIRLA A CALIDAD 
                // Se mira cuanto tiempo del total es el que afecta como calidad
                var tiempoCalidad = (tiempoOEE * (row.hmoar_cantidadAchatarrada + row.hmoar_cantidadApartada)) / cantidad_row;
                // PERO EN CALIDAD si que ira este tiempo inregro. Por eso se hace despues de sumarlo arriba            
                tiempoCalidad = Math.min(tiempoCalidad, tEstLag);
                // Se añade al tiempo negativo de calidad
                tTotalNegativoCalidad += tiempoCalidad;
                // ESTO NO SE HACE SI ES ENTERA DE CHATARRA... NO HARA FALTA?? // // // Quitamos el tiempo correspondiente del estimado. Este tiempo es ya de calidad.
                // ESTO NO SE HACE SI ES ENTERA DE CHATARRA... NO HARA FALTA?? // // tEstimadoTotal -= row.hmoar_tiempoEstimadoOperacion * (row.hmoar_cantidadAchatarrada + row.hmoar_cantidadApartada);
                // ESTO NO SE HACE SI ES ENTERA DE CHATARRA... NO HARA FALTA?? // // // En caso de este tiempo ser menor que el estimado, solo restaremos el tiempo estimado a la perdida de rendimiento, pues es el que le hemos sumado antes. 
                // ESTO NO SE HACE SI ES ENTERA DE CHATARRA... NO HARA FALTA?? Se resta del tiempo de perdida de RENDIMIENTO, pues ya se sumara en calidad
                tTotalPerdidaRendimiento -= Math.min(sumaAtiempoRendimiento, tiempoCalidad); //NO LE PODEMOS QUITAR MAS DE EL QUE LE HEMOS AÑADIDO!
              }
            }
          }
          tTotal += tiempoOEE;
        }
      });

    //#region REDONDEO DE MINUTOS -- En muchos casos los minutos no cuadran en la solucion final. por eso despues de calcular todos los datos se cuadran los minutos de arriba a abajo.
    // Disponibilidad
    infoDisponibilidad.tiempoMicroParada = this.myFunctions.rendondeo_mod(infoDisponibilidad.tiempoMicroParada, 60.0)
    infoDisponibilidad.tiempoParada = this.myFunctions.rendondeo_mod(infoDisponibilidad.tiempoParada, 60.0)
    infoDisponibilidad.tiempoAlarma = this.myFunctions.rendondeo_mod(infoDisponibilidad.tiempoAlarma, 60.0)
    infoDisponibilidad.tiempoApagada = this.myFunctions.rendondeo_mod(infoDisponibilidad.tiempoApagada, 60.0)
    infoDisponibilidad.tiempoMantenimiento = this.myFunctions.rendondeo_mod(infoDisponibilidad.tiempoMantenimiento, 60.0)
    // Rendimiento
    infoRendimiento.tiempoMicroParada = this.myFunctions.rendondeo_mod(infoRendimiento.tiempoMicroParada, 60.0)
    infoRendimiento.tiempoParada = this.myFunctions.rendondeo_mod(infoRendimiento.tiempoParada, 60.0)
    infoRendimiento.tiempoAlarma = this.myFunctions.rendondeo_mod(infoRendimiento.tiempoAlarma, 60.0)
    infoRendimiento.tiempoApagada = this.myFunctions.rendondeo_mod(infoRendimiento.tiempoApagada, 60.0)
    infoRendimiento.tiempoMantenimiento = this.myFunctions.rendondeo_mod(infoRendimiento.tiempoMantenimiento, 60.0)
    // Calidad
    infoCalidad.tiempoMicroParada = this.myFunctions.rendondeo_mod(infoCalidad.tiempoMicroParada, 60.0)
    infoCalidad.tiempoParada = this.myFunctions.rendondeo_mod(infoCalidad.tiempoParada, 60.0)
    infoCalidad.tiempoAlarma = this.myFunctions.rendondeo_mod(infoCalidad.tiempoAlarma, 60.0)
    infoCalidad.tiempoApagada = this.myFunctions.rendondeo_mod(infoCalidad.tiempoApagada, 60.0)
    infoCalidad.tiempoMantenimiento = this.myFunctions.rendondeo_mod(infoCalidad.tiempoMantenimiento, 60.0)
    // DESVIOS REALES: Se ha decidido que todas las perdidas de rendimiento vayan juentas
    tTotalPerdidaRendimiento = this.myFunctions.rendondeo_mod(tTotalPerdidaRendimiento, 60.0)
    // CALIDAD DENTRO DE OPERACIONES
    tTotalNegativoCalidad = this.myFunctions.rendondeo_mod(tTotalNegativoCalidad, 60.0)
    //#endregion

    // DESVIOS: Se ha decidido que todas las perdidas de rendimiento vayan juentas
    tTotalPerdidaRendimiento += infoRendimiento.tiempoMicroParada + infoRendimiento.tiempoParada + infoRendimiento.tiempoAlarma +
      infoRendimiento.tiempoApagada + infoRendimiento.tiempoMantenimiento;
    infoRendimiento.tiempoMicroParada = 0;
    infoRendimiento.tiempoParada = 0;
    infoRendimiento.tiempoAlarma = 0;
    infoRendimiento.tiempoApagada = 0;
    infoRendimiento.tiempoMantenimiento = 0;



    // los demas tiempos:
    infoDisponibilidad.tiempoPositivo = tTotal - 0 - infoDisponibilidad.tiempoMicroParada - infoDisponibilidad.tiempoParada - infoDisponibilidad.tiempoAlarma - infoDisponibilidad.tiempoApagada - infoDisponibilidad.tiempoMantenimiento - 0;
    infoRendimiento.tiempoPositivo = infoDisponibilidad.tiempoPositivo - tTotalPerdidaRendimiento - infoRendimiento.tiempoMicroParada - infoRendimiento.tiempoParada - infoRendimiento.tiempoAlarma - infoRendimiento.tiempoApagada - infoRendimiento.tiempoMantenimiento - 0;
    infoCalidad.tiempoPositivo = infoRendimiento.tiempoPositivo - 0 - infoCalidad.tiempoMicroParada - infoCalidad.tiempoParada - infoCalidad.tiempoAlarma - infoCalidad.tiempoApagada - infoCalidad.tiempoMantenimiento - tTotalNegativoCalidad;

    // porcentajes OEE:
    infoDisponibilidad.porcentaje = 0;
    infoRendimiento.porcentaje = 0;
    infoCalidad.porcentaje = 0;
    if ((infoDisponibilidad.tiempoPositivo + 0 + infoDisponibilidad.tiempoMicroParada + infoDisponibilidad.tiempoParada + infoDisponibilidad.tiempoAlarma + infoDisponibilidad.tiempoApagada + infoDisponibilidad.tiempoMantenimiento + 0) != 0)
      infoDisponibilidad.porcentaje = infoDisponibilidad.tiempoPositivo * 100 / (infoDisponibilidad.tiempoPositivo + 0 + infoDisponibilidad.tiempoMicroParada + infoDisponibilidad.tiempoParada + infoDisponibilidad.tiempoAlarma + infoDisponibilidad.tiempoApagada + infoDisponibilidad.tiempoMantenimiento + 0);
    if ((infoRendimiento.tiempoPositivo + tTotalPerdidaRendimiento + infoRendimiento.tiempoMicroParada + infoRendimiento.tiempoParada + infoRendimiento.tiempoAlarma + infoRendimiento.tiempoApagada + infoRendimiento.tiempoMantenimiento + 0) != 0)
      infoRendimiento.porcentaje = infoRendimiento.tiempoPositivo * 100 / (infoRendimiento.tiempoPositivo + tTotalPerdidaRendimiento + infoRendimiento.tiempoMicroParada + infoRendimiento.tiempoParada + infoRendimiento.tiempoAlarma + infoRendimiento.tiempoApagada + infoRendimiento.tiempoMantenimiento + 0);
    if ((infoCalidad.tiempoPositivo + 0 + infoCalidad.tiempoMicroParada + infoCalidad.tiempoParada + infoCalidad.tiempoAlarma + infoCalidad.tiempoApagada + infoCalidad.tiempoMantenimiento + tTotalNegativoCalidad) != 0)
      infoCalidad.porcentaje = infoCalidad.tiempoPositivo * 100 / (infoCalidad.tiempoPositivo + 0 + infoCalidad.tiempoMicroParada + infoCalidad.tiempoParada + infoCalidad.tiempoAlarma + infoCalidad.tiempoApagada + infoCalidad.tiempoMantenimiento + tTotalNegativoCalidad);

    // NO DEBERIA, PERO...
    infoDisponibilidad.porcentaje = Math.min(100, Math.max(0, infoDisponibilidad.porcentaje))
    infoRendimiento.porcentaje = Math.min(100, Math.max(0, infoRendimiento.porcentaje))
    infoCalidad.porcentaje = Math.min(100, Math.max(0, infoCalidad.porcentaje))

    return { tTotal, tTotalPerdidaRendimiento, tTotalNegativoCalidad, infoDisponibilidad, infoRendimiento, infoCalidad };
  }


  private calcular_resumen_info(historico_completo_filtrado) {
    // COMO HAY MAS DE UN FILTRO SE FILTRA LA TABLA Y DESPUES AQUI SE CALCULA EL OEE DE ESA TABLA FILTRADA.

    // SE IDENTIFICAN LOS SUBESTADOS DIFERENTES (por si se filtran varias maquinas) Y SE CREA LA ESTRUCTURA QUE SE VA A DEVOLVER
    var tiempo: any = {
      real: { segundos: 0 },
      estimado: { segundos: 0, desvio: { segundos: 0, porcentaje: 0 } },
      previsto: { segundos: 0, desvio: { segundos: 0, porcentaje: 0 } }
    };
    var cantidad: any = { definida: 0, terminada: 0, achatarrada: 0, apartada: 0 };
    var roturasHerramienta: any = {};
    var cambiosPlaca: any = {};

    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS SUBESTADOS NECESARIOS
    historico_completo_filtrado.forEach(
      row => {
        if (row.idTipoOEE == 2 && (row.operacion == 1 || row.idEstado == 1 || row.idEstado == 3)) {
          // calculamos el tiempo que afecta a OEE por cada linea, teniendo en cuenta las lineas simultaneas.
          var tiempoRow = row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;
          tiempo.real.segundos += tiempoRow;

          // Aqui solo entrara si tiempo es de rendimiento y tiene operacion, o si no tiene operacion pero es de ejecucion o preparacion, en cullo caso se tendra
          //  en cuenta el porcentaje de tiempo que repercute a...
          var tEstimadoOperacion = row.hmoar_tiempoEstimadoOperacion * row.hmoar_cantidad; // use lotes o no, la cantidad sera 1 o la cantidad tratada en esa operacion (las 3 cantidades sumadas o 1 en caso de que estas sean 0)
          var tPrevistoOperacion = row.hmoar_tiempoPredictivoOperacion * row.hmoar_cantidad;

          var tEstimadoTotal = 0;
          var tPrevistoTotal = 0;
          if (row.hmoar_tiempoOperacion > 0) { // tiene operacion que no es de preparacion
            tEstimadoTotal += tEstimadoOperacion;
            tPrevistoTotal += tPrevistoOperacion;
          }
          if (row.hmoar_tiempoPreparacion > 0) { // tiene preparacion
            tEstimadoTotal += row.hmoar_tiempoEstimadoPreparacion;
            tPrevistoTotal += row.hmoar_tiempoEstimadoPreparacion;
          }

          var tTotalOperacion = row.hmoar_tiempoTotalOperacion;
          if (tEstimadoOperacion <= 0) { // no tiene operacion o no tiene tiempo estimado en la operacion   
            tTotalOperacion = 0;
            tPrevistoTotal = 0;
          }

          if (tTotalOperacion <= 0) {
            // if (row.idOperacion <= 0){
            tiempo.estimado.segundos += tiempoRow * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
            tiempo.previsto.segundos += tiempoRow * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
          }
          else {
            var tEstimadoParcial = (tEstimadoTotal * row.duracion / tTotalOperacion) / row.lineasHistorico_maquinas_resumenesSimultaneas;
            tiempo.estimado.segundos += tEstimadoParcial;
            tiempo.previsto.segundos += (tPrevistoTotal * row.duracion / tTotalOperacion) / row.lineasHistorico_maquinas_resumenesSimultaneas;

            // solo cuento las piezas que tienen una operacion asignada. PERO HAY QUE CALCULAR LA CANTIDAD PARCIAL SEGUN SU TIEMPO!
            cantidad.definida += Math.floor(row.hmoar_cantidad * tEstimadoParcial / tTotalOperacion);
            cantidad.terminada += Math.floor(row.hmoar_cantidadTerminada * tEstimadoParcial / tTotalOperacion);
            cantidad.achatarrada += Math.floor(row.hmoar_cantidadAchatarrada * tEstimadoParcial / tTotalOperacion);
            cantidad.apartada += Math.floor(row.hmoar_cantidadApartada * tEstimadoParcial / tTotalOperacion);
          }
        }
      });

    tiempo.estimado.desvio.segundos = tiempo.real.segundos - tiempo.estimado.segundos;
    tiempo.previsto.desvio.segundos = tiempo.real.segundos - tiempo.previsto.segundos;

    tiempo.estimado.desvio.porcentaje = Math.floor((tiempo.real.segundos - tiempo.estimado.segundos) * 100 / tiempo.estimado.segundos);
    tiempo.previsto.desvio.porcentaje = Math.floor((tiempo.real.segundos - tiempo.previsto.segundos) * 100 / tiempo.previsto.segundos);

    return { tiempo, cantidad, roturasHerramienta, cambiosPlaca };
  }
  private crear_compacto(historico_completo_filtrado, columnas, tipoOEE) {
    /* COMPACTA LAS FECHAINI Y FECHAFIN */
    return this.crear_agrupado(historico_completo_filtrado, columnas, 2, tipoOEE, true);
  }
  private crear_agrupado(historico_completo_filtrado, columnas, tipoAgrupado, idTipoOEE, continuas) {
    /*
    
        tipoAgrupado:
          1- Rendimiento Operacion
          2- Perdidas
          3- Mantenimientos
          4- Alarmas
    
    */



    // DEFINIMOS LA VARIABLE QUE RELLENAREMOS SEGUN EL AGRUPADO
    var grid_data_array: any = [];

    var idHO_contados: any = [];
    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS SUBESTADOS NECESARIOS
    historico_completo_filtrado.forEach(
      rowOriginal => {
        var row = this.myFunctions.copy(rowOriginal);

        // ***  tipoAgrupado  ***
        // Para que sea mas facil la comprension de que es cada punto. Se creara el bool aqui para aplicar en el if donde le toque.
        var tipoRendimientoOperacion = (tipoAgrupado == 1 && row.idTipoOEE == 2 && (row.operacion || row.idEstado == 1 || row.idEstado == 3));
        var tipoPerdidas =
          tipoAgrupado == 2 &&
          (row.idTipoOEE == idTipoOEE || idTipoOEE == 0) &&
          ((row.idEstado == 2 && !row.micro) || row.idEstado == 6 || row.idEstado == 8 || // Todas las paradas (no micro), alarmas y apagadas sin perdida
            row.idPerdida > 0); // Todos los tiempos con alguna perdida (sea el estado que sea)
        var tipoMantenimientos = tipoAgrupado == 3 && row.idMaquinas_mantenimientos > 0 && (row.idTipoOEE == idTipoOEE || idTipoOEE == 0);
        var tipoAlarmas = tipoAgrupado == 4 && row.idEstado == 6 && (row.idTipoOEE == idTipoOEE || idTipoOEE == 0);

        if (tipoRendimientoOperacion || tipoPerdidas || tipoMantenimientos || tipoAlarmas) {
          // SI ES UNA PIEZA DE CALIDAD Y ESTAMOS BUSCANDO RENDIMIENTO, hay que quitar la linea 
          if (!(tipoAgrupado == 1 && (row.idEstado_pieza == 4 || row.idEstado_pieza == 5) && idTipoOEE == 2)) {

            row.perdida_achatarra = ""
            if (tipoAgrupado == 1 && idTipoOEE == 3) {
              // AQUI TAMBIEN SE TENDRAN QUE METER LOS MOTIVOS DE PERDIDA CUANDO HAYA CANTIDAD APARTADA O ACHATARRADA DESDE LA TABLA QUE LE CORRESPONDA. 
              var pieza = (this.info_nserie as any).find(f => f.id == row.idHistorico_piezas);
              if (pieza) {
                if (row.idEstado_pieza == 4)
                  row.perdida_achatarra = pieza.perdida_apartada != "" ? row.perdida_achatarra = pieza.perdida_apartada : pieza.observacion_apartada;
                else if (row.idEstado_pieza == 5)
                  row.perdida_achatarra = pieza.perdida_achatarra != "" ? row.perdida_achatarra = pieza.perdida_achatarra : pieza.observacion_achatarrada;
              }
            }

            if (tipoAgrupado == 1)
              var a = 1

            var agrupado = ""
            if (continuas) {
              var mismoRow = true;
              if (grid_data_array.length > 0) {
                if (row.fechaIni.getTime() != grid_data_array[grid_data_array.length - 1].fechaFin.getTime()) {
                  mismoRow = false;
                }
                else {
                  columnas.forEach(columna => {
                    if (row[columna] != grid_data_array[grid_data_array.length - 1][columna]) {
                      mismoRow = false;
                    }
                  });
                }
              } else {
                mismoRow = false;
              }
              if (mismoRow) {
                agrupado = (grid_data_array.length - 1) + "";
              } else {
                agrupado = grid_data_array.length; // cuando sea 0 o no cumpla criterio, crea una linea nueva.
              }
              // CRITERIO DE AGRUPACION

            } else {
              columnas.forEach(columna => { agrupado += "_" + row[columna] });
            }

            if (grid_data_array[agrupado] == undefined) {// crear row vacia


              grid_data_array[agrupado] = {};

              // si se hace el compacto hay que añadir las variables que lo compactan. 
              if (continuas) {
                columnas.forEach(columna => { grid_data_array[agrupado][columna] = row[columna]; });
              }

              //IDs para redirect
              grid_data_array[agrupado].idsHistorico_piezas = [];
              grid_data_array[agrupado].idsHistoricoOperaciones = [];
              grid_data_array[agrupado].idsMaquinas = [];
              grid_data_array[agrupado].idsOperarios = [];
              grid_data_array[agrupado].idsClientes = [];
              grid_data_array[agrupado].idsPiezas = [];
              grid_data_array[agrupado].idsOperaciones = [];
              // añadir datos principales
              grid_data_array[agrupado].clientes = [];
              grid_data_array[agrupado].operarios = [];
              grid_data_array[agrupado].operario_colores = [];
              grid_data_array[agrupado].operario_reducidos = [];
              grid_data_array[agrupado].maquinas = [];
              grid_data_array[agrupado].maquina_colores = [];
              grid_data_array[agrupado].ofs = [];
              grid_data_array[agrupado].planos = [];
              grid_data_array[agrupado].operaciones = [];
              grid_data_array[agrupado].piezas = [];
              grid_data_array[agrupado].perdidas = [];
              grid_data_array[agrupado].subperdidas = [];
              grid_data_array[agrupado].grupoperdidas = [];
              grid_data_array[agrupado].mantenimientos = [];
              grid_data_array[agrupado].descripciones = [];
              grid_data_array[agrupado].alarmas = [];
              grid_data_array[agrupado].observacionesHistoricoPerdidas = [];
              grid_data_array[agrupado].perdidas_achatarra = [];
              grid_data_array[agrupado].nseries = [];
              grid_data_array[agrupado].coladas = [];
              grid_data_array[agrupado].lotes = [];

              // añadir datos calculados
              grid_data_array[agrupado].tTotal = 0;
              grid_data_array[agrupado].tTotalParcial = 0;
              grid_data_array[agrupado].tEstimadoTotal = 0;
              grid_data_array[agrupado].tEstimadoParcial = 0;
              grid_data_array[agrupado].tPrevistoTotal = 0;
              grid_data_array[agrupado].tPredictivoParcial = 0;

              grid_data_array[agrupado].desvioEstimadoSegundos = 0;
              grid_data_array[agrupado].desvioPrevistoSegundos = 0;
              grid_data_array[agrupado].desvioEstimadoPorcen = 0;
              grid_data_array[agrupado].desvioPrevistoPorcen = 0;

              grid_data_array[agrupado].cantidadDefinida = 0;
              grid_data_array[agrupado].cantidadDefinidaParcial = 0;
              grid_data_array[agrupado].cantidadTerminada = 0;
              grid_data_array[agrupado].cantidadTerminadaParcial = 0;
              grid_data_array[agrupado].cantidadAchatarrada = 0;
              grid_data_array[agrupado].cantidadAchatarradaParcial = 0;
              grid_data_array[agrupado].cantidadApartada = 0;
              grid_data_array[agrupado].cantidadApartadaParcial = 0;

              grid_data_array[agrupado].fechaIni = row.fechaIni;
              grid_data_array[agrupado].fechaFin = row.fechaFin;
              grid_data_array[agrupado].duracion = 0;
            }

            //IDs para redirect
            if (!grid_data_array[agrupado].idsHistorico_piezas.includes(row.idHistorico_piezas)) {
              grid_data_array[agrupado].idsHistorico_piezas.push(row.idHistorico_piezas)
            }
            if (!grid_data_array[agrupado].idsHistoricoOperaciones.includes(row.idHistorico_operaciones)) {
              grid_data_array[agrupado].idsHistoricoOperaciones.push(row.idHistorico_operaciones)
            }
            if (!grid_data_array[agrupado].idsMaquinas.includes(row.idMaquina)) {
              grid_data_array[agrupado].idsMaquinas.push(row.idMaquina)
            }
            if (!grid_data_array[agrupado].idsOperarios.includes(row.idOperario)) {
              grid_data_array[agrupado].idsOperarios.push(row.idOperario)
            }
            if (!grid_data_array[agrupado].idsClientes.includes(row.idCliente)) {
              grid_data_array[agrupado].idsClientes.push(row.idCliente)
            }
            if (!grid_data_array[agrupado].idsPiezas.includes(row.idPieza)) {
              grid_data_array[agrupado].idsPiezas.push(row.idPieza)
            }
            if (!grid_data_array[agrupado].idsOperaciones.includes(row.idOperacion)) {
              grid_data_array[agrupado].idsOperaciones.push(row.idOperacion)
            }
            // agrupar datos principales
            if (!grid_data_array[agrupado].clientes.includes(row.cliente)) {
              grid_data_array[agrupado].clientes.push(row.cliente)
            }
            if (!grid_data_array[agrupado].operarios.includes(row.operario)) {
              grid_data_array[agrupado].operarios.push(row.operario)
            }
            if (!grid_data_array[agrupado].operario_colores.includes(row.operario_color)) {
              grid_data_array[agrupado].operario_colores.push(row.operario_color)
            }
            if (!grid_data_array[agrupado].operario_reducidos.includes(row.operario_reducido)) {
              grid_data_array[agrupado].operario_reducidos.push(row.operario_reducido)
            }
            if (!grid_data_array[agrupado].maquinas.includes(row.maquina)) {
              grid_data_array[agrupado].maquinas.push(row.maquina)
            }
            if (!grid_data_array[agrupado].maquina_colores.includes(row.maquinaC)) {
              grid_data_array[agrupado].maquina_colores.push(row.maquinaC)
            }
            if (!grid_data_array[agrupado].ofs.includes(row.numeroOF)) {
              grid_data_array[agrupado].ofs.push(row.numeroOF)
            }
            if (!grid_data_array[agrupado].planos.includes(row.numeroPlano)) {
              grid_data_array[agrupado].planos.push(row.numeroPlano)
            }
            if (!grid_data_array[agrupado].operaciones.includes(row.nombreOperacion)) {
              grid_data_array[agrupado].operaciones.push(row.nombreOperacion)
            }
            if (!grid_data_array[agrupado].piezas.includes(row.nombrePieza)) {
              grid_data_array[agrupado].piezas.push(row.nombrePieza)
            }
            if (!grid_data_array[agrupado].perdidas.includes(row.perdida)) {
              grid_data_array[agrupado].perdidas.push(row.perdida)
            }
            if (!grid_data_array[agrupado].subperdidas.includes(row.subPerdida)) {
              grid_data_array[agrupado].subperdidas.push(row.subPerdida)
            }
            if (!grid_data_array[agrupado].grupoperdidas.includes(row.grupoPerdida)) {
              grid_data_array[agrupado].grupoperdidas.push(row.grupoPerdida)
            }
            if (!grid_data_array[agrupado].mantenimientos.includes(row.mantenimiento)) {
              grid_data_array[agrupado].mantenimientos.push(row.mantenimiento)
            }
            if (!grid_data_array[agrupado].descripciones.includes(row.descripcion)) {
              grid_data_array[agrupado].descripciones.push(row.descripcion)
            }
            if (!grid_data_array[agrupado].alarmas.includes(row.alarma)) {
              grid_data_array[agrupado].alarmas.push(row.alarma)
            }
            if (!grid_data_array[agrupado].observacionesHistoricoPerdidas.includes(row.observacionHistoricoPerdida)) {
              grid_data_array[agrupado].observacionesHistoricoPerdidas.push(row.observacionHistoricoPerdida)
            }
            if (!grid_data_array[agrupado].perdidas_achatarra.includes(row.perdida_achatarra)) {
              grid_data_array[agrupado].perdidas_achatarra.push(row.perdida_achatarra)
            }
            if (!grid_data_array[agrupado].nseries.includes(row.nserie)) {
              grid_data_array[agrupado].nseries.push(row.nserie)
            }
            if (!grid_data_array[agrupado].coladas.includes(row.colada)) {
              grid_data_array[agrupado].coladas.push(row.colada)
            }
            if (!grid_data_array[agrupado].lotes.includes(row.lote)) {
              grid_data_array[agrupado].lotes.push(row.lote)
            }

            // agrupar datos calculados
            //  calculamos el tiempo que afecta a OEE por cada linea, teniendo en cuenta las lineas simultaneas.
            var tiempoTotalParcial = row.duracion / row.lineasHistorico_maquinas_resumenesSimultaneas;

            // Aqui solo entrara si tiempo es de rendimiento y tiene operacion, o si no tiene operacion pero es de ejecucion o preparacion, en cullo caso se tendra
            //  en cuenta el porcentaje de tiempo que repercute a...
            var tEstimadoOperacion = row.hmoar_tiempoEstimadoOperacion * row.hmoar_cantidad; // use lotes o no, la cantidad sera 1 o la cantidad tratada en esa operacion (las 3 cantidades sumadas o 1 en caso de que estas sean 0)
            var tPrevistoOperacion = row.hmoar_tiempoPredictivoOperacion * row.hmoar_cantidad;

            var tEstimadoTotal = 0;
            var tPrevistoTotal = 0;
            if (row.hmoar_tiempoOperacion > 0) { // tiene operacion que no es de preparacion
              tEstimadoTotal += tEstimadoOperacion;
              tPrevistoTotal += tPrevistoOperacion;
            }
            if (row.hmoar_tiempoPreparacion > 0) { // tiene preparacion
              tEstimadoTotal += row.hmoar_tiempoEstimadoPreparacion;
              tPrevistoTotal += row.hmoar_tiempoEstimadoPreparacion;
            }

            var tiempoTotal = row.hmoar_tiempoTotalOperacion;

            // Se añaden los datos totales de operacion
            grid_data_array[agrupado].tTotalParcial += tiempoTotalParcial;

            if (tEstimadoOperacion <= 0) { // no tiene operacion o no tiene tiempo estimado en la operacion   
              tiempoTotal = 0;
              tPrevistoTotal = 0;
            }
            if (tiempoTotal <= 0) {
              // OPERACION SIN ASIGNAR
              grid_data_array[agrupado].tEstimadoParcial += tiempoTotalParcial * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
              grid_data_array[agrupado].tPredictivoParcial += tiempoTotalParcial * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);

              grid_data_array[agrupado].tTotal += tiempoTotalParcial;

              grid_data_array[agrupado].tEstimadoTotal += tiempoTotalParcial * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
              grid_data_array[agrupado].tPrevistoTotal += tiempoTotalParcial * (this.info_configuracion.porcentajeEjecucionSinOperacionRepercuteOEE / 100);

              grid_data_array[agrupado].cantidadDefinida = row.hmoar_cantidad; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
              grid_data_array[agrupado].cantidadTerminada = row.hmoar_cantidadTerminada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
              grid_data_array[agrupado].cantidadAchatarrada = row.hmoar_cantidadAchatarrada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
              grid_data_array[agrupado].cantidadApartada = row.hmoar_cantidadApartada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
            }
            else {
              // OPERACION ASIGNADA
              var tEstimadoParcial = (tEstimadoTotal * row.duracion / tiempoTotal) / row.lineasHistorico_maquinas_resumenesSimultaneas;
              grid_data_array[agrupado].tEstimadoParcial += tEstimadoParcial;
              grid_data_array[agrupado].tPredictivoParcial += (tPrevistoTotal * row.duracion / tiempoTotal) / row.lineasHistorico_maquinas_resumenesSimultaneas;

              // solo cuento las piezas que tienen una operacion asignada. PERO HAY QUE CALCULAR LA CANTIDAD PARCIAL SEGUN SU TIEMPO!
              grid_data_array[agrupado].cantidadDefinidaParcial += Math.floor(row.hmoar_cantidad * tEstimadoParcial / tiempoTotal);
              grid_data_array[agrupado].cantidadTerminadaParcial += Math.floor(row.hmoar_cantidadTerminada * tEstimadoParcial / tiempoTotal);
              grid_data_array[agrupado].cantidadAchatarradaParcial += Math.floor(row.hmoar_cantidadAchatarrada * tEstimadoParcial / tiempoTotal);
              grid_data_array[agrupado].cantidadApartadaParcial += Math.floor(row.hmoar_cantidadApartada * tEstimadoParcial / tiempoTotal);

              // si no se agrupa solo por operacion, es necesario guardar que HOs se han tratado para no sumar los tiempos estimados mas de una vez
              if (!idHO_contados.includes(row.idHistorico_operaciones)) {
                grid_data_array[agrupado].tTotal += tiempoTotal + 0;

                grid_data_array[agrupado].tEstimadoTotal += tEstimadoTotal + 0;
                grid_data_array[agrupado].tPrevistoTotal += tPrevistoTotal + 0;

                grid_data_array[agrupado].cantidadDefinida = row.hmoar_cantidad; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
                grid_data_array[agrupado].cantidadTerminada = row.hmoar_cantidadTerminada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
                grid_data_array[agrupado].cantidadAchatarrada = row.hmoar_cantidadAchatarrada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)
                grid_data_array[agrupado].cantidadApartada = row.hmoar_cantidadApartada; // antes se hacia + en cada linea (cuando es por hmr y se hacian cantidades enormes en GMN)

                idHO_contados.push(row.idHistorico_operaciones)
              }
            }

            if (grid_data_array[agrupado].fechaIni > row.fechaIni) {
              grid_data_array[agrupado].fechaIni = row.fechaIni;
            }
            if (grid_data_array[agrupado].fechaFin < row.fechaFin) {
              grid_data_array[agrupado].fechaFin = row.fechaFin;
            }
            grid_data_array[agrupado].duracion += row.duracion;
          }
        }
      });

    //PARA PERDER LA REFERENCIA DEL AGRUPADO, LO PASAMOS A UN ARRAY NORMAL
    var grid_data: any = [];

    Object.keys(grid_data_array).forEach(
      key => {
        //IDs para redirect
        grid_data_array[key].idHistorico_piezas = grid_data_array[key].idsHistorico_piezas.join(',');
        grid_data_array[key].idHistoricoOperaciones = grid_data_array[key].idsHistoricoOperaciones.join(',');
        grid_data_array[key].idMaquina = grid_data_array[key].idsMaquinas.join(',');
        grid_data_array[key].idOperario = grid_data_array[key].idsOperarios.join(',');
        grid_data_array[key].idCliente = grid_data_array[key].idsClientes.join(',');
        grid_data_array[key].idPieza = grid_data_array[key].idsPiezas.join(',');
        grid_data_array[key].idOperacion = grid_data_array[key].idsOperaciones.join(',');
        // Separamos el primer valor principal, si esta dentro del criterio de agrupacion sera unico, sino... nos dara igual y se usara solo la cantidad.
        grid_data_array[key].cliente = grid_data_array[key].clientes.join(',');
        grid_data_array[key].operario = grid_data_array[key].operarios.join(',');
        grid_data_array[key].operario_color = grid_data_array[key].operario_colores.join(',');
        grid_data_array[key].operario_reducido = grid_data_array[key].operario_reducidos.join(',');
        grid_data_array[key].maquina = grid_data_array[key].maquinas.join(',');
        grid_data_array[key].maquina_color = grid_data_array[key].maquina_colores.join(',');
        grid_data_array[key].of = grid_data_array[key].ofs.join(',');
        grid_data_array[key].plano = grid_data_array[key].planos.join(',');
        grid_data_array[key].operacion = grid_data_array[key].operaciones.join(',');
        grid_data_array[key].pieza = grid_data_array[key].piezas.join(',');
        grid_data_array[key].perdida = grid_data_array[key].perdidas.join(',');
        grid_data_array[key].subperdida = grid_data_array[key].subperdidas.join(',');
        grid_data_array[key].grupoperdida = grid_data_array[key].grupoperdidas.join(',');
        grid_data_array[key].mantenimiento = grid_data_array[key].mantenimientos.join(',');
        grid_data_array[key].descripcion = grid_data_array[key].descripciones.join(',');
        grid_data_array[key].alarma = grid_data_array[key].alarmas.join(',');
        grid_data_array[key].observacionHistoricoPerdida = grid_data_array[key].observacionesHistoricoPerdidas.join(',');
        grid_data_array[key].perdida_achatarra = grid_data_array[key].perdidas_achatarra.join(',');
        grid_data_array[key].nserie = grid_data_array[key].nseries.join(',');
        grid_data_array[key].colada = grid_data_array[key].coladas.join(',');
        grid_data_array[key].lote = grid_data_array[key].lotes.join(',');

        grid_data_array[key].identificadorOperacion = '';
        if (grid_data_array[key].of != '')
          grid_data_array[key].identificadorOperacion += ' - ' + grid_data_array[key].of
        if (grid_data_array[key].cliente != '')
          grid_data_array[key].identificadorOperacion += ' - ' + grid_data_array[key].cliente
        if (grid_data_array[key].pieza != '')
          grid_data_array[key].identificadorOperacion += ' - ' + grid_data_array[key].pieza
        if (grid_data_array[key].plano != '')
          grid_data_array[key].identificadorOperacion += ' - ' + grid_data_array[key].plano
        if (grid_data_array[key].operacion != '')
          grid_data_array[key].identificadorOperacion += ' - ' + grid_data_array[key].operacion

        if (grid_data_array[key].identificadorOperacion != "")
          grid_data_array[key].identificadorOperacion = grid_data_array[key].identificadorOperacion.substring(2, grid_data_array[key].identificadorOperacion.length)


        //IDs para redirect
        grid_data_array[key].cantidad_idHistorico_piezas = grid_data_array[key].idsHistorico_piezas.length;
        grid_data_array[key].cantidad_idHistoricoOperaciones = grid_data_array[key].idsHistoricoOperaciones.length;
        grid_data_array[key].cantidad_idMaquina = grid_data_array[key].idsMaquinas.length;
        grid_data_array[key].cantidad_idOperario = grid_data_array[key].idsOperarios.length;
        grid_data_array[key].cantidad_idCliente = grid_data_array[key].idsClientes.length;
        grid_data_array[key].cantidad_idPieza = grid_data_array[key].idsPiezas.length;
        grid_data_array[key].cantidad_idOperacion = grid_data_array[key].idsOperaciones.length;
        // calculamos la cantidad de valores principales, si esta dentro del criterio de agrupacion nos dara igual... sino, se usara como numero de operaciones (por ejemplo)
        grid_data_array[key].cantidad_clientes = grid_data_array[key].clientes.length;
        grid_data_array[key].cantidad_operarios = grid_data_array[key].operarios.length;
        grid_data_array[key].cantidad_operario_colores = grid_data_array[key].operario_colores.length;
        grid_data_array[key].cantidad_operario_reducidos = grid_data_array[key].operario_reducidos.length;
        grid_data_array[key].cantidad_maquinas = grid_data_array[key].maquinas.length;
        grid_data_array[key].cantidad_maquina_colores = grid_data_array[key].maquina_colores.length;
        grid_data_array[key].cantidad_ofs = grid_data_array[key].ofs.length;
        grid_data_array[key].cantidad_planos = grid_data_array[key].planos.length;
        grid_data_array[key].cantidad_operaciones = grid_data_array[key].operaciones.length;
        grid_data_array[key].cantidad_piezas = grid_data_array[key].piezas.length;
        grid_data_array[key].cantidad_perdidas = grid_data_array[key].perdidas.length;
        grid_data_array[key].cantidad_subperdidas = grid_data_array[key].subperdidas.length;
        grid_data_array[key].cantidad_grupoperdidas = grid_data_array[key].grupoperdidas.length;
        grid_data_array[key].cantidad_mantenimientos = grid_data_array[key].mantenimientos.length;
        grid_data_array[key].cantidad_descripciones = grid_data_array[key].descripciones.length;
        grid_data_array[key].cantidad_alarmas = grid_data_array[key].alarmas.length;
        grid_data_array[key].cantidad_observacionesHistoricoPerdidas = grid_data_array[key].observacionesHistoricoPerdidas.length;
        grid_data_array[key].cantidad_perdidas_achatarra = grid_data_array[key].perdidas_achatarra.length;
        grid_data_array[key].cantidad_nserie = grid_data_array[key].nseries.length;
        grid_data_array[key].cantidad_colada = grid_data_array[key].coladas.length;
        grid_data_array[key].cantidad_lote = grid_data_array[key].lotes.length;

        // se calculan los desvios
        grid_data_array[key].desvioEstimadoSegundos = grid_data_array[key].tTotal - grid_data_array[key].tEstimadoTotal;
        grid_data_array[key].desvioPrevistoSegundos = grid_data_array[key].tTotal - grid_data_array[key].tPrevistoTotal;
        grid_data_array[key].desvioEstimadoPorcen = Math.floor(grid_data_array[key].tTotal - grid_data_array[key].tEstimadoTotal) * 100 / grid_data_array[key].tEstimadoTotal;
        grid_data_array[key].desvioPrevistoPorcen = Math.floor(grid_data_array[key].tTotal - grid_data_array[key].tPrevistoTotal) * 100 / grid_data_array[key].tPrevistoTotal;

        grid_data_array[key].desvioEstimadoParcialSegundos = grid_data_array[key].tTotalParcial - grid_data_array[key].tEstimadoParcial;
        grid_data_array[key].desvioPrevistoParcialSegundos = grid_data_array[key].tTotalParcial - grid_data_array[key].tPredictivoParcial;
        grid_data_array[key].desvioEstimadoParcialPorcen = Math.floor(grid_data_array[key].tTotalParcial - grid_data_array[key].tEstimadoParcial) * 100 / grid_data_array[key].tEstimadoParcial;
        grid_data_array[key].desvioPrevistoParcialPorcen = Math.floor(grid_data_array[key].tTotalParcial - grid_data_array[key].tPredictivoParcial) * 100 / grid_data_array[key].tPredictivoParcial;

        grid_data.push(grid_data_array[key]);
      });

    return grid_data;
  }
  private load_Donut_data_grid(columna_sobre, columna_de, chartDonut, data_grid) {
    var newData: any = {};
    console.log('______________________________________________________________________________')
    console.log('columna_sobre: ' + columna_sobre)
    console.log('columna_de: ' + columna_de)
    console.log('chartDonut: ' + chartDonut)
    console.log('data_grid: ' + data_grid)
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    var columnasContadas = [];
    data_grid.forEach(row => {
      if (row[columna_sobre] == "")
        row[columna_sobre] = this.translateService.instant('sinAsignar');
      var index = columnasContadas.indexOf(row[columna_sobre]);
      if (index <= 0) {
        columnasContadas.push(row[columna_sobre]);
        nuevoArray.push([row[columna_sobre], row[columna_de]]);
      }
      else {
        nuevoArray[index][1] = nuevoArray[index][1] + row[columna_de];
      }
    });

    //Ordenamos el grid por valor
    nuevoArray = nuevoArray.sort((s1, s2) => {
      if (s1[1] > s2[1]) {
        return -1;
      } else {
        return 1;
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      newData = nuevoArray;
    } else {
      var arrayParaEnsenar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnsenar.push([this.translateService.instant('otros'), totalOtros]);
      newData = arrayParaEnsenar;
    }

    var names = {};
    newData.forEach(row => {
      if (row[0].length > 30)
        names[row[0]] = row[0].substring(0, 27) + "... (" + this.myFunctions.secondsTo_HH_MM_SS(row[1]) + ")";
      else
        names[row[0]] = row[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(row[1]) + ")";
    }, this);

    var data = {
      columns: newData,
      names: names
    };

    this.myCharts.load(chartDonut, data);
  }


  private get_resumen_piezas_turno(historico_contador_filtrado, fechaIni, fechaFin, tipoTurnos, mostrarEnHoras, tipoContador) {

    // COMO LA TABLA DEL HISTORICO PUEDE TENER MUCHOS DATOS, LA FILTRAMOS ANTES DE ENTRAR A HACER LAS SUMAS DE LOS TIEMPOS DE ESTADOS
    var historico_contador_filtrado: any = this.myFunctions.copy((historico_contador_filtrado as any).filter(
      f => (f.fechaTurno >= fechaIni &&
        f.fechaTurno <= fechaFin &&
        (tipoTurnos.includes(f.idTipoTurno) || tipoTurnos.length == 0) &&
        (f.idTipoTurno > 0))
    ));
    return this.calcular_piezas_turno(historico_contador_filtrado, mostrarEnHoras, tipoContador);
  }
  private calcular_piezas_turno(historico_contador_filtrado, mostrarEnHoras, tipoContador) {
    // SE IDENTIFICAN LOS ESTADOS DIFERENTES (por si se filtran varias maquinas) Y SE CREA LA ESTRUCTURA QUE SE VA A DEVOLVER
    var infPiezasTurno: any = {
      mañana: { buenas: 0.0, malas: 0.0 },
      tarde: { buenas: 0.0, malas: 0.0 },
      noche: { buenas: 0.0, malas: 0.0 }
    };

    // var idHmoarContados = [];
    // CON LA TABLA YA FILTRADA, EMPEZAMOS A HACER LAS SUMAS DE LOS ESTADOS NECESARIOS
    historico_contador_filtrado.forEach(
      row => {
        var turno = "";
        if (row.idTipoTurno == 1) {
          turno = "mañana"
        } else if (row.idTipoTurno == 2) {
          turno = "tarde"
        } else if (row.idTipoTurno == 3) {
          turno = "noche"
        }

        if (turno != "" &&
          ((tipoContador == 1 && (row.piezasBuenasAutocorregido > 0 || row.piezasMalasAutocorregido > 0)) || // Autocorregido
            (tipoContador == 2 && row.cantidadContador > 0))) { // Automatico  
          var buenas = row.piezasBuenasAutocorregido;
          var malas = row.malas;
          /*if (row.cantidadValidada > 0) { //pendiente de repaso!
            buenas = row.cantidadValidada;  //pendiente de repaso!
          } else if (row.cantidadAsignadaOperario > 0) {  //pendiente de repaso!
            buenas = row.cantidadAsignadaOperario;  //pendiente de repaso!
          } else  else if (row.cantidadContador > 0 && tipoContador == 2) {
            buenas = row.cantidadContador;
          }*/
          if (tipoContador == 2) {
            buenas = row.cantidadContador;
          }
          if (row.idEstado_pieza == 4 || row.idEstado_pieza == 5) {
            malas = buenas;
            buenas = 0;
          }

          var totales = buenas + malas;

          if (totales > 0) {
            if (!mostrarEnHoras) {
              infPiezasTurno[turno].buenas += buenas;
              infPiezasTurno[turno].malas += malas;
            } else {
              // if(!idHmoarContados.includes(row.idHistorico_maquinas_operaciones_relacionadas)){
              // al funcionar con horas, no se aplica lineas simultaneas ni se tiene en cuenta ponderaciones. La pieza ENTERA y todo su tiempo va a donde se han inputado.
              // (Aitor) Las piezas se contaran con su tiempo estimado, sin tener en cuenta OEE o ponderaciones.
              infPiezasTurno[turno].buenas += buenas * row.hmoar_tiempoEstimadoOperacion; // (buenas * row.hmoar_tiempoOperacion) / row.hmoar_cantidad; // totales;
              infPiezasTurno[turno].malas += malas * row.hmoar_tiempoEstimadoOperacion; // (malas * row.hmoar_tiempoOperacion) / row.hmoar_cantidad; //totales;
              // idHmoarContados.push(row.idHistorico_maquinas_operaciones_relacionadas);
              // }else{
              //   var a = 1;
              // }
            }
          }
        }
      });

    return infPiezasTurno;
  }
  //#endregion

}
